<template>
  <div class="business-by-point" :class="{ active }">
    <div class="business-by-point__info scroll ver">
      <div class="business-by-point__top">
        <button class="business-by-point__back btn btn-pretender" @click="$emit('setState', false)">
          <i class="fa-solid fa-arrow-left-long" />
          <span>Back to Geogrid</span>
        </button>
        <div v-if="pageState !== 'live'" class="business-by-point__main-business">
          <div class="business-by-point__name">{{ geogrid.attrs.businessName }}</div>
          <div class="business-by-point__address">{{ geogrid.attrs.businessAddress }}</div>
          <div class="business-by-point__search-term">
            <span>{{ geogrid.searchTerm }}</span>
          </div>
        </div>
        <p class="business-by-point__coordinates">
          <span>GPS: </span>
          <b>{{ coordsString }}</b>
          <button class="business-by-point__copy button-copy" @click="copyCoords">
            <i class="far fa-fw fa-copy" />
          </button>
        </p>
      </div>
      <div class="business-by-point__list">
        <spot-info
          v-for="place in places"
          :key="`place_${place.placeId}_${place.order}`"
          :place="place"
          @showCompetitorGeogrid="showCompetitorGeogrid" />
      </div>
    </div>
    <div class="business-by-point__map" ref="map" />
  </div>
</template>

<script>
import toastr from 'toastr'
import hotkeys from 'hotkeys-js'
import { mapState } from 'vuex'

import { copyToClipboardWithAnimation } from 'common/copy_to_clipboard'
import {
  DEFAULT_CENTER, DEFAULT_ZOOM, renderMarker
} from '../../../common/map_helpers'

import SpotInfo from './spot_info'

export default {
  components: { SpotInfo },
  props: {
    active: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      map: null,
      places: [],
      markers: [],
      coordsString: null
    }
  },
  computed: mapState(['geogridSet', 'geogrid', 'pageState']),
  watch: {
    geogridSet() {
      this.$emit('setState', true)

      this.$nextTick(() => {
        this.drawMarkers()
      })
    }
  },
  methods: {
    copyCoords(e) {
      copyToClipboardWithAnimation({ element: e.currentTarget, textToCopy: this.coordsString })
    },
    initMap() {
      this.map = new google.maps.Map(this.$refs.map, {
        center: DEFAULT_CENTER,
        zoom: DEFAULT_ZOOM,
        scaleControl: true
      })
    },
    drawMarkers() {
      const el = this.geogridSet
      this.coordsString = `${el.latitude}, ${el.longitude}`
      this.markers.forEach((m) => {
        google.maps.event.clearListeners(m, 'click')
        m.setMap(null)
      })
      this.markers = []

      this.places = el.placesSpots
        .sort((a, b) => (a.order > b.order ? 1 : -1))
        .map((b) => ({ ...b, selected: false }))

      if (this.places.length) {
        const bounds = new google.maps.LatLngBounds()

        // If place does not contain address it also usually has odd coordinates, as result
        // we sometimes draw points in the middle of ocean. Lets skip those places on map.
        this.places
          .filter((p) => p.address != null && p.lat != null && p.lon != null)
          .forEach((p) => {
            const marker = renderMarker(
              parseFloat(p.lat, 10),
              parseFloat(p.lon, 10),
              this.map,
              p.order
            )

            const location = new google.maps.LatLng(marker.position.lat(), marker.position.lng())
            bounds.extend(location)

            marker.addListener('click', () => {
              const selectedPlace = this.places.find((v) => v.selected)
              if (selectedPlace) selectedPlace.selected = false
              p.selected = true // eslint-disable-line no-param-reassign
            })

            this.markers.push(marker)
          })
        if (this.markers.length) {
          this.map.fitBounds(bounds)
        } else {
          this.map.setCenter(new google.maps.LatLng(el.latitude, el.longitude))
        }
      } else {
        toastr.info('Sorry! no places for this spot.')
      }
    },
    handleEsc(event, handler) {
      if (handler.key === 'esc') {
        event.preventDefault()
        this.$emit('setState', false)
      }
    },
    showCompetitorGeogrid(event) {
      this.$emit('showCompetitorGeogrid', event)
      this.$emit('setState', false)
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initMap()
    })
    hotkeys('esc', this.handleEsc)
  },
  destroyed() {
    hotkeys.unbind('esc', this.handleEsc)
  }
}
</script>
