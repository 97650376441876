<template>
  <div class="media-gallery">
    <div class="media-gallery-pictures">
      <template v-if="imagesCount">
        <GalleryHead />
        <GalleryFilter
          :gallery-tags="imageTags"
          :selected-gallery-tags="selectedTags"
          @toggleGalleryTag="selectTag" />
        <GalleryImages />
      </template>
      <template v-else-if="imagesCount !== null">
        <NoPictures />
      </template>
    </div>

    <ExportWidget />
    <ScheduledWidget />

    <Droparea ref="droparea" />

    <SlidingPanel
      :show-panel="showScheduledPanel"
      @closeSlidingPanel="toggleScheduledPannel">
      <Scheduled
        :categories="categories"
        :images-ids="selectedImages"
        @closeScheduledPanel="toggleScheduledPannel" />
    </SlidingPanel>

    <SlidingPanel
      :show-panel="showExportPanel"
      @closeSlidingPanel="toggleExportPannel">
      <ExportMedia @closeExportPanel="toggleExportPannel" />
    </SlidingPanel>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { subscribe, removeChannel } from 'vue_widgets/audit_report_configurations/helpers'

import GalleryHead from './gallery_head'
import GalleryFilter from './gallery_filter'
import GalleryImages from './gallery_images'
import NoPictures from './no_pictures'
import Droparea from './droparea'
import Scheduled from './scheduled'
import ExportMedia from './export_media'
import ScheduledWidget from './scheduled_widget/scheduled_widget'
import SlidingPanel from '../components/sliding_panel'
import ExportWidget from './export_widget'

export default {
  components: {
    GalleryHead,
    GalleryFilter,
    GalleryImages,
    NoPictures,
    Droparea,
    Scheduled,
    ExportMedia,
    ScheduledWidget,
    SlidingPanel,
    ExportWidget
  },
  props: {
    categories: { type: Array, default: () => []}
  },
  created() {
    this.channel = subscribe('ImagesUpdateTagsChannel', (payload) => {
      if (payload.type === 'update_tags') {
        toastr.success('Saving tags completed.')
        this.setTags(payload.tags)
        this.setPage(0)
        this.fetchImages()
      }
    })
  },
  beforeDestroy() {
    removeChannel(this.channel)
  },
  mounted() {
    this.fetchImages()
  },
  computed: mapState([
    'imagesCount',
    'selectedImages',
    'showScheduledPanel',
    'showExportPanel',
    'imageTags',
    'selectedTags'
  ]),
  methods: {
    ...mapActions(['fetchImages', 'removeSelected', 'selectTag']),
    ...mapMutations(['setTags', 'toggleScheduledPannel', 'toggleExportPannel', 'setPage'])
  }
}
</script>
