(($) => $.extend($.fn, {
  turboSelect(params) {
    return this.each(() => {
      const $el = $(this)
      if ($el.data('select2') === undefined && $el.next().hasClass('select2-container')) {
        $el.next().remove()
      }
      $el.select2(params)
    })
  }
}))($ || jQuery)
