<template>
  <div class="geobooster-widgets-edit-form__line" @keyup="handleKeypress">
    <div
      class="geobooster-widgets-edit-form__line align-items-end gap-2 flex-row">
      <Component
        v-model="inputObject.value"
        :values="displayedValues"
        :is="inputObject.component"
        :key="inputObject.name"
        :error="inputObject.error"
        :error-text="inputObject.errorText"
        :label="inputObject.label"
        :name="inputObject.name"
        :allow-empty="inputObject.allowEmpty"
        :open-direction="inputObject.openDirection"
        :content-min-height="inputObject.contentMinHeight"
        :placeholder="inputObject.placeholder"
        :disabled="inputObject.disabled"
        :preselect-first="inputObject.preselectFirst"
        @select="handleSelect" />
      <button
        ref="addButton"
        class="geobooster-widgets-edit-form__button geobooster-widgets-edit-form__button--inline btn"
        :class="{ disabled: disableAddButton }"
        :disabled="disableAddButton"
        @click="$emit('addFilter')">
        Add
      </button>
    </div>
    <div
      v-if="selectedFilters.length !== 0"
      class="d-flex flex-wrap justify-content-start gap-2">
      <div
        v-for="(selectedFilter, index) in selectedFilters"
        class="geobooster-widgets-edit-form__tag geobooster-widgets-edit-form__tag--inline">
        <span>{{ selectedFilter }}</span>
        <a
          href="#"
          class="geobooster-widgets-edit-form__cancel"
          @click.prevent="$emit('removeFilter', index)">
          <i class="far fa-times" />
        </a>
      </div>
      <div class="geobooster-widgets-edit-form__spacer" />
      <button
        class="geobooster-widgets-edit-form__clear btn btn-pretender"
        @click="$emit('clearFilter')">
        <i class="far fa-eraser" />
        <span>Clear {{ filterText }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import CustomInput from '../../../components/custom_input'
import CustomSelect from '../../../components/custom_select'

export default {
  components: {
    CustomInput,
    CustomSelect
  },
  props: {
    input: {
      type: Object,
      required: true
    },
    selectedFilters: {
      type: Array,
      required: true
    },
    filterName: {
      type: String,
      required: true
    },
    filterText: {
      type: String,
      required: true
    },
    disableAddButton: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    inputObject() {
      return this.input
    },
    displayedValues() {
      return this.inputObject?.values?.filter((option) => !this.selectedFilters.includes(option.value))
    }
  },
  methods: {
    handleKeypress(e) {
      if (e.key === 'Enter') {
        this.$emit('addFilter')
      }
    },
    handleSelect() {
      setTimeout(() => {
        this.$refs.addButton?.focus()
      }, 100)
    }
  }
}
</script>
