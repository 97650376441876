<template>
  <transition name="sliding-panel" @afterEnter="afterEnter" @beforeLeave="beforeLeave">
    <div v-if="showPanel" :class="['sliding-panel-backdrop', { disabled: backdropDisable }]" @click="closeSlidingPanel">
      <div :class="['sliding-panel', position]" @click.stop>
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    position: {
      type: String,
      default: 'right'
    },
    showPanel: {
      type: Boolean,
      default: false
    },
    backdropDisable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeSlidingPanel() {
      this.$emit('closeSlidingPanel')
    },
    afterEnter() {
      this.$emit('shown')
    },
    beforeLeave() {
      this.$emit('beforeHide')
    }
  },
  watch: {
    showPanel(bool) {
      document.body.classList.toggle('noscroll', bool)
    }
  }
}
</script>
