<template>
  <div class="gallery-widget export-widget">
    <template v-if="mediaExportsList.length">
      <div class="gallery-widget-head">
        <h2>Exports</h2>
        <div class="dropdown">
          <div class="dropdown-head" data-toggle="dropdown">
            <p>{{ filter.label }}</p>
            <i class="far fa-chevron-down" />
          </div>
          <div class="dropdown-menu">
            <a
              @click.prevent="selectOption(option)"
              class="dropdown-item"
              href="#"
              v-for="option in filterOptions"
              :key="option.label">{{ option.label }}</a>
          </div>
        </div>
        <div class="scroll-controls ml-auto p-2">
          <button @click="scrollBack"><i class="far fa-play" /></button>
          <button @click="scrollForward"><i class="far fa-play" /></button>
        </div>
      </div>
      <div
        ref="track"
        @wheel="horizontalScroll"
        class="export-widget-body scroll hor">
        <div
          @click="downloadArchive(item)"
          :class="[
            'export-item ',
            {
              failed: item.processStatus === 'failed',
              done: item.processStatus === 'done',
            },
          ]"
          v-for="item in mediaExportsList"
          :key="item.id">
          <div class="export-item-head">
            <i v-if="item.processStatus === 'done'" class="far fa-archive" />
            <i
              v-else-if="item.processStatus === 'failed'"
              class="far fa-info-circle" />
            <img v-else src="~/rombus-loader.gif">
            <p class="title">
              <template v-if="item.processStatus === 'done'">
                Export archive
              </template>
              <template v-else-if="item.processStatus === 'failed'">
                Failed
              </template>
              <template v-else>In progress</template>
            </p>
          </div>
          <div class="export-item-info">
            <p><i class="far fa-map-marker-alt" /> {{ item.locationCount }}</p>
            <p><i class="far fa-image-polaroid" /> {{ item.mediaCount }}</p>
          </div>
          <p><span>Create date:</span> {{ formatDate(item.createdAt) }}</p>

          <div class="export-item-layout" />
        </div>

        <div v-if="loading" class="export-item loading">
          <img src="~/rombus-loader.gif">
        </div>
      </div>
    </template>
    <template v-else>
      <div class="empty">
        <h2>You haven't exported anything yet</h2>
        <p>You can do this after selecting the images you are interested in</p>
      </div>
    </template>
  </div>
</template>

<script>
import axios from 'axios'
import { App } from 'common/rails'
import moment from 'moment'

export default {
  data() {
    return {
      mediaExportsList: [],
      filter: 'By date',
      filterOptions: [{ label: 'By date', value: 'date' }, { label: 'By status', value: 'status' }],
      page: 1,
      allExports: 0,
      loading: false
    }
  },
  created() {
    this.selectOption(this.filterOptions[0])

    this.cable = App.cable.subscriptions.create(
      { channel: 'ImagesExportChannel' },
      {
        received: ({ payload, type }) => {
          const item = this.mediaExportsList.find((i) => i.obfuscatedId === payload.obfuscatedId)

          if (type === 'initiated' && !item) {
            this.mediaExportsList.unshift(payload)
          }

          if (type === 'updated_status' && item) {
            item.processStatus = payload.status
          }

          if (type === 'done' && item) {
            item.fileUrl = payload.fileUrl
            item.fileSize = payload.fileSize
            item.processStatus = payload.processStatus
          }
        }
      }
    )
    $(document).one('turbolinks:visit', () => {
      App.cable.subscriptions.remove(this.cable)
    })
  },
  methods: {
    fetchExportItems() {
      this.loading = true

      axios.get('/api/images_exports', {
        params: {
          order_by: this.filter.value,
          page: this.page
        }
      }).then((res) => {
        this.loading = false
        this.mediaExportsList = [...this.mediaExportsList, ...res.data.rows]
        this.allExports = parseInt(res.headers['x-total-count'], 10)
      })
    },
    selectOption(option) {
      this.filter = option
      this.page = 1
      this.mediaExportsList = []
      this.fetchExportItems()
    },
    formatDate(date) {
      return moment(date).format('DD.MM.YY')
    },
    scrollBack() {
      this.$refs.track.scrollLeft -= 200
    },
    scrollForward() {
      const { track } = this.$refs
      track.scrollLeft += 200

      if (track.offsetWidth + track.scrollLeft >= track.scrollWidth - 1) {
        if (this.mediaExportsList.length !== this.allExports) {
          this.page++
          this.loading = true
          this.fetchExportItems(this.filterOptions[0])
        }
      }
    },
    horizontalScroll(event) {
      const modifier = 2
      const { track } = this.$refs
      const startPos = track.scrollLeft

      if (event.deltaY !== 0) {
        track.scrollLeft += modifier * event.deltaY
      }

      if (event.deltaX !== 0) {
        track.scrollLeft += modifier * event.deltaX
      }

      if (startPos !== track.scrollLeft) {
        event.preventDefault()
      }

      if (track.offsetWidth + track.scrollLeft >= track.scrollWidth - 1 && this.mediaExportsList.length !== this.allExports) {
        this.page++
        this.loading = true
        this.fetchExportItems(this.filterOptions[0])
      }
    },
    downloadArchive(item) {
      if (item.processStatus === 'done') {
        window.location.href = item.fileUrl
      }
    }
  }
}
</script>
