<template>
  <div :class="['table-actions d-flex justify-content-between flex-wrap', { sticky: isSticky }]">
    <Checkbox
      :value="checked"
      :part-selected="partSelected.isPartSelected"
      @input="onChecked(false)">
      <div v-if="partSelected.selectedCount === partSelected.length" class="d-inline-flex align-items-center gap-2">
        <span>{{ partSelected.captionAll }}</span>
        <button class="btn btn-link" @click.prevent="onChecked(false)">Unselect</button>
      </div>
      <div v-else class="d-inline-flex align-items-center gap-2">
        <span>{{ partSelected.caption }}</span>
        <button class="btn btn-link" @click.prevent="onChecked(true)">Select All</button>
        <button class="btn btn-link" @click.prevent="onChecked(false)">Unselect</button>
      </div>
    </Checkbox>
    <div class="actions gap-4">
      <DropdownTags v-if="!onlyDeleted"
        :tag-options="tagOptions"
        @saveTags="saveTags"
        text="Add tags for all selected configs."
        class="m-0">
        <span class="font-size-14">Tags</span>
        <i class="far fa-chevron-down icon font-size-14" />
      </DropdownTags>
      <button class="btn btn-secondary btn-sm rounded gap-2" :disabled="loadingCsv" @click="$emit('downloadCsv')">
        <span v-if="loadingCsv" class='wrap-icon'><i class="fa-duotone fa-solid fa-spinner-third fa-spin" /></span>
        <span v-else class='wrap-icon'><i class='far fa-file-csv' /></span>
        <span class='text'>CSV</span>
      </button>
      <button v-if="!onlyDeleted" type="button" class="btn btn-sm gap-2 rounded bg-transparent" @click="runGeogrids">
        <i class="far fa-play" />
        <span class="font-size-12">Run now</span>
      </button>
      <button v-if="onlyDeleted" type="button" class="btn btn-sm gap-2 rounded bg-transparent" @click="restoreGeogrids">
        <i class="far fa-sync" />
        <span class="font-size-12">Restore</span>
      </button>
      <button v-if="!onlyDeleted" type="button" class="btn btn-sm gap-2 btn-outline-danger rounded bg-transparent" @click="deleteGeogrids">
        <i class="far fa-trash" />
        <span class="font-size-12">Delete</span>
      </button>
    </div>
  </div>
</template>

<script>
import Checkbox from '../../../components/checkbox'
import DropdownTags from '../../../gmb/locations/index/dropdown_tags'

export default {
  components: { Checkbox, DropdownTags },
  props: {
    checked: Boolean,
    configs: Array,
    tagOptions: Array,
    rowsTotalCount: Number,
    partSelected: Object,
    onlyDeleted: Boolean,
    loadingCsv: Boolean
  },
  data() {
    return {
      isSticky: false
    }
  },
  mounted() {
    const tableActions = document.querySelector('.table-actions')
    if (tableActions) {
      document.addEventListener('scroll', () => {
        this.isSticky = window.scrollY >= tableActions.offsetTop - 1
      })
    }
  },
  methods: {
    onChecked(value) {
      this.$emit('onChecked', value)
    },
    runGeogrids() {
      this.$emit('runGeogrids')
    },
    deleteGeogrids() {
      this.$emit('deleteGeogrids')
    },
    restoreGeogrids() {
      this.$emit('restoreGeogrids')
    },
    saveTags(tags) {
      this.$emit('saveTags', tags)
    }
  }
}
</script>
