<template>
  <div>
    <span class="font-weight-bold text-dark">{{ mainCategory }}{{ secondaryCategories?.length ? ';' : '.' }}</span>
    <span v-if="secondaryCategories?.length">{{ secondaryCategories.join('; ') }}.</span>
  </div>
</template>
<script>
export default {
  props: {
    categories: {
      type: Array,
      required: true
    }
  },
  computed: {
    mainCategory() {
      return this.categories[0]
    },
    secondaryCategories() {
      return this.categories.slice(1)
    }
  }
}
</script>
