<template>
  <div :class="['chart-contaner', chartDirection]">
    <div class="chart" ref="chart" :style="{height: `${chartHeight}px`}">CHART</div>
    <div class="chart-legend">
      <ChartLegeng v-for="(legend, index) in legends" :key="index"
        :color="legend.color"
        :name="legend.name"
        :description="legend.description"
        :values="legend.values"
        :percentage="legend.percentage"
        :compare="compare" />
    </div>
  </div>
</template>

<script>
import camelCaseKeys from 'camelcase-keys-deep'
import { mapGetters } from 'vuex'
import Highcharts from 'highcharts'
import { humanizeRangeDate } from '../../../../common/datepicker'
import {
  getValues, getPercentage, chartOptionsDefault, compileTooltip
} from './helpers'
import ChartLegeng from './chart_legend'

const DESCRIPTIONS = {
  queries_direct: 'Was shown when searching for the location directly.',
  queries_indirect: 'Was shown for a related or categorical search.',
  queries_chain: 'Was shown as a result of a search for the chain it belongs to or the brand it sells.'
}

export default {
  components: { ChartLegeng },
  props: {
    chartType: {
      type: String,
      default: 'column'
    },
    chartIds: {
      type: Array,
      required: true
    },
    chartDirection: {
      type: String,
      default: ''
    },
    chartHeight: {
      type: Number,
      default: 300
    },
    chartColors: {
      type: Array,
      default() { return [] }
    }
  },
  data() {
    return {
      legends: []
    }
  },
  computed: mapGetters(['insightsDateRanges', 'stats', 'compare']),
  methods: {
    initChart(data) {
      if (this.chart) this.chart.destroy()

      const { ranges } = this.insightsDateRanges
      let subtitle = humanizeRangeDate(ranges[0])
      if (this.compare) subtitle += ` vs ${humanizeRangeDate(ranges[1])}`

      const legends = []
      const pieData = {
        size: '100%',
        innerSize: '40%',
        center: ['50%', '50%'],
        data: []
      }
      const pieCompare = {
        size: '65%',
        innerSize: '40%',
        center: ['16%', '50%'],
        data: []
      }
      const pieSeries = []
      let indexColor = 0

      data.forEach((element) => {
        if (!this.chartIds.includes(element.id)) return
        legends.push({
          name: element.name,
          description: DESCRIPTIONS[element.id],
          color: this.chartColors[indexColor],
          values: getValues(element, this.compare),
          percentage: getPercentage(camelCaseKeys(element))
        })

        pieData.data.push({
          name: element.name,
          color: this.chartColors[indexColor],
          y: element.sum,
          ranges: ranges[0]
        })

        if (this.compare) {
          pieCompare.data.push({
            name: element.name,
            color: `${this.chartColors[indexColor]}80`,
            y: element.sum_compare,
            ranges: ranges[1]
          })
        }
        indexColor++
      })

      this.legends = legends
      pieSeries.push(pieData)
      if (this.compare) {
        pieData.center = ['73%', '50%']
        pieSeries.push(pieCompare)
      }

      const tooltip = compileTooltip(true)
      this.chart = Highcharts.chart(this.$refs.chart, {
        ...chartOptionsDefault({
          subtitle,
          chartType: this.chartType,
          chartHeight: this.chartHeight
        }),
        plotOptions: {
          pie: {
            dataLabels: { enabled: false },
            states: {
              inactive: { opacity: 1 },
              hover: { enabled: false }
            }
          }
        },
        series: pieSeries,
        tooltip: tooltip
      })
    }
  },
  mounted() {
    this.initChart(this.stats)
  },
  watch: {
    stats: 'initChart'
  }
}
</script>
