<template>
  <div>
    <WebhooksLegacyNotice v-if="isLegacyWebhookUrlPresent" :webhook-url="legacyWebhookUrl" @clearLegacyWebhook="clearLegacyWebhook" />
    <WebhooksEdit v-if="isCreating"
      :webhooks-api-url="webhooksApiUrl"
      @newWebhookCreated="refetchTable"
      @save="showTable"
      @close="showTable" />
    <WebhooksTable
      ref="table"
      :webhooks-api-url="webhooksApiUrl"
      :show-new-webhook-button="!isCreating"
      @newWebhook="createNewWebhook" />
  </div>
</template>

<script>

import axios from 'axios'
import WebhooksLegacyNotice from './webhook_legacy_notice'
import WebhooksTable from './webhooks_table'
import WebhooksEdit from './edit'

export default {
  props: {
    legacyWebhookUrl: { Type: String, required: false, default: '' },
    webhooksApiUrl: { type: String, required: true }
  },
  components: {
    WebhooksLegacyNotice,
    WebhooksTable,
    WebhooksEdit
  },
  data() {
    return {
      isLegacyWebhookUrlPresent: this.legacyWebhookUrl,
      isCreating: false
    }
  },
  created() {
    this.columns = [
      {
        key: 'url',
        title: 'Url'
      },
      {
        key: 'events',
        title: 'Events'
      },
      {
        key: 'actions',
        title: 'Actions'
      }
    ]
  },
  methods: {
    refetchTable() {
      this.$refs.table.refetchTable()
      this.showTable()
    },
    clearLegacyWebhook() {
      return axios
        .post(`${this.webhooksApiUrl}/disable_legacy`)
        .then(() => {
          this.isLegacyWebhookUrlPresent = false

          toastr.success('Webhook deleted successfully')
        })
    },
    showTable() {
      this.isCreating = false
    },
    createNewWebhook() {
      this.isCreating = true
    }
  }
}
</script>
