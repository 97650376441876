<template>
  <div class='form-field'>
    <span class='caption'>Google region:</span>
    <b-form-radio-group
      class='custom-control-inline mt-1'
      v-model='language'
      :options='resultsIn' />
  </div>
</template>

<script>

export default {
  name: 'LanguageResults',
  data() {
    return {
      resultsIn: ['Global', 'Local']
    }
  },
  computed: {
    language: {
      get() { return this.$store.state.localLanguageEnabled },
      set(val) { this.$store.commit('localLanguageEnabled', val) }
    },
    languageFlag() {
      return this.language !== 'Global'
    }
  }
}
</script>
