<template>
  <div>
    <ClipLoader v-if="syncState === 'initial'" />

    <template v-if="syncState === 'success'">
      <ServiceCategory v-for="category in categoriesWithServiceItems" :key="category.categoryId" :category="category" />
    </template>

    <div v-if="syncState === 'failed'" class="alert alert-danger" role="alert">
      {{ errorMsg }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

import { DEFAULT_ERROR_MESSAGE } from 'common/constants'
import ServiceCategory from './category'

export default {
  components: { ClipLoader, ServiceCategory },
  data() {
    return {
      syncState: 'initial'
    }
  },
  computed: mapState(['serviceList', 'categoriesWithServiceItems']),
  created() {
    this.errorMsg = DEFAULT_ERROR_MESSAGE
    this.$store.dispatch('fetchBase')
      .then(() => { this.syncState = 'success' })
      .catch(() => { this.syncState = 'failed' })
  }
}
</script>
