<template>
  <div class="gg-requests">
    <h2>Geogrid Requests</h2>

    <GeogridRequestFilter @applyFilter="applyFilter" />

    <VueSlimTable
      ref="table"
      :source="tableSource"
      :columns="columns"
      class="responsive-table">
      <template #row="{ row }">
        <TableRow :row="row" />
      </template>
    </VueSlimTable>
  </div>
</template>

<script>
import TableRow from './table_row'
import GeogridRequestFilter from './filter'

export default {
  props: {
    wlcDomain: { type: String, required: true }
  },
  components: { TableRow, GeogridRequestFilter },
  data() {
    return {
      title: '',
      filters: {}
    }
  },
  created() {
    this.url = `/api${window.location.pathname}`
    this.columns = [
      { title: 'Business', key: 'business' },
      { title: 'Search term', key: 'keyword' },
      { title: 'Site', key: 'site' },
      { title: 'Client', key: 'client' },
      { title: 'Created at', key: 'created_at' },
      { title: 'Geogrid', key: 'geogrid' }
    ]
  },
  methods: {
    applyFilter(filters) {
      this.filters = filters
      this.$refs.table.refetch()
    },
    tableSource(params) {
      return axios.get(
        `/api${window.location.pathname}`,
        { params: { ...params, ...this.filters } }
      ).then((res) => res.data)
    }
  }
}
</script>
