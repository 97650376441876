import VueForm from 'vue_widgets/gmb/service_lists'
import createStore from 'vue_widgets/gmb/service_lists/store'

Styxie.Initializers.GmbServiceLists = {
  show({ locationCategories }) {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-service-item-form'),
        store: createStore({ locationCategories }),
        render: (h) => h(VueForm)
      })
    })
  }
}
