<template>
  <div class="form-group row">
    <label class="col-12 col-sm-3 col-form-label text-sm-right" for="location_ids">Locations</label>
    <div class="col-12 col-sm-8 col-lg-6">
      <select name="" id="location_ids" ref="select" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

const PER_PAGE = 10

export default {
  computed: mapState(['apiOrgLocationsPath', 'preselectedLocations']),
  mounted() {
    const $select = $(this.$refs.select)
    $select.turboSelect({
      width: '100%',
      dropdownAutoWidth: 'true',
      multiple: true,
      data: this.preselectedLocations.map(({ remoteId, text }) => ({ id: remoteId, text, selected: true })),
      ajax: {
        url: `${this.apiOrgLocationsPath}/index_for_select`,
        dataType: 'json',
        delay: 250,
        data(params) {
          return {
            search: params.term,
            page: params.page || 1,
            per_page: PER_PAGE
          }
        },
        processResults(results) {
          return {
            results: results,
            pagination: { more: results.length === PER_PAGE }
          }
        }
      }
    }).change(() => {
      this.$emit('selectChanged', $select.select2('data').map((el) => el.id))
    }).trigger('change')
  }
}
</script>
