<template>
  <div v-if="schedule" class="scheduled-publications" @dragover.prevent="setRemovingImage(true)">
    <a href="/gallery" class="scheduled-publications-backlink"><i class="far fa-arrow-left mr-2" />Media Gallery</a>
    <h1>
      The Schedule of Publications
      <span v-if="schedule.imagesCount" class="sub">{{ schedule.imagesCount }}</span>
    </h1>
    <div :class="['scheduled-publications-info', {'failed': schedule.processStatus === 'failed'}]">
      <ScheduleInfo />

      <div class="d-flex flex-column">
        <p v-if="schedule.processStatus === 'processing'" class="mb-2">
          <i class="color-primary far fa-spinner-third fa-spin mr-2" />
          <span class="text-mute">Processing</span>
        </p>
        <button
          @click="deleteSchedule(schedule.obfuscatedId)"
          class="btn btn-danger with-icon">
          <i class="far fa-trash" />Delete
        </button>
      </div>
    </div>

    <ScheduleTable v-if="schedule.processStatus !== 'failed'" />

    <div v-else class="alert alert-danger" role="alert">
      <div class='message'>
        Failed to create media publishing schedules. Please delete this schedule and try again.
      </div>
    </div>

    <div :class="['backdrop', { show: removingImage }]"
      @drop.prevent.stop="removeImage(false)"
      @dragover.prevent.stop="setRemovingImage(true)"
      @dragleave.prevent.stop="setRemovingImage(false)">
      <i class="far fa-times-circle" />
    </div>
  </div>
</template>

<script>
import { App } from 'common/rails'
import { mapState, mapMutations, mapActions } from 'vuex'

import ScheduleInfo from './show/info'
import ScheduleTable from './show/table'

export default {
  components: { ScheduleInfo, ScheduleTable },
  created() {
    this.cable = App.cable.subscriptions.create({
      channel: 'Gmb::MediaChannel'
    }, {
      received: ({ payload, type }) => {
        const { obfuscatedId } = this.schedule
        if (type === `updated_status_${obfuscatedId}`) {
          this.updateScheduleProperty({
            processStatus: payload.status
          })
        }

        if (type === `updated_media_${obfuscatedId}`) {
          this.addMediaToSchedule(payload)
          this.updateScheduleProperty({
            mediaCount: this.schedule.mediaCount + 1
          })
        }

        if (type === `updated_media_schedule_${obfuscatedId}`) {
          this.updateSchedule(payload)
        }
      }
    })
    $(document).one('turbolinks:visit', () => {
      App.cable.subscriptions.remove(this.cable)
    })
  },
  computed: mapState(['schedule', 'removingImage']),
  methods: {
    ...mapMutations([
      'updateSchedule',
      'updateScheduleProperty',
      'addMediaToSchedule',
      'setRemovingImage'
    ]),
    ...mapActions(['deleteSchedule', 'removeImage'])
  }
}
</script>
