<template>
  <div class="geobooster-widgets-edit-form">
    <div class="geobooster-widgets-edit-form__line align-items-end gap-2 flex-row">
      <CustomSelect
        v-model="getBusinessField.value"
        :key="getBusinessField.name"
        :values="displayedValues"
        :error="getBusinessField.error"
        :error-text="getBusinessField.errorText"
        :label="getBusinessField.label"
        :name="getBusinessField.name"
        :placeholder="'Select business'"
        :allow-empty="true" />
      <button
        class="geobooster-widgets-edit-form__button geobooster-widgets-edit-form__button--inline btn btn--rem"
        :class="{ disabled: disableAddButton }"
        :disabled="disableAddButton"
        @click="addBusiness">
        Add
      </button>
    </div>
    <div
      v-if="fields.selectedBusinesses.length !== 0"
      class="geobooster-widgets-edit-form__line flex-column gap-2">
      <div
        v-for="(selectedBusiness, index) in formattedSelectedBusinesses"
        :key="selectedBusiness.value"
        class="geobooster-widgets-edit-form__tag">
        <div class="geobooster-widgets-edit-form__business-info">
          <span>{{ selectedBusiness.text }}</span>
          <span v-if="selectedBusiness.address">Address: {{ selectedBusiness.address }}</span>
          <span v-else>Place id: {{ selectedBusiness.placeId }}</span>
        </div>
        <a
          href="#"
          class="geobooster-widgets-edit-form__cancel"
          @click.prevent="removeBusiness(index)">
          <i class="far fa-times" />
        </a>
      </div>
    </div>
    <div v-else class="geobooster-widgets-edit-form__line">
      <p>Please add at least one business to see moments</p>
    </div>
  </div>
</template>

<script>
import { runSwal } from 'common/delete_with_swal'
import CustomSelect from '../../../components/custom_select'

export default {
  components: {
    CustomSelect
  },
  props: {
    fields: {
      type: Object,
      required: true
    },
    activePageFilters: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  methods: {
    addBusiness() {
      const businessToAdd = this.fields.businesses.value.trim()

      if (!this.fields.selectedBusinesses.includes(businessToAdd)) {
        const newSelectedBusinesses = [businessToAdd, ...this.fields.selectedBusinesses]
        this.$emit('updateBusinesses', newSelectedBusinesses)
      }
    },
    removeBusiness(index) {
      const doRemoveBusiness = () => {
        const newSelectedBusinesses = [...this.fields.selectedBusinesses]
        newSelectedBusinesses.splice(index, 1)
        this.$emit('updateBusinesses', newSelectedBusinesses)
      }

      const categoriesToRemove = this.findRemovedCategories(index)

      if (!categoriesToRemove.length) {
        doRemoveBusiness()
        return
      }

      const confirmationPopupText = `
      <div class="text-left">
        <p>Following business-related categories will be removed from the widget filter settings:</p>
        <p>${categoriesToRemove.join(', ')}</p>
      </div>
      `

      runSwal(() => {
        doRemoveBusiness()
      }, { content: confirmationPopupText, buttons: ['Cancel', 'OK']})
    },
    findRemovedCategories(index) {
      const removedBusinessId = this.fields.selectedBusinesses[index]
      const removedCategories = new Set()

      this.activePageFilters.filters.forEach((pageFilter) => {
        pageFilter.categories.forEach((selectedCategory) => {
          if (removedCategories.has(selectedCategory)) return
          // Since selected categories are just strings, we need to find select option to which they relate
          // so we can get access to business ids this category relates to
          const selectOption = this.activePageFilters.fields.categories.values.find((category) => category.text === selectedCategory)
          // if this category only relates to the very business we're removing - count it
          if (selectOption && selectOption.businessIds.length === 1 && selectOption.businessIds[0] === removedBusinessId) {
            removedCategories.add(selectedCategory)
          }
        })
      })

      return Array.from(removedCategories)
    }
  },
  computed: {
    noBusinessSelected() {
      return !this.getBusinessField.value
    },
    selectedBusinessAlreadyAdded() {
      return this.fields.selectedBusinesses.includes(this.fields.businesses.value)
    },
    disableAddButton() {
      return this.selectedBusinessAlreadyAdded || this.noBusinessSelected
    },
    getBusinessField() {
      return this.fields.businesses
    },
    displayedValues() {
      return this.fields.businesses.values.filter((business) => !this.fields.selectedBusinesses.includes(business.value))
    },
    formattedSelectedBusinesses() {
      const formattedSelectedBusinesses = []

      this.fields.selectedBusinesses.forEach((selectedBusinessId) => {
        const businessOption = this.fields.businesses.values.find((business) => business.value === selectedBusinessId)
        formattedSelectedBusinesses.push({
          value: selectedBusinessId,
          ...businessOption
        })
      })
      return formattedSelectedBusinesses
    }
  }
}
</script>
