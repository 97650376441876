<template>
  <div class="tab-pane active" role="tabpanel">
    <FormEventPeriod title="Offer Title" />

    <div class="form-group row">
      <label class="col-12 col-sm-3 col-form-label text-sm-right" for="coupon_code">Coupon code (optional)</label>
      <div class="col-12 col-sm-8 col-lg-6">
        <input v-model="object.offer.couponCode" class="form-control" type="text" id="coupon_code">
      </div>
    </div>

    <div class="form-group row">
      <label class="col-12 col-sm-3 col-form-label text-sm-right" for="redeem_online_url">Link to redeem offer (optional)</label>
      <div class="col-12 col-sm-8 col-lg-6">
        <input v-model="object.offer.redeemOnlineUrl" class="form-control" type="text" id="redeem_online_url">
      </div>
    </div>

    <div class="form-group row">
      <label class="col-12 col-sm-3 col-form-label text-sm-right" for="terms_conditions">Terms and conditions (optional)</label>
      <div class="col-12 col-sm-8 col-lg-6">
        <input v-model="object.offer.termsConditions" class="form-control" type="text" id="terms_conditions">
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import FormEventPeriod from './common/period'

export default {
  components: { FormEventPeriod },
  computed: mapState(['object'])
}
</script>
