export default {
  data: () => ({
    pieChartColors: [
      '#A1B4FF',
      '#7698FF',
      '#377DFF',
      '#1A56DB',
      '#42389D',
      '#94DDB6',
      '#5FCB91',
      '#29BA6C',
      '#18A359',
      '#12864E',
      '#FACA15',
      '#E3A008',
      '#C27803',
      '#9F580A',
      '#723B13',
      '#CABFFD',
      '#AC94FA',
      '#9061F9',
      '#7E3AF2'
    ]
  })
}
