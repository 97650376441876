<template>
  <div class="coords">
    <a class="btn btn-link" @click="toggleMap">
      <i class="far fa-map-marker" />
      Set Geogrid default center
    </a>
    <div v-if="isMapOpened" class="coords-map">
      <CoordMap
        :google-map-api-key="googleMapApiKey"
        :initial-coords="{lat: parseFloat(orgLocationGeodata['lat']), lng: parseFloat(orgLocationGeodata['lng'])}"
        @setupGeodata="setupGeodata" />
      <SlidingPanel :show-panel="true" :backdrop-disable="true">
        <div class="settings-form">
          <div class="settings-form-box pb-2">
            <div class="settings-form-head">
              <h4>Geogrid default center</h4>
            </div>
          </div>

          <div class="scroll ver">
            <div class="settings-form-box pt-2">
              <Alert type="alert-info-secondary">
                This setting <b>will not</b> trigger any changes in your GMB. This is an internal {{ brandname }} setting for GeoGrid Searches.
              </Alert>
              <div class="form-field settings-form-field">
                <span class="caption">Address:</span>
                <p class="m-0 mt-2"><strong>{{ mapMarkerGeodata.formatted_address || 'Not set' }}</strong></p>
              </div>
              <div class="form-field settings-form-field">
                <span class="caption">Latitude:</span>
                <p class="m-0 mt-2"><strong>{{ mapMarkerGeodata.lat || 'Not set' }}</strong></p>
              </div>
              <div class="form-field settings-form-field">
                <span class="caption">Longitude:</span>
                <p class="m-0 mt-2"><strong>{{ mapMarkerGeodata.lng || 'Not set' }}</strong></p>
              </div>
            </div>
          </div>

          <div class="settings-form-control flex-wrap">
            <Alert type="alert-info-secondary w-100">
              <b>Please note</b>: this will not lead to update all existing scheduled geogrids configs.
            </Alert>
            <button @click='toggleMap' class="btn btn-primary-outline">Cancel</button>
            <button @click='saveLocation' :class="applyBtnClasses">Apply</button>
          </div>
        </div>
      </SlidingPanel>
    </div>
  </div>
</template>

<script>
import axiosTransform from 'common/axios'
import hotkeys from 'hotkeys-js'
import SlidingPanel from '../../../components/sliding_panel'
import Alert from '../../../components/alert'
import CoordMap from './map'

export default {
  components: { CoordMap, Alert, SlidingPanel },
  props: {
    googleMapApiKey: {
      type: String,
      default: null
    },
    userFormattedAddress: {
      type: String,
      default: null
    },
    apiOrgLocationPath: {
      type: String,
      required: true
    },
    coords: {
      type: Object,
      default: () => ({ lat: null, lng: null })
    }
  },
  data() {
    return {
      mapMarkerGeodata: {
        lat: null,
        lng: null,
        formatted_address: null,
        address_components: null
      },
      isMapOpened: false,
      orgLocationGeodata: {}
    }
  },
  created() {
    this.brandname = Styxie.WLC.brand || 'LocalViking'
  },
  mounted() {
    hotkeys('esc', (event, handler) => {
      if (handler.key === 'esc') {
        event.preventDefault()
        this.toggleMap()
      }
    })
    const { lat, lng } = this.coords
    this.orgLocationGeodata.lat = lat
    this.orgLocationGeodata.lng = lng
    this.mapMarkerGeodata.lat = lat
    this.mapMarkerGeodata.lng = lng
    this.mapMarkerGeodata.formatted_address = this.userFormattedAddress
    this.orgLocationGeodata.formatted_address = this.userFormattedAddress
  },
  computed: {
    applyBtnClasses() {
      const { lat, lng } = this.mapMarkerGeodata
      let btnClasses = 'btn btn-primary'
      if (!lat || !lng) {
        btnClasses += ' disabled'
      }

      return btnClasses
    }
  },
  methods: {
    toggleMap() {
      this.isMapOpened = !this.isMapOpened
      document.body.classList.toggle('noscroll', this.isMapOpened)
    },
    saveLocation() {
      axios.put(this.apiOrgLocationPath, {
        organizationsLocation: {
          userGridCenterLat: this.mapMarkerGeodata.lat,
          userGridCenterLng: this.mapMarkerGeodata.lng,
          userFormattedAddress: this.mapMarkerGeodata.formatted_address,
          userAddressComponents: this.mapMarkerGeodata.address_components
        }
      }, axiosTransform).then(() => {
        this.orgLocationGeodata.lat = this.mapMarkerGeodata.lat
        this.orgLocationGeodata.lng = this.mapMarkerGeodata.lng
        this.orgLocationGeodata.formatted_address = this.mapMarkerGeodata.formatted_address
        this.toggleMap()
      })
    },
    setupGeodata(data) {
      this.mapMarkerGeodata.lat = data.lat
      this.mapMarkerGeodata.lng = data.lng
      this.mapMarkerGeodata.formatted_address = data.formatted_address
      this.mapMarkerGeodata.address_components = data.address_components
    }
  }
}
</script>
