<template>
  <div>
    <appForm />
    <hr>
    <appTable />
  </div>
</template>

<script>
import appForm from './form'
import appTable from './table'

export default {
  components: { appForm, appTable }
}
</script>
