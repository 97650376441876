<template>
  <GroupMetrics v-if="hasPropertisData(parsedData, deviceBreakdownProps)"
    :properties="deviceBreakdownProps"
    :parsed-data="parsedData"
    :map-icons="mapIcons"
    :reverse="true">
    <template #title>Device Breakdown</template>
  </GroupMetrics>
</template>

<script>
import { mapState } from 'vuex'
import GroupMetrics from './group_metrics'
import { deviceBreakdownProps, mapIcons, hasPropertisData } from './helpers'

export default {
  components: { GroupMetrics },
  created() {
    this.mapIcons = mapIcons
    this.deviceBreakdownProps = deviceBreakdownProps
  },
  computed: mapState(['parsedData']),
  methods: {
    hasPropertisData
  }
}
</script>
