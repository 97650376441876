<template>
  <li class="list-group-item list-group-item-secondary">
    <div v-if="showForm">
      <fieldset :disabled="isLoading">
        <h5>Add services you offer and get discovered by customers</h5>
        <div class="custom-control custom-checkbox" v-for="({ displayName, serviceTypeId }) in availableServiceItems" :key="serviceTypeId">
          <input type="checkbox" class="custom-control-input" :id="serviceTypeId"
            :value="serviceTypeId"
            ref="checkbox">
          <label class="custom-control-label" :for="serviceTypeId">{{ displayName }}</label>
        </div>

        <hr>

        <h5>Custom services</h5>
        <p>Don’t see a service you offer? Create your own.</p>

        <CustomItemInput v-for="input in customInputs" ref="customInput" :key="input"
          :max-length="customInputMaxLength"
          @removeCustomInput="removeCustomInput(input)" />

        <button type="button" @click="toggleCustom" class="btn btn-link d-block text-primary mb-4">Add custom service</button>

        <button type="button" class="btn btn-primary" @click="onSave">
          <i v-if="isLoading" class="far fa-spin fa-spinner" />
          <template v-else>Save</template>
        </button>
        <button type="button" class="btn btn-link" @click="showForm = false">Cancel</button>
      </fieldset>
    </div>

    <button v-else type="button" class="btn btn-link text-primary" @click="showForm = true">Add Service Item</button>
  </li>
</template>

<script>
import CustomItemInput from './custom_item_input'

const uniqID = () => new Date().getTime()

export default {
  components: { CustomItemInput },
  props: {
    availableServiceItems: { type: Array, required: true },
    category: { type: Object, required: true }
  },
  data() {
    return {
      showForm: false,
      isLoading: false,
      customInputs: []
    }
  },
  methods: {
    onSave() {
      const newItems = []

      if (this.$refs.checkbox) {
        this.$refs.checkbox.forEach((c) => {
          if (c.checked) {
            newItems.push({
              isOffered: true,
              structuredServiceItem: { serviceTypeId: c.value }
            })
          }
        })
      }

      if (this.$refs.customInput) {
        this.$refs.customInput.forEach((input) => {
          if (input.description.length && input.description.length <= this.customInputMaxLength) {
            newItems.push({
              isOffered: true,
              freeFormServiceItem: {
                categoryId: this.category.categoryId,
                label: { displayName: input.description }
              }
            })
          }
        })
      }

      if (newItems.length) {
        this.isLoading = true
        this.$store.dispatch('addItems', newItems)
          .then(() => {
            this.isLoading = false
            this.showForm = false
            toastr.success('New Service Items successfully created!')
          })
      }
    },
    toggleCustom() {
      if (this.customInputs.length) {
        if (this.$refs.customInput[this.$refs.customInput.length - 1].description.length) return this.customInputs.push(uniqID())
        return this.customInputs.pop()
      }
      return this.customInputs.push(uniqID())
    },
    removeCustomInput(input) {
      this.customInputs = this.customInputs.filter((i) => i !== input)
    }
  },
  created() {
    this.customInputMaxLength = 120
  }
}
</script>
