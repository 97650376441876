/* eslint-disable no-param-reassign */
(($) => {
  $.ajax2 = (options) => {
    options.beforeSend = (xhr) => {
      xhr.setRequestHeader('X-CSRF-Token', $("meta[name='csrf-token']").attr('content'))
    }

    return $.ajax(options)
  }
})($)
/* eslint-enable no-param-reassign */
