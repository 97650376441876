<template>
  <div class="geobooster-widgets">
    <NoWidgets v-if="noWidgetsCreated" />
    <template v-else>
      <GeoboosterWidgetsFilter
        class="geobooster-widgets__filters"
        :fields="fields"
        @clearFilters="clearFilters" />
      <GeoboosterWidgetsTableView
        ref="table"
        class="geobooster-widgets__table"
        :table-source="fetchWidgets"
        :businesses="content.businesses"
        :widget-src="'filler'" />
    </template>
  </div>
</template>

<script>

import axios from 'axios'
import GeoboosterWidgetsTableView from './geobooster_widgets_table_view'
import GeoboosterWidgetsFilter from './geobooster_widgets_filter'
import NoWidgets from './no_widgets'

export default {
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  components: {
    GeoboosterWidgetsTableView,
    GeoboosterWidgetsFilter,
    NoWidgets
  },
  data: () => ({
    fields: {
      searchTerms: {
        name: 'searchTerms',
        value: '',
        defaultValue: '',
        component: 'InputSearch',
        placeholder: 'Search by name',
        label: 'Search terms',
        error: false,
        errorText: ''
      },
      business: {
        component: 'CustomSelect',
        name: 'business',
        value: [],
        defaultValue: '',
        // todo: add businesses here
        values: [],
        label: 'Business',
        multiple: true,
        error: false,
        errorText: '',
        native: false,
        allowEmpty: true,
        placeholder: 'Search by business'
      }
    },
    filterTimeout: null
  }),
  methods: {
    async fetchWidgets() {
      return axios({
        method: 'get',
        url: '/gb/ajax/widgets',
        params: {
          business_id: this.fields.business.value,
          widget_name: this.fields.searchTerms.value
        }
      })
        .then((response) => response.data)
    },
    refetchTable() {
      clearTimeout(this.filterTimeout)

      this.filterTimeout = setTimeout(() => {
        this.$refs.table.$refs.table.refetch()
      }, 500)
    },
    clearFilters() {
      Object.keys(this.fields).forEach((key) => {
        const field = this.fields[key]

        field.value = field.defaultValue
      })
    },
    setBusinessesFilterValues() {
      this.fields.business.values = [
        ...this.content.businesses.map((business) => ({
          value: business.id,
          text: business.name,
          description: [business.address, business.storeCode]
        }))
      ]
    }
  },
  computed: {
    filter() {
      return {
        searchTerms: this.fields.searchTerms.value,
        business: this.fields.business.value
      }
    },
    noWidgetsCreated() {
      return !this.content.anyWidgetExists
    }
  },
  watch: {
    filter: {
      deep: true,
      handler: 'refetchTable'
    }
  },
  mounted() {
    this.setBusinessesFilterValues()
  }
}
</script>
