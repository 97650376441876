<template>
  <div>
    <div class="d-flex align-items-center justify-content-between gap-4 mb-4">
      <strong class="text-dark">{{ competitorsCounterText }}</strong>
      <button type="button" class="btn btn-link gap-3" @click="clearSelectedCompetitors">
        <i class="far fa-eraser" />
        Clear competitors
      </button>
    </div>
    <div class="competitors-list">
      <div v-for="(competitor, index) in competitors" :key="competitor.id" :class="['competitors-list-item', { checked: competitor.checked }]">
        <Checkbox v-model="competitor.checked" @input="onChange" :disabled="!competitor.checked && isLimitReached">
          {{ index + 1 }}
        </Checkbox>
        <div class="d-flex gap-4">
          <div class="img-container">
            <img v-if="!competitor.isImageBroken && competitor.imageSrc" :src="competitor.imageSrc" alt="" @error="onImgError(competitor)">
            <i v-else class="far fa-image" />
          </div>
          <div class="d-flex flex-column gap-2">
            <span class="text-primary">{{ competitor.name }}</span>
            <p class="m-0">{{ competitor.formattedAddress }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LIMIT_COMPETITORS } from '../helpers'
import Checkbox from '../../components/checkbox'

export default {
  components: { Checkbox },
  props: {
    competitorsList: { type: Array, required: true },
    chosenCompetitors: { type: Array, default: () => []}
  },
  data() {
    return {
      competitors: []
    }
  },
  computed: {
    isLimitReached() {
      return this.competitors.filter((c) => c.checked).length >= LIMIT_COMPETITORS
    },
    competitorsCounterText() {
      return `Selected competitors: ${this.competitors.filter((c) => c.checked).length}`
    }
  },
  methods: {
    onChange() {
      this.$emit('onSelectCompetitors', this.competitors.filter((c) => c.checked))
    },
    onImgError(competitor) {
      competitor.isImageBroken = true
    },
    clearSelectedCompetitors() {
      this.competitors.forEach((c) => {
        c.checked = false
      })
      this.onChange()
    }
  },
  watch: {
    competitorsList: {
      immediate: true,
      handler() {
        this.competitors = this.competitorsList.map((c) => ({
          ...c,
          isImageBroken: false,
          checked: !!this.chosenCompetitors.find((chosenCompetitor) => chosenCompetitor.id === c.id)
        }))

        const chosenCompetitorsIds = this.chosenCompetitors.map(({ id }) => id)
        this.$emit('onSelectCompetitors', this.competitorsList.filter((competitor) => chosenCompetitorsIds.includes(competitor.id)))
      }
    }
  }
}
</script>
