<template>
  <div>
    <div class="d-flex align-items-lg-center justify-content-between flex-column flex-md-row gap-4 mb-4">
      <div>
        <h1 class="h2 m-0 font-weight-semi-bold color-dark">{{ headerTitle }}</h1>
        <span class="text-muted">{{ headerText }}</span>
      </div>
    </div>

    <CollectDataStep v-if="isDataStep" />
    <CollectCompetitorsStep v-else-if="configuration.status === 'searching_competitors' || configuration.status === 'choosing_competitors'" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CollectDataStep from './form/collect_data_step'
import CollectCompetitorsStep from './form/collect_competitors_step'
import { LIMIT_COMPETITORS } from './helpers'

export default {
  components: { CollectDataStep, CollectCompetitorsStep },
  computed: {
    ...mapState(['configuration', 'basePath']),
    isDataStep() {
      return !this.configuration.id ||
        this.configuration.status === 'initialized' ||
        this.configuration.status === 'scheduled' ||
        this.configuration.status === 'disabled'
    },
    headerTitle() {
      return this.isDataStep ? (`${this.configuration?.id ? 'Edit' : 'New'} Audit configuration`) : 'Competitors'
    },
    headerText() {
      return this.isDataStep ?
        '' :
        `Choose up to ${LIMIT_COMPETITORS}.`
    }
  }
}
</script>
