<template>
  <div class="schedule-ui">
    <span class="d-block h4 text-dark font-weight-semi-bold">Schedule</span>

    <slot name="description">
      <p class="mb-4 pt-2 text-muted">
        Set the audit frequency and the day of the week on which it will be generated.
      </p>
    </slot>

    <fieldset class="form-field mb-2 schedule report-configuration-properties">
      <div v-for="periodicity in periodicityOptions" :key="periodicity.value" class="field-check">
        <label class="m-0">
          <input type="radio"
            name="periodicity"
            :value="periodicity.value"
            v-model="scheduleAttributes.periodicity"
            @change="onChange">
          <i class="checker" />
          {{ periodicity.name }}
        </label>
      </div>
    </fieldset>

    <div class="col-gap-6 d-flex flex-md-nowrap flex-wrap row-gap-4">
      <div v-if="scheduleAttributes.periodicity === 'day_of_month'" class="form-field w-100 w-md-50">
        <span>Day of the Month:</span>
        <Multiselect
          v-model="scheduleAttributes.monthDay"
          :options="monthOptions"
          :searchable="false"
          :show-labels="false"
          :allow-empty="false"
          @input="onChange" />
      </div>
      <template v-else-if="scheduleAttributes.periodicity !== 'once'">
        <div v-if="scheduleAttributes.periodicity !== 'day_of_month'" class="form-field w-100">
          <span>Day of week:</span>
          <Multiselect
            v-model="scheduleAttributes.dayNumber"
            :options="daysOptions"
            track-by="value"
            label="name"
            :searchable="false"
            :show-labels="false"
            :allow-empty="false"
            @input="onChange" />
        </div>
        <div v-if="scheduleAttributes.periodicity !== 'day_of_month'" class="form-field w-100">
          <span>Week number:</span>
          <Multiselect
            v-model="scheduleAttributes.weekNumber"
            :options="weekOptions"
            track-by="value"
            label="name"
            :searchable="false"
            :show-labels="false"
            :allow-empty="false"
            :disabled="scheduleAttributes.periodicity === 'weekly'"
            :preselect-first="true"
            placeholder="-every week-"
            @input="onChange" />
          <small v-if="error['scheduleWeekNumber']" class="text-danger">{{ error['scheduleWeekNumber'].join(', ') }}</small>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { periodicityOptions, daysOptions, weekOptions } from '../helpers'

export default {
  components: { Multiselect },
  props: {
    value: { type: Object, required: true },
    error: { type: Object, default: () => ({}) }
  },
  created() {
    this.periodicityOptions = periodicityOptions
    this.daysOptions = daysOptions
    this.weekOptions = weekOptions
    this.monthOptions = Array.from({ length: 31 }, (_, index) => index + 1)
  },
  data() {
    return {
      scheduleAttributes: {
        periodicity: this.value.periodicity,
        dayNumber: daysOptions.find((opt) => opt.value === this.value.dayNumber),
        weekNumber: this.value.periodicity === 'weekly' ? null : this.getWeekNumberOption(this.value.weekNumber),
        monthDay: this.value.monthDay
      }
    }
  },
  methods: {
    onChange() {
      if (this.scheduleAttributes.periodicity === 'weekly') {
        this.scheduleAttributes.weekNumber = null
      } else if (this.scheduleAttributes.weekNumber === null) {
        this.scheduleAttributes.weekNumber = this.getWeekNumberOption(this.value.weekNumber)
      }

      this.$emit('input', {
        periodicity: this.scheduleAttributes.periodicity,
        dayNumber: this.scheduleAttributes.dayNumber.value,
        weekNumber: this.scheduleAttributes.weekNumber?.value,
        monthDay: this.scheduleAttributes.monthDay
      })
    },
    getWeekNumberOption(weekNumber) {
      return weekOptions.find((opt) => opt.value === weekNumber) || weekOptions[0]
    }
  }
}
</script>
