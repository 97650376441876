<template>
  <div class="media-gallery-container scroll hor" @scroll="infiniteScroll">
    <div
      class="media-gallery-wrapper"
      :class="{'media-gallery-wrapper--selected': isSelected(image.id)}"
      v-for="(image, index) in images"
      :key="image.id"
      @click="selectImage(image.id)">
      <div
        :class="[
          'media-gallery-image',
          {
            selected: isSelected(image.id),
            loading: image.status === 'uploading',
            success: image.status === 'success',
            error: image.status === 'error',
          },
        ]">
        <AppProgress
          v-if="image.progress"
          :data-dasharray="[Math.ceil(image.progress), 100]"
          :radius="4" />
        <img v-else-if="image.dataURL || image.previewUrl" :src="image.dataURL || image.previewUrl" alt="" @click.stop="showImage(index)">
        <template v-else>
          <i class="fas fa-exclamation-triangle" />
          <p class="error-msg" v-if="image.error">{{ image.error.msg }}</p>
        </template>
        <button v-if="image.url" :id="`copy-url-button-${index}`"
          ref="copyUrlButton" class="btn btn-pretender media-gallery-image-copy" @click.stop="copyImageUrl(index)">
          <i class="far fa-copy" />
        </button>
        <b-tooltip :target="`copy-url-button-${index}`" triggers="hover" noninteractive>Copy image url</b-tooltip>
      </div>
      <div class="media-gallery-image-controls">
        <i :class="['checked', { selected: isSelected(image.id), 'error-upload': image.error }]" />
        <div :class="[
          'd-flex align-items-center justify-content-between gap-1 font-size-14',
          { 'text-primary': isSelected(image.id), 'color-dark': Boolean(image.tags?.length) }
        ]">
          <i class="far fa-tag" />
          {{ image.tags?.length ?? 0 }}
        </div>
      </div>
    </div>

    <div v-if="showMoreLoading" class="loader">
      <i class="far fa-spinner-third fa-spin" />
    </div>

    <PreviewImages v-if="imageIndex !== null"
      :media="images"
      :current-media-index="imageIndex"
      @close="closeImage" />
  </div>
</template>

<script>
import hotkeys from 'hotkeys-js'
import {
  mapState, mapGetters, mapActions, mapMutations
} from 'vuex'
import { copyToClipboardWithAnimation } from '../../common/copy_to_clipboard'
import AppProgress from './progress'
import PreviewImages from './preview_images'

export default {
  components: { AppProgress, PreviewImages },
  data() {
    return {
      imageIndex: null
    }
  },
  computed: {
    ...mapState(['images', 'showScheduledPanel', 'showMoreLoading']),
    ...mapGetters(['isSelected', 'showPagination'])
  },
  mounted() {
    this.initHotkeys()
  },
  methods: {
    ...mapActions(['fetchImages', 'removeSelected']),
    ...mapMutations(['selectImage', 'selectAll', 'shiftPressed']),
    showImage(index) {
      this.imageIndex = index
    },
    closeImage() {
      this.imageIndex = null
    },
    initHotkeys() {
      hotkeys('ctrl+a,command+a,esc', (event, handler) => {
        if (!this.showScheduledPanel && !this.imageIndex) {
          if (handler.key === 'ctrl+a' || handler.key === 'command+a') {
            event.preventDefault()
            this.selectAll(true)
          }
          if (handler.key === 'esc') {
            event.preventDefault()
            this.selectAll(false)
          }
        }
      })

      document.addEventListener('keydown', (event) => {
        const key = event.key || event.keyCode
        if (key === 'Shift' || key === 16) {
          this.shiftPressed(true)
        }
      })

      document.addEventListener('keyup', (event) => {
        const key = event.key || event.keyCode
        if (key === 'Shift' || key === 16) {
          this.shiftPressed(false)
        }
      })
    },
    infiniteScroll(event) {
      if (!this.showPagination || this.showMoreLoading) return
      const el = event.currentTarget
      const vertical = 'vertical'
      const horizontal = 'horizontal'
      const tresholds = {
        [vertical]: el.offsetHeight + el.scrollTop >= el.scrollHeight - 1,
        [horizontal]: el.offsetWidth + el.scrollLeft >= el.scrollWidth - 1
      }
      const currentScroll = window.innerWidth >= 1024 ? vertical : horizontal
      const shouldFetch = tresholds[currentScroll]
      if (shouldFetch) {
        this.fetchImages()
      }
    },
    copyImageUrl(imageIndex) {
      copyToClipboardWithAnimation({ element: this.$refs.copyUrlButton[imageIndex], textToCopy: this.images[imageIndex].url })
    }
  }
}
</script>
