<template>
  <tr>
    <td class="text-left text-break w-20">
      <a>{{ row.title }}</a>
    </td>
    <td class="w-8">
      <component
        :is="row.pendingCount === 0 ? 'span' : 'a'"
        :class="row.pendingCount === 0 ? 'text-muted' : 'text-primary'"
        :href="buildUrl({ status: 'pending' })"
        target="_blank">
        {{ row.pendingCount }}
      </component>
    </td>
    <td class="w-8">
      <component
        :is="row.approvedCount === 0 ? 'span' : 'a'"
        :class="row.approvedCount === 0 ? 'text-muted' : 'text-primary'"
        :href="buildUrl({ status: 'approved' })"
        target="_blank">
        {{ row.approvedCount }}
      </component>
    </td>
    <td class="w-8">
      <component
        :is="row.rejectedCount === 0 ? 'span' : 'a'"
        :class="row.rejectedCount === 0 ? 'text-muted' : 'text-primary'"
        :href="buildUrl({ status: 'rejected' })"
        target="_blank">
        {{ row.rejectedCount }}
      </component>
    </td>
    <td class="w-8">
      <component
        :class="row.totalCount === 0 ? 'text-muted' : 'text-primary'"
        :is="row.totalCount === 0 ? 'span' : 'a'"
        :href="buildUrl()"
        target="_blank">
        {{ row.totalCount }}
      </component>
    </td>
  </tr>
</template>

<script>
import qs from 'qs'

const ALL_MOMENTS_PATH = '/gb/moments'
const FILTER_KEYS = {
  category: 'categories',
  employee: 'employees'
}
const VALUE_KEYS = {
  category: 'title',
  employee: 'id'
}

export default {
  props: {
    row: { type: Object, required: true },
    byPeriod: { type: Array, required: false, default: null },
    tableSourceName: { type: String, required: true, default: 'category' },
    businessId: {
      type: String,
      required: true
    }
  },
  created() {
    this.allMomentsPath = ALL_MOMENTS_PATH
  },
  methods: {
    buildUrl({ status } = {}) {
      const filterKey = FILTER_KEYS[this.tableSourceName]
      const valueKey = VALUE_KEYS[this.tableSourceName]

      const params = {
        [filterKey]: [this.row[valueKey]],
        business: this.businessId
      }

      if (status) {
        params.status = status
      }
      if (this.byPeriod?.length) {
        const [start, end] = this.byPeriod
        params.by_created_at = [start, end]
      }
      return `${this.allMomentsPath}?${qs.stringify(params)}`
    }
  }
}
</script>
