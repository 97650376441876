<template>
  <div class="tab-pane scroll ver pr-1">
    <div class="form-group">
      <label for="addr-autocomplete">Place to search in</label>
      <input type="new-password" class="form-control" id="addr-autocomplete"
        ref="addrAutocomplete"
        placeholder="Enter address"
        v-model="searchVal"
        :disabled="searchInProgress">
      <small v-if="selectedAddress === false" class="text-danger">Address not found</small>
    </div>

    <div class="form-group">
      <label for="keyword">Keyword</label>

      <div class="keyword-controls">
        <input v-model="keyword"
          type="new-password"
          class="form-control"
          id="keyword"
          placeholder="Enter keyword"
          :disabled="searchInProgress">

        <ProgressButton
          class="btn btn-secondary"
          :in-progress="searchInProgress"
          :disabled="isSearchBtnDisabled"
          @click.native.prevent="onSearchClick">
          Search
        </ProgressButton>
      </div>
    </div>

    <div v-if="places.length" class="places">
      <div class="places-item" v-for="place in places" :key="place.placeId">
        <AppCheckbox :value="place.selected" @input="onCheckboxChange({ place, checked: $event })">
          <b>{{ place.name }}</b>
          <p>{{ place.place_id_formatted_address }}</p>
        </AppCheckbox>
      </div>
    </div>

    <div v-else-if="searchInProgress" class="place-not-selected">
      <img src="~/rombus-loader.gif">
    </div>

    <div v-else class="place-not-selected">
      <p>Places will be displayed here after entering address and keyword</p>
    </div>

    <div class="text-right">
      <button type="button" class="btn btn-link mr-3" @click.prevent="toggleSlidePannel(false)">Cancel</button>

      <ProgressButton
        class="btn btn-primary"
        :disabled="!selectedPlaces.length"
        :in-progress="submitInProgress"
        @click.native.prevent="onSubmitClick">
        Confirm
      </ProgressButton>
    </div>
  </div>
</template>

<script>
import ProgressButton from 'vue_widgets/components/progress_button'
import AppCheckbox from 'vue_widgets/components/checkbox'

import { App } from 'common/rails'
import { DEFAULT_ERROR_MESSAGE } from 'common/constants'

export default {
  components: { ProgressButton, AppCheckbox },
  props: {
    googeSessionToken: Object
  },
  data() {
    return {
      searchVal: '',
      selectedAddressFromDropdown: '',
      selectedAddress: null,
      keyword: null,
      searchInProgress: false,
      submitInProgress: false,
      places: [],
      jid: null
    }
  },
  computed: {
    selectedPlaces() {
      return this.places.filter((place) => place.selected)
    },
    isSearchBtnDisabled() {
      return !this.selectedAddress ||
        !this.keyword?.trim() ||
        this.selectedAddressFromDropdown !== this.searchVal
    }
  },
  inject: ['toggleSlidePannel'],
  methods: {
    onSearchClick() {
      this.places = []
      this.searchInProgress = true
      axios.post('/api/google_places/search', {
        lat: this.selectedAddress.geometry.location.lat(),
        lng: this.selectedAddress.geometry.location.lng(),
        country: this.selectedAddress.address_components.find((el) => el.types.includes('country')).short_name,
        keyword: this.keyword
      }).catch(() => {
        this.searchInProgress = false
        toastr.error(DEFAULT_ERROR_MESSAGE)
      })
    },
    onSubmitClick() {
      this.submitInProgress = true
      axios.post('/api/google_places/bulk_create', {
        jid: this.jid,
        place_ids: this.selectedPlaces.map((place) => place.place_id)
      }).then(() => {
        toastr.success('Syncing data for selected places...')
        this.toggleSlidePannel(true)
      }).catch((err) => {
        toastr.error(err.response?.data?.message || DEFAULT_ERROR_MESSAGE)
        this.submitInProgress = false
        this.places = []
        this.$emit('placesChanged', [])
      })
    },
    onCheckboxChange({ place, checked }) {
      place.selected = checked
      this.$emit('placesChanged', this.places)
    }
  },
  mounted() {
    const autocomplete = new google.maps.places.Autocomplete(
      this.$refs.addrAutocomplete,
      {
        types: ['geocode'],
        sessionToken: this.googeSessionToken
      }
    )
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace()
      if (place.geometry) {
        this.selectedAddress = place
        this.searchVal = this.$refs.addrAutocomplete.value
        this.selectedAddressFromDropdown = this.$refs.addrAutocomplete.value
      } else {
        this.selectedAddress = false
        this.selectedAddressFromDropdown = ''
      }
    })

    this.cable = App.cable.subscriptions.create(
      { channel: 'GooglePlacesChannel' },
      {
        received: ({ kind, places, jid }) => {
          if (kind === 'places:fetch') {
            this.searchInProgress = false
            this.places = places.map((place) => ({
              ...place, selected: false, lat: place.cid_lat, lng: place.cid_lng
            }))
            this.jid = jid

            this.$emit('placesChanged', this.places)
          }
        }
      }
    )
    $(document).one('turbolinks:visit', () => {
      App.cable.subscriptions.remove(this.cable)
    })
  },
  beforeDestroy() {
    App.cable.subscriptions.remove(this.cable)
  }
}
</script>
