<template>
  <div class="card-review-dashboard">
    <div class="card-review-dashboard__top">
      <div class="card-review-dashboard__business">
        <i class="fa-regular fa-briefcase mr-1" />
        <a :href="review.linkToOrgloc" target="_blank">{{ review.locationName }}</a>
      </div>
      <div
        v-if="review.locationStoreCode"
        class="card-review-dashboard__store-code">
        Store Code: {{ review.locationStoreCode }}
      </div>
    </div>
    <div class="card-review-dashboard__wrapper">
      <div class="card-review-dashboard__author">
        <div v-if="review.reviewer.profilePhotoUrl" class="card-review-dashboard__avatar">
          <img :src="review.reviewer.profilePhotoUrl" :alt="`${review.reviewer.displayName} avatar`">
        </div>
        <div v-else class="card-review-dashboard__avatar">
          <span>
            {{ review.reviewer.displayName[0] }}
          </span>
        </div>
        <span>{{ review.reviewer.displayName }}</span>
        <!--
          <a class="card-review-dashboard__link" :href="``">
          <i class="fa-regular fa-arrow-up-right-from-square"></i>
          </a>
        -->
      </div>
      <div class="card-review-dashboard__body">
        <div class="card-review-dashboard__rating">
          <i v-for="i in 5" :key="i"
            class="fa-solid fa-star"
            :class="{ 'active': i <= review.starRating }" />
          <span class="card-review-dashboard__date">{{ review.remoteCreatedAt }}</span>
        </div>
        <p>{{ formattedReviewText }}</p>
        <button
          v-if="!review.reviewReply && !showReplyForm"
          class="card-review-dashboard__reply-button btn btn-primary btn-xs"
          @click="setReplyFormShow(true)">
          Reply
        </button>
      </div>
    </div>
    <div v-if="review.reviewReply" class="card-review-dashboard__bottom">
      <div class="card-review-dashboard__bottom-left">Reply:</div>
      <div class="card-review-dashboard__response">
        <span>{{ review.reviewReply.createdAt }}</span>
        <p>{{ formattedReviewReplyText }}</p>
      </div>
      <button
        class="card-review-dashboard__delete btn btn-pretender text-danger"
        @click="removeReply">
        <i class="fa-regular fa-trash" />
      </button>
    </div>
    <ReplyForm
      v-if="showReplyForm"
      :review-id="review.remoteId"
      :has-openai-key="review.hasOpenaiKey"
      :ai-messages="aiMessages"
      @removeAiMessage="$emit('removeAiMessage')"
      @sendReply="sendReply"
      @close="setReplyFormShow(false)" />
  </div>
</template>
<script>

import axios from 'axios'
import axiosTransform from 'common/axios'
import { runSwal } from 'common/delete_with_swal'
import ReplyForm from './reply_form.vue'

export default {
  components: {
    ReplyForm
  },
  props: {
    review: { type: Object, required: true },
    showReplyForm: { type: Boolean, required: true },
    aiMessages: { type: Array, required: true }
  },
  created() {
    this.translationFormatRegexp = /(\(Translated by Google\)|\(Original\))/g
  },
  methods: {
    setReplyFormShow(state) {
      this.$emit('setReplyFormState', state)
    },
    sendReply(comment) {
      const { locationRemoteId, remoteId: reviewRemoteId } = this.review

      axios
        .patch(`/api/gmb/locations/${locationRemoteId}/reviews/${reviewRemoteId}`, {
          gmbReview: {
            reviewReply: {
              comment
            }
          }
        }, axiosTransform)
        .then(() => {
          toastr.success('Review reply sent successfully')
          this.$emit('updateReview', {
            reviewReply: {
              comment,
              createdAt: new Date().toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hourCycle: 'h12'
              })
            }
          })
          this.setReplyFormShow(false)
        })
    },
    removeReply() {
      runSwal(() => {
        const { locationRemoteId, remoteId: reviewRemoteId } = this.review

        axios
          .delete(`/api/gmb/locations/${locationRemoteId}/reviews/${reviewRemoteId}/delete_reply`)
          .then(() => {
            toastr.success('Review reply removed successfully')
            this.$emit('updateReview', {
              reviewReply: null
            })
          })
      })
    },
    formatCommentText(comment) {
      if (!comment) return ''
      const formatted = comment.replace(this.translationFormatRegexp, '\n$1:\n').trim()
      return formatted.replace(/\n{2,}/g, '\n')
    }
  },
  computed: {
    formattedReviewText() {
      return this.formatCommentText(this.review.comment)
    },
    formattedReviewReplyText() {
      return this.formatCommentText(this.review.reviewReply?.comment)
    }
  }
}
</script>
