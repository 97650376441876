<template>
  <div class="tags-select">
    <div
      :class="['tag-item add', {editing: editing}]"
      @click.stop="edit">
      <i class="far fa-tag icon" />
      <span class="caption">Select tags</span>
      <TagForm
        v-if="editing"
        :selected-tags="selectedTagsInternal"
        :tags="tags"
        @saveTags="saveTags"
        @closeDropdown="edit" />
    </div>
    <Tag
      v-for="(tag, index) in selectedTagsInternal"
      :key="index"
      :tag="tag"
      @removeTag="removeTag" />
  </div>
</template>

<script>
import axios from 'axios'
import Tag from './tags_select/tag'
import TagForm from './tags_select/tag_form'

export default {
  components: {
    Tag,
    TagForm
  },
  props: {
    tags: {
      type: Array,
      default() { return [] }
    },
    selectedTags: {
      type: Array,
      default() { return [] }
    },
    apiOrgLocationPath: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      editing: false,
      selectedTagsInternal: []
    }
  },
  created() {
    this.selectedTagsInternal = this.selectedTags
  },
  methods: {
    edit() {
      this.editing = !this.editing
      if (this.editing) {
        document.addEventListener('click', this.edit)
      } else {
        document.removeEventListener('click', this.edit)
      }
    },
    apply() {
      axios.patch(this.apiOrgLocationPath, {
        organizations_location: { tags: [...this.selectedTagsInternal]}
      })
    },
    saveTags(tags) {
      this.selectedTagsInternal = tags
      this.apply()
    },
    removeTag(tag) {
      this.selectedTagsInternal = this.selectedTagsInternal.filter((item) => item !== tag)
      this.apply()
    }
  }
}
</script>
