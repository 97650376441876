<template>
  <b-row>
    <b-col md=8 sm=12 class='mb-2'>
      <identifyBusiness
        class='mb-3 pr-3'
        ref='identifyBusiness'
        :all-locations='allLocations'
        :gmb-locations-path='gmbLocationsPath'
        select2prefix='new-config'
        @removeKeywords='removeKeywords'
        @renderKeywords='renderKeywords' />
      <searchTerms
        class='mb-2'
        ref='searchTerms'
        select2prefix='new-config' />
      <tags :show-edit-buttons='true' ref='tags' />
    </b-col>
    <b-col md=4 sm=12>
      <configSchedule
        :periodicity-options='periodicityOptions'
        :week-day-number-options='dayNumberOptions'
        :week-number-options='weekNumberOptions'
        :time-zone='chosenTimeZone()'
        :time-zones="timeZones"
        :is-new="true"
        select2prefix='new-config'
        ref='configSchedule' />
      <languageResults class='mt-4' ref='languageResults' />
      <button class='btn btn-primary my-2 w-100 rounded' type='submit' @click='createConfig()'>Create config</button>
    </b-col>
  </b-row>
</template>

<script>
import axios from 'axios'
import toastr from 'toastr'
import snakeCaseKeys from 'snakecase-keys'
import { mapMutations, mapState } from 'vuex'
import identifyBusiness from '../attrs_components/business'
import searchTerms from './components/search_terms'
import tags from '../components/tags'
import configSchedule from './components/config_schedule'
import languageResults from '../attrs_components/language'
import geogridAttrsPresetsDefined from '../../../common/gmb/geogrids/helpers'
import { DEFAULT_ERROR_MESSAGE } from '../../../common/constants'

export default {
  name: 'NewConfig',
  components: {
    identifyBusiness, searchTerms, configSchedule, languageResults, tags
  },
  props: {
    periodicityOptions: Object,
    dayNumberOptions: Array,
    weekNumberOptions: Array,
    keysPath: String,
    placesApiKeys: Array,
    apiConfigsPath: String,
    allLocations: Array,
    gmbLocationsPath: String,
    userTimeZone: String,
    timeZones: Array
  },
  data() {
    return {
      config: {
        trackedBusiness: null,
        attrs: {
          businessPlaceId: null
        }
      }
    }
  },
  watch: {
    newConfig(newVal) {
      this.$refs.identifyBusiness.renderForm(newVal.attrs)
      this.$refs.languageResults.language = newVal.attrs.localLanguageEnabled ? 'Local' : 'Global'
      newVal.searchTerms = newVal.searchTerms.filter((searchTerm) => searchTerm.active)
        .map((searchTerm) => ({ ...searchTerm, geogridsCount: null }))
      this.$refs.searchTerms.renderSearchTerms(newVal)
      this.$refs.configSchedule.renderForm(newVal.schedule, null)
      this.$refs.tags.renderTags(newVal.tags)
    }
  },
  computed: mapState(['newConfig']),
  mounted() {
    this.$refs.searchTerms.renderSearchTerms({})
    this.$refs.tags.renderTags([])
  },
  methods: {
    ...mapMutations(['showLoader', 'hideLoader']),
    cleanForm() {
      this.$refs.identifyBusiness.clearForm()
      this.$refs.searchTerms.clearSearhTerms()
    },
    removeKeywords() {
      this.$refs.searchTerms.removeKeywords()
    },
    renderKeywords(keywords) {
      this.$refs.searchTerms.renderKeywords(keywords)
    },
    chosenTimeZone() {
      if (this.$store.state.chosenTimeZone) {
        return this.$store.state.chosenTimeZone
      }

      return this.userTimeZone
    },
    createConfig() {
      const data = {
        tags: this.$refs.tags.tags,
        searchTermsAttributes: this.$refs.searchTerms.getSearchTerms(),
        scheduleAttributes: this.$refs.configSchedule.getSchedule,
        attrsAttributes: {
          localLanguageEnabled: this.$refs.languageResults.languageFlag,
          placesDataSource: 'crawl',
          gridCenterLat: this.$store.state.mapCenterLat,
          gridCenterLng: this.$store.state.mapCenterLng,
          gridPointDistance: this.$store.state.distance,
          gridDistanceMeasure: this.$store.state.distanceMeasure,
          gridSize: this.$store.state.gridSize,
          ...this.$refs.identifyBusiness.getBusinessInfo(),
          disabledPoints: Array.from(this.$store.state.disabledPoints)
        }
      }

      if (!data.attrsAttributes.businessName) {
        toastr.error('Please provide <b> business </b> to create config.')
      } else if (!data.attrsAttributes.gridCenterLat || !data.attrsAttributes.gridCenterLng) {
        toastr.error("We can't create geogrids without coordinates', 'Please, use option 'Sticky grid'")
      } else if (data.attrsAttributes.dataEngineFailed) {
        toastr.error('Please provide <b> proper data engine </b> to save config changes.')
      } else if (!data.searchTermsAttributes.length) {
        toastr.error('Please add at least one <b> search term </b> to save config changes.')
      } else {
        this.showLoader()
        axios.post(this.apiConfigsPath, { config: snakeCaseKeys(data, { deep: true }) }).then((response) => {
          toastr.success('Config successfully created!')
          this.cleanForm()
          this.$emit('createdNewConfig', response.data)
          geogridAttrsPresetsDefined(data.attrsAttributes)
        }).catch((_error) => {
          toastr.error(DEFAULT_ERROR_MESSAGE)
        }).finally(() => {
          this.hideLoader()
        })
      }
    }
  }
}
</script>
