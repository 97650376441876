<template>
  <div class="d-flex">
    <button class="button-copy"
      :id="`copy_geogrid_link_${geogrid.obfuscatedId}`"
      @click="copyGeogridLink">
      <i class="far fa-copy" />
    </button>
    <b-tooltip :target="`copy_geogrid_link_${geogrid.obfuscatedId}`" title="Copy geogrid link" />
    <button class="button-copy"
      :id="`copy_geogrid_public_link_${geogrid.obfuscatedId}`"
      @click="copyGeogridPublicLink">
      <i class="fa-regular fa-globe" />
    </button>
    <b-tooltip :target="`copy_geogrid_public_link_${geogrid.obfuscatedId}`" title="Copy public geogrid link" />
    <div class='btn-group ml-2'>
      <button @click='getDropdownDirection' type='button' class='btn-context m-auto d-block'
        data-toggle='dropdown'
        aria-haspopup='true' aria-expanded='false'>
        <span class='wrap-icon'><i class='far fa-ellipsis-h' /></span>
      </button>
      <div class='dropdown-menu dropdown-menu-right geogrids-table-dropdown'>
        <div @click='repeatGeogrid(geogrid)' class='action-block'>
          <span class='wrap-icon'><i class='far fa-undo' /></span> Repeat
        </div>
        <span class='dropdown-divider' />
        <buttonGetGeogridImage
          class='action-block'
          :ref='`${geogrid.obfuscatedId}-button-geogrid-get-image`'
          :with-text='true'
          :geogrid='geogrid' />
        <span class='dropdown-divider' />
        <a :href='`${geogridsPath}/${geogrid.obfuscatedId}/csv_export`' class='action-block' data-turbolinks='false'>
          <span class='wrap-icon'><i class='far fa-file-csv' /></span> Download CSV
        </a>
        <span class='dropdown-divider' />
        <a :href="geogrid.publicUrl" target="_blank" class='action-block' data-turbolinks='false'>
          <span class='wrap-icon'><i class="fa-regular fa-globe" /></span> Public view
        </a>
        <span class='dropdown-divider' />
        <div @click='deleteGeogrid(geogrid.obfuscatedId)' class='action-block danger'>
          <span class='wrap-icon'><i class='far fa-trash' /></span> Delete
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import { copyToClipboardWithAnimation } from 'common/copy_to_clipboard'
import buttonGetGeogridImage from '../../components/geogrid_image'

export default {
  name: 'DropdownActions',
  components: {
    buttonGetGeogridImage
  },
  props: {
    geogrid: Object,
    geogridsPath: String,
    getGifLink: String,
    gifDate: String,
    isOnIndex: Boolean
  },
  methods: {
    ...mapMutations(['repeatGeogrid']),
    ...mapActions(['deleteGeogrid']),
    getDropdownDirection(event) {
      const btn = event.currentTarget
      btn.nextElementSibling.classList.toggle('up', window.innerHeight - btn.getBoundingClientRect().top < 320)
    },
    copyGeogridLink(e) {
      copyToClipboardWithAnimation({ element: e.currentTarget, textToCopy: this.geogrid.url })
    },
    copyGeogridPublicLink(e) {
      copyToClipboardWithAnimation({ element: e.currentTarget, textToCopy: this.geogrid.publicUrl, initialIconClass: 'fa-globe' })
    }
  }
}
</script>
