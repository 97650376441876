<template>
  <div class="form-group row mt-3 mb-2">
    <label class="col-12 col-sm-3 col-form-label text-sm-right" for="gmb_media_category">Category</label>
    <div class="col-12 col-sm-8 col-lg-6">
      <div class="form-group">
        <select class="form-control" id="gmb_media_category" @change="onChange">
          <option v-for="el in categories" :value="el.val" :selected="el.val === value">{{ el.text }}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['value', 'categories'],
  methods: {
    onChange(e) {
      this.$emit('input', e.currentTarget.value)
    }
  }
}
</script>
