<template>
  <div class="geobooster-widgets-edit-form">
    <div
      v-for="(line, lineIndex) in appearanceStepLayout"
      class="geobooster-widgets-edit-form__line"
      :class="{ 'flex-xlg-row': line.length > 1 }" :key="`line${lineIndex}`">
      <Component
        v-for="field in line"
        v-model="field.value"
        :key="field.name"
        :is="field.component"
        :values="field.values"
        :error="field.error"
        :error-text="field.errorText"
        :type="field.type ? field.type : 'text'"
        :label="field.label"
        :name="field.name"
        :disabled="field.disabled"
        :default-value="field.defaultValue"
        :map-marker-color="fields.mapMarkerColor.value"
        :show-wrapper="fields.showCustomMapMarkerWrapper.value"
        @input="field.error = false"
        @blur="$emit('triggerValidate')"
        @setError="$emit('setError', { error: $event.error, errorText: $event.errorText, field })" />
    </div>
  </div>
</template>

<script>
import InfoPanel from 'vue_widgets/components/info_panel'
import CustomInput from 'vue_widgets/components/custom_input'
import CustomSelect from 'vue_widgets/components/custom_select'
import Radio from 'vue_widgets/components/radio_panels'
import CustomCheckbox from 'vue_widgets/components/checkbox'
import ColorPicker from 'vue_widgets/components/color_picker'
import MapMarkerSelect from '../blanks/map_marker_select.vue'

export default {
  components: {
    CustomInput,
    CustomSelect,
    Radio,
    CustomCheckbox,
    ColorPicker,
    MapMarkerSelect,
    InfoPanel
  },
  props: {
    fields: {
      type: Object,
      required: true
    }
  },
  computed: {
    appearanceStepLayout() {
      const layout = []
      layout.push([this.fields.theme])
      layout.push([this.fields.widgetLayout])
      layout.push([this.fields.mapMarkerIcon])

      if (this.fields.mapMarkerIcon.value === 'custom') {
        layout.push([this.fields.showCustomMapMarkerWrapper])
        layout.push([this.fields.customMapMarkerIcon])
      }

      layout.push([this.fields.mapMarkerColor])

      if (this.fields.mapMarkerIcon.value === 'custom') {
        layout.push([this.fields.customMapMarkerWarning])
      }

      layout.push([this.fields.momentCardLayout])

      if (this.fields.momentCardLayout.value === 'tile') {
        layout.push([this.fields.momentCardCssClass])
      }

      layout.push([this.fields.momentListLayout])
      layout.push([this.fields.font])
      layout.push([this.fields.backgroundColor, this.fields.borderColor])
      layout.push([this.fields.momentCardBgColor])
      layout.push([this.fields.customerNameColor, this.fields.textColor])
      layout.push([this.fields.dateColor, this.fields.dateFormat])
      layout.push([this.fields.categoryTextColor, this.fields.categoryBgColor])

      return layout
    }
  }
}
</script>
