<template>
  <div :class="['mv-business-card', { 'd-block': isRequesting }]">
    <a v-for="business in businessList" :key="business.id" class="mv-business-card-item" :href="getBusinessLink(business.id)">
      <div class="mv-business-card-info">
        <div class="d-flex gap-2 mb-1">
          <template v-if="business.disconnected">
            <i :id="business.id" class="mt-1 color-warning far fa-exclamation-circle" />
            <BTooltip :target="business.id" title="Location is disconnected" />
          </template>
          <span class="business-title">{{ business.name }}</span>
        </div>
        <small class="d-block text-muted mb-3">{{ transformLocationData(business.locationData) }}</small>
        <div class="d-flex align-items-center mt-auto">
          <div class="d-flex align-items-center color-dark mr-4" data-toggle="tooltip" data-original-title="Moments">
            <img src="~/mv/checkin_icon.svg" class="mr-2">
            {{ business.checkinsCount }}
          </div>
          <div class="d-flex align-items-center color-dark mr-4" data-toggle="tooltip" data-original-title="Posts">
            <img src="~/mv/posts_icon.svg" class="mr-2">
            {{ business.postsCount }}
          </div>
          <div class="d-flex align-items-center color-dark" data-toggle="tooltip" data-original-title="Workers">
            <img src="~/mv/employee.svg" class="mr-2">
            {{ business.employeesCount }}
          </div>
        </div>
      </div>
    </a>
    <div v-if="isRequesting" class="text-center h2 py-4 text-primary">
      <i class="far fa-spinner-third fa-spin" />
    </div>
    <div v-else-if="!businessList.length" class="py-4">
      No records found
    </div>
  </div>
</template>

<script>
export default {
  props: {
    getBusinessLink: { type: Function },
    getBusinesses: { type: Function },
    transformLocationData: { type: Function },
    search: { type: String, default: '' }
  },
  data() {
    return {
      businessList: [],
      isRequesting: false
    }
  },
  mounted() {
    this.fetchBusiness()
  },
  methods: {
    fetchBusiness() {
      this.isRequesting = true
      this.businessList = []
      this.getBusinesses().then((data) => {
        this.businessList = data
        this.$nextTick(() => {
          $('[data-toggle="tooltip"]').tooltip()
        })
      }).finally(() => {
        this.isRequesting = false
      })
    }
  },
  watch: {
    search() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(this.fetchBusiness, 500)
    }
  }
}
</script>
