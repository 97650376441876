<template>
  <div class="report-categories">
    <div class="report-categories__wrapper-chart audit-chart">
      <h2 class="report-categories__title h4 mb-2 font-weight-semi-bold">Categories</h2>
      <AuditDescription class="mb-4">
        Categories describe your business. They connect you to customers who search for your products or services.
        <a href="https://support.google.com/business/answer/7249669" target="_blank">How to choose a category</a>
      </AuditDescription>
      <div class="report-categories__wrapper-inner">
        <div ref="chart" class="report-categories__chart" />
        <div class="report-categories__matches">
          <div class="report-categories__match">
            <strong class="report-categories__subtitle">Primary Category</strong>
            <span class="report-categories__count">{{ primaryCategoriesText }}</span>
            <div class="report-categories__list">
              <div
                v-for="{category, categoryName} in primaryCategories"
                :key="categoryName"
                class="report-categories__item" :class="{'report-categories__item--matched': category.matched}">
                <span>
                  {{ getFormattedPercentage(category.occurences, totalPrimaryCategories) }}% {{ categoryName }}
                </span>
              </div>
            </div>
          </div>
          <div class="report-categories__match">
            <strong class="report-categories__subtitle">Secondary Category</strong>
            <span class="report-categories__count">{{ secondaryCategoriesText }}</span>
            <div class="report-categories__list">
              <div
                v-for="{category, categoryName} in secondaryCategories"
                :key="categoryName"
                class="report-categories__item" :class="{'report-categories__item--matched': category.matched}">
                <span>
                  {{ getFormattedPercentage(category.occurences, totalSecondaryCategories) }}% {{ categoryName }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h2 class="h4 mb-2 font-weight-semi-bold">Competitors</h2>

    <VueSlimTable
      ref="table"
      :source="() => categoriesForAllBusinesses"
      :columns="columns"
      class="responsive-table borderless-table audit-table competitors">
      <template #row="{ row }">
        <tr>
          <td class="column" column-caption="Name">
            <component :is="row.placeId ? 'a' : 'span'"
              :href="row.placeId ? `https://www.google.com/maps/search/?api=1&query=1&query_place_id=${row.placeId}` : null"
              target="_blank">
              {{ row.name || 'My Location' }}
            </component>
            <small v-if="row.formattedAddress" class="d-block">{{ row.formattedAddress }}</small>
          </td>
          <td column-caption="Primary Category">
            {{ (row.primary || {}).name }}
          </td>
          <td column-caption="Secondary Category">
            <div class="d-flex flex-wrap gap-2">
              <div v-for="{ name, remoteId } in row.additional" :key="remoteId" class="tag">
                <div class="tag-text">
                  <span>{{ name }}</span>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </template>
      <template #pagination>
        <span>&nbsp;</span>
      </template>
    </VueSlimTable>
  </div>
</template>

<script>

import Highcharts from 'highcharts'
import HighchartsMore from 'highcharts/highcharts-more'

HighchartsMore(Highcharts)

export default {
  props: {
    sectionData: { type: Object, required: true }
  },
  data() {
    return {
      primaryCategories: [],
      secondaryCategories: [],
      totalPrimaryCategories: 0,
      totalSecondaryCategories: 0
    }
  },
  created() {
    this.columns = [
      { key: 'name', title: 'Name', orderable: false },
      { key: 'primary', title: 'Primary Category', orderable: false },
      { key: 'additional', title: 'Secondary Category', orderable: false }
    ]

    this.categoriesForAllBusinesses = [
      {
        ...this.sectionData.current
      },
      ...this.sectionData.competitors
    ]
  },
  computed: {
    currentCategories() {
      return this.sectionData.current
    },
    primaryCategoriesText() {
      const primaryMatches = this.primaryCategories.find(({ categoryName }) => categoryName === this.currentCategories.primary.name)?.category?.matched ?? 0

      if (primaryMatches === 0) {
        return 'Your primary category does not match with any of competitors\'.'
      }

      return `Your category matches with ${primaryMatches} ${this.pluralize('competitor', primaryMatches)}.`
    },
    secondaryCategoriesText() {
      const currentCategories = new Set(this.currentCategories.additional.map(({ name }) => name))
      let secondaryMatches = 0
      this.secondaryCategories.forEach(({ categoryName, category }) => {
        if (currentCategories.has(categoryName) && category.matched > 0) {
          ++secondaryMatches
        }
      })

      if (secondaryMatches === 0) {
        return 'None of your secondary categories have matches with competitors\' locations.'
      }

      const uniqueCategoriesAmount = Object.keys(this.secondaryCategories).length

      return `${secondaryMatches} out of ${
        uniqueCategoriesAmount
      } ${this.pluralize('categor', uniqueCategoriesAmount, ['y', 'ies'])} match.`
    }
  },
  mounted() {
    this.computeCategories()
    this.drawChart()
  },
  methods: {
    computeCategories() {
      const primaryCategoriesMap = new Map()
      const secondaryCategoriesMap = new Map()

      const currentPrimaryCategoryName = this.currentCategories.primary.name

      if (currentPrimaryCategoryName) {
        this.totalPrimaryCategories += 1
        primaryCategoriesMap.set(currentPrimaryCategoryName, {
          occurences: 1,
          matched: 0
        })
      }

      const currentSecondaryCategoryNames = new Set(this.currentCategories.additional.map((category) => category.name))

      currentSecondaryCategoryNames.forEach((categoryName) => {
        this.totalSecondaryCategories += 1
        secondaryCategoriesMap.set(categoryName, {
          occurences: 1,
          matched: 0
        })
      })

      this.sectionData.competitors.forEach(({ primary, additional }) => {
        if (primary) {
          this.totalPrimaryCategories += 1

          const alreadyMappedCategory = primaryCategoriesMap.get(primary.name)

          if (alreadyMappedCategory) {
            alreadyMappedCategory.occurences += 1
            if (primary.name === currentPrimaryCategoryName) {
              alreadyMappedCategory.matched += 1
            }
          } else {
            primaryCategoriesMap.set(primary.name, {
              occurences: 1,
              matched: 0
            })
          }
        }

        additional.forEach((secondary) => {
          this.totalSecondaryCategories += 1

          const alreadyMappedCategory = secondaryCategoriesMap.get(secondary.name)

          if (alreadyMappedCategory) {
            alreadyMappedCategory.occurences += 1
            if (currentSecondaryCategoryNames.has(secondary.name)) {
              alreadyMappedCategory.matched += 1
            }
          } else {
            secondaryCategoriesMap.set(secondary.name, {
              occurences: 1,
              matched: 0
            })
          }
        })
      })

      const mappedPrimaryCategories = []
      const mappedSecondaryCategories = []
      primaryCategoriesMap.forEach((category, categoryName) => {
        mappedPrimaryCategories.push({
          category,
          categoryName
        })
      })
      secondaryCategoriesMap.forEach((category, categoryName) => {
        mappedSecondaryCategories.push({
          category,
          categoryName
        })
      })

      const sortPredicate = (a, b) => {
        if (a.category.matched && !b.category.matched) {
          return -1
        }

        if (!a.category.matched && b.category.matched) {
          return 1
        }

        return 0
      }

      this.primaryCategories = mappedPrimaryCategories.sort(sortPredicate)
      this.secondaryCategories = mappedSecondaryCategories.sort(sortPredicate)
    },
    drawChart() {
      const chartNode = this.$refs.chart
      const dataTemplate = () => ({ y: 0, relatedCategories: []})
      const primaryMatched = dataTemplate()
      const primaryUnmatched = dataTemplate()
      const secondaryMatched = dataTemplate()
      const secondaryUnmatched = dataTemplate()

      this.primaryCategories.forEach(({ category, categoryName }) => {
        const percentage = this.getFormattedPercentage(category.occurences, this.totalPrimaryCategories, 3)
        const relatedGroup = category.matched ? primaryMatched : primaryUnmatched

        relatedGroup.y += percentage
        relatedGroup.relatedCategories.push(categoryName)
      })

      this.secondaryCategories.forEach(({ category, categoryName }) => {
        const percentage = this.getFormattedPercentage(category.occurences, this.totalSecondaryCategories, 3)
        const relatedGroup = category.matched ? secondaryMatched : secondaryUnmatched

        relatedGroup.y += percentage
        relatedGroup.relatedCategories.push(categoryName)
      })

      const matched = {
        name: 'Matched',
        data: [primaryMatched, secondaryMatched]
      }
      const unmatched = {
        name: 'Unmatched',
        data: [primaryUnmatched, secondaryUnmatched]
      }

      // colors and series are in opposite order because chart is inverted
      const series = [unmatched, matched]
      Highcharts.chart(chartNode, {
        colors: ['#BDC5D1', '#29BA6C'],
        chart: {
          type: 'column',
          inverted: true,
          polar: true
        },
        credits: { enabled: false },
        title: {
          text: ''
        },
        pane: {
          size: '85%',
          innerSize: '45%',
          endAngle: 270
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            borderWidth: 3,
            pointPadding: 0,
            groupPadding: 0,
            borderRadius: '2%'
          },
          series: {
            dataLabels: {
              enabled: true,
              formatter() {
                return `<span>${Math.round(this.point.y)}%</span>`
              }
            }
          }
        },
        xAxis: {
          labels: {
            align: 'right',
            useHTML: true,
            allowOverlap: true,
            step: 1,
            y: 3,
            style: {
              fontSize: '13px'
            }
          },
          lineWidth: 0,
          gridLineWidth: 0,
          categories: [
            `<span class="report-categories__bar-title">${this.primaryCategories.length > 0 ? 'Primary' : ''}</span>`,
            `<span class="report-categories__bar-title">${this.secondaryCategories.length > 0 ? 'Secondary' : ''}</span>`
          ]
        },
        yAxis: {
          visible: false,
          labels: {
            enabled: false
          }
        },
        legend: {
          enabled: false
        },
        tooltip: {
          borderWidth: 0,
          borderRadius: 0,
          padding: 0,
          useHTML: true,
          shadow: false,
          className: 'report-categories__tooltip',
          formatter() {
            const percentage = `${this.y.toFixed(2)}%`
            const relatedCategories = `<br><strong>Related categories:</strong><br>${this.point.relatedCategories.join('<br>')}`
            return `<div>${percentage}${relatedCategories}</div>`
          }
        },
        series
      })
    },
    pluralize(s, num, endings = ['', 's']) {
      const [nonPluralEnding, pluralEnding] = endings
      return num === 1 ? `${s}${nonPluralEnding}` : `${s}${pluralEnding}`
    },
    getFormattedPercentage(num1, num2, precision = 1) {
      return parseFloat((num1 / num2 * 100).toFixed(precision))
    },
    matchCategories(currentCategories, competitorCategory) {
      return Boolean(currentCategories.find(({ name }) => name === competitorCategory))
    }
  }
}
</script>
