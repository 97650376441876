<template>
  <div>
    <h2 class="mb-2 font-weight-semi-bold">Title & Description</h2>
    <AuditDescription class="mb-4">
      See the concise ways that businesses describe themselves.
      This information can help you think of better ways to connect with potential customers.
    </AuditDescription>
    <VueSlimTable
      ref="table"
      :source="() => rows"
      :columns="columns"
      class="responsive-table borderless-table audit-table competitors last-head-col-right-1">
      <template #row="{ row }">
        <tr>
          <td
            :column-caption="columns[0].title"
            @click="showContentSidebar(row, 'titleAndDescription', [{ title: row.title, description: row.description }])">
            <div class="d-flex flex-column">
              <span v-if="row.currentLocation" class="font-size-12 text-muted">My location</span>
              <span class="text-primary cursor-pointer">{{ row.name }}</span>
            </div>
          </td>
          <td
            v-for="(column, index) in columns.slice(1)"
            :column-caption="column.title"
            :key="column.key"
            :class="{'text-grey': !row[column.key], 'text-capitalize': column.key === 'titleCity', 'text-right': index === columns.length - 2}">
            {{ row[column.key] ?? NA }}
          </td>
        </tr>
      </template>
    </VueSlimTable>
  </div>
</template>
<script>
import showContentSidebarMixin from './show_content_sidebar_mixin'

export default {
  mixins: [showContentSidebarMixin],
  props: {
    sectionData: { type: Object, required: true }
  },
  created() {
    this.NA = 'N/A'

    this.columns = [
      { key: 'name', title: 'Competitors', orderable: false },
      { key: 'titleCity', title: 'City in Title', orderable: false },
      { key: 'titleKeyword', title: 'Target Keyword in Title', orderable: false },
      { key: 'descriptionLength', title: 'Description Length (symbols)', orderable: false },
      { key: 'descriptionWords', title: 'Words in description', orderable: false }
    ]

    this.rows = [
      { currentLocation: true, ...this.sectionData.current },
      ...this.sectionData.competitors
    ]
  }

}
</script>
