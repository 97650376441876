<template>
  <div class="transactions-history">
    <div class="transactions-history-head">
      <div class="container">
        <div class="d-flex justify-content-between flex-wrap m-b-3">
          <h1 class="bold">{{ type[creditType].title }}</h1>
          <a :href="type[creditType].link.url" class="btn btn-link with-icon"><i class="far fa-ballot-check" />{{ type[creditType].link.text }}</a>
        </div>

        <div class="d-flex justify-content-end flex-wrap gap-4">
          <div class="form-field border-white">
            <span>Type of transaction</span>
            <Multiselect
              v-model="kindVal"
              :allow-empty="false"
              :options="transactionsType"
              :searchable="false"
              :close-on-select="true"
              :show-labels="false"
              track-by="value"
              label="name"
              placeholder="Pick a state" />
          </div>

          <div class="form-field border-white">
            <span>Period</span>
            <datepicker :just-months="true" date-format="Y-m-d" :emit-when-create='true'
              :value="dateVal"
              :max-date="new Date()"
              @input="getDate" />
          </div>
        </div>
      </div>
    </div>
    <div class="body pb-4">
      <div class="container pt-4">
        <div class="d-flex align-items-center justify-content-end font-weight-semi-bold mb-4 gap-4">
          <div>
            <button @click="downloadCsv" :disabled="loadingCsv"
              class="btn btn-secondary btn-sm rounded" data-turbolinks="false">
              <span class='wrap-icon'><i class='far fa-file-csv' /></span>
              <span class='text'>CSV</span>
            </button>
          </div>
          <div>
            Total Accrued:
            <span class="credits color-success">
              {{ totalCredits.accrued | thousandsSeparator }}
            </span>
          </div>
          <div>
            Total Spent:
            <span class="credits color-danger">
              {{ totalCredits.spent | thousandsSeparator }}
            </span>
          </div>
        </div>
        <VueSlimTable
          ref="table"
          v-if="date"
          :source="tableSource"
          :columns="type[creditType].columns"
          class="table table-sortable"
          :per-page="100">
          <template #row="{ row }">
            <TransactionsRow :row="row" :credit-type="creditType" />
          </template>
        </VueSlimTable>
      </div>
    </div>
  </div>
</template>

<script>
import axiosTransform from 'common/axios'
import Multiselect from 'vue-multiselect'
import TransactionsRow from './transactions_row'
import Datepicker from '../components/datepicker.vue'

export default {
  components: {
    Multiselect,
    TransactionsRow,
    Datepicker
  },
  props: {
    creditType: {
      type: String
    }
  },
  computed: {
    getExportCsvUrl() {
      const params = {
        kind: this.kind, start_date: this.date
      }
      const path = this.type[this.creditType].csvExport
      const searchParams = new URLSearchParams(params)
      return `${path}?${searchParams.toString()}`
    }
  },
  data() {
    return {
      date: null,
      kind: '',
      kindVal: { name: 'All', value: '' },
      startDate: null,
      totalCredits: {
        accrued: 0,
        spent: 0
      },
      loadingCsv: false
    }
  },
  created() {
    this.type = {
      keyword: {
        title: 'Keyword Credits Transactions',
        // link to other page:
        link: {
          text: 'GeoGrid Credits Transactions',
          url: '/geogrid_transactions'
        },
        getData: '/api/profile/keyword_transactions',
        csvExport: '/api/profile/keyword_transactions/csv_export',
        columns: [
          { title: 'Date', key: 'created_at', orderable: true },
          { title: 'Keyword', key: 'search_term' },
          { title: 'Location', key: 'location' },
          { title: 'Credits / Results', key: 'credits' }
        ]
      },
      geogrid: {
        title: 'GeoGrid Credits Transactions',
        link: {
          text: 'Keyword Credits Transactions',
          url: '/keyword_transactions'
        },
        getData: '/api/profile/geogrid_transactions',
        csvExport: '/api/profile/geogrid_transactions/csv_export',
        columns: [
          { title: 'Date', key: 'created_at', orderable: true },
          { title: 'GeoGrid', key: 'geogrid' },
          { title: 'Search Term', key: 'search_term' },
          { title: 'Location', key: 'location' },
          { title: 'Credits / Results', key: 'credits' }
        ]
      }
    }
    this.transactionsType = [
      { name: 'All', value: '' },
      { name: 'Charge off', value: 'negative' },
      { name: 'Top up', value: 'positive' }
    ]
    this.dateVal = [(new Date()).setDate(1)]
  },
  watch: {
    kindVal(val) {
      this.kind = val.value
      this.$refs.table.refetch()
    },
    date() {
      this.$refs.table?.refetch()
    }
  },
  methods: {
    getDate(date) {
      [this.date] = date
    },
    tableSource(params) {
      return axios.get(
        this.type[this.creditType].getData,
        {
          params: {
            ...params, kind: this.kind, start_date: this.date
          },
          paramsSerializer(json) { return qs.stringify(json, { arrayFormat: 'brackets' }) },
          ...axiosTransform
        }
      ).then((res) => {
        this.totalCredits.accrued = res.data.totalAccured
        this.totalCredits.spent = res.data.totalSpent

        return res.data.transactions
      })
    },
    downloadCsv() {
      this.loadingCsv = true
      axios.get(this.getExportCsvUrl, {
      }, axiosTransform)
        .then((res) => {
          toastr.success('Your CSV is preparing.')
          window.open(`/downloads/${res.data.jid}`)
        })
        .finally(() => {
          setTimeout(() => {
            this.loadingCsv = false
          }, 500)
        })
    }
  }
}
</script>
