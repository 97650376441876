<template>
  <svg class="diagram" viewBox="0 0 42 42">
    <circle
      ref="mainDiagram"
      :stroke="stroke"
      :stroke-dasharray="dasharray"
      :stroke-dashoffset="dashoffset"
      :stroke-width="strokeWidth"
      :r="radius"
      cx="50%"
      cy="50%" />
  </svg>
</template>

<script>
export default {
  props: {
    dataDasharray: {
      type: Array,
      required: true
    },
    radius: {
      type: Number,
      default: 18
    },
    strokeWidth: {
      type: Number,
      default: 1
    },
    stroke: {
      type: String,
      default: '#fff'
    }
  },
  data() {
    return {
      dasharray: '0 100',
      dashoffset: '0',
      radiusBaseVal: 0,
      circumference: 0
    }
  },
  mounted() {
    this.radiusBaseVal = this.$refs.mainDiagram.r.baseVal.value
    this.circumference = 2 * Math.PI * this.radiusBaseVal
  },
  watch: {
    dataDasharray: {
      handler() {
        const percent = (this.dataDasharray[0] * 100 / this.dataDasharray[1]).toFixed(1)
        const offset = this.circumference - (-percent / 100) * this.circumference
        this.dasharray = `${offset} ${this.circumference}`
        this.dashoffset = this.circumference.toFixed(3)
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.diagram {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: visible;
}
circle {
  fill: transparent;
  transform-origin: center;
  transform: rotate(-90deg);
  transition: stroke-dasharray 1s ease;
  z-index: 10;
}
</style>
