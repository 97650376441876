<template>
  <div v-if="showButton" class="d-inline-block" :class="{ 'd-none': configHasGeogrids }">
    <button
      id="dropdownGenerateGif"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      :disabled="isRequesting"
      type="button"
      class="btn btn-sm">
      <span class="wrap-icon"><i class="far fa-gif" /></span>
      <span class="text">GIF</span>
    </button>
    <div class='dropdown-menu p-0' aria-labelledby='dropdownGenerateGif'>
      <a
        :href='searchTerm.getAnimationLink'
        target='_blank'
        v-show='searchTerm.geogridsHistoryTimestamp'
        class='dropdown-item small p-x-1'>
        <i class="far fa-download mr-2" />
        GIF ({{ searchTerm.geogridsHistoryTimestamp }})
      </a>
      <a class='dropdown-item small p-x-1' @click.stop='createGif'>
        <i class="far fa-film mr-2" />Generate new GIF
      </a>
      <a class='dropdown-item small p-x-1' @click.stop='createGif(false)'>
        <i class="far fa-film mr-2" />Generate new GIF (no loop)
      </a>
    </div>
  </div>
</template>
<script>

import { DEFAULT_ERROR_MESSAGE } from 'common/constants'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['searchTerm', 'config', 'pageTab']),
    configHasGeogrids() {
      return !this.searchTerm.geogrids?.length
    }
  },
  data: () => ({
    isRequesting: false,
    showButton: true
  }),
  methods: {
    createGif(loop = false) {
      this.isRequesting = true
      axios.post(
        this.searchTerm.createAnimationLink,
        { keyword: this.searchTerm.text, loop: loop }
      ).then((response) => {
        if (response.data.error === 'gif in progress') {
          toastr.error(
            `For ${this.config.attrs.businessName}: ${this.searchTerm.text}`,
            'GIF generation in progress'
          )
        } else {
          toastr.success(
            `For ${this.config.attrs.businessName}: ${this.searchTerm.text}`,
            'GIF generation started',
            { timeOut: 1000 }
          )
        }
      }).catch((_error) => {
        toastr.error(DEFAULT_ERROR_MESSAGE)
      }).finally(() => {
        this.isRequesting = false
      })
    }
  },
  watch: {
    // hack, but i guess kinda required
    pageTab() {
      this.showButton = false
      this.$nextTick(() => {
        this.showButton = true
      })
    }
  }
}
</script>
