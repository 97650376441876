<template>
  <div class="col-md-12">
    <div class="bg-light col-md-12 mt-4 mb-2 pt-3 pb-2">
      <h4>File naming</h4>
      <label class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" v-model="object.keepOriginalFilename">
        <span class="custom-control-label">Keep original filename for google</span>
      </label>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: mapState(['object'])
}
</script>
