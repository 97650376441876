<template>
  <div class="widget">
    <div class="d-flex justify-content-between">
      <strong class="caption">Location listing stats</strong>
      <ChartSwitcher
        v-if="!breakdownByMonths"
        v-model="mainChartsType"
        :options="chartTypeOptions"
        name="mainStats" />
    </div>
    <p class="mb-3 mt-2">
      The number of times the listing was viewed on Maps and Search
    </p>
    <template v-if="breakdownByMonths">
      <ChartBreakdown class="in-row" chart-id="business_impressions_maps" chart-color="#377dff" />
      <ChartBreakdown class="in-row" chart-id="business_impressions_search" chart-color="#29BA6C" />
    </template>
    <template v-else>
      <ChartLines
        v-show="mainChartsType === 'listingStats'"
        :chart-ids="listingStatsChart.ids"
        :chart-colors="listingStatsChart.colors"
        chart-direction="in-row" />
      <MainChartWithTiles v-show="mainChartsType === 'fullChart'" />
    </template>
  </div>
</template>

<script>
import ChartSwitcher from './chart_switcher'
import ChartLines from './chart_lines'
import MainChartWithTiles from './main_chart_with_tiles'
import ChartBreakdown from './chart_breakdown'

export default {
  components: {
    ChartSwitcher,
    ChartLines,
    MainChartWithTiles,
    ChartBreakdown
  },
  props: {
    breakdownByMonths: { type: Boolean, default: false }
  },
  data() {
    return {
      mainChartsType: 'listingStats'
    }
  },
  created() {
    this.chartTypeOptions = [{
      value: 'listingStats',
      iconClasses: 'far fa-chart-line'
    }, {
      value: 'fullChart',
      iconClasses: 'far fa-analytics'
    }]

    this.listingStatsChart = {
      ids: ['business_impressions_maps', 'business_impressions_search'],
      colors: ['#377dff', '#29BA6C']
    }
  }
}
</script>
