export default {
  mounted() {
    this.fetchFilters()
  },
  methods: {
    async fetchFilters() {
      const tagsField = this.fields.find((field) => field.key === 'selectedTags')
      const categoriesField = this.fields.find((field) => field.key === 'selectedCategories')
      const { data: { tags, categories } } = await axios.get(`${this.filtersPath}/filters`)
      tagsField.options = tags
      categoriesField.options = categories
    }
  }
}
