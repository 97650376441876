import axios from 'axios'
import toastr from 'toastr'
import { airbrakeNotify } from 'common/airbrake'
import deleteWithSwal from './common/delete_with_swal'
import showFlashes from './common/flashes'
import { App } from './common/rails'
import { DEFAULT_ERROR_MESSAGE, NOT_AUTHORIZED_MESSAGE } from './common/constants'
import { bindClickToCopyAttr } from './common/copy_to_clipboard'

const ignoreActions = ['posts_calendar']

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.status === 500) {
        if (!ignoreActions.some((actionName) => error.request.responseURL.includes(actionName))) {
          toastr.error(DEFAULT_ERROR_MESSAGE)
        }
      } else if (error.response.status === 403) {
        const msg = error.response.data && error.response.data.type === 'internal' ? error.response.data.text : NOT_AUTHORIZED_MESSAGE
        toastr.error(msg)
      }
    } else if (process.env.NODE_ENV !== 'development') {
      airbrakeNotify({
        error: 'Unknown error happened on LV backend call',
        params: { error: error }
      })
    }
    return Promise.reject(error)
  }
)

function initOnce() {
  deleteWithSwal()

  const ignorePaths = ['/sign_up', '/sign_in', '/password/new', '/password',
    '/stats/reports/', '/stats/gmb/locations/', '/stats/geogrids/']

  if (ignorePaths.some((i) => window.location.pathname.includes(i))) {
    return
  }

  App.cable.subscriptions.create('PlacesChannel', {
    received: (msg) => {
      if (msg.type === 'geogrid.error' || msg.key_error) {
        toastr.error(
          `${msg.info}.</br> Visit <a href="/geogrids"><u>GeoGrid Search page</u></a> for more details.`,
          'Your GeoGrid has failed',
          { timeOut: 0 }
        )
      } else if (msg.type === 'geogrid.finished') {
        toastr.success(
          `<a href="${msg.geogrid_link}"><u>${msg.info}</u></a>.`,
          'Your GeoGrid is ready',
          { timeOut: 5000 }
        )
      } else if (msg.type === 'scheduled_geogrids.finished') {
        toastr.success('All your GeoGrids are ready!', null, { timeOut: 0 })
      }
    }
  })
}

if (document.readyState !== 'loading') {
  initOnce()
} else {
  document.addEventListener('DOMContentLoaded', initOnce)
}

$(document).on('turbolinks:load', () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover({ trigger: 'hover' })
  $('[stopPropagation]').click((e) => { e.stopPropagation() })
  showFlashes()
  bindClickToCopyAttr('#clipboard-btn', 'clipboardTarget')
})

$(document).on('click', '.wallet-menu', (e) => {
  e.stopPropagation()
})

$(document).on('click', '.js-copy-title', (e) => {
  const copyText = e.currentTarget.getAttribute('title')
  navigator.clipboard.writeText(copyText)
})
