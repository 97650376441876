export default {
  computed: {
    geogridDiff() {
      const values = {
        agr: this.isGeogridValuesNaN('agr') ? 0 : Math.round((parseFloat(this.geogrids[0].agr) - parseFloat(this.geogrids[1].agr)) * 100) / 100,
        atgr: this.isGeogridValuesNaN('atgr') ? 0 : Math.round((parseFloat(this.geogrids[0].atgr) - parseFloat(this.geogrids[1].atgr)) * 100) / 100,
        solv: this.isGeogridValuesNaN('solv') ? 0 : Math.round((parseFloat(this.geogrids[1].solv) - parseFloat(this.geogrids[0].solv)) * 100)
      }

      return {
        agr: {
          diff: Math.abs(values.agr),
          wrapClass: this.diffClasses(values.agr, ['indifferent', 'positive', 'negative']),
          iconClass: `font-size-12 fa-solid fa-${this.diffClasses(values.agr, ['circle-small', 'caret-up', 'caret-down'])}`
        },
        atgr: {
          diff: Math.abs(values.atgr),
          wrapClass: this.diffClasses(values.atgr, ['indifferent', 'positive', 'negative']),
          iconClass: `font-size-12 fa-solid fa-${this.diffClasses(values.atgr, ['circle-small', 'caret-up', 'caret-down'])}`
        },
        solv: {
          diff: Math.abs(values.solv),
          wrapClass: this.diffClasses(values.solv, ['indifferent', 'positive', 'negative']),
          iconClass: `font-size-12 fa-solid fa-${this.diffClasses(values.solv, ['circle-small', 'caret-up', 'caret-down'])}`
        }
      }
    }
  },
  methods: {
    diffClasses(value, classes) {
      if (parseFloat(value) === 0) {
        return classes[0]
      }

      return parseFloat(value) > 0 ? classes[1] : classes[2]
    },
    isGeogridValuesNaN(key) {
      return Boolean(this.geogrids.filter((gg) => Number.isNaN(Number(gg[key])) || gg[key] === null).length)
    },
    toFixed(number, fractionDigits = 2) {
      if (Number.isNaN(Number(number))) {
        return number
      }

      return Number(number).toFixed(fractionDigits)
    }
  }
}
