<template>
  <div class="form-field mb-0">
    <span class="caption">Point Distance</span>
    <multiselect
      v-model="model"
      :allow-empty="false"
      :options="pointDistances[geogridWidget.gridDistanceMeasure]"
      :searchable="false"
      :show-labels="false"
      :max-height="540"
      label="text"
      class="distance" />
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapState } from 'vuex'

export default {
  components: { Multiselect },
  computed: {
    ...mapState(['geogridWidget', 'pointDistances']),
    errors() { return this.$store.state.geogridWidget.errors },
    model: {
      get() {
        return this.pointDistances[this.geogridWidget.gridDistanceMeasure]
          .find((distance) => distance.id === this.$store.state.geogridWidget.gridPointDistance)
      },
      set(val) { this.$store.commit('changeGeogridWidgetKey', { key: 'gridPointDistance', val: val.id }) }
    }
  }
}
</script>
