<template>
  <div class="audit-card-qa" ref="card">
    <div class="audit-card-qa__top">
      <div v-if="qna.author" class="audit-card-qa__author">
        <div v-if="qna.author.avatar" class="audit-card-qa__avatar">
          <img :src="qna.author.avatar" :alt="`${qna.author.name} avatar`">
        </div>
        <div class="audit-card-qa__wrapper">
          <a v-if="qna.author.homepage" :href="qna.author.homepage" target="_blank">{{ qna.author.name }}</a>
          <p v-else>{{ qna.author.name }}</p>
          <span v-if="qna.author.rank">{{ qna.author.rank }}</span>
        </div>
      </div>
      <span class="audit-card-qa__date">{{ qna.when }}</span>
    </div>
    <div class="audit-card-qa__body">
      <p v-if="qna.body" class="audit-card-qa__text">{{ qna.body }}</p>
      <div v-if="qna.answers?.length" class="audit-card-qa__responses">
        <div v-for="answer in qna.answers" :key="answer.id" class="audit-card-qa__response">
          <div class="audit-card-qa__response-top">
            <div v-if="answer.author" class="audit-card-qa__author">
              <div v-if="answer.author.avatar" class="audit-card-qa__avatar">
                <img :src="answer.author.avatar" :alt="`${answer.author.name} avatar`">
              </div>
              <div class="audit-card-qa__wrapper">
                <a v-if="answer.author.homepage" :href="answer.author.homepage" target="_blank">{{ answer.author.name }}</a>
                <p v-else>{{ answer.author.name }}</p>
                <span v-if="answer.author.rank">{{ answer.author.rank }}</span>
              </div>
            </div>
            <span class="audit-card-qa__date">{{ answer.when }}</span>
          </div>
          <p class="audit-card-qa__text">{{ answer.body }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// todo: adjust when backend arrives
export default {
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  computed: {
    qna() {
      const {
        body, author, when, answers
      } = this.content

      return {
        body,
        when,
        author,
        answers
      }
    }
  }
}
</script>
