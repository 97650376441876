import RequestsTable from '../../vue_widgets/admin/private_instance_requests_table'

Styxie.Initializers.AdminPrivateInstanceRequests = {
  index: () => {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-private-instance-requests'),
        render: (h) => h(RequestsTable)
      })
    })
  }
}
