<template>
  <ul class="sidebar scroll hor">
    <li v-if="locations.length > 1">
      <a href="#" :class="['nav-link', { active: !chosenLocation }]" @click.prevent="selectLocation(null)">
        <span>All Locations</span>
      </a>
    </li>
    <li v-for="{ id, name } in locations" :key="id">
      <a href="#" :class="['nav-link', { active: chosenLocation && chosenLocation.id === id }]" @click.prevent="selectLocation(id)">
        <span>{{ name }}</span>
      </a>
    </li>
  </ul>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  computed: mapState(['chosenLocation', 'locations']),
  methods: mapActions(['selectLocation'])
}
</script>
