<template>
  <tr>
    <td column-caption="Business" class="column">
      <a class="location-name" target="_blank" :href="mapLink">
        {{ row.placeAttrs.name }}
      </a>
      <span class="d-block text-muted">{{ row.placeAttrs.address ?? 'Service Area Business' }}</span>
    </td>
    <td column-caption="Search term">
      {{ row.keyword }}
    </td>
    <td column-caption="Site">
      {{ siteFrom }}
    </td>
    <td column-caption="Client" class="gg-client">
      <div class="text-right text-lg-left">
        <a class="d-block client-email" :href="`mailto:${row.email}`">{{ row.email }}</a>
        <span v-if="row.name" class="d-block text-muted">{{ row.name }}</span>
        <span v-if="row.phone" class="d-block text-muted">{{ row.phone }}</span>
      </div>
    </td>
    <td column-caption="Created at" class="gg-client">
      {{ formattedDate }}
    </td>
    <td column-caption="Geogrid" class="gg-state">
      <div v-if="row.geogrid" class="d-flex align-items-center gap-2 justify-content-end flex-wrap">
        <span
          v-if="row.geogrid.state === 'error'"
          class="color-danger">
          <i class="far fa-exclamation-square" />
          Smth went wrong
        </span>
        <template v-else-if="ggLink">
          <a :href="ggLink">
            <i class="far fa-eye" />
          </a>
          <a :href="pdfLink">
            <i class="far fa-file-pdf" />
          </a>
          <a v-if="row.geogrid.imagePath" :href="row.geogrid.imagePath">
            <i class='far fa-file-image' />
          </a>
        </template>
        <span v-else>{{ row.geogrid.state }}</span>
      </div>
    </td>
  </tr>
</template>

<script>
import qs from 'qs'
import moment from 'moment'

export default {
  props: {
    row: { type: Object, required: true }
  },
  computed: {
    ggLink() {
      if (this.row.geogrid && this.row.geogrid.state === 'finished') return this.row.geogrid.path
      return false
    },
    mapLink() {
      const params = {
        api: 1,
        query_place_id: this.row.placeId,
        query: this.row.placeAttrs.address
      }

      return `https://www.google.com/maps/search/?${qs.stringify(params)}`
    },
    pdfLink() {
      const { wlcDomain } = this.$parent.$parent
      return `//${wlcDomain}/stats/geogrids/${this.row.geogrid.id}.pdf`
    },
    siteFrom() {
      return new URL(this.row.related.url).host
    },
    formattedDate() {
      return moment(this.row.createdAt).format('MMM D, YYYY h:mm A')
    }
  }
}
</script>
