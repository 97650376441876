import BusinessesStep from '../../edit_steps/geobooster_widgets_businesses'

export default {
  components: {
    BusinessesStep
  },
  data: () => ({
    steps: {
      business: {
        businesses: {
          component: 'CustomSelect',
          name: 'businesses',
          value: '',
          label: 'Business',
          values: [],
          error: false,
          errorText: ''
        },
        selectedBusinesses: [],
        initialBusinessesIds: []
      }
    }
  }),
  methods: {
    updateBusinessesSelect(newSelectedBusinesses) {
      this.steps.business.selectedBusinesses = newSelectedBusinesses
    }
  }
}
