<template>
  <div class="accordion">
    <h3 @click.prevent="open = !open">
      <slot name="title" />
      <i :class="`far fa-angle-${open ? 'up' : 'down'}`" />
    </h3>

    <TransitionExpand>
      <slot v-if="open" name="body" />
    </TransitionExpand>
  </div>
</template>

<script>
import TransitionExpand from 'vue_widgets/components/transition_expand'

export default {
  components: { TransitionExpand },
  props: {
    // accordion: { type: Object, required: true }
  },
  data() {
    return {
      open: false
    }
  }
}
</script>
