import snakeCaseKeys from 'snakecase-keys'

export function expandQuery(str) {
  const params = {}

  if (str) {
    str.substring(1).replace(/\+/g, '%20').replace(/([^=&]+)=([^&]*)/g, (_, key, val) => {
      params[decodeURIComponent(key)] = decodeURIComponent(val)
    })
  }

  return params
}

export function searchParams() {
  return expandQuery(window.location.search)
}

export function updateSearchParams(options) {
  const params = searchParams()
  Object.assign(params, snakeCaseKeys(options))
  window.location.search = `?${$.param(params)}`
}

export function removeQueryParametersFromUrl(...parameterNames) {
  const params = new URLSearchParams(window.location.search)
  parameterNames.forEach((parameterName) => {
    params.delete(parameterName)
  })
  let stringifiedParams = params.toString()
  if (!stringifiedParams.startsWith('?') && stringifiedParams) {
    stringifiedParams = `?${stringifiedParams}`
  }
  const newUrl = `${window.location.pathname}${stringifiedParams}`

  window.history.pushState({}, document.title, newUrl)
}
