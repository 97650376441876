(($) => $.extend($.fn, {
  turboTable(hsh) {
    if (this.length !== 1) throw new Error('Specify right selector')

    const $this = $(this)
    if ($.fn.dataTable.isDataTable($this)) return false

    const $dataTable = $this.DataTable(hsh)

    $(document).one('turbolinks:before-cache', () => {
      if ($dataTable) $dataTable.destroy()
    })

    return $dataTable
  }
}))($ || jQuery)
