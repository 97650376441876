import { pick, populateLocalStorage } from '../../helpers'

export default function geogridAttrsPresetsDefined(data) {
  const attrs = pick(
    data,
    [
      'grid_size', 'grid_distance_measure',
      'grid_point_distance', 'local_language_enabled',
      'should_notify'
    ]
  )
  populateLocalStorage(attrs)
}
