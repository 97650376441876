import toastr from 'toastr'
import axios from 'axios'

import { DEFAULT_ERROR_MESSAGE } from '../../common/constants'
import { ensureGoogleMap } from '../../common/map_helpers'

const TIME_REGEX = /^(([01]\d|2[0-3]):([0-5]\d))|(24:00)$/
const DATE_REGEX = /^([1-9]|1[012])[- /.]([1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/

function randomString(length = 7) {
  return Math.random().toString(36).substring(length)
}

function toggleError($target, $submit, $alert, bool, text = '22:45') {
  $target.toggleClass('is-invalid', bool)
  $submit.attr('disabled', bool)
  $alert.find('strong').text(text)
  $alert.attr('hidden', !bool)
}

function removeAdditionalHours($tr) {
  const $next = $tr.next()
  if ($next.hasClass('js-additional-hours')) {
    removeAdditionalHours($next)
    $next.remove()
  }
}

function bindRegularHoursEvents($modal, $submit) {
  const $alert = $modal.find('.alert')

  // add row
  $modal.on('click', '.js-plus-hours', (e) => {
    e.preventDefault()

    const $target = $(e.currentTarget)
    const $tr = $target.closest('tr')
    const $newTr = $tr.clone()
    $newTr.find('td:nth-child(-n+2)').empty()
    $newTr.find('.js-plus-hours').remove()
    $newTr.find('.js-delete-hours').removeAttr('hidden')
    $newTr.addClass('js-additional-hours')
    $tr.after($newTr)
  })

  // changing working
  $modal.find('.js-open').change((e) => {
    const bool = e.currentTarget.checked
    const $tr = $(e.currentTarget).closest('tr')
    $tr.find('.js-open-def').text(bool ? 'Open' : 'Closed')
    $tr.find('.form-field').attr('hidden', !bool).attr('disabled', !bool)
    $tr.find('.js-plus-hours').attr('hidden', !bool)
    $tr.find('input[type="hidden"]').attr('disabled', !bool)

    removeAdditionalHours($tr)
  })

  // removing row
  $modal.on('click', '.js-delete-hours', (e) => {
    e.preventDefault()
    $(e.currentTarget).closest('tr').remove()
  })

  // validation
  $modal.on('keyup', '.form-control', (e) => {
    const $target = $(e.currentTarget)
    toggleError($target, $submit, $alert, !$target.val().match(TIME_REGEX))
  })
}

function bindSpecialHoursEvents($modal, $submit) {
  const $alert = $modal.find('.alert')
  const $tbody = $modal.find('tbody')
  const $template = $tbody.find('tr[hidden]')

  // adding new row
  $modal.on('click', '#js-add-date', () => {
    const $clone = $template.clone()
    $clone.removeAttr('hidden')
    $clone.find('input').removeAttr('disabled')
    const checkId = randomString()
    $clone.find('.switch-button input[type="checkbox"]').attr('id', checkId)
    $clone.find('.switch-button label').attr('for', checkId)
    $tbody.append($clone)
  })

  // adding more hours
  $modal.on('click', '.js-plus-hours', (e) => {
    e.preventDefault()
    const $tr = $(e.currentTarget).closest('tr')
    const $newTr = $tr.clone()
    $newTr.find('td:first-child input').attr('hidden', true)
    $newTr.find('.switch-button, .js-plus-hours, .js-open-def').remove()
    $newTr.addClass('js-additional-hours')
    $tr.after($newTr)
  })

  // changing working
  $modal.on('change', '.js-open', (e) => {
    const bool = e.currentTarget.checked
    const $tr = $(e.currentTarget).closest('tr')

    $tr.find('.js-open-def').text(bool ? 'Closed' : 'Open')
    $tr.find('.time-input').attr('hidden', bool).attr('disabled', bool)
    $tr.find('.js-plus-hours').attr('hidden', bool)

    removeAdditionalHours($tr)
  })

  // validate date
  $modal.on('keyup', '.js-date-input', (e) => {
    const $target = $(e.currentTarget)
    const isCorrect = $target.val().match(DATE_REGEX)
    toggleError($target, $submit, $alert, !isCorrect, '1/11/2019')
    $target.siblings('.form-control').val($target.val())
  })

  // validate time
  $modal.on('keyup', '.time-input', (e) => {
    const $target = $(e.currentTarget)
    toggleError($target, $submit, $alert, !$target.val().match(TIME_REGEX))
  })

  // removing row
  $modal.on('click', '.js-delete-hours', (e) => {
    e.preventDefault()
    const $tr = $(e.currentTarget).closest('tr')
    if (!$tr.hasClass('js-additional-hours')) removeAdditionalHours($tr)
    $tr.remove()
  })
}

function bindPhonesEvents($modal) {
  $modal.on('click', '.js-delete-phone', (e) => {
    e.preventDefault()
    e.target.closest('tr').remove()
  })

  $modal.on('click', '#js-add-phone', (e) => {
    e.preventDefault()

    const id = randomString()
    $modal.find('tbody').append($('<tr>', {
      html: [
        $('<td>', {
          html: [
            $('<div>', {
              class: 'form-group',
              html: [
                $('<label>', { text: 'Additional phone', for: id }),
                $('<input>', {
                  type: 'text', class: 'form-control', name: 'gmb_location[additional_phones][]', id: id
                })
              ]
            })
          ]
        }),
        $('<td>', {
          class: 'delete-action',
          html:
            $('<a>', {
              class: 'btn btn-rounded btn-space btn-secondary js-delete-phone',
              href: '#',
              html: $('<i>', { class: 'icon far fa-xmark' })
            })
        })
      ]
    }))
  })
}

function bindServiceAreaEvents($modal) {
  const $clearBtn = $modal.find('.js-clear-areas')

  const $select = $modal.find('#js-service-select').select2({
    width: '100%',
    minimumInputLength: 3,
    dropdownParent: $modal,
    ajax: {
      url: '/api/geogrids/service_areas_search',
      data: (params) => ({ term: params.term }),
      processResults: (results) => ({ results })
    }
  }).on('change', (e) => {
    $clearBtn.prop('hidden', e.currentTarget.value === '')
  })

  $clearBtn.click((e) => {
    e.preventDefault()
    $select.val(null).trigger('change')
  })
}

function createEmptyFormData($form) {
  const data = new FormData()
  data.append('_method', 'patch')
  data.append('authenticity_token', $form.find('[name="authenticity_token"]').val())
  return data
}

function addressFields($modal) {
  return {
    $addrLine: $modal.find('[name="gmb_location[gmb_address][address_lines][]"]'),
    $city: $modal.find('#gmb_location_gmb_address_locality'),
    $area: $modal.find('#gmb_location_gmb_address_administrative_area'),
    $zip: $modal.find('#gmb_location_gmb_address_postal_code'),
    $regionCode: $modal.find('#gmb_location_gmb_address_region_code'),
    $languageCode: $modal.find('#gmb_location_gmb_address_language_code')
  }
}

function serializeForm(control, $form) {
  if (control === 'service_area') {
    const data = createEmptyFormData($form)
    const places = $form.find('#js-service-select').select2('data')

    if (places.length) {
      places.forEach((el) => {
        data.append('gmb_location[service_area][places][place_infos][][place_id]', el.id)
        data.append('gmb_location[service_area][places][place_infos][][name]', el.text)
      })
    } else {
      data.append('gmb_location[service_area][places][place_infos][]', '')
    }

    data.append(
      'gmb_location[service_area_locked]',
      $form.find('#gmb_location_service_area_locked').is(':checked')
    )

    return data
  }
  if (control === 'gmb_address') {
    const data = createEmptyFormData($form)
    const fields = addressFields($form)

    Object.keys(fields).forEach((key) => {
      fields[key].each((_, el) => {
        data.append(el.name, el.value)
      })
    })

    return data
  }

  if (control === 'category_id') {
    const data = new FormData($form.get(0))
    const additional = data.getAll('gmb_location[additional_categories][]')
    data.delete('gmb_location[additional_categories][]')
    additional.forEach((el) => {
      if (el.length) data.append('gmb_location[additional_categories][]', el)
    })

    return data
  }

  return new FormData($form.get(0))
}

// eslint-disable-next-line no-unused-vars
function bindCategoriesEvents($modal, params) {
  $modal.find('select').select2({
    width: '100%',
    minimumInputLength: 3,
    dropdownParent: $modal,
    ajax: {
      url: '/api/gmb/categories/search',
      data: (prms) => ({ q: prms.term, country_code: params.countryCode }),
      processResults: (results) => ({ results })
    }
  })
}

function bindLabelsEvents($modal) {
  $('#js-location-labels').select2({
    width: '100%',
    tags: true,
    tokenSeparators: [','],
    dropdownParent: $modal,
    multiple: true
  })

  $('#js-location-labels > option').prop('selected', 'selected')
  $('#js-location-labels').trigger('change')
}

function bindAddress(prms) {
  const fields = addressFields(prms.$modal)
  const $addLine = prms.$modal.find('#js-add-line')
  const $tableBody = prms.$modal.find('.table tbody')

  function findValue(arr, key, name = 'long_name') {
    const el = arr.find((q) => q.types.includes(key))
    return el ? el[name] : ''
  }

  const $map = prms.$modal.find('#js-addr-map')
  const $mapToggle = prms.$modal.find('#js-show-map')
  const $applyToggle = prms.$modal.find('#js-apply-addr')
  const lat = prms.coords ? prms.coords.lat : 0
  const lng = prms.coords ? prms.coords.lng : 0

  const myLatlng = new google.maps.LatLng(lat, lng)
  const mapOptions = {
    zoom: 15,
    center: myLatlng,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false
  }

  let addr = []
  const map = new google.maps.Map(document.getElementById('js-addr-map'), mapOptions)
  const geocoder = new google.maps.Geocoder()
  new google.maps.Marker({
    position: myLatlng,
    map: map,
    draggable: true,
    title: 'Drag me!'
  }).addListener('dragend', (e) => {
    geocoder.geocode({ location: e.latLng }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          addr = results[0].address_components
          $applyToggle.attr('hidden', false)
        } else {
          toastr.error('No results found')
        }
      } else {
        toastr.error(`Geocoder failed due to: ${status}`)
      }
    })
  })

  $mapToggle.click((e) => {
    e.preventDefault()

    $map.attr('hidden', false)
    $mapToggle.attr('hidden', true)
  })

  $applyToggle.click((e) => {
    e.preventDefault()
    fields.$city.val(findValue(addr, 'locality', 'long_name'))
    fields.$regionCode.val(findValue(addr, 'country', 'short_name'))
    fields.$zip.val(findValue(addr, 'postal_code'))
    fields.$languageCode.val(findValue(addr, 'language_code'))
    fields.$area.val(findValue(addr, 'administrative_area_level_1'))
    fields.$addrLine.val(`${findValue(addr, 'route')} ${findValue(addr, 'street_number')}`)

    $map.attr('hidden', true)
    $mapToggle.attr('hidden', false)
    $applyToggle.attr('hidden', true)
  })

  $tableBody.on('click', '.js-delete-line', (e) => {
    e.preventDefault()
    e.target.closest('tr').remove()
  })

  $addLine.click((e) => {
    e.preventDefault()
    $tableBody.append($('<tr>', {
      html: [
        $('<td>', {
          html: $('<input>', {
            type: 'text',
            class: 'form-control',
            name: 'gmb_location[gmb_address][address_lines][]'
          })
        }),
        $('<td>', {
          html: $('<a>', {
            class: 'btn btn-rounded btn-secondary ml-2 js-delete-line',
            href: '#',
            html: $('<i>', { class: 'icon far fa-xmark' })
          })
        })
      ]
    }))
  })

  const $deleteLink = prms.$modal.find('#js-delete-address')
  if ($deleteLink.length) {
    $deleteLink.click((e) => {
      e.preventDefault()

      Object.keys(fields).forEach((key) => {
        fields[key].val('')
      })

      $tableBody.html('')
      $addLine.attr('hidden', true)
    })
  }
}

function loadEvents(params, href, $modal) {
  const $submit = $modal.find('.btn-primary')
  const $form = $modal.find('form')
  const $alert = $modal.find('.alert')
  const control = new URL(href).searchParams.get('control')
  $form.submit((e) => {
    let stopSubmit = false
    e.preventDefault()

    // prevent submit on address autocomplete enter
    if (document.activeElement.id === 'gmb_location_address_autocomplete') {
      return
    }

    $form.find('.js-date-input').filter(':visible').each((_i, el) => {
      const $el = $(el)
      if (!$el.val().match(DATE_REGEX)) {
        stopSubmit = true
        toggleError($el, $submit, $alert, !$el.val().match(TIME_REGEX), '1/11/2022')
      }
    })

    if (stopSubmit) {
      return
    }

    $form.toggleWrapper()

    axios.patch($form.attr('action'), serializeForm(control, $form))
      .then(
        () => { window.location.reload() },
        (err) => {
          $submit.attr('disabled', false)
          $form.toggleWrapper({}, false)
          if (err.response && err.response.data && err.response.data.template) {
            const $template = $(err.response.data.template).find('.modal-body')
            $modal.find('.modal-body').replaceWith($template)
            if (control === 'service_area') bindServiceAreaEvents($modal)
            if (control === 'category_id') bindCategoriesEvents($modal, params)
            if (control === 'gmb_address') bindAddress({ googleMapApiKey: params.googleMapApiKey, $modal: $modal, coords: params.coords })
            if (control === 'gmb_dash_id') toastr.error(err.response.data.errors)
          }
        }
      )
  })

  if (href.indexOf('profile') !== -1) {
    const $textarea = $modal.find('textarea')
    $textarea.on('keyup', (e) => { e.currentTarget.style.height = `${e.currentTarget.scrollHeight + 4}px` })
    $modal.one('shown.bs.modal', () => { $textarea.trigger('keyup') })
  }

  if (control === 'category_id') bindCategoriesEvents($modal, params)
  if (control === 'regular_hours') bindRegularHoursEvents($modal, $submit)
  if (control === 'special_hours') bindSpecialHoursEvents($modal, $submit)
  if (control === 'primary_phone') bindPhonesEvents($modal, $submit)
  if (control === 'service_area') bindServiceAreaEvents($modal)
  if (control === 'gmb_address') ensureGoogleMap({ googleMapApiKey: params.googleMapApiKey, $modal: $modal, coords: params.coords }, bindAddress)
  if (control === 'labels') bindLabelsEvents($modal)
}

function toggleLocationState({ apiOrgLocationPath }) {
  const ajaxSettings = {
    checked: {
      successMessage: 'Location was enabled.',
      url: `${apiOrgLocationPath}/enable`
    },
    unchecked: {
      successMessage: 'Location was disabled.',
      url: `${apiOrgLocationPath}/disable`
    },
    errorMessage: 'Something went wrong! Please try again later.'
  }

  $('.js-gmb-location-enabled-checkbox').on('change', (e) => {
    const $target = $(e.currentTarget)
    const state = $target.prop('checked') ? 'checked' : 'unchecked'
    const param = ajaxSettings[state]
    $target.prop('disabled', true)
    $('.alert-info').toggleClass('d-none')
    $('.nav-tabs').toggleClass('disabled-tabs')

    $.ajax2({
      method: 'POST',
      url: param.url,
      error: (res) => {
        const message = (res.responseJSON && res.responseJSON.error) || ajaxSettings.errorMessage
        toastr.warning(message)
        $target.prop('checked', !$target.prop('checked'))
      },
      success: () => {
        toastr.success(param.successMessage)
      },
      complete: () => {
        $target.prop('disabled', false)
      }
    })
  })
}

Styxie.Initializers.GmbLocations = {
  edit: (params) => {
    const ICON_CLASSES = ['fa-spinner', 'fa-pulse', 'fa-sliders-up']
    $(() => {
      toggleLocationState(params)
      $('.js-edit-control').click((e) => {
        e.preventDefault()
        const target = e.currentTarget
        const icon = target.querySelector('i')
        ICON_CLASSES.forEach((c) => icon.classList.toggle(c))
        target.classList.add('disabled')

        $.ajax({
          url: target.href,
          success: (res) => {
            const $modal = $(res.template)
            $('body').append($modal)
            $modal.one('hidden.bs.modal', () => { $modal.remove() })
            $modal.modal('show')
            loadEvents(params, target.href, $modal)
          },
          complete: () => {
            target.classList.remove('disabled')
            ICON_CLASSES.forEach((c) => icon.classList.toggle(c))
          },
          error: () => {
            toastr.error(DEFAULT_ERROR_MESSAGE)
          }
        })
      })
    })
  },
  admin_page: (params) => {
    const ICON_CLASSES = ['fa-spinner', 'fa-pulse', 'fa-sliders-up']
    $(() => {
      $('.js-edit-control').click((e) => {
        e.preventDefault()
        const target = e.currentTarget
        const icon = target.querySelector('i')
        ICON_CLASSES.forEach((c) => icon.classList.toggle(c))
        target.classList.add('disabled')

        $.ajax({
          url: target.href,
          success: (res) => {
            const $modal = $(res.template)
            $('body').append($modal)
            $modal.one('hidden.bs.modal', () => { $modal.remove() })
            $modal.modal('show')
            loadEvents(params, target.href, $modal)
            $("[data-use='select2']").each(function chooser() {
              const $this = $(this)
              $this.select2({
                width: '100%',
                minimumInputLength: 3,
                allowClear: true,
                placeholder: '',
                ajax: {
                  url: $this.data('source'),
                  data: (prms) => ({ q: prms.term, page: prms.page || 1 }),
                  processResults: (data) => ({
                    results: data.results,
                    pagination: {
                      more: (data.page * 1) < data.count_filtered
                    }
                  })
                }
              })
            })
          },
          complete: () => {
            target.classList.remove('disabled')
            ICON_CLASSES.forEach((c) => icon.classList.toggle(c))
          },
          error: () => {
            toastr.error(DEFAULT_ERROR_MESSAGE)
          }
        })
      })

      $('.js-organizations-location-settings-checkbox').change((e) => {
        const { checked } = e.target
        const text = `${checked ? 'En' : 'Dis'}abled`
        const data = new FormData()
        data.set('organizations_location[allow_edit_location_attributes]', checked)
        axios.patch(params.apiAdminOrgLocationPath, data)
          .then(() => toastr.success(`${text} successfully`))
          .catch(() => {
            e.target.checked = !checked
            toastr.error(`${text} failed`)
          })
      })
    })
  }
}
