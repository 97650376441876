<template>
  <div>
    <ul class="nav nav-pills mb-3">
      <li v-for="(text, key) in tabs" class="nav-item">
        <a class="nav-link" :class="{ active: key === imageSelectTab }" href="#" @click.prevent="changeImageSelectTab(key)">{{ text }}</a>
      </li>
    </ul>

    <component :is="`select-${imageSelectTab}`" />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import SelectGallery from './select/gallery'
import SelectUpload from './select/upload'

export default {
  data() {
    return {
      tab: 'upload'
    }
  },
  computed: mapState(['imageSelectTab']),
  methods: mapMutations(['changeImageSelectTab', 'changeImageStep']),
  components: { SelectGallery, SelectUpload },
  created() {
    this.tabs = {
      upload: 'Upload',
      gallery: 'Gallery'
    }
  }
}
</script>
