<template>
  <p class="json-ld__warning" :class="{[`json-ld__warning--${modifier}`]: modifier}">
    We were unable to retrieve the code from this page.
    <span v-if="status">
      <br>
      <template v-if="isStatusOk">
        JSON-LD was not found on this page.
      </template>
      <template v-else-if="pageTimedOut">
        Page is unreachable.
      </template>
      <template v-else-if="noWebsite">
        This competitor doesn't have a website
      </template>
      <template v-else>
        Page responded with a status of {{ status }}.
      </template>
    </span>
  </p>
</template>

<script>
const PAGE_TIMEOUT_STATUS = -1
const NO_WEBSITE_STATUS = -2

export default {
  props: {
    status: {
      type: Number,
      required: true
    },
    modifier: {
      type: String,
      required: false
    }
  },
  created() {
    this.isStatusOk = this.status >= 200 && this.status < 300
    this.pageTimedOut = this.status === PAGE_TIMEOUT_STATUS
    this.noWebsite = this.status === NO_WEBSITE_STATUS
  }
}
</script>
