<template>
  <div>
    <FilterComponent :fields="fields" @onFilterChange="onFilterChange" :session-key="sessionKey" class="mb-3 flex-column flex-lg-row" />

    <VueSlimTable
      ref="table"
      :source="tableSource"
      :columns="columns"
      :per-page="perPage"
      class="responsive-table borderless-table m-0">
      <template #head:scheduledAt="{ column }">
        {{ column.title }}
        <i class="far fa-circle-info" :id="`${_uid}-mlp-scheduledAt`" />
        <b-popover :target="`${_uid}-mlp-scheduledAt`" triggers="hover" placement="top">
          <div class="text-dark">
            In location timezone
          </div>
        </b-popover>
      </template>
      <template #head:action="{ column }">
        <div class="text-right">
          {{ column.title }}
        </div>
      </template>
      <template #row="{ row }">
        <TableRow :row="row"
          :base-url="baseUrl"
          :api-base-url="apiBaseUrl"
          :first-location-id="filter.by_location"
          @reload="reload" />
      </template>
      <template #pagination>
        <Pagination :page="currentPage" :per-page="perPage" :total-count="totalRecords" @updatePage="updatePageNumber" />
      </template>
    </VueSlimTable>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import FilterComponent from 'vue_widgets/components/filter'
import Pagination from 'vue_widgets/components/pagination'
import axiosTransform from 'common/axios'
import { MLP_FILTER_KEY } from 'common/constants'
import TableRow from './table_row'

export default {
  components: { FilterComponent, Pagination, TableRow },
  props: {
    authors: { type: Array, default: () => []},
    baseUrl: { type: String, required: true },
    apiLocationsPath: { type: String, required: true }
  },
  data() {
    return {
      fields: [{
        type: 'select',
        key: 'by_location',
        title: 'By Location',
        classes: 'w-100 w-lg-50',
        placeholder: 'All',
        trackBy: 'id',
        label: 'name',
        options: [],
        sourceOptions: this.getLocations,
        searchable: true,
        internalSearch: false,
        showLabels: false,
        allowEmpty: true,
        maxHeight: 540
      }, {
        type: 'select',
        key: 'by_author',
        title: 'By Author',
        classes: 'w-100 w-lg-25',
        placeholder: 'All',
        trackBy: 'id',
        label: 'name',
        options: this.authors,
        searchable: false,
        showLabels: false,
        allowEmpty: true,
        maxHeight: 540
      }, {
        type: 'selectMonth',
        key: 'by_period',
        title: 'Period',
        classes: 'w-100 w-lg-25'
      }],

      currentPage: 1,
      perPage: 10,
      totalRecords: 0,
      filter: {}
    }
  },
  created() {
    this.columns = [
      { title: 'Created', key: 'createdAt', orderable: false },
      { title: 'Locations', key: 'locations', orderable: false },
      { title: 'Publishing Date', key: 'scheduledAt', orderable: false },
      { title: 'Total Posts', key: 'postsCount', orderable: false },
      { title: 'Author', key: 'author', orderable: false },
      { title: 'Actions', key: 'action', orderable: false }
    ]
    this.apiBaseUrl = `/api${this.baseUrl}`
    this.sessionKey = `${MLP_FILTER_KEY}_${Cookies.get('user_email')}`
  },
  methods: {
    onFilterChange(newFilterValues) {
      this.filter = newFilterValues
      this.currentPage = 1
      this.reload()
    },
    tableSource(prms) {
      this.totalRecords = 0
      const params = {
        ...prms,
        ...this.filter,
        page: this.currentPage
      }

      return axios.get(
        this.apiBaseUrl,
        {
          params,
          paramsSerializer(json) { return qs.stringify(json, { arrayFormat: 'brackets' }) }
        }
      )
        .then((res) => {
          this.totalRecords = parseInt(res.headers['total-count'], 10)
          return res.data
        })
    },
    updatePageNumber(page) {
      this.currentPage = page
      this.reload()
    },
    reload() {
      this.$refs.table?.refetch()
    },
    getLocations(params = {}) {
      return axios.get(this.apiLocationsPath, { params, axiosTransform })
    }
  }
}
</script>
