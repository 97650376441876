<template>
  <div class="table-bulk-head-row">
    <div
      v-for="column in columns"
      :key="column.key"
      :class="['table-bulk-head-cell', { 'vst-orderable': column.orderable }]"
      v-on="column.orderable ? { click: () => onOrderClick(column.key) } : {}">
      <div v-if="column.orderable">
        <slot
          :name="`thead:${column.key}`"
          :column="column">
          {{ column.title }}
        </slot>

        <a href="#" :class="['vst-orderable-toggle', orders[column.key]]" @click.prevent />
      </div>

      <slot
        v-else
        :name="`thead:${column.key}`"
        :column="column">
        {{ column.title }}
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    columns: { type: Array, required: true }
  },
  data() {
    return {
      orders: {}
    }
  },
  mounted() {
    this.orders = this.$parent.$refs.table.orders
  },
  methods: {
    onOrderClick(key) {
      if (this.orders[key] === 'asc') {
        this.orders = { [key]: 'desc' }
      } else if (this.orders[key] === 'desc') {
        this.orders = {}
      } else {
        this.orders = { [key]: 'asc' }
      }

      this.$parent.$refs.table.orders = this.orders
    }
  }
}
</script>
