<template>
  <div class="d-flex justify-content-between pt-2 pb-3">
    <InputSearch v-model="search" placeholder="Search" class="w-100" />
  </div>
</template>

<script>
import InputSearch from '../../components/input_search_ui'

export default {
  components: { InputSearch },
  data() {
    return {
      search: '',
      timeout: null
    }
  },
  watch: {
    search() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.$emit('setFilter', this.search.trim())
      }, 500)
    }
  }
}
</script>
