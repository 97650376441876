<template>
  <div class="chart-switcher">
    <label class="chart-radio">
      <input
        :name="name"
        :value="valueLeft"
        type="radio"
        v-model="chartType">
      <slot name="left-icon">
        <i class="icon far fa-chart-scatter" />
      </slot>
    </label>
    <label class="chart-radio">
      <input
        :name="name"
        :value="valueRight"
        type="radio"
        v-model="chartType">
      <slot name="right-icon">
        <i class="icon far fa-chart-line" />
      </slot>
    </label>
  </div>
</template>

<script>
import { extractSessionStorage, insertSessionStorage } from 'common/helpers'

export default {
  props: {
    name: {
      type: String,
      required: true
    },
    valueLeft: { type: String, default: 'other' },
    valueRight: { type: String, default: 'line' }
  },
  data() {
    return {
      chartType: 'other'
    }
  },
  created() {
    this.chartType = extractSessionStorage(this.name) || this.valueLeft
  },
  watch: {
    chartType() {
      insertSessionStorage(this.name, this.chartType)
      this.$emit('input', this.chartType)
    }
  }
}
</script>
