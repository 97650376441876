<template>
  <div class="form-field mb-5">
    <span>White label configuration</span>
    <MultiSelect v-model="selectedWLC"
      :options="wlConfigurations"
      track-by="id"
      label="domain"
      :searchable="true"
      :show-labels="false"
      :allow-empty="true">
      <template slot="singleLabel" slot-scope="{ option }">
        {{ optionText(option) }}
      </template>
      <template slot="option" slot-scope="{ option }">
        {{ optionText(option) }}
      </template>
    </MultiSelect>
    <small class="text-muted">Leave empty to use default White Label configuration.</small>
    <input type="hidden" name="report_configuration[white_label_configuration_id]" :value="selectedWLC ? selectedWLC.id : ''">
  </div>
</template>

<script>
import MultiSelect from 'vue-multiselect'

export default {
  components: { MultiSelect },
  props: {
    wlConfigurations: { type: Array, default: () => []},
    wlConfiguration: { type: Object, default: null }
  },
  data() {
    return {
      selectedWLC: this.wlConfiguration
    }
  },
  methods: {
    optionText(option) {
      if (option.publicSubdomain || option.domain) {
        return `${option.publicSubdomain} / ${option.domain ? option.domain : ''}`
      }

      return ''
    }
  }
}
</script>
