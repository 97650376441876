<template>
  <form class="form-size" @submit.prevent="submit">
    <div class="form-field">
      <span>Audit Report Name</span>
      <input type="text" v-model="auditReportConfiguration.name">
      <small class="d-block my-1 text-muted">Optional, dynamically generated name is shown when blank</small>
      <small v-if="error['name']" class="text-danger">{{ error['name'].join(', ') }}</small>
    </div>

    <hr class="delimiter">

    <Schedule v-model="auditReportConfiguration.scheduleAttributes" :error="error" />

    <hr class="delimiter">

    <div v-if="configuration.id" class="d-flex align-items-center gap-2 mt-3 mb-4 text-warning font-weight-semi-bold">
      <i class="far fa-exclamation-circle" />
      Select new competitors after editing the location, keyword or advanced grid settings to ensure an accurate audit.
    </div>

    <div class="form-field mb-4 pb-2">
      <span>Keyword</span>
      <input type="text" v-model="auditReportConfiguration.keywords" :disabled="configuration.createdViaPublicApi">
      <small v-if="error['keywords']" class="text-danger">{{ error['keywords'].join(', ') }}</small>
      <p class="text-muted p-t-1">Use only one search term or phrase per audit. Multiple might affect output results</p>
    </div>

    <div class="form-field mb-5">
      <span>Choose location</span>
      <Multiselect
        v-model="auditReportConfiguration.location"
        :options="locationsList"
        :allow-empty="false"
        :internal-search="false"
        :searchable="true"
        :show-labels="false"
        :max-height="540"
        @search-change="searchingLocationList"
        :disabled=configuration.createdViaPublicApi>
        <template slot="singleLabel" slot-scope="{ option }">
          {{ option.name }}
        </template>
        <template slot="option" slot-scope="{ option }">
          <div :class="['option__desc text-truncate', { 'text-muted': !option.coords }]">
            <span class="option__title">{{ option.name }}</span>
            <br>
            <small v-if="option.locationType === 'sab'" class="d-block option__small">Service Area Business</small>
            <small v-else-if="option.address" class="d-block option__small">{{ option.address }} </small>
            <small v-if="option.storeCode" class="d-block option__small">{{ option.storeCode }} </small>
            <small v-if="!option.hasCoords" class="d-block text-danger">Has no coords</small>
            <small v-if="!option.placeId" class="d-block text-danger">Has no place ID</small>
            <small v-if="!option.cid" class="d-block text-danger">Has no customer ID</small>
          </div>
        </template>
        <template slot="afterList">
          <div v-observe-visibility="reachedEndOfList" />
        </template>
      </Multiselect>
      <small v-if="error['location_id']" class="text-danger">{{ error['location_id'].join(', ') }}</small>
    </div>

    <div v-if="!organizationLinda" class="form-field mb-5">
      <span>Choose white label configuration</span>
      <Multiselect
        v-model="auditReportConfiguration.whiteLabelConfiguration"
        :options="whiteLabelList"
        track-by="id"
        :allow-empty="true"
        :internal-search="false"
        :searchable="true"
        :show-labels="false"
        :max-height="540">
        <template slot="singleLabel" slot-scope="{ option }">
          {{ optionText(option) }}
        </template>
        <template slot="option" slot-scope="{ option }">
          {{ optionText(option) }}
        </template>
      </Multiselect>
    </div>

    <hr class="delimiter">

    <div class="form-field mb-5">
      <span class="d-block h4 text-dark font-weight-semi-bold">Advanced settings</span>

      <div class="form-field mb-3">
        <span class="caption">Unit</span>
        <multiselect
          v-model='auditReportConfiguration.distanceMeasure'
          :allow-empty="false"
          :options="distanceMeasures"
          :searchable="false"
          :show-labels="false"
          :max-height="540"
          :disabled=configuration.createdViaPublicApi>
          <template slot="singleLabel" slot-scope="{ option }">
            {{ option.text }}
          </template>
          <template slot="option" slot-scope="{ option }">
            {{ option.text }}
          </template>
        </Multiselect>
      </div>
      <div class="form-field mb-3">
        <span class="caption">Geogrid Size</span>
        <multiselect
          v-model="auditReportConfiguration.gridSize"
          :allow-empty="false"
          :options="gridSizes"
          :searchable="false"
          :show-labels="false"
          :max-height="540"
          :disabled=configuration.createdViaPublicApi>
          <template slot="singleLabel" slot-scope="{ option }">
            {{ option.text }}
          </template>
          <template slot="option" slot-scope="{ option }">
            {{ option.text }}
          </template>
        </Multiselect>
      </div>

      <div class="form-field mb-3">
        <span class="caption">Step</span>
        <multiselect
          v-model="auditReportConfiguration.pointDistance"
          :allow-empty="false"
          :options="currentPointDistancesList"
          :searchable="false"
          :show-labels="false"
          :max-height="540"
          :disabled=configuration.createdViaPublicApi>
          <template slot="singleLabel" slot-scope="{ option }">
            {{ option.text }}
          </template>
          <template slot="option" slot-scope="{ option }">
            {{ option.text }}
          </template>
        </Multiselect>
      </div>
    </div>

    <div class="d-flex gap-4 mb-5">
      <template v-if="(configuration.status === 'scheduled' || configuration.status === 'initialized') && !isConfigEdited">
        <button type="submit" class="btn btn-primary rounded" :disabled="isRequested">
          Save
        </button>
        <button v-if="!configuration.createdViaPublicApi"
          type="button"
          class="btn rounded"
          :disabled="isRequested"
          @click="chooseCompetitors">
          Choose competitors
        </button>
      </template>

      <button v-else type="submit" class="btn btn-primary rounded" :disabled="isRequested">
        Choose competitors
      </button>

      <a :href="basePath" class="btn gap-2 rounded ml-auto">
        <i class="far fa-times" />
        Cancel
      </a>
    </div>
  </form>
</template>

<script>
import { ObserveVisibility } from 'vue-observe-visibility'
import { mapActions, mapMutations, mapState } from 'vuex'
import Multiselect from 'vue-multiselect'
import Schedule from './schedule'

Vue.directive('observe-visibility', ObserveVisibility)

export default {
  components: { Multiselect, Schedule },
  data() {
    return {
      auditReportConfiguration: {},
      locationListPage: 1,
      locationListSearch: '',
      currentPointDistancesList: []
    }
  },
  computed: {
    ...mapState([
      'configuration',
      'locationsList',
      'whiteLabelList',
      'basePath',
      'isRequested',
      'error',
      'pointDistances',
      'gridSizes',
      'distanceMeasures',
      'organizationLinda'
    ]),
    isConfigEdited() {
      return this.auditReportConfiguration.keywords !== this.configuration?.keywords?.join() ||
        this.auditReportConfiguration.location?.id !== this.configuration?.locationId ||
        this.auditReportConfiguration.distanceMeasure?.value !== this.configuration?.gridDistanceMeasure ||
        this.auditReportConfiguration.gridSize?.id !== this.configuration?.gridSize ||
        this.auditReportConfiguration.pointDistance?.id !== Number(this.configuration?.gridPointDistance)
    }
  },
  created() {
    this.auditReportConfiguration = {
      name: this.configuration?.name || '',
      distanceMeasure: this.distanceMeasures
        .find((element) => element.value === this.configuration?.gridDistanceMeasure) || this.distanceMeasures[1],
      gridSize: this.gridSizes.find((element) => element.id === this.configuration?.gridSize) || this.gridSizes[1],
      pointDistance: this.pointDistancesSet(),
      whiteLabelConfiguration: this.configuration?.whiteLabelConfiguration ? {
        id: this.configuration?.whiteLabelConfiguration.id,
        domain: this.configuration?.whiteLabelConfiguration.domain,
        publicSubdomain: this.configuration?.whiteLabelConfiguration.publicSubdomain
      } : null,
      location: this.configuration?.locationId ? {
        id: this.configuration?.locationId,
        ...this.configuration?.location
      } : null,
      keywords: this.configuration?.keywords?.join() || '',

      scheduleAttributes: this.configuration?.schedule || {
        periodicity: 'once',
        dayNumber: 0,
        weekNumber: 1,
        monthDay: 1
      }
    }
    this.getLocationList()
    this.getWhiteLabelList()
  },
  methods: {
    ...mapMutations(['setConfigurationState']),
    ...mapActions(['getLocationList', 'saveConfiguration', 'getWhiteLabelList']),
    optionText(option) {
      if (option.publicSubdomain || option.domain) {
        return `${option.publicSubdomain} / ${option.domain ? option.domain : ''}`
      }

      return ''
    },
    submit() {
      this.saveConfiguration({
        config: this.auditReportConfiguration,
        closeOnSave: !this.isConfigEdited
      })
    },
    chooseCompetitors() {
      this.setConfigurationState({
        status: 'choosing_competitors',
        scheduleAttributes: this.auditReportConfiguration.scheduleAttributes
      })
    },
    searchingLocationList(search) {
      this.locationListSearch = search
      this.getLocationList({
        search: this.locationListSearch,
        page: 1
      })
    },
    reachedEndOfList(isVisible) {
      if (!isVisible) return

      this.locationListPage++
      this.getLocationList({
        search: this.locationListSearch,
        page: this.locationListPage
      })
    },
    pointDistancesSet() {
      if ('gridDistanceMeasure' in this.configuration) {
        return this.pointDistances[this.configuration.gridDistanceMeasure]
          .find((element) => element.id === Number(this.configuration?.gridPointDistance))
      }
      return this.pointDistances.miles[2]
    },
    updateCurrentPointDistances(newVal, oldVal) {
      const currentPointDistances = this.pointDistances[newVal.value]
      this.currentPointDistancesList = currentPointDistances
      if (oldVal === undefined) return

      const currentPointDistance = currentPointDistances[2]
      this.auditReportConfiguration.pointDistance = currentPointDistance
    }
  },
  watch: {
    'auditReportConfiguration.distanceMeasure': {
      handler(newVal, oldVal) {
        this.updateCurrentPointDistances(newVal, oldVal)
      }
    }
  }
}
</script>
