<template>
  <div class="keyword-new">
    <template v-if="correctCoordinatesPresent">
      <h2 class='page-title text-break'>Add keywords</h2>
      <div :class="['form-field keyword-field', { error: errors.emptyKeywordField }]">
        <span>Keywords</span>
        <div class="position-relative">
          <textarea v-model="keywordTerm" rows="9" placeholder="Enter text" />
          <span :class="['keyword-counter', { 'blue': countKeywords }]">{{ countKeywords }}</span>
        </div>
        <small>Add keywords above, one per line</small>
        <p v-if="errors.emptyKeywordField">Fill at least one keyword</p>
      </div>
      <div class="mb-3">
        <span class="caption">Types.</span>
        <small :class="{ 'text-muted': !errors.typesUnchecked,'color-danger': errors.typesUnchecked }">
          Check at least one type
        </small>
      </div>
      <div class="keyword-types">
        <label class="keyword-types-checkbox mb-4" v-for="type in types" :key="type.type">
          <input tabindex="0" type="checkbox" :value="type.type" v-model="selectedTypes">
          <i :class="['icon', type.icon]" />
          <span class="caption">{{ type.caption }}</span>
          <span class="text-muted">{{ type.description }}</span>
        </label>
      </div>
      <div class="form-field keyword-frequency">
        <span class="caption">Check frequency</span>
        <Multiselect
          v-model="frequency"
          :options="frequencyStates"
          :searchable="false"
          :close-on-select="true"
          :show-labels="false"
          :allow-empty="false"
          label="label"
          track-by="value" />
      </div>
      <button class="btn btn-primary with-icon" @click="addkeywords">
        <template v-if="btnLocked">
          <i class="fal fa-circle-notch fa-spin" aria-hidden="true" />
          <span>Adding</span>
        </template>
        <span v-else>Add</span>
      </button>
    </template>

    <SetKeywordsLocation v-else />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Multiselect from 'vue-multiselect'
import { DEFAULT_ERROR_MESSAGE } from '../../../common/constants'
import SetKeywordsLocation from './keyword_set_location'

export default {
  components: { Multiselect, SetKeywordsLocation },
  data() {
    return {
      keywordTerm: '',
      selectedTypes: [],
      frequency: { label: 'One week', value: 'one_week' },
      btnLocked: false,
      errors: {
        emptyKeywordField: false,
        typesUnchecked: false
      },
      initialLat: null,
      initialLng: null
    }
  },
  created() {
    this.types = [{
      type: 'local',
      caption: 'Local',
      description: 'For a search on the map',
      icon: 'far fa-map-marked-alt'
    }, {
      type: 'organic',
      caption: 'Organic',
      description: 'For naturally browser search',
      icon: 'far fa-search'
    }, {
      type: 'organic_mobile',
      caption: 'Organic Mobile',
      description: 'For naturally search in a mobile browser',
      icon: 'far fa-mobile'
    }]
    this.frequencyStates = [
      { label: '48 hours', value: 'two_days' },
      { label: 'One week', value: 'one_week' },
      { label: 'Two weeks', value: 'two_weeks' },
      { label: 'One month', value: 'one_month' }
    ]
    this.frequency = this.frequencyStates.find(({ value }) => value === this.defaultCheckFrequency)

    this.initialLat = this.lat
    this.initialLng = this.lng
  },
  computed: {
    countKeywords() {
      return this.keywordTerm.split(/\n|\r|\r\n/g).filter((term) => term.length).length
    },
    correctCoordinatesPresent() {
      return this.initialLat && this.initialLng
    },
    ...mapState([
      'lat',
      'lng',
      'keywordPath',
      'defaultCheckFrequency'
    ])
  },
  methods: {
    validateForm() {
      this.errors = {
        emptyKeywordField: false,
        typesUnchecked: false
      }
      if (!this.countKeywords) {
        this.errors.emptyKeywordField = true
      }
      if (!this.selectedTypes.length) {
        this.errors.typesUnchecked = true
      }
      return this.errors.emptyKeywordField || this.errors.typesUnchecked
    },
    addkeywords() {
      if (this.btnLocked) return
      if (this.validateForm()) return
      this.btnLocked = true
      const url = this.keywordPath
      axios.post(url, {
        keywords: this.keywordTerm,
        types: this.selectedTypes,
        check_frequency: this.frequency.value
      }).then(() => {
        window.location.assign(url)
      }).catch(() => {
        this.btnLocked = false
        toastr.error(DEFAULT_ERROR_MESSAGE)
      })
    }
  }
}
</script>
