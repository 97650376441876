<template>
  <VueSlimTable
    ref="table"
    class="table"
    :columns="columns"
    :source="tableSource">
    <template #row="{ row }">
      <tr>
        <td><a :href="`/gb/widgets/${row.id}/edit`" data-turbolinks='false'>{{ row.name }}</a></td>
        <td>{{ getBusinesses(row) }}</td>
        <td>
          <button
            class="geobooster-widgets__delete btn btn-sm btn-pretender text-danger"
            @click="handleDelete(row)">
            <i class="far fa-trash" />
          </button>
        </td>
      </tr>
    </template>
  </VueSlimTable>
</template>

<script>

import axios from 'axios'
import { runSwal } from '../../../common/delete_with_swal'

export default {
  props: {
    tableSource: {
      type: Function,
      required: true
    },
    widgetSrc: {
      type: String,
      required: true
    },
    businesses: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    columns: [
      {
        key: 'name',
        title: 'Widget name'
        // orderable: true
      },
      {
        key: 'businesses',
        title: 'Businesses'
      },
      { key: 'actions', title: '' }
    ]
  }),
  methods: {
    handleDelete(row) {
      const text = `Are you sure you want to delete widget "${row.name}"? This action cannot be undone.`

      runSwal(() => {
        axios.delete(`/gb/ajax/widgets/${row.id}`)
          .then(() => {
            toastr.success(`Widget "${row.name}" is deleted successfully`)
            this.$refs.table.refetch()
          })
          .catch((error) => {
            if (error.response?.status === 404) {
              toastr.info(`Widget "${row.name}" was already deleted`)

              this.$refs.table.refetch()
            } else {
              toastr.error(`An error occurred while deleting widget "${row.name}"`)
            }
          })
      }, { content: text })
    },
    getBusinesses(row) {
      return row.businesses && row.businesses.length ?
        row.businesses.map((business) => this.businesses.find((item) => {
          const obfuscatedId = business.obfuscatedId || business.obfuscated_id
          return item.id === obfuscatedId
        }).name)
          .join(', ') :
        'None'
    }
  }
}
</script>
