<template>
  <div class="form-field border-white d-flex flex-column">
    <span class="caption text-muted">Additional and Extra Fields</span>
    <Checkbox v-model="name">Your Name</Checkbox>
    <TransitionExpand>
      <FormTextInput v-if="name" field="namePlaceholder" placeholder="Enter Placeholder" />
    </TransitionExpand>
    <Checkbox v-model="phone">Phone</Checkbox>
    <TransitionExpand>
      <FormTextInput v-if="phone" field="phonePlaceholder" placeholder="Enter Placeholder" />
    </TransitionExpand>

    <Checkbox v-model="gdpr">GDPR Acceptance (checkbox)</Checkbox>
    <TransitionExpand>
      <FormTextInput v-if="gdpr" field="gdprText" type="textarea" placeholder="GDPR Acceptance" />
    </TransitionExpand>
    <Checkbox v-model="tos">TOS Acceptance (checkbox)</Checkbox>
    <TransitionExpand>
      <FormTextInput v-if="tos" field="tosText" type="textarea" placeholder="TOS Acceptance" />
    </TransitionExpand>
  </div>
</template>

<script>
import Checkbox from '../../../../vue_widgets/components/checkbox'
import FormTextInput from './text_input'

export default {
  components: { Checkbox, FormTextInput },
  computed: {
    name: {
      get() { return this.$store.state.geogridWidget.nameField },
      set(val) { this.$store.commit('changeGeogridWidgetKey', { key: 'nameField', val }) }
    },
    phone: {
      get() { return this.$store.state.geogridWidget.phoneField },
      set(val) { this.$store.commit('changeGeogridWidgetKey', { key: 'phoneField', val }) }
    },
    gdpr: {
      get() { return this.$store.state.geogridWidget.gdpr },
      set(val) { this.$store.commit('changeGeogridWidgetKey', { key: 'gdpr', val }) }
    },
    tos: {
      get() { return this.$store.state.geogridWidget.tos },
      set(val) { this.$store.commit('changeGeogridWidgetKey', { key: 'tos', val }) }
    }
  }
}
</script>
