<template>
  <div class="form-field mb-5">
    <span>Notified users</span>
    <CustomSelect :option-data="field" v-model="selectedUsers" />
    <div class="tags-selector-taglist flex-column">
      <div v-for="{ id, email } in selectedUsers" :key="id" class="tags-selector-taglist-item w-100 justify-content-between">
        {{ email }}
        <span class="icon" @click="onUnselectUser(id)">
          <i class="far fa-times" />
        </span>
      </div>
    </div>
    <select class="d-none" name="report_configuration[notified_user_ids][]" multiple="true" v-model="notifiedUsersIds">
      <option v-for="{ id, email } in userList" :key="id" :value="id" :selected="{ selected: selectedUsers.find((user) => user.id === id) }">
        {{ email }}
      </option>
    </select>
  </div>
</template>

<script>
import CustomSelect from 'vue_widgets/components/filter/select'

export default {
  components: { CustomSelect },
  props: {
    notifiedUsers: { type: Array, default: () => []},
    userList: { type: Array, default: () => []}
  },
  data() {
    return {
      selectedUsers: this.notifiedUsers,

      field: {
        trackBy: 'id',
        label: 'email',
        searchable: true,
        internalSearch: true,
        showLabels: false,
        allowEmpty: true,
        maxHeight: 540,
        multiple: true,
        placeholder: '',
        options: this.userList
      }
    }
  },
  computed: {
    notifiedUsersIds() {
      return this.selectedUsers.map(({ id }) => id)
    }
  },
  methods: {
    onSelectUsers(value) {
      this.selectedUsers = value
    },
    onUnselectUser(userId) {
      this.onSelectUsers(this.selectedUsers.filter(({ id }) => id !== userId))
    }
  }
}
</script>
