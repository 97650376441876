<template>
  <tr v-if="creditType === 'keyword' && (Number(row.credits.amount) < 0)">
    <td>{{ row.createdAt }}</td>
    <td>
      <a
        v-if="row.keywordId && row.location.orglocOid"
        :href="`locations/${row.location.orglocOid}/keywords/${row.keywordId}`">
        {{ row.searchTerm }}
      </a>
      <template v-else>{{ row.searchTerm }}</template>
    </td>
    <td>{{ row.location.name }}</td>
    <td>
      <span class="credits color-danger">{{ row.credits.amount | thousandsSeparator }}</span>
      <span class="font-size-14">{{ row.result | thousandsSeparator }}</span>
    </td>
  </tr>

  <tr v-else-if="creditType === 'geogrid' && (Number(row.credits.amount) < 0)">
    <td>{{ row.createdAt }}</td>
    <td>
      <template v-if="row.geogridType">{{ `${row.geogridType}, ${row.geogridSize}x${row.geogridSize}` }}</template>
      <template v-else>{{ `${row.geogridSize}x${row.geogridSize}` }}</template>
    </td>
    <td>{{ row.searchTerm }}</td>
    <td>
      <template v-if="row.businessName">{{ row.businessName }}</template>
      <template v-else>{{ row.location }}</template>
    </td>
    <td>
      <span class="credits color-danger">{{ row.credits.amount | thousandsSeparator }}</span>
      <span class="font-size-14">{{ row.result | thousandsSeparator }}</span>
    </td>
  </tr>

  <tr v-else>
    <td>{{ row.createdAt }}</td>
    <td v-if="creditType === 'geogrid'" colspan="3">{{ row.reason }}</td>
    <td v-else colspan="2">{{ row.reason }}</td>
    <td>
      <span class="credits color-success">+{{ row.credits.amount | thousandsSeparator }}</span>
      <span class="font-size-14">{{ row.result | thousandsSeparator }}</span>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    row: { type: Object, required: true },
    creditType: { type: String }
  }
}
</script>
