<template>
  <div class="geobooster-widgets-edit-form">
    <div class="geobooster-widgets-edit-form__line">
      <p>Embed the following code snippet into your website to display {{ gbName }} moments.</p>
    </div>
    <div class="geobooster-widgets-edit-form__line">
      <div class="geobooster-widgets-edit-form__code">
        <pre ref="code">
&lt;script
  id="gb_widget_script"
  type="module"
  src="{{ widgetSrc }}"
  data-widget-token="{{ configuration.widgetToken.trim() }}"&gt;
&lt;/script&gt;
        </pre>
        <button class="button-copy" @click="onCopy">
          <i class="far fa-fw fa-copy" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { copyToClipboardWithAnimation } from '../../../../common/copy_to_clipboard'

export default {
  props: {
    configuration: {
      type: Object,
      required: true
    },
    widgetSrc: {
      type: String,
      required: true
    }
  },
  computed: {
    gbName() {
      return Styxie.WLC.gbName
    }
  },
  methods: {
    onCopy(e) {
      copyToClipboardWithAnimation({ element: e.currentTarget, textToCopy: this.$refs.code.innerText.trim() })
    }
  }
}
</script>
