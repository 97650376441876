<template>
  <div class='h-100 main-widget-geogrid-map'>
    <div class='position-relative h-100'>
      <MapControls
        :class="{'d-none': mapAlertMessage }"
        :point-distances='pointDistances'
        :distance-measure-options='distanceMeasureOptions'
        ref='MapControls' />
      <geogridButtons
        v-if="!showConfigDetailsExpanded"
        :class="{'d-none': mapAlertMessage || competitorGeogridIsSelected }"
        :geogrids-path='geogridsPath'
        @showCompetitorsScore="$emit('showCompetitorsScore')"
        ref='geogridButtons' />
      <div v-show='mapAlertMessage' class='mapoverlay'>
        <div style='background-color: #00000059; width: 100%; height: 100%; padding-top: 22.5vh;'>
          <div class='mapoverlay-message'>{{ mapAlertMessage }}</div>
        </div>
      </div>
      <geogridInfo v-if="!showConfigDetailsExpanded && showOnMapComponents"
        :competitor-geogrid-is-selected="competitorGeogridIsSelected" />
      <div id='map' :class="['h-100', {'blur-map': mapAlertMessage }]" ref='map' />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import MapControls from './map_controls'
import geogridButtons from './geogrid_actions_buttons'
import geogridInfo from './geogrid_info'

export default {
  components: {
    MapControls, geogridButtons, geogridInfo
  },
  props: {
    pointDistances: Object,
    geogridsPath: String,
    distanceMeasureOptions: Array,
    competitorGeogridIsSelected: Object,
    showConfigDetailsExpanded: Boolean
  },
  data() {
    return {
      ranks: [...Array(21).keys()].slice(1),
      mapMarkers: [],
      message: '',
      showedBusinessLocation: false,
      noDataMessage: 'No geogrids created yet'
    }
  },
  computed: mapState(['mapAlertMessage', 'geogrid', 'showOnMapComponents'])
}
</script>

<style scoped>
  .mapoverlay{
    width: 100%;
    position: absolute;
    height: 100%;
    z-index: 1;
  }
  .mapoverlay-message{
    background-color: #f4f4f4;
    padding: 20px 40px;
    width: fit-content;
    box-shadow: 0px 5px 18px #000000bf;
    margin-right: auto;
    margin-left: auto;
  }

  #colorscale {
    margin-top: 5px;
  }

  #colorscale li {
    height: 20px;
    width: 5%;
    text-align: center;
  }
  .blur-map{
    filter: blur(2px);
  }
</style>
