<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="row">
    <div class="col-10 offset-1 offset-lg-2 ">
      <div class="mb-5 pt-5">
        <h3 class="mb-3">Please share your reason for leaving</h3>
        <div v-for="(cancelationReason, index) in cancelationReasons" :key="index">
          <radio tabindex="1"
            v-model="reason"
            @keyup.enter.native="reason = index"
            :item="index"
            :key="index"
            :id-name="`radio-${index}`">
            {{ cancelationReason.inputLabel }}
          </radio>
          <div class="cancelation-feedback" v-if="reason === index" :class="{'loading': feedbackSending}">
            <form v-if="!feedbackSent" class="pr-5 pb-3" @submit.prevent="sendFeedback">
              <h5>{{ cancelationReason.headline }}</h5>
              <p v-html="cancelationReason.paragraph" />
              <div v-if="cancelationReason.checkboxes?.length">
                <div v-for="cancellationCheckbox in cancelationReason.checkboxes"
                  :key="cancellationCheckbox.value"
                  class="mb-1 cancelation-checkbox">
                  <checkbox
                    :value="selectedCheckboxes.includes(cancellationCheckbox.value)"
                    @input="updateCheckboxes(cancellationCheckbox.value)">
                    <template v-if="cancellationCheckbox.text">
                      {{ cancellationCheckbox.text }}
                    </template>
                    <span v-else class="text-capitalize">
                      {{ cancellationCheckbox.value }}
                    </span>
                  </checkbox>
                </div>
              </div>
              <textarea v-model="feedback" tabindex="1"
                class="w-100 pl-2 cancelation-textarea"
                rows="3" />
              <button class="btn btn-primary btn-sm">{{ cancelationReason.ctaText }}</button>
            </form>
            <div v-if="feedbackSent" class="p-5">
              {{ feedbackSuccessMessage }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-11 col-sm-8 offset-1 offset-lg-2">
      <div class="mb-5">
        <h3 class="mb-3">What is the likelihood of you returning in the future?</h3>
        <ul class="cancelation-rating d-inline-flex">
          <li v-for="index in 10" :key="index" class="star-rating">
            <label class="star-rating-item"
              tabindex="1"
              @keyup.enter="likelihoodToReturn = index">
              <input type="radio"
                v-model="likelihoodToReturn"
                :value="index"
                class='star-rating_radio'>
              <i :class="likelihoodToReturn >= index ? 'fas fa-star' : 'far fa-star' " />
              <span>{{ index }}</span> </label>
          </li>
        </ul>
      </div>
    </div>

    <div class="col-11 col-sm-9 offset-1 offset-lg-2">
      <div class="mb-3 cancelation-checkbox">
        <checkbox v-model="acceptTerms" @keyup.enter.native="acceptTerms = !acceptTerms" tabindex="1">
          I understand that by proceeding, I will lose access to my rank tracking, geogrid, and post data,
          as well as my entire account history when my paid period ends.
        </checkbox>
      </div>
    </div>

    <div class="col-8 offset-1 offset-lg-2">
      <div class="mb-6">
        <button tabindex="1"
          class="btn btn-primary mr-1 mb-2"
          @click.prevent="cancelCancelation()">
          Nevermind
        </button>
        <button
          tabindex="1"
          class="btn mb-2"
          @click.prevent="finalizeCancelation()">
          Cancel my plan
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'
import toastr from 'toastr'
import Vue from 'vue'
import { ModalPlugin } from 'bootstrap-vue'
import cancelationReasons from './cancelation_reasons'
import checkbox from '../components/checkbox'
import radio from '../components/radio'

Vue.use(ModalPlugin)

const FEEDBACK_SUCCESS_MSG = 'Thank you for your feedback.'
const FEEDBACK_ERROR_MSG = 'Please enter your feedback.'
const SUBMIT_ERROR_MSG = 'Please fill in all required fields.'

function makeCancelationRequest(cancelationId, reason, likelihoodToReturn) {
  const url = `/subscription_cancelations/${cancelationId}/finalize`
  const payload = {
    reason,
    likelihoodToReturn: likelihoodToReturn
  }

  axios.post(url, payload)
    .then(() => {
      const main = document.getElementById('js-cancelation-main')
      const final = document.getElementById('js-cancelation-final')
      main.classList.toggle('d-none')
      final.classList.toggle('d-none')
    })
    .catch((error) => toastr.error(error))
}

export default {
  components: { checkbox, radio },
  props: {
    billingUrl: {
      type: String,
      required: true
    },
    subscriptionCancelationId: {
      type: String,
      required: true
    },
    scheduleGroupDemoUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      reason: null,
      feedback: null,
      likelihoodToReturn: null,
      feedbackSent: false,
      feedbackSending: false,
      acceptTerms: false,
      showInputError: false,
      selectedCheckboxes: []
    }
  },
  created() {
    this.brand = Styxie.WLC.brand
    this.cancelationReasons = cancelationReasons(this.brand)
    this.feedbackSuccessMessage = FEEDBACK_SUCCESS_MSG
  },
  watch: {
    reason() {
      this.feedback = null
      this.selectedCheckboxes = []
      this.feedbackSending = false
      this.feedbackSent = false
    }
  },
  computed: {
    validation() {
      return (!(this.acceptTerms && this.likelihoodToReturn !== null && this.reason !== null))
    }
  },
  methods: {
    modalId(key) {
      return `modal-${key}`
    },
    cancelCancelation() {
      const url = `/subscription_cancelations/${this.subscriptionCancelationId}/cancel`

      axios.post(url)
        .then(window.location = '/billing/edit')
        .catch((error) => toastr.error(error))
    },
    finalizeCancelation() {
      if (this.validation) {
        toastr.error(SUBMIT_ERROR_MSG)
        return false
      }

      makeCancelationRequest(
        this.subscriptionCancelationId,
        this.reason,
        this.likelihoodToReturn
      )

      return true
    },
    sendFeedback() {
      if (!this.feedback) {
        toastr.error(FEEDBACK_ERROR_MSG)
        return false
      }

      const url = `/subscription_cancelations/${this.subscriptionCancelationId}/feedback`
      const { feedback: comments, reason, selectedCheckboxes: complainedFeatures } = this
      const payload = {
        subscription_cancelation: {
          comments,
          reason,
          complained_features: complainedFeatures
        }
      }

      this.feedbackSending = true
      axios.post(url, payload)
        .then(() => {
          this.feedback = ''
          this.feedbackSent = true
        })
        .catch((error) => toastr.error(error))
        .finally(() => {
          this.feedbackSending = false
        })

      return true
    },
    updateCheckboxes(checkboxValue) {
      const indexOfCheckbox = this.selectedCheckboxes.indexOf(checkboxValue)
      if (indexOfCheckbox !== -1) {
        this.selectedCheckboxes.splice(indexOfCheckbox, 1)
      } else {
        this.selectedCheckboxes.push(checkboxValue)
      }
    }
  }
}
</script>
