import Vue from 'vue'
import AuditReports from 'vue_widgets/audit_reports/index'
import initAuditReportsShow from './common/audit_report_show'

Styxie.Initializers.AuditReports = {
  index: (props) => {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-audit-reports'),
        render: (h) => h(AuditReports, { props })
      })
    })
  },
  show: initAuditReportsShow
}
