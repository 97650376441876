import Vue from 'vue'

import createLKStore from 'vue_widgets/report_configurations/locations_search_terms/store'
import LocationsSearchTerms from 'vue_widgets/report_configurations/locations_search_terms/index'
import LocationsTags from 'vue_widgets/report_configurations/locations_tags'
import reportConfigurations from 'vue_widgets/report_configurations/index/table'
import ConfigSchedule from 'vue_widgets/report_configurations/schedule'
import NotifiedUsers from 'vue_widgets/report_configurations/notified_users'
import WhiteLabelConfigurationSelect from 'vue_widgets/report_configurations/white_label_configuration_select'

function initForm(prms) {
  $(() => {
    /* eslint-disable no-new */
    new Vue({
      el: document.getElementById('vue-locations-keywords'),
      render: (h) => h(LocationsSearchTerms),
      store: createLKStore(prms)
    })

    new Vue({
      el: document.getElementById('vue-locations-tags'),
      render: (h) => h(LocationsTags, { props: prms })
    })

    new Vue({
      el: document.getElementById('vue-config-schedule'),
      render: (h) => h(ConfigSchedule, { props: prms })
    })

    new Vue({
      el: document.getElementById('vue-notified-users'),
      render: (h) => h(NotifiedUsers, { props: prms })
    })

    new Vue({
      el: document.getElementById('vue-white-label-configuration'),
      render: (h) => h(WhiteLabelConfigurationSelect, { props: prms })
    })
  })
}

Styxie.Initializers.ReportConfigurations = {
  edit: initForm,
  update: initForm,
  create: initForm,
  new: initForm,
  index: (params) => {
    $(() => {
      new Vue({
        el: document.getElementById('vue-report-configurations'),
        render: (h) => h(reportConfigurations, { props: params })
      })
    })
  }
}
