<template>
  <div class='card'>
    <div class="card-header">
      <select v-model="perPage" class="form-control" style="width: auto;">
        <option v-for="per in perPages" :value="per" :key="per">{{ per }}</option>
      </select>

      <input v-model="search" type="text" class="form-control float-right"
        placeholder="Email or Name"
        style="width: auto">
    </div>

    <div class='card-body'>
      <div class='project-list'>
        <VueSlimTable
          ref="table"
          :source="tableSource"
          :columns="columns"
          class="table table-sortable table-striped"
          :per-page="parseInt(perPage, 10)">
          <template #row="{ row }">
            <TableRow :row="row" />
          </template>
        </VueSlimTable>
      </div>
    </div>
  </div>
</template>

<script>
import { App } from 'common/rails'

import TableRow from './table_row'

const LV_PER_PAGE_KEY = 'lv_ga_per_page'
const PER_PAGES = [25, 50, 100]

export default {
  data() {
    return {
      perPage: window.localStorage.getItem(LV_PER_PAGE_KEY) || PER_PAGES[0],
      search: ''
    }
  },
  components: { TableRow },
  methods: {
    tableSource(params) {
      return axios.get(
        '/api/google_accounts',
        {
          params: { ...params, search: this.search },
          paramsSerializer(json) { return qs.stringify(json, { arrayFormat: 'brackets' }) }
        }
      )
        .then((res) => res.data)
    }
  },
  watch: {
    perPage(val) {
      window.localStorage.setItem(LV_PER_PAGE_KEY, val)
    },
    search() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(this.$refs.table.refetch, 300)
    }
  },
  created() {
    this.perPages = PER_PAGES
    this.columns = [
      { key: 'name', title: 'Name', orderable: true },
      { key: 'email', title: 'Email', orderable: true },
      { key: 'locations', title: 'Locations', orderable: true },
      { key: 'is_alive', title: 'State', orderable: true },
      { key: 'actions', title: '' }
    ]

    this.cable = App.cable.subscriptions.create(
      { channel: 'SyncAccountChannel' },
      {
        received: ({ payload }) => {
          if (payload.type === 'synced') {
            this.$nextTick(this.$refs.table.refetch)
          }
        }
      }
    )

    $(document).one('turbolinks:visit', () => {
      App.cable.subscriptions.remove(this.cable)
    })
  }
}
</script>
