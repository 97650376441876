import GeogridRequests from 'vue_widgets/geogrid_requests/index/index'

function index({ wlcDomain }) {
  $(() => {
    // eslint-disable-next-line no-new
    new Vue({
      el: document.getElementById('vue-geogrid-requests'),
      render: (h) => h(GeogridRequests, { props: { wlcDomain } })
    })
  })
}

Styxie.Initializers.GeogridRequests = { index }
