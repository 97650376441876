<template>
  <div class="settings-form">
    <div class="settings-form-box pb-2">
      <div class="settings-form-head">
        <h4>Export Media</h4>
        <span class="sub">{{ selectedImages.length }}</span>
      </div>
    </div>
    <div class="scroll ver h-100">
      <div class="settings-form-box">
        <LocationSelector @addLocationsTags="addLocationsTags" />
      </div>
      <hr class="m-0">
      <div class="settings-form-box">
        <div class="form-field mb-3">
          <span class="caption">EXIF Settings</span>
          <Radio v-for="( text, key ) in exifOptions" :key="key"
            v-model="exifOption"
            :item="key"
            :id-name="key"
            name="exif_settings"
            @input="changeEXIF">
            {{ text }}
          </Radio>

          <div v-if="exifOption === 'edit'">
            <Multiselect
              v-model="selectedEXIFTemplate"
              :options="exifTemplates"
              :allow-empty="false"
              :close-on-select="true"
              :show-labels="false"
              :preselect-first="true"
              label="title"
              track-by="id" />
            <small class="text-muted">Manage <a href="/exif_templates">EXIF Templates</a></small>
          </div>
        </div>
        <div class="form-field settings-form-field">
          <Checkbox v-model="seoOptimized">Optimize image names for SEO</Checkbox>
          <p class="m-0 mt-2">The names of the images will be changed for better indexing in Google</p>
        </div>
      </div>
    </div>

    <div class="settings-form-control">
      <button class="btn btn-primary-outline rounded" @click="closeExportPanel">Cancel</button>
      <button :disabled="sending" :class="['btn btn-primary rounded', {'with-icon': sending}]" @click="submit">
        <i v-if="sending" class="far fa-spinner-third fa-spin" />
        Export
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import axios from 'axios'
import toastr from 'toastr'
import Multiselect from 'vue-multiselect'
import Checkbox from '../components/checkbox'
import Radio from '../components/radio'
import LocationSelector from '../components/location_selector'

export default {
  components: {
    Multiselect, Checkbox, Radio, LocationSelector
  },
  data() {
    return {
      seleltedLocations: [],
      seleltedTags: [],
      seoOptimized: true,
      exifOption: 'keep',
      exifOptions: {
        keep: 'Keep Original',
        edit: 'Override with Template',
        clean: 'Clean all EXIF data'
      },
      selectedEXIFTemplate: '',
      exifTemplates: [],
      sending: false
    }
  },
  computed: {
    ...mapState(['selectedImages'])
  },
  methods: {
    ...mapMutations(['selectAll']),
    closeExportPanel() {
      this.$emit('closeExportPanel')
    },
    async changeEXIF(value) {
      if (value === 'edit') {
        this.exifTemplates = (await axios.get('/api/exif_templates/index_for_select')).data
      }
    },
    submit() {
      const url = '/api/images_exports'
      this.sending = true
      axios.post(url, this.collectData())
        .then((res) => {
          toastr.success(res.data.notice)
          this.$emit('closeExportPanel')
          this.selectAll()
        })
        .catch((err) => {
          this.sending = false
          toastr.error(err.response.data.errors)
        })
    },
    addLocationsTags({ locations, tags }) {
      this.seleltedLocations = locations
      this.seleltedTags = tags
    },
    collectData() {
      const data = {
        location_tags: this.seleltedTags,
        location_ids: this.seleltedLocations,
        images: this.selectedImages,
        images_export: {
          exif_state: this.exifOption
        }
      }

      if (this.seoOptimized) data.filename_option = 'seoOptimized'
      else data.filename_option = 'keepOriginal'

      if (this.exifOption === 'edit') data.exif_template = this.selectedEXIFTemplate.id

      return data
    }
  }
}
</script>
