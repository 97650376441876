<template>
  <div class="scroll ver">
    <script
      id="gb_widget_script"
      type="module"
      :src="widgetSrc"
      data-skip-autostart
      @load="initWidget" />
  </div>
</template>

<script>
// todo add widget link depending on .env variable
export default {
  props: {
    config: {
      type: Object,
      required: true
    },
    widgetSrc: {
      type: String,
      required: true
    },
    activeFilter: {
      type: Object,
      required: false
    },
    lastActiveFilter: {
      type: Number,
      required: false,
      default: null
    }
  },
  data: () => ({
    widgetInterface: null,
    timeout: null,
    filterToApply: null
  }),
  methods: {
    initWidget() {
      this.widgetInterface = window.GeoboosterWidgetInterface

      this.widgetInterface.start({
        ...this.config,
        filters: this.filterToApply ?? {},
        ignorePagePath: true
      })
    },
    async updateWidgetConfig(newWidgetConfig, _oldValue, forceUpdate = false) {
      if (this.widgetInterface) {
        clearTimeout(this.timeout)

        await this.widgetInterface.applyConfig({
          ...newWidgetConfig,
          filters: this.filterToApply ?? {},
          ignorePagePath: true
        }, forceUpdate)
      }
    },
    async refetchPreviewMoments() {
      await this.updateWidgetConfig(this.config, null, true)
    }
  },
  watch: {
    config: {
      deep: true,
      handler: 'updateWidgetConfig'
    },
    activeFilter: {
      deep: true,
      handler(newValue) {
        this.$nextTick(() => {
          if (!newValue && this.lastActiveFilter) return

          if (newValue) {
            this.filterToApply = JSON.parse(JSON.stringify(newValue))
          } else {
            this.filterToApply = null
          }

          this.updateWidgetConfig(this.config)
        })
      }
    }
  }
}
</script>
