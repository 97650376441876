<template>
  <div class="form-field mb-0">
    <div class="radio-buttons">
      <template v-for="{ value, text } in gridDistanceMeasures">
        <input
          type="radio"
          name="gridDistanceMeasure"
          :value="value"
          :key="value"
          :id="value"
          v-model="model">
        <label class="radio-btn" :for="value" :key="text+value">{{ text }}</label>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['gridDistanceMeasures', 'pointDistances']),
    errors() { return this.$store.state.geogridWidget.errors },
    model: {
      get() { return this.$store.state.geogridWidget.gridDistanceMeasure },
      set(val) {
        this.$store.commit('changeGeogridWidgetKey', { key: 'gridDistanceMeasure', val })
        this.$store.commit('changeGeogridWidgetKey', { key: 'gridPointDistance', val: this.pointDistances[val][0].id })
      }
    }
  }
}
</script>
