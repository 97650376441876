<template>
  <div class='keywords-common'>
    <dl class='keywords-common-box'>
      <dt class="font-weight-semi-bold">Domain Name:</dt>
      <dd class="m-0 text-break">{{ domain || '—' }}</dd>
    </dl>
    <dl class='keywords-common-box'>
      <dt class="font-weight-semi-bold">Local Business Name:</dt>
      <dd class="m-0 ">{{ keywordsCommon.businessName || '—' }}</dd>
    </dl>
    <dl class='keywords-common-box'>
      <dt class="font-weight-semi-bold">Region:</dt>
      <dd class="m-0 ">{{ keywordsCommon.region || '—' }}</dd>
    </dl>
    <dl class='keywords-common-box'>
      <dt class="font-weight-semi-bold">
        Search Location:
        <i class="far fa-info-circle ml-1"
          data-toggle="tooltip"
          data-placement="right"
          ref="searchLocationTip"
          :title="tooltipText" />
      </dt>
      <dd class="m-0 ">{{ keywordsCommon.searchLocation || '—' }}</dd>
    </dl>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    keywordsCommon: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    ...mapGetters(['domain'])
  },
  created() {
    this.brandname = Styxie.WLC.brand || 'LocalViking'
    this.tooltipText = `
      <p class="text-left font-size-12">${this.brandname} uses the address data of your locale for keyword tracking, usually a city name and zip.</p>
      <p class="text-left font-size-12">If you want to track keywords in a different (e.g., wider) region - specify the address with the accuracy you want.</p>
      <p class="text-left font-size-12 m-0">For Service Area Business we cannot automatically determine the address, so this setting is mandatory for SABs.</p>
    `
  },
  mounted() {
    $(this.$refs.searchLocationTip).tooltip({
      delay: { show: 100, hide: 200 },
      container: this.$refs.searchLocationTip,
      html: true
    })
  }
}
</script>
