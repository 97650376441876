<template>
  <label class='checkbox-ui'>
    <input type='checkbox' :disabled="disabled" :checked="value" @change="onChange">
    <i :class='{
      "icon far fa-square": !value && !partSelected,
      "icon far fa-check-square": value && !partSelected,
      "icon far fa-minus-square": partSelected
    }' />
    <span class='caption'>
      <slot>
        {{ label }}
      </slot>
    </span>
  </label>
</template>

<script>
export default {
  props: {
    value: [Boolean, Number],
    partSelected: Boolean,
    disabled: Boolean,
    label: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    onChange(e) {
      this.$emit('input', e.target.checked)
    }
  }
}
</script>
