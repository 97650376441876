<template>
  <div>
    <h2 class="mb-2 font-weight-semi-bold">Reviews</h2>
    <AuditDescription class="mb-4">
      Review quantity and quality are major ranking factors.
    </AuditDescription>

    <p class="text-muted mb-4">Due to technical limitations, only approximately the latest 1100 reviews are analyzed.</p>

    <h3 class="h4 mb-2 font-weight-semi-bold">Reviews for all time</h3>

    <div class="widget p-0 mb-4">
      <div ref="audit-line-chart" />
    </div>

    <VueSlimTable
      ref="table"
      :source="() => reviews"
      :columns="totalColumns"
      class="responsive-table borderless-table audit-table posts average last-head-col-right-1">
      <template #row="{ row }">
        <tr>
          <td column-caption="Competitors" @click="showContentSidebar(row, 'reviews')">
            <span class="text-primary cursor-pointer">{{ row.name }}</span>
          </td>
          <td column-caption="Rating">
            <div>
              <i class="fas fa-star color-warning" />
              {{ row.googleRating ? row.googleRating : 'N/a' }}
            </div>
          </td>
          <td column-caption="Total Count" class="text-right">{{ row.googleTotalReviewsCount }}</td>
        </tr>
      </template>
    </VueSlimTable>

    <h3 class="h4 pt-2 mb-2 font-weight-semi-bold">Reviews: Past 180 Days</h3>

    <div :class="['type-rating mb-2', { 'max-competitors': reviews.length >= 5 }]">
      <div v-for="(businessReviews, index) in reviews" :key="index" class="type-rating-item">
        <div class="d-flex justify-content-between align-items-baseline gap-2 mb-4">
          <strong>{{ businessReviews.name }}</strong>
          <strong class="text-grey">{{ businessReviews.total }}</strong>
        </div>
        <div class="d-flex justify-content-between gap-2 mt-auto">
          <div class="d-flex flex-column-reverse gap-2 w-100">
            <div v-for="n in 5" class="type-rating-item-stars" :key="n">
              <i class="fas fa-star color-warning" />
              {{ n }}
              <div class="w-100">
                <div :style="`width: ${businessReviews.ratio[n - 1]}%`" class="star-point" />
              </div>
              {{ businessReviews.ratio[n - 1] }}%
            </div>
          </div>
        </div>
      </div>
    </div>

    <VueSlimTable
      ref="table"
      :source="() => reviews"
      :columns="columns"
      class="responsive-table borderless-table audit-table reviews average last-head-col-right-4">
      <template #head:responsed>
        <span>Response Rate</span>
        <i id="audit_report_reviews_responded" class="noprint far fa-info-circle color-primary" />
        <b-tooltip target="audit_report_reviews_responded" title="The percentage of reviews a business has replied to." />
      </template>
      <template #head:velocityTitle>
        <span>Velocity (days)</span>
        <i id="audit_report_reviews_velocity" class="noprint far fa-info-circle color-primary" />
        <b-tooltip target="audit_report_reviews_velocity" title="This metric measures the number of reviews businesses receive in a given time period." />
      </template>

      <template #row="{ row }">
        <tr>
          <td column-caption="Competitors"
            @click="showContentSidebar(row, 'reviews')">
            <span class="text-primary cursor-pointer">{{ row.name }}</span>
          </td>
          <td column-caption="Rating">
            <div>
              <i class="fas fa-star color-warning" />
              {{ row.rating ? row.rating : 'N/a' }}
            </div>
          </td>
          <td column-caption="Total Count">{{ row.total }}</td>
          <td column-caption="Response Rate">{{ responseRate(row) }}%</td>
          <td column-caption="Velocity (days)">
            <span class="d-lg-none d-print-none">{{ row.velocity.join(' / ') }}</span>
          </td>
          <td class="text-right d-none d-lg-table-cell d-print-table-cell">{{ row.velocity[0] }}</td>
          <td class="text-right d-none d-lg-table-cell d-print-table-cell">{{ row.velocity[1] }}</td>
          <td class="text-right d-none d-lg-table-cell d-print-table-cell">{{ row.velocity[2] }}</td>
        </tr>
      </template>
    </VueSlimTable>
  </div>
</template>

<script>
import lineChartMixin from './line_chart_mixin'
import showContentSidebarMixin from './show_content_sidebar_mixin'

function calcReviews(obj) {
  const total = obj.reviews.reduce((a, b) => a + b)
  const ratio = obj.reviews.map((c) => Math.round((c / total || 0) * 100))
  // note: this rating is calculated for reviews posted withing last 180 days
  const rating = obj.reviews.reduce((accum, occurrences, index) => accum + occurrences * (index + 1), 0) / total
  const roundedRating = Math.round(rating * 10) / 10
  const max = Math.max(...obj.reviews)
  return {
    name: 'My Location',
    ...obj,
    total,
    ratio,
    rating: roundedRating,
    max
  }
}

export default {
  mixins: [lineChartMixin, showContentSidebarMixin],
  props: {
    sectionData: { type: Object, required: true }
  },
  methods: {
    responseRate(row) {
      return Math.round(row.responsed / (row.total || 1) * 100)
    }
  },
  created() {
    this.totalColumns = [
      { key: 'name', title: 'Competitors' },
      { key: 'googleRating', title: 'Rating' },
      { key: 'googleTotalReviewsCount', title: 'Total Count' }
    ]

    this.columns = [
      { key: 'name', title: 'Competitors' },
      { key: 'rating', title: 'Rating' },
      { key: 'total', title: 'Total Count' },
      { key: 'responsed', title: 'Response Rate' },
      { key: 'velocityTitle', title: 'Velocity (days)' },
      { key: 'velocity180', title: '180' },
      { key: 'velocity90', title: '90' },
      { key: 'velocity30', title: '30' }
    ]

    this.maxCount = 1
    this.reviews = [
      this.sectionData.current,
      ...this.sectionData.competitors
    ].map((obj) => {
      const out = calcReviews(obj)
      if (out.total > this.maxCount) this.maxCount = out.total
      return out
    })
  }
}
</script>
