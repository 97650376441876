import axios from 'axios'
import toastr from 'toastr'

import StripeForm from '../common/stripe_form'

Styxie.Initializers.ProfileBilling = {
  edit: (data) => {
    $(() => {
      if (data.subscriptionInactive) {
        $.getScript('https://js.stripe.com/v3/').done(() => {
          new StripeForm(data.stripeKey, 'payment_data').init()
        })
      }

      $('.js-open-support-chat').on('click', (e) => {
        e.preventDefault()
        window.Intercom('show')
      })

      $('.js-cancel-subscription').on('click', (e) => {
        e.preventDefault()

        axios.post(data.subscriptionCancelationUrl, { paypal_user: data.paypalUser })
          .then((response) => { window.location = `/subscription_cancelations/${response.data.id}` })
          .catch(() => { toastr.error('There has been an error') })
      })
    })
  }
}
