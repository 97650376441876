<template>
  <div>
    <h2 class="h3 m-0 mb-2 font-weight-semi-bold text-dark">Webhooks</h2>
    <p class="pt-1 mb-3 narrow-form wide">
      To receive data in your Wordpress instance on new moments, fill in the 'Add Wordpress webhook' field.<br>
      With this attribute filled in, we will immediately send data upon receiving a new
      (approved) moment. If you want to use Bearer authentication, please, fill in the appropriate field and
      we will add this header to the request.<br>
      To receive data on new moments in your webhook processing service, fill in the 'Add webhook' field.
    </p>
    <form class="narrow-form mb-5" @submit.prevent="updateWebhooks">
      <div class="d-flex gap-4 form-field pt-1 mb-3">
        <input v-model="webhookUrlLocal"
          :disabled="isWebhookLocked"
          placeholder="Add Wordpress webhook"
          type="text">
      </div>

      <div class="d-flex gap-4 form-field pt-1">
        <input v-model="webhookBearerTokenLocal"
          :disabled="isWebhookLocked"
          placeholder="Add bearer token for Wordpress"
          type="text">
      </div>

      <p class="text-warning my-3">
        <i class="fa-regular fa-circle-info" />
        <span>The WordPress plugin is deprecated.<br>
          Please use the <a href="/gb/widgets" target="_blank">GB widget</a> for publishing your content on a website.
        </span>
      </p>

      <div class="d-flex gap-4 form-field pt-1 mb-3">
        <input v-model="zapierWebhookUrlLocal"
          :disabled="isWebhookLocked"
          placeholder="Add webhook"
          type="text">
      </div>

      <div class="d-flex gap-4 form-field pt-1">
        <button :disabled="isWebhookLocked" type="submit" class="btn btn-outline rounded">Update</button>
      </div>
    </form>
  </div>
</template>

<script>
import { DEFAULT_ERROR_MESSAGE } from '../../../../common/constants'

export default {
  props: {
    webhookUrl: { type: String, default: '' },
    webhookBearerToken: { type: String, default: '' },
    zapierWebhookUrl: { type: String, default: '' },
    mobileBusinessPath: { type: String, default: '' }
  },
  data() {
    return {
      webhookUrlLocal: this.webhookUrl || '',
      webhookBearerTokenLocal: this.webhookBearerToken || '',
      zapierWebhookUrlLocal: this.zapierWebhookUrl || '',
      isWebhookLocked: false
    }
  },
  methods: {
    updateWebhooks() {
      this.isWebhookLocked = true
      const formData = new FormData()

      formData.append('mobile_business[webhook_url]', this.webhookUrlLocal)
      formData.append('mobile_business[webhook_bearer_token]', this.webhookBearerTokenLocal)
      formData.append('mobile_business[zapier_webhook_url]', this.zapierWebhookUrlLocal)

      axios.patch(this.mobileBusinessPath, formData)
        .then(() => {
          toastr.success('Webhooks updated successfully')
        })
        .catch(() => {
          toastr.error(DEFAULT_ERROR_MESSAGE)
        })
        .finally(() => {
          this.isWebhookLocked = false
        })
    }
  }
}
</script>
