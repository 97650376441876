<template>
  <div>
    <FilterComponent class="mb-4 pb-2" :fields="fields" @onFilterChange="onFilterChange" />

    <VueSlimTable
      ref="table"
      :source="tableSource"
      :columns="columns"
      class="responsive-table borderless-table m-0 report-configurations-table"
      :per-page="perPage">
      <template #head:actions>
        <div class="text-right">Actions</div>
      </template>
      <template #row="{ row }">
        <TableRow
          :row="row" :key="row.id"
          :report-configurations-url="reportConfigurationsUrl"
          :api-report-configurations-url="apiReportConfigurationsUrl"
          :can-remove-configuration="canRemoveConfiguration"
          @reportRemoved="reportRemoved" />
      </template>
      <template #pagination>
        <Pagination class="pb-0"
          :page="currentPage"
          :per-page="perPage"
          :total-count="totalCount"
          @updatePage="updatePageNumber" />
      </template>
    </VueSlimTable>
  </div>
</template>

<script>
import FilterComponent from 'vue_widgets/components/filter'
import Pagination from 'vue_widgets/components/pagination'
import axiosTransform from 'common/axios'
import { App } from 'common/rails'
import TableRow from './table_row'

export default {
  components: { FilterComponent, TableRow, Pagination },
  props: {
    reportsUrl: { type: String, required: true },
    reportConfigurationsUrl: { type: String, required: true },
    apiReportConfigurationsUrl: { type: String, required: true },
    canRemoveConfiguration: { type: Boolean, required: true },
    reportConfigurationLocations: { type: Array, default: () => []}
  },
  data() {
    return {
      currentPage: 1,
      totalCount: 0,

      fields: [{
        type: 'search',
        key: 'search',
        title: 'Configuration',
        classes: 'w-100',
        placeholder: 'Type a name of configuration'
      }, {
        type: 'select',
        key: 'by_location',
        title: 'Location',
        classes: 'w-100',
        placeholder: 'All',
        options: this.reportConfigurationLocations,
        trackBy: 'remoteId',
        label: 'name',
        searchable: true,
        showLabels: false,
        allowEmpty: true,
        multiple: false,
        maxHeight: 540
      }, {
        type: 'dateRange',
        key: 'created_at',
        title: 'Created At',
        classes: 'w-100',
        placeholder: 'All',
        maxDate: 'today',
        minDate: null
      }]
    }
  },
  methods: {
    tableSource(prms) {
      return axios.get(
        this.apiReportConfigurationsUrl,
        {
          params: {
            ...prms,
            ...this.filter,
            page: this.currentPage
          },
          paramsSerializer(json) { return qs.stringify(json, { arrayFormat: 'brackets' }) },
          ...axiosTransform
        }
      ).then((response) => {
        this.totalCount = parseInt(response.headers['total-count'], 10)
        return response.data
      })
    },
    updatePageNumber(page) {
      this.currentPage = page
      this.reload()
    },
    onFilterChange(newFilterValues) {
      this.filter = newFilterValues
      this.currentPage = 1
      this.reload()
    },
    reload() {
      this.$refs.table.reload()
    },
    connectToWS() {
      const channel = App.cable.subscriptions.create('GenerateReportChannel', {
        received: (data) => {
          if (data.status === 'success') {
            const reportLinks = data.report_ids.map((r) => `<a href="${this.reportsUrl}/${r}" target="_blank">${this.reportsUrl}/${r}</a>`).join('<br>')
            toastr.success(reportLinks, data.msg)
            this.reload()
          } else {
            toastr.error(data.msg)
          }
        }
      })

      document.addEventListener('turbolinks:before-visit', () => {
        channel.unsubscribe(channel)
      })
    },
    reportRemoved(msg) {
      this.reload()
      toastr.success(msg)
    }
  },
  created() {
    this.perPage = 20
    this.columns = [
      { title: 'Configuration name', key: 'name' },
      { title: 'White Labeling', key: 'brand', orderable: true },
      { title: 'Locations', key: 'locations' },
      { title: 'Created At', key: 'created_at', orderable: true },
      { title: 'Last run', key: 'processed_at', orderable: true },
      { title: 'Next run', key: 'scheduled_at', orderable: true },
      { title: 'Schedule', key: 'schedule' },
      { title: 'Reports', key: 'reports_count', orderable: true },
      { title: 'Actions', key: 'actions' }
    ]

    this.connectToWS()
  }
}
</script>
