<template>
  <div class="form-field mb-0">
    <span class="caption">Geogrid Size</span>
    <multiselect
      v-model="model"
      :allow-empty="false"
      :options="gridSizes"
      :searchable="false"
      :show-labels="false"
      :max-height="540"
      label="text"
      class="distance" />
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapState } from 'vuex'

export default {
  components: { Multiselect },
  computed: {
    ...mapState(['gridSizes']),
    model: {
      get() {
        return this.gridSizes.find((size) => size.id === this.$store.state.geogridWidget.gridSize)
      },
      set(val) {
        this.$store.commit('changeGeogridWidgetKey', { key: 'gridSize', val: val.id })
      }
    }
  }
}
</script>
