<template>
  <div class="dropdown-action dropdown">
    <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="d-flex align-items-baseline gap-1">
      Tags
      <i class="far fa-chevron-down icon" />
    </div>
    <div class="dropdown-box dropdown-menu" ref="dropdownBox" @click.stop>
      <div class="tag-form" @click.stop>
        <div class="tag-searching">
          <input
            v-model.trim="search"
            type="text"
            placeholder="Enter tag name"
            maxlength="30"
            @keyup.enter="addToTags(search)">
        </div>
        <div class="tag-list scroll ver">
          <Checkbox v-for="(tag, index) in filteredTags"
            :key="index"
            :value="tag.selected"
            :part-selected="tag.partSelected"
            @input="(isSelected) => toggleTag(tag, isSelected)">
            {{ tag.value }}
          </Checkbox>
          <Checkbox
            class="new-item"
            v-if="canCreateTag"
            @input="() => addToTags(search)">
            <span>{{ search }}</span>
            <small>(NEW)</small>
          </Checkbox>
        </div>
        <div class="confirm-box d-flex gap-2">
          <button class="btn btn-sm btn-primary rounded" @click="saveTags">{{ saveButtonText }}</button>
          <button class="btn btn-sm rounded" @click="resetTagList">Clear All</button>
          <button class="btn btn-link ml-auto gap-2" @click="closeDropdown">
            Close
            <i class="far fa-times" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from 'vue_widgets/components/checkbox'

export default {
  components: { Checkbox },
  props: {
    tags: { type: Array, default: () => []},
    elements: { type: Array, default: () => []}
  },
  data() {
    return {
      search: '',
      tagList: []
    }
  },
  created() {
    this.resetTagList()
  },
  computed: {
    filteredTags() {
      return this.tagList.filter(({ value }) => value.toLowerCase().includes(this.search.toLowerCase()))
    },
    canCreateTag() {
      return this.search && !this.filteredTags.length
    },
    tagsCounterInElements() {
      return this.tagList.reduce((memo, { value }) => {
        memo[value] = 0
        this.elements.forEach(({ tags }) => {
          if (tags.includes(value)) {
            memo[value]++
          }
        })

        return memo
      }, {})
    },
    saveButtonText() {
      return this.canCreateTag ? 'Add' : 'Save'
    }
  },
  methods: {
    closeDropdown() {
      this.$refs.dropdownBox.classList.remove('show')
    },
    addToTags(tag) {
      if (!this.canCreateTag) return
      this.search = ''
      this.tagList.unshift({
        value: tag,
        selected: true,
        partSelected: false
      })
    },
    isTagSelected(tag) {
      return Boolean(this.elements.find(({ tags }) => tags.includes(tag)))
    },
    isTagPartSelected(tag) {
      return Boolean(this.tagsCounterInElements[tag] &&
        this.tagsCounterInElements[tag] !== 0 &&
        this.tagsCounterInElements[tag] !== this.elements.length)
    },
    toggleTag(clickedTag, isSelected) {
      this.tagList.forEach((tag) => {
        if (clickedTag.value === tag.value) {
          tag.selected = isSelected
          tag.partSelected = false
        }
      })
    },
    resetTagList() {
      this.tagList = this.tags.map((tag) => ({
        value: tag,
        selected: this.isTagSelected(tag),
        partSelected: this.isTagPartSelected(tag)
      }))
    },
    saveTags() {
      if (this.canCreateTag) {
        const tagToAdd = this.search
        this.addToTags(tagToAdd)
        return
      }

      const tagsToSave = []
      const tagsToRemove = []

      this.tagList.forEach((tag) => {
        if (!tag.partSelected) {
          if (tag.selected) {
            tagsToSave.push(tag.value)
          } else {
            tagsToRemove.push(tag.value)
          }
        }
      })

      this.$emit('updateTags', { tagsToSave, tagsToRemove })

      this.closeDropdown()
    }
  },
  watch: {
    elements: {
      handler() {
        this.resetTagList()
      }
    }
  }
}
</script>
