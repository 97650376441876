<template>
  <div v-if="searchTerms && searchTerms.length" class="color-dark">
    <h2 class="mb-3 font-weight-semi-bold">Maps Search Geogrids</h2>

    <div class="report-maps-search-geogrids">
      <template v-for="{ id, oldestImage, newestImage } in searchTerms">
        <div :key="`${id}_oldest`" class="image-container">
          <img v-if="oldestImage" :src="oldestImage" alt="" class="report-geogrid-image">
          <span v-else class="h4 m-0">No data for this range</span>
        </div>
        <div :key="`${id}_newest`" class="image-container">
          <img v-if="newestImage" :src="newestImage" alt="" class="report-geogrid-image">
          <span v-else class="h4 m-0">No data for this range</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: mapState(['searchTerms'])
}
</script>
