<template>
  <div class="radio-buttons">
    <button type="button" @click="setPreset('month')" :class="['radio-btn', {active: preset === 'month'}]">Month</button>
    <button type="button" @click="setPreset('quarter')" :class="['radio-btn', {active: preset === 'quarter'}]">Quarter</button>
    <button type="button" @click="setPreset('year')" :class="['radio-btn', {active: preset === 'year'}]">Year</button>
  </div>
</template>

<script>
import moment from 'moment'
import { TIME_FORMAT } from '../../../../common/datepicker'

export default {
  props: {
    preset: {
      type: String,
      default: ''
    }
  },
  created() {
    this.setPreset('month')
  },
  methods: {
    offsetMoment() {
      return moment.utc().subtract(3, 'days')
    },
    setPreset(preset) {
      const plural = `${preset}s`
      this.$emit('setPreset', {
        preset: preset,
        ranges: [
          {
            start: this.offsetMoment().subtract(1, plural).format(TIME_FORMAT),
            end: this.offsetMoment().format(TIME_FORMAT)
          },
          {
            start: this.offsetMoment().subtract(2, plural).format(TIME_FORMAT),
            end: this.offsetMoment().subtract(1, plural).format(TIME_FORMAT)
          }
        ]
      })
    }
  }
}
</script>
