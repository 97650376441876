<template>
  <div class="onboarding-form-container">
    <div class="card" v-if="currentWlc && currentWlc.domain && currentWlc.domainCheckedAt">
      <div class="card-head">
        <h3>
          White label domain:

          <a :href="`//${currentWlc.domain}`" @click.prevent="redirectWlc">{{ currentWlc.domain }}</a>
        </h3>
      </div>
    </div>
    <div class="card" v-else>
      <div class="card-head">
        <h3>White label domain</h3>

        <div v-if="currentWlc.id && !currentWlc.domainCheckedAt">
          <span v-if="!showRefresh" class="in-progress-label">Verification in progress</span>

          <a v-if="currentWlc.id && showRefresh" href="#" @click.prevent="revereficate">
            <i class="far fa-sync" />
          </a>
        </div>
      </div>

      <div class="card-body">
        <div class="form-group">
          <input type="text"
            v-once
            class="form-control"
            placeholder="wl1.lvstaging.space"
            :value="currentWlc.domain"
            @input="onDomainChange">
          <span v-if="errors.domain" class="invalid-feedback d-block">{{ errors.domain }}</span>
        </div>

        <p>
          Enter domain (or a subdomain) which you'd like to use and configure its CNAME DNS record to <b>wl.localbusinessreporting.com</b>.
        </p>
      </div>
    </div>

    <div class="card">
      <div class="card-head">
        <h3>Google API</h3>
      </div>

      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div :class="['form-group', { 'is-invalid': errors.googleAppId }]">
              <label for="google_app_id">Google App ID</label>
              <input
                type="text"
                :disabled="!currentWlc.domainCheckedAt"
                class="form-control"
                placeholder="Enter the key you received from Google"
                id="google_app_id"
                v-model="currentWlc.googleAppId">
              <span v-if="errors.googleAppId" class="invalid-feedback d-block">{{ errors.googleAppId }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div :class="['form-group', { 'is-invalid': errors.googleAppSecret }]">
              <label for="google_app_secret">Google App Secret</label>
              <input
                type="text"
                :disabled="!currentWlc.domainCheckedAt"
                class="form-control"
                placeholder="Enter the key you received from Google"
                id="google_app_secret"
                v-model="currentWlc.googleAppSecret">
              <span v-if="errors.googleAppSecret" class="invalid-feedback d-block">{{ errors.googleAppSecret }}</span>
            </div>
          </div>
        </div>

        <p>Paste Client ID and Client Secret credentials from your OAuth Client here</p>

        <div class="text-center mt-5">
          <a class="btn btn-primary" :disabled="connectDisabled" href="/google_accounts/new?state=onboarding">Connect Google Account</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { App } from 'common/rails'
import axiosTransform from 'common/axios'
import toastr from 'toastr'

const REFRESH_TIMEOUT = 60 * 1000

export default {
  props: {
    wlc: { type: Object },
    csrf: { type: String, required: true }
  },
  data() {
    return {
      currentWlc: this.wlc || {},
      errors: {},
      showRefresh: !this.wlc?.domainCheckedAt && !this.domainChecking,
      timeout: null
    }
  },
  computed: {
    connectDisabled() {
      return !this.currentWlc.googleAppId?.length || !this.currentWlc.googleAppSecret?.length || !this.currentWlc.domainCheckedAt || this.timeout
    }
  },
  methods: {
    onDomainChange(e) {
      clearTimeout(this.timeout)
      const domain = e.target.value
      this.errors = {}
      if (domain.length) {
        this.$emit('domain-changed', domain)
        this.timeout = setTimeout(() => {
          let req
          if (this.currentWlc.id) {
            req = axios.put(`/api/white_label_configurations/${this.currentWlc.id}`, { whiteLabelConfiguration: { domain } }, axiosTransform)
          } else {
            req = axios.post('/api/white_label_configurations', { whiteLabelConfiguration: { domain } }, axiosTransform)
          }

          req
            .then((res) => {
              this.currentWlc = res.data.attributes
              this.verificateTimeout()
            })
            .catch((err) => {
              this.errors = err.response.data?.errors || { domain: 'Domain is invalid' }
            })
        }, 300)
      } else {
        this.errors = { domain: "Can't be blank" }
      }
    },
    onGoogleKeysChange() {
      clearTimeout(this.timeout)
      this.errors = {}
      this.timeout = setTimeout(() => {
        if (this.currentWlc.id && this.currentWlc.googleAppId && this.currentWlc.googleAppSecret) {
          axios.put(
            `/api/white_label_configurations/${this.currentWlc.id}`,
            { white_label_configuration: { googleAppId: this.currentWlc.googleAppId?.trim(), googleAppSecret: this.currentWlc.googleAppSecret?.trim() } },
            axiosTransform
          ).then(() => {
            this.timeout = null
          }).catch((err) => {
            this.errors = err.response.data?.errors || {}
          })
        }
      }, 300)
    },
    redirectWlc() {
      const form = document.createElement('form')
      form.action = `/wl_auth_tokens?authenticity_token=${this.csrf}&domain=${this.currentWlc.domain}`
      form.method = 'post'
      document.body.appendChild(form)
      form.submit()
    },
    verificateTimeout() {
      setTimeout(() => {
        this.showRefresh = true
      }, REFRESH_TIMEOUT)
    },
    revereficate() {
      axios.post(`/white_label_configurations/${this.currentWlc.id}/domain_check`)
        .then(() => {
          this.showRefresh = false
          this.verificateTimeout()
        })
    }
  },
  watch: {
    'currentWlc.googleAppId': 'onGoogleKeysChange',
    'currentWlc.googleAppSecret': 'onGoogleKeysChange'
  },
  created() {
    this.cable = App.cable.subscriptions.create(
      { channel: 'WhiteLabelConfigurationChannel' },
      {
        received: ({ wlc, status, message }) => {
          if (status === 'success') {
            this.currentWlc = wlc
            this.redirectWlc()
          } else {
            toastr.warning(message)
            this.showRefresh = true
          }
        }
      }
    )
    $(document).one('turbolinks:visit', () => {
      App.cable.subscriptions.remove(this.cable)
    })

    if (this.currentWlc.id && !this.currentWlc.domainCheckedAt) this.verificateTimeout()
  }
}
</script>
