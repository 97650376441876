function findScript(src) {
  return document.querySelector(`script[src="${src}"]`)
}

export default function injectScript(src) {
  return new Promise((resolve, reject) => { // eslint-disable-line consistent-return
    if (findScript(src)) return resolve() // eslint-disable-line no-promise-executor-return
    const script = document.createElement('script')
    script.async = true
    script.src = src
    script.addEventListener('load', resolve)
    script.addEventListener('error', () => reject(new Error('Error loading script.')))
    script.addEventListener('abort', () => reject(new Error('Script loading aborted.')))
    document.head.appendChild(script)
  })
}
