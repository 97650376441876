<template>
  <div class="chart" ref="chart" />
</template>

<script>
import Highcharts from 'highcharts'
import { tooltipOptions } from '../locations/insights/helpers'
import { tooltipHeader, tooltipPoint } from './helpers'

export default {
  props: {
    dataSet: {
      type: Array,
      required: true
    }
  },
  mounted() {
    let needMaxAxisValue = false
    const modifiedDataSet = this.dataSet.map((series) => {
      series.data.forEach((data) => {
        if (!data.y) {
          data.y = 100
          data.isFakeValue = true
          needMaxAxisValue = true
        } else if (data.y >= 75) {
          needMaxAxisValue = true
        }
        const dateWithoutTime = (new Date(data.x)).setHours(0, 0, 0, 0)
        data.x = (new Date(dateWithoutTime)).getTime()
      })

      return series
    })
    this.chart = Highcharts.chart(this.$refs.chart, {
      alignTicks: false,
      allowDecimals: false,
      credits: { enabled: false },
      chart: {
        height: 280,
        style: {
          display: 'block',
          fontFamily: '"Nunito", sans-serif'
        }
      },
      title: false,
      legend: { enabled: false },
      subtitle: { enabled: false },
      xAxis: {
        type: 'datetime',
        labels: {
          formatter() {
            return Highcharts.dateFormat('%b %e', this.value)
          },
          style: {
            fontSize: '14px',
            color: '#77838f'
          }
        },
        tickLength: 0
      },
      yAxis: {
        reversed: true,
        allowDecimals: false,
        title: {
          enabled: false
        },
        max: needMaxAxisValue ? 100 : undefined,
        labels: {
          formatter() {
            return this.value >= 100 ? 'N/A' : this.value
          },
          style: {
            fontSize: '14px',
            color: '#77838f'
          }
        }
      },
      plotOptions: {
        line: {
          states: {
            inactive: { opacity: 1 }
          },
          marker: {
            enabled: false,
            symbol: 'circle'
          }
        }
      },
      time: { useUTC: false },
      series: modifiedDataSet,
      tooltip: {
        ...tooltipOptions,
        shared: true,
        formatter() {
          const memo = []
          memo.push(tooltipHeader(this.x))
          this.points.forEach((point) => {
            memo.push(tooltipPoint(point))
          })
          return memo.join('')
        }
      }
    })
  }
}
</script>
