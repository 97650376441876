import { Notifier } from '@airbrake/browser'
import Vue from 'vue'
import Cookies from 'js-cookie'

const airbrake = new Notifier({
  projectId: 212358,
  projectKey: 'c86e32270d810f5a17cca9907e6f9a93'
})

airbrake.addFilter((notice) => {
  if (process.env.NODE_ENV === 'development') {
    throw notice.errors[0]
  }
  notice.context.user = { email: Cookies.get('user_email') }
  return notice
})

Vue.config.errorHandler = (err, vm, info) => {
  const params = { info: info }
  airbrake.notify({
    error: err,
    params: params
  })
}

// eslint-disable-next-line import/prefer-default-export
export function airbrakeNotify(err) {
  airbrake.notify(err)
}
