<template>
  <div class="chart-contaner">
    <div class="chart chartbar-custom">
      <div class="label">{{ subtitle }}</div>
      <div class="x-axis" data-min="0" :data-max="maxValue">
        <div class="tick" v-for="n in 11" :key="n" />
      </div>
      <div class="bar-chart" v-for="(chart, index) in series" :key="chart.id">
        <div
          :class="['line', {compare: compare}]"
          :style="{
            backgroundColor: chartColors[index],
            width: `${parseFloat(chart.sum) / maxValue * 100 }%`
          }" />
        <div
          v-if="compare"
          class="line compare"
          :style="{
            backgroundColor: `${chartColors[index]}80`,
            width: `${parseFloat(chart.sum_compare) / maxValue * 100 }%`
          }" />
        <div class="chart-legend bar">
          <ChartLegeng
            :color="chartColors[index]"
            :name="chart.name"
            :description="chart.description"
            :values="chart.values"
            :percentage="chart.percentage"
            :compare="compare" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import camelCaseKeys from 'camelcase-keys-deep'
import { mapGetters } from 'vuex'
import { humanizeRangeDate } from '../../../../common/datepicker'
import { getValues, getPercentage } from './helpers'
import ChartLegeng from './chart_legend'

const DESCRIPTIONS = {
  photos_views_customers: 'Of views on media items uploaded by customers.',
  photos_views_merchant: 'Of views on media items uploaded by the GMB listing owner(s).',
  photos_count_merchant: 'Of media items that are currently live that have been uploaded by the GMB owner(s).',
  photos_count_customers: 'Of media items that are currently live that have been uploaded by customers.'
}
export default {
  components: { ChartLegeng },
  props: {
    chartIds: {
      type: Array,
      required: true
    },
    chartColors: {
      type: Array,
      default() { return [] }
    }
  },
  data() {
    return {
      subtitle: '',
      maxValue: 0,
      series: []
    }
  },
  computed: mapGetters(['insightsDateRanges', 'stats', 'compare']),
  methods: {
    initChart(data) {
      if (this.chart) this.chart.destroy()
      const { ranges } = this.insightsDateRanges
      this.subtitle = humanizeRangeDate(ranges[0])
      if (this.compare) this.subtitle += ` vs ${humanizeRangeDate(ranges[1])}`
      this.series = data
        .filter((chart) => this.chartIds.includes(chart.id))
        .map((chart) => {
          chart.values = getValues(chart, this.compare)
          chart.percentage = getPercentage(camelCaseKeys(chart))
          return chart
        })
      this.maxValue = 0
      this.series.forEach((element) => {
        this.maxValue = Math.max(this.maxValue, element.sum, element.sum_compare)
        element.description = DESCRIPTIONS[element.id]
      })
      this.maxValue = this.maxValue * 1.3 + 10
      this.maxValue -= (this.maxValue % 10)
    }
  },
  mounted() {
    this.initChart(this.stats)
  },
  watch: {
    stats: 'initChart'
  }
}
</script>
