import PaymentData from 'vue_widgets/payment_data'

function initStripe(props) {
  $(() => {
    new Vue({ // eslint-disable-line no-new
      el: document.getElementById('vue-payment-form'),
      render: (h) => h(PaymentData, { props })
    })
  })
}

Styxie.Initializers.PaymentData = {
  edit: initStripe,
  update: initStripe
}
