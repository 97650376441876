<template>
  <div class="geogrid-competitors-score">
    <div class="geogrid-competitors-score__head">
      <PrintHint class="noprint" />
      <h2 class="geogrid-competitors-score__title">Competitors</h2>
      <button class="geogrid-competitors-score__close btn btn-pretender" @click="$emit('close')">close<i class="fa-solid fa-xmark pl-2" /></button>
      <div class="geogrid-competitors-score__geogrid-info print-show">
        <div class="geogrid-competitors-score__line">
          <p>Search term:</p>
          <span>{{ geogrid.searchTerm }}</span>
        </div>
        <div class="geogrid-competitors-score__line">
          <p>Created at:</p>
          <span>{{ geogrid.createdAt }}</span>
        </div>
      </div>
      <VueSlimTable v-if="geogrid.obfuscatedId"
        class="geogrid-competitors-score__current"
        :columns="columns"
        :source="() => ([{}])">
        <template #thead>
          <tr />
        </template>
        <template #row>
          <tr>
            <td class="geogrid-competitors-score__column geogrid-competitors-score__column--current geogrid-competitors-score__column--image" />
            <td class="geogrid-competitors-score__column geogrid-competitors-score__column--current geogrid-competitors-score__column--name">
              <div class="d-flex flex-column gap-1">
                <span class="geogrid-competitors-score__name geogrid-competitors-score__name--current">{{ current.name }}</span>
                <span v-if="current.storeCode" class="geogrid-competitors-score__store-code">{{ current.storeCode }}</span>
                <span class="geogrid-competitors-score__address geogrid-competitors-score__address--current">{{ current.address }}</span>
                <span v-if="current.category" class="geogrid-competitors-score__main-category">{{ current.category }}</span>
              </div>
            </td>
            <td class="geogrid-competitors-score__column geogrid-competitors-score__column--current geogrid-competitors-score__column--agr">
              <p>AGR:</p>
              <span>{{ current.geogrid.agr }}</span>
            </td>
            <td class="geogrid-competitors-score__column geogrid-competitors-score__column--current geogrid-competitors-score__column--atgr">
              <p>ATGR:</p>
              <span>{{ current.geogrid.atgr }}</span>
            </td>
            <td class="geogrid-competitors-score__column geogrid-competitors-score__column--current geogrid-competitors-score__column--solv">
              <p>SoIV:</p>
              <span>{{ current.geogrid.solv }}</span>
            </td>
            <td class="geogrid-competitors-score__column geogrid-competitors-score__column--current geogrid-competitors-score__column--rating">
              <p>Rating:</p>
              <span>{{ currentCompetitorLocation?.rating?.toFixed(1) ?? 'N/A' }}</span>
            </td>
            <td class="geogrid-competitors-score__column geogrid-competitors-score__column--geogrid" />
          </tr>
        </template>
      </VueSlimTable>
    </div>
    <div class="geogrid-competitors-score__list scroll ver hor">
      <VueSlimTable
        v-if="geogrid.obfuscatedId"
        ref="table"
        :source="loadCompetitors"
        :columns="columns"
        class="geogrid-competitors-score__table">
        <template #row="{ row, index }">
          <CompetitorScoreRow :row="row" @showCompetitorGeogrid="showCompetitorGeogrid($event, index + 1)" />
        </template>
        <template #pagination>
          <div />
        </template>
      </VueSlimTable>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import hotkeys from 'hotkeys-js'
import { mapState, mapActions } from 'vuex'
import axiosTransform from '../../../common/axios'

import CompetitorScoreRow from './competitor_score_row'
import PrintHint from '../../components/print_hint'

export default {
  components: {
    CompetitorScoreRow,
    PrintHint
  },
  data: () => ({
    current: {},
    competitors: null,
    updateCompetitors: true
  }),
  created() {
    this.columns = [
      {
        title: '',
        key: 'imageSrc'
      },
      {
        title: 'Business',
        key: 'name'
      },
      {
        title: 'AGR',
        key: 'agr',
        orderable: true
      },
      {
        title: 'ATGR',
        key: 'atgr',
        orderable: true
      },
      {
        title: 'SolV',
        key: 'solv',
        orderable: true
      },
      {
        title: 'Rating'
      },
      {
        title: ''
      }
    ]
  },
  methods: {
    handleEsc(event, handler) {
      if (handler.key !== 'esc') {
        return
      }
      event.preventDefault()

      this.$emit('close')
    },
    async loadCompetitors(params) {
      const order = params?.order
      let sortBy = 'solv'
      let sortOrder = 'desc'
      if (order) {
        sortBy = order[0].field
        sortOrder = order[0].direction
      }

      const sortingFunction = (a, b) => {
        const direction = sortOrder === 'asc' ? 1 : -1
        return (a.geogrid[sortBy] - b.geogrid[sortBy]) * direction
      }

      if (this.updateCompetitors) {
        const { data: competitors } = await axios.get(`api/geogrids/${this.geogrid.obfuscatedId}/competitors_list`, {
          ...axiosTransform
        })

        this.competitors = competitors ?? []
        this.updateCompetitors = false
      }

      return this.competitors.sort(sortingFunction)
    },
    showCompetitorGeogrid(competitor, index) {
      this.$emit('showCompetitorGeogrid', { competitor, index })
    },
    ...mapActions(['showGeogrid'])
  },
  computed: {
    ...mapState(['geogrid']),
    currentCompetitorLocation() {
      return this.competitors?.find((competitor) => competitor.yourCompany)
    }
  },
  mounted() {
    hotkeys('esc', this.handleEsc)
    // We need these values to be formatted to the two digits after the dot.
    // We receive these values as strings from backend for whatever reason, so we kinda have to do these gimmicks.
    let agr = parseFloat(this.geogrid.agr)
    let atgr = parseFloat(this.geogrid.atgr)
    let solv = parseFloat(this.geogrid.solv)
    agr = Number.isNaN(agr) ? 'N/A' : agr.toFixed(2)
    atgr = Number.isNaN(atgr) ? 'N/A' : atgr.toFixed(2)
    solv = Number.isNaN(solv) ? '0%' : `${(solv * 100).toFixed(0)}%`

    this.current = {
      name: this.geogrid.attrs.businessName,
      storeCode: this.geogrid.attrs.businessStoreCode,
      address: this.geogrid.attrs.businessAddress || 'Service Area Business',
      category: this.geogrid.attrs.businessCategories?.length ? this.geogrid.attrs.businessCategories[0] : null,
      geogrid: {
        agr,
        atgr,
        solv
      }
    }
  },
  destroyed() {
    hotkeys.unbind('esc', this.handleEsc)
  }
}
</script>
