<template>
  <div class="form-group row">
    <label for="media_file" class="col-12 col-sm-3 col-form-label text-sm-right">Image</label>
    <div class="col-12 col-sm-8 col-lg-6">
      <button type="button" class="btn btn btn-default btn-secondary" @click.prevent="showModal = true">Select Image</button>

      <template v-if="imageSrc">
        <img :src="imageSrc" class="img-thumbnail d-block my-2">
        <button type="button" class="btn btn btn-default btn-secondary mb-2" @click.prevent="removeSelectedImage">Remove Image</button>

        <TextareaWithLimit
          v-if="!object.topicType"
          :limit="1500"
          :input-attrs="{
            name: 'media-description',
            id: 'media-description',
            class: 'form-control',
            placeholder: 'Media Description'
          }"
          :value="mediaDescription"
          @change="changeMediaDescription" />
      </template>

      <AppModal v-model="showModal" size="xxl vue-image-select-modal">
        <template slot="header">
          <h3>Image Select</h3>
        </template>

        <template slot="body">
          <component :is="`${imageStep}-step`" ref="body" />
        </template>
      </AppModal>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'

import AppModal from '../modal'
import SelectStep from './steps/select'
import AdvancedStep from './steps/advanced'
import TextareaWithLimit from '../textarea_with_limit'

export default {
  components: {
    AppModal, SelectStep, AdvancedStep, TextareaWithLimit
  },
  data() {
    return {
      showModal: false
    }
  },
  computed: {
    ...mapState(['imageStep', 'object', 'mediaDescription']),
    imageSrc() {
      if (this.object.mediaFormat === 'video') return null
      if (this.object.galleryImage) return this.object.galleryImage.url
      return this.object.imagePath
    }
  },
  methods: {
    ...mapActions(['fetchExifPreview', 'fetchExifTemplates']),
    ...mapMutations(['removeSelectedImage', 'changeMediaDescription'])
  },
  watch: {
    'object.galleryImage'() {
      if (this.$refs.body) $(this.$refs.body.$refs.wrapper).toggleWrapper({}, false)
      this.showModal = false
    },
    showModal(bool) {
      if (bool) this.fetchExifPreview()
    }
  },
  created() {
    this.fetchExifTemplates()
  }
}
</script>
