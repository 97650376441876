import Vue from 'vue'

function handleVueDestruction(vue) {
  document.addEventListener('turbolinks:visit', function teardown() {
    vue.$destroy()
    document.removeEventListener('turbolinks:visit', teardown)
  })
}

Vue.mixin({
  beforeMount() {
    if (this.$el && this.$el.parentNode) {
      handleVueDestruction(this)
      this.$originalEl = this.$el.outerHTML
    }
  },
  destroyed() {
    if (this.$el && this.$originalEl) {
      this.$el.outerHTML = this.$originalEl
    }
  }
})
