<template>
  <div :class="['location-selector', { 'form-opened': isFormOpened }]" @click="toggleForm">
    <div class="location-selector-view" @click.stop>
      <i class="far fa-plus-circle view-location-add" @click="toggleForm" />
      <span class="view-location-caption" @click="toggleForm">
        Locations {{ selectedLocations.length }}&nbsp;&nbsp;Tags {{ selectedTags.length }}</span>
      <div v-if="selectedLocations.length" class="view-control">
        <button class="btn btn-link btn-danger mr-3" @click="clearAll">Clear</button>
        <button class="btn btn-link mr-2" @click="toggleList">{{ isListShown ? 'Hide' : 'Show' }}</button>
      </div>
    </div>
    <ul v-if="selectedLocations.length && isListShown" class="location-selector-list" @click.stop>
      <li class="selected-location-head">Locations:</li>
      <li v-for="{ id, name, address } in selectedLocations" :key="id" class="selected-location">
        <i class="far fa-minus-circle selected-location-remove mr-2" @click="removeSelectedLocation(id)" />
        <div class="selected-location-info">
          <p class="text-truncate m-0">{{ name }}</p>
          <p class="text-truncate text-muted m-0">{{ address }}</p>
        </div>
      </li>
    </ul>
    <ul v-if="selectedTags.length && isListShown" class="location-selector-list" @click.stop>
      <li class="selected-location-head">Tags:</li>
      <li v-for="tag in selectedTags" :key="tag" class="selected-location">
        <i class="far fa-minus-circle selected-location-remove mr-2" @click="removeSelectedTag(tag)" />
        <div class="selected-location-info">
          <p class="text-truncate m-0">{{ tag }}</p>
        </div>
      </li>
    </ul>
    <LocationSelectorDropdown v-if="isFormOpened" @addLocationsTags="addLocationsTags" />
  </div>
</template>

<script>
import LocationSelectorDropdown from './dropdown'

export default {
  components: { LocationSelectorDropdown },
  data() {
    return {
      selectedLocations: [],
      selectedTags: [],
      isListShown: false,
      isFormOpened: false
    }
  },
  methods: {
    clearAll() {
      this.selectedLocations = []
      this.selectedTags = []
      this.emitData()
    },
    toggleForm() {
      this.isFormOpened = !this.isFormOpened
      this.isListShown = false
    },
    toggleList() {
      this.isListShown = !this.isListShown
      this.isFormOpened = false
    },
    removeSelectedLocation(id) {
      this.selectedLocations = this.selectedLocations.filter((location) => location.id !== id)
      this.emitData()
    },
    removeSelectedTag(tag) {
      this.selectedTags = this.selectedTags.filter((selectedTag) => selectedTag !== tag)
      this.emitData()
    },
    addLocationsTags({ locations, tags }) {
      this.selectedTags = this.selectedTags.filter((tag) => !tags.find((newTag) => tag === newTag)).concat(tags)
      this.selectedLocations = this.selectedLocations.filter((location) => !locations.find((newLocation) => location.id === newLocation.id)).concat(locations)
      this.toggleForm()
      this.emitData()
    },
    emitData() {
      this.$emit('addLocationsTags', {
        tags: this.selectedTags,
        locations: this.selectedLocations.map((location) => location.id)
      })
    }
  }
}
</script>
