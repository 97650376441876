<template>
  <div class="geobooster-widgets-filter d-flex align-items-end row-gap-4 col-gap-6">
    <div
      v-for="field in fields"
      class="geobooster-widgets-filter__field w-100">
      <Component
        class="form-field--rem"
        v-model="field.value"
        :key="field.name"
        :is="field.component"
        :values="field.values"
        :placeholder="field.placeholder"
        :error="field.error"
        :error-text="field.errorText"
        :type="field.type ? field.type : 'text'"
        :label="field.label"
        :name="field.name"
        :native="field.native"
        :multiple="field.multiple"
        :allow-empty="field.allowEmpty" />
    </div>
    <button
      class="geobooster-widgets-filter__clear btn btn-link btn--rem gap-3"
      @click="$emit('clearFilters')">
      <i class="far fa-eraser" /><span>Clear filters</span>
    </button>
  </div>
</template>

<script>
import InputSearch from '../../components/input_search_ui'
import CustomSelect from '../../components/custom_select'

export default {
  components: {
    InputSearch,
    CustomSelect
  },
  props: {
    fields: {
      type: Object,
      required: true
    }
  }
}
</script>
