<template>
  <tr>
    <td column-caption="Name">
      <a :href="`${basePath}/${row.id}/edit`">{{ row.title }}</a>
    </td>
    <td column-caption="Location" class="column">
      {{ row.location.name }}
      <span v-if="row.location.storeCode" class="d-block text-grey font-size-12">{{ row.location.storeCode }}</span>
      <span v-if="row.location.address" class="d-block text-muted font-size-12">{{ row.location.address }}</span>
    </td>
    <td column-caption="Keyword">
      {{ row.keywords.join(', ') }}
    </td>
    <td column-caption="Competitors" class="column">
      <div class="d-flex flex-wrap gap-2">
        <div v-for="{ name } in row.chosenCompetitors" class="tag">
          <div class="tag-text">{{ name }}</div>
        </div>
      </div>
    </td>
    <td column-caption="Status">
      {{ statuses[row.status] }}
    </td>
    <td column-caption="Schedule">
      {{ frequency[row.schedule?.periodicity] ?? 'N/A' }}
    </td>
    <td column-caption="Last audit">
      {{ row.lastReportCreatedAt }}
    </td>
    <td column-caption="Next audit">
      {{ row.scheduledAt }}
    </td>
    <td column-caption="Audits">
      <a href="#" @click.prevent="findAudits(row.id)">{{ row.reportsCount }}</a>
    </td>
    <td>
      <div class="d-flex flex-wrap align-items-center justify-content-center gap-3">
        <button v-if="row.status === 'scheduled'"
          type="button"
          class="btn btn-link gap-2"
          :disabled="isRequested"
          @click="onRunReport(row.id)">
          <i class="far fa-play" />
        </button>
        <button type="button" class="btn btn-link btn-danger" :disabled="isRequested" @click="onDelete(row.id)">
          <i class="far fa-trash" />
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
import { DEFAULT_ERROR_MESSAGE } from 'common/constants'
import { insertSessionStorage } from 'common/helpers'
import { AUDIT_REPORT_CONFIG_ID_KEY } from 'vue_widgets/audit_report_configurations/helpers'

export default {
  props: {
    row: { type: Object, required: true },
    basePath: { type: String, required: true },
    apiBasePath: { type: String, required: true }
  },
  data() {
    return {
      isRequested: false
    }
  },
  created() {
    this.frequency = {
      weekly: 'Weekly',
      monthly: 'Monthly',
      day_of_month: 'Day of month',
      once: 'On Demand Run'
    }

    this.statuses = {
      initialized: 'Initialized',
      searching_competitors: 'Searching competitors',
      choosing_competitors: 'Choosing competitors',
      scheduled: 'Scheduled',
      disabled: 'Disabled'
    }
  },
  methods: {
    onDelete(id) {
      swal({
        text: 'This will also lead to deletion of all generated audit reports.',
        icon: 'warning',
        buttons: ['Cancel', 'Delete'],
        dangerMode: true
      }).then((result) => {
        if (result) {
          this.isRequested = true
          axios.delete(`${this.apiBasePath}/${id}`).then(() => {
            toastr.success('Configuration successfully deleted.')
            this.$emit('reload')
          }).catch(() => {
            toastr.error(DEFAULT_ERROR_MESSAGE)
          }).finally(() => {
            this.isRequested = false
          })
        }
      })
    },
    onRunReport(id) {
      this.isRequested = true
      axios.post(`${this.apiBasePath}/${id}/run_report`)
        .then(() => {
          toastr.success('Audit successfully run.')
          const indexComponent = this.$root.$children[0]
          const auditComponent = indexComponent?.$refs['audit-table']
          auditComponent?.$refs.table.reload()
        })
        .catch((err) => {
          if (err.response.status === 422 && err.response.data.type === 'validation') {
            toastr.error(err.response.data.text)
          } else {
            toastr.error(DEFAULT_ERROR_MESSAGE)
          }
        })
        .finally(() => {
          this.isRequested = false
        })
    },
    findAudits(id) {
      insertSessionStorage(AUDIT_REPORT_CONFIG_ID_KEY, id)
      Turbolinks.visit('/audit_reports')
    }
  }
}
</script>
