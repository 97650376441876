<template>
  <div class="widget">
    <div class="d-flex justify-content-between mb-3">
      <strong class="caption">How customers search for a business</strong>
      <ChartSwitcher
        v-if="!breakdownByMonths"
        v-model="impressionsChartType"
        :options="chartTypeOptions"
        name="impressionsChartType" />
    </div>
    <div class="d-flex justify-content-center mb-3">
      <ChartSwitcher
        v-model="impressionsPlatformType"
        :options="platformTypeOptions"
        name="impressionsPlatformType" />
    </div>
    <div v-if="breakdownByMonths" class="d-flex flex-column gap-8">
      <ChartBreakdown
        v-show="impressionsPlatformType === 'mobile'"
        chart-id="business_impressions_mobile_maps"
        chart-color="#377dff"
        :no-labels="true" />
      <ChartBreakdown
        v-show="impressionsPlatformType === 'mobile'"
        chart-id="business_impressions_mobile_search"
        chart-color="#29BA6C"
        :no-labels="true" />
      <ChartBreakdown
        v-show="impressionsPlatformType === 'desktop'"
        chart-id="business_impressions_desktop_maps"
        chart-color="#377dff"
        :no-labels="true" />
      <ChartBreakdown
        v-show="impressionsPlatformType === 'desktop'"
        chart-id="business_impressions_desktop_search"
        chart-color="#29BA6C"
        :no-labels="true" />
    </div>
    <template v-else>
      <ChartPie
        v-show="impressionsChartType === 'pie' && impressionsPlatformType === 'mobile'"
        chart-type="pie"
        :chart-height="300"
        :chart-ids="impressionsChartMobile.ids"
        :chart-colors="impressionsChartMobile.colors" />
      <ChartLines
        v-show="impressionsChartType === 'line' && impressionsPlatformType === 'mobile'"
        :chart-ids="impressionsChartMobile.ids"
        :chart-colors="impressionsChartMobile.colors" />
      <ChartPie
        v-show="impressionsChartType === 'pie' && impressionsPlatformType === 'desktop'"
        chart-type="pie"
        :chart-height="300"
        :chart-ids="impressionsChartDesktop.ids"
        :chart-colors="impressionsChartDesktop.colors" />
      <ChartLines
        v-show="impressionsChartType === 'line' && impressionsPlatformType === 'desktop'"
        :chart-ids="impressionsChartDesktop.ids"
        :chart-colors="impressionsChartDesktop.colors" />
    </template>
  </div>
</template>

<script>
import ChartSwitcher from './chart_switcher'
import ChartLines from './chart_lines'
import ChartPie from './chart_pie'
import ChartBreakdown from './chart_breakdown'

export default {
  components: {
    ChartSwitcher,
    ChartLines,
    ChartPie,
    ChartBreakdown
  },
  props: {
    breakdownByMonths: { type: Boolean, default: false }
  },
  data() {
    return {
      impressionsChartType: 'pie',
      impressionsPlatformType: 'mobile'
    }
  },
  created() {
    this.chartTypeOptions = [{
      value: 'pie',
      iconClasses: 'far fa-chart-pie'
    }, {
      value: 'line',
      iconClasses: 'far fa-chart-line'
    }]
    this.platformTypeOptions = [{
      value: 'mobile',
      iconClasses: 'far fa-mobile',
      label: 'Mobile'
    }, {
      value: 'desktop',
      iconClasses: 'far fa-desktop',
      label: 'Desktop'
    }]

    this.impressionsChartMobile = {
      ids: ['business_impressions_mobile_maps', 'business_impressions_mobile_search'],
      colors: ['#377dff', '#29BA6C']
    }
    this.impressionsChartDesktop = {
      ids: ['business_impressions_desktop_maps', 'business_impressions_desktop_search'],
      colors: ['#377dff', '#29BA6C']
    }
  }
}
</script>
