<template>
  <VueSlimTable
    class="table table-striped"
    :columns="columns"
    :source="tableSource">
    <template #row="{ row }">
      <tr>
        <td>
          <a v-if="row.pictures" :href="row.pictures.normal" target="_blank">
            <img :src="row.pictures.tiny" alt="post image">
          </a>

          {{ row.summary.slice(0, 60) }}
        </td>
        <td>
          <a :href="`/gmb/locations/${row.location.remoteId}/local_posts/${row.id}/edit`">{{ row.syncStatus }}</a>
        </td>
        <td>
          {{ row.topicType }}
        </td>
        <td>
          <a v-for="({ id, title }) in row.linkedAccounts" :href="`/google_accounts/${id}`">{{ title }}</a>
        </td>
        <td>
          <a :href="`/gmb/locations/${row.location.remoteId}`">{{ row.location.title }}</a>
        </td>
        <td width="40px">
          {{ row.scheduled }}
        </td>
        <td width="200px">
          {{ row.publishingDate }}
        </td>
      </tr>
    </template>
  </VueSlimTable>
</template>

<script>
import axiosTransform from 'common/axios'

export default {
  methods: {
    tableSource(params) {
      return axios.get(`/api/gmb/bulk_posts/${this.bulkPostId}/posts`, { params, ...axiosTransform })
        .then((res) => res.data)
    }
  },
  created() {
    this.bulkPostId = window.location.pathname.split('/')[4] // eslint-disable-line prefer-destructuring

    this.columns = [
      { key: 'summary', title: 'Summary' },
      { key: 'status', title: 'Status' },
      { key: 'topicType', title: 'Type' },
      { key: 'linkedAccounts', title: 'Account' },
      { key: 'location', title: 'Location' },
      { key: 'scheduled', title: 'Scheduled?' },
      { key: 'publishingDate', title: 'Publishing Date' }
    ]
  }
}
</script>
