<template>
  <div class="tag-item">
    <span class="caption">{{ tag }}</span>
    <button @click.prevent="remove" class="btn-delete" />
  </div>
</template>

<script>
export default {
  props: {
    tag: {
      type: String,
      default: ''
    }
  },
  methods: {
    remove() {
      this.$emit('removeTag', this.tag)
    }
  }
}
</script>
