<template>
  <div class="location-search-impressions">
    <h2 class="content-title">Search Impressions</h2>

    <p>
      The table below consists of search keywords used to find a business in search or maps. Each search keyword is
      accompanied by impressions that indicate the sum of the number of unique users that used the keyword in a month.
    </p>

    <VueSlimTable
      ref="table"
      :source="tableSource"
      :columns="columns"
      class="table table-striped table-light"
      :per-page="10">
      <template #cell:month="{ row }">
        <small>{{ row.current_month }}</small>
      </template>

      <template #cell:searchKeyword="{ row }">
        {{ row.search_keyword }}
      </template>

      <template #cell:trend="{ row }">
        <div v-if="row.past_insight > 0" class="d-flex justify-content-between gap-2 trend-container">
          <ChartLines :data-set="sparklineData(row)" :revert-y=false tooltip-title="Insight" :y-axis-max="0" />

          <div class="d-flex flex-column">
            <span v-if="row.trend > 0" class="good-trend">
              <i class="fa fa-arrow-up-long" />
              +{{ row.trend }}
            </span>

            <span v-else-if="row.trend < 0" class="bad-trend">
              <i class="fa fa-arrow-down-long" />
              {{ row.trend }}
            </span>
          </div>
        </div>
        <div v-else>
          –
        </div>
      </template>

      <template #cell:pastInsight="{ row }">
        <div v-if="row.past_insight > 0">
          <span class="insight-value">
            <small v-if="row.past_insight <= 15 && row.past_insight > 0">≤</small>{{ row.past_insight }}
          </span>
          <span class="small-insight-date"> /{{ row.past_month }}</span>
        </div>
        <div v-else>
          –
        </div>
      </template>

      <template #cell:currentInsight="{ row }">
        <span class="insight-value" :class="insightValueColor(row)">
          <small v-if="row.current_insight <= 15">≤</small>{{ row.current_insight }}
          <span class="small-insight-date"> /{{ row.current_month }}</span>
        </span>
      </template>
    </VueSlimTable>
  </div>
</template>

<script>
import axios from 'axios'
import ChartLines from '../keywords/keyword_chart'

export default {
  components: { ChartLines },
  data() {
    return {
    }
  },
  props: {
    locationId: {
      type: String
    }
  },
  created() {
    this.columns = [
      { title: 'Month', key: 'month' },
      { title: 'Search Keyword', key: 'searchKeyword' },
      { title: 'Trend', key: 'trend' },
      { title: 'Past Insight', key: 'pastInsight' },
      { title: 'Current Insight', key: 'currentInsight' }
    ]

    this.dataSet = [{
      data: this.insights,
      color: '#01c9a7',
      name: 'Insight'
    }]
  },
  methods: {
    insightValueColor(row) {
      if (row.past_insight === 0) {
        return ''
      }

      if (row.current_insight - row.past_insight > 0) {
        return 'good-trend'
      }

      if (row.current_insight - row.past_insight < 0) {
        return 'bad-trend'
      }

      return ''
    },
    tableSource(params) {
      return axios.get(
        `/api/organizations_locations/${this.locationId}/search_impressions`,
        {
          params: { ...params, ...this.customFilters, search: this.search },
          paramsSerializer(json) {
            return qs.stringify(json, { arrayFormat: 'brackets' })
          }
        }
      ).then((res) => res.data)
    },
    sparklineData(row) {
      const data = []

      for (let i = 0; i < row.sparkline.length; i++) {
        const date = new Date(`${row.current_month}-01`)
        date.setMonth(date.getMonth() - (row.sparkline.length - i - 2))

        data.push({
          x: date,
          y: row.sparkline[i]
        })
      }

      return [{
        data: data,
        color: '#01c9a7',
        name: 'Insights'
      }]
    }
  }
}
</script>

// Colors below are stolen from common/variables.scss unfortunately I don't know how to import
// them here to use SCSS variables.
<style>
.location-search-impressions {
  table {
    thead {
      th.vst-th {
        font-weight: bold;
        color: #535c65;
        padding-right: 14px;

        &:nth-child(3), &:nth-child(4), &:nth-child(5) {
          text-align: right;
        }
      }
    }

    td {
      height: 55px;
      padding-top: 3px;
      padding-bottom: 3px;

      &:nth-child(2) {
        width: 55%;
      }
      &:nth-child(3), &:nth-child(4), &:nth-child(5) {
        text-align: right;
      }
    }
  }

  .trend-container {
    align-items: center;
    margin-left: auto;
  }

  span.insight-value {
    color: #3d454d;
  }

  span.good-trend, & span.small-insight-date {
    color: #29BA6C;
  }

  span.bad-trend, & span.small-insight-date {
    color: #dc443d;
  }

  span.small-insight-date {
    font-size: 0.65em;
    color: #828e9a;
  }
}
</style>
