<template>
  <div class='alert alert-danger alert-dismissible' role='alert' v-if="showErrors">
    <div class='message'>
      <p>{{ object.syncErrors.message }}</p>
      <ul v-if="showDetails">
        <li v-for="(d, index) in object.syncErrors.details" :key="index">
          <span class="text-capitalize">{{ humanAttributeName(d[0].field) }}</span>: {{ d[0].message }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { isEmpty } from '../../../../common/helpers'

export default {
  computed: {
    ...mapState(['object']),
    showErrors() {
      return !isEmpty(this.object.syncErrors)
    },
    showDetails() {
      return !isEmpty(this.object.syncErrors.details)
    }
  },
  methods: {
    humanAttributeName(name) {
      return name?.replaceAll(/[_.]/g, ' ')
    }
  }
}
</script>
