/* eslint-disable no-undef */
import Vue from 'vue'
import { App } from 'common/rails'
import MultiLocationPostIndex from 'vue_widgets/gmb/multi_location_posts/index'
import form from './local_posts'

const statusCaption = {
  pending: 'Pending',
  failed: 'Failed',
  done: 'Published'
}

const textColors = {
  pending: 'dark',
  failed: 'danger',
  done: 'success'
}

const iconNames = {
  pending: 'hourglass-half',
  failed: 'times',
  done: 'check-circle'
}

const mlpBadgeClass = {
  creating: 'badge-info',
  publishing: 'badge-info',
  done: 'badge-success',
  failed: 'badge-danger'
}

const mlpBadgeIcon = {
  creating: 'fa-spinner-third fa-spin',
  publishing: 'fa-spinner-third fa-spin',
  done: 'fa-shield-check',
  failed: 'fa-exclamation-circle'
}

function updateStatus(status) {
  const badge = $('<div>', {
    class: `badge ${mlpBadgeClass[status]} border-0 py-2 font-size-16 font-weight-semi-bold rounded text-capitalize d-inline-flex gap-1`
  }).append($('<i>', {
    class: `far ${mlpBadgeIcon[status]} `
  })).append(status)

  $('#js-mlp-status').html(badge)
}

function updateRow(localPostId, data) {
  const $row = $(`tr[data-local-post-id='${localPostId}']`)

  if ($row.length) {
    Object.keys(data).forEach((key) => {
      const spanClasses = `d-flex align-items-center gap-1 text-capitalize text-${textColors[data[key]]}`
      const iconClasses = `far fa-${iconNames[data[key]]}`
      $row.find(`[data-local-post-attribute='${key}']`)
        .html(`<span class="${spanClasses}"><i class="${iconClasses}"></i>${statusCaption[data[key]]}</span>`)
    })
    $('#js-publishing-date').html(`${data.publishing_date}<small class="d-block text-muted">in location timezone</small>`)
    $('#js-next-publishing-date').html(`${data.next_publishing_date}<small class="d-block text-muted">in location timezone</small>`)
  } else {
    window.location.reload()
  }
}

Styxie.Initializers.GmbMultiLocationPosts = {
  index(props) {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-multi-location-posts-index'),
        render: (h) => h(MultiLocationPostIndex, { props })
      })
    })
  },
  new: form,
  create: form,
  show: ({ multiLocationPostId }) => {
    $(() => {
      const cable = App.cable.subscriptions.create({ channel: 'Gmb::LocalPostChannel' }, {
        connected: () => {
          cable.perform('check_ready', { multiLocationPostId })
        },
        received: (data) => {
          if (data.type === 'updated') {
            updateRow(data.payload.local_post_id, data.payload)
          } else {
            updateStatus(data.type)
          }
        }
      })
    })
  }
}

/* eslint-enable no-undef */
