<template>
  <div class="report-ratio-bar">
    <div class="report-ratio-bar__background" />
    <div
      v-for="(part, index) in shownParts"
      class="report-ratio-bar__part"
      :key="`${part.color}${index}`"
      :style="{ backgroundColor: part.color, width: `${part.value / total * 100}%` }">
      <span>{{ part.value }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    parts: {
      type: Array,
      required: true
    },
    total: {
      type: Number,
      required: true
    }
  },
  computed: {
    shownParts() {
      return this.parts.filter((part) => part.value > 0)
    }
  }
}
</script>
