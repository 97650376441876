<template>
  <div class="scheduled-geogrid-details-preview">
    <button class="scheduled-geogrid-details-preview__expand" @click="showConfigDetails">
      <i class="fa-regular fa-chevron-right" />
    </button>
    <div class="scheduled-geogrid-details-preview__buttons">
      <button v-if="attrs.businessLocationLat"
        id="toggle-map-marker"
        class="scheduled-geogrid-details-preview__show-business"
        @click="$emit('toggleShowBusinessLocation')">
        <i class="far fa-fw fa-map-marker" :class="{'fas': businessLocationVisible}" />
        <b-popover
          placement='top'
          target="toggle-map-marker"
          custom-class='geogrids-popover p-1'
          triggers='hover'>
          {{ businessLocationVisible ? 'Hide' : 'Show' }} my business
        </b-popover>
      </button>

      <div class="scheduled-geogrid-details-preview__public" v-if="config.publicUrl">
        <a id="open-public-config"
          target="_blank"
          :href="config.publicUrl">
          <i class="far fa-fw fa-globe" />
        </a>
        <b-popover
          placement='top'
          target="open-public-config"
          custom-class='geogrids-popover p-1'
          triggers='hover'>
          Open public page
        </b-popover>
      </div>

      <div v-if="!attrs.orgLocationOid">
        <a id="connect-with-location" href="#" @click.prevent="connectWithLocation">
          <i class="far fa-fw fa-exclamation" />
        </a>
        <b-popover
          placement='top'
          target="connect-with-location"
          custom-class='geogrids-popover p-1'
          triggers='hover'>
          Connect with location
        </b-popover>
      </div>
    </div>

    <div class="scheduled-geogrid-details-preview__top">
      <component id="open-location" class="scheduled-geogrid-details-preview__name" :is="attrs.locationPath ? 'a' : 'span'"
        target="_blank"
        :href="attrs.locationPath">
        <span>{{ attrs.businessName }}</span>
        <div v-if="attrs.locationPath" class="scheduled-geogrid-details-preview__icon-wrapper">
          <i class="fa-regular fa-arrow-up-right-from-square" />
        </div>
      </component>
      <b-popover
        v-if="attrs.locationPath"
        placement='top'
        target="open-location"
        custom-class='geogrids-popover p-1'
        triggers='hover'>
        Open location page
      </b-popover>
    </div>
    <p v-if="attrs.businessStoreCode" class="scheduled-geogrid-details-preview__store-code">{{ attrs.businessStoreCode }}</p>
    <p class="scheduled-geogrid-details-preview__address">{{ attrs.businessAddress ? attrs.businessAddress : 'Service Area Business' }}</p>
    <p v-if="attrs.businessCategories?.length" class="scheduled-geogrid-details-preview__category">{{ attrs.businessCategories[0] }}</p>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    businessLocationVisible: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapState(['config', 'searchTerm']),
    attrs() {
      return this.config.attrs
    }
  },
  methods: {
    ...mapActions(['connectWithLocation']),
    showConfigDetails() {
      this.$emit('showConfigDetails')
    }
  },
  watch: {
    'searchTerm.text': {
      handler() {
        this.$emit('toggleShowBusinessLocation', false)
      }
    }
  }
}
</script>
