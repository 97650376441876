<template>
  <button
    @click="cloneKeyword(rankType)"
    type="button"
    class="btn btn-sm with-icon rounded">
    <template v-if="btnLocked">
      <i class="far fa-circle-notch fa-spin" aria-hidden="true" />
      <span>Adding</span>
    </template>
    <template v-else>
      <i class="far fa-plus-circle" aria-hidden="true" />
      <span>Add</span>
    </template>
  </button>
</template>

<script>
import { mapState } from 'vuex'
import { DEFAULT_ERROR_MESSAGE } from 'common/constants'

export default {
  props: {
    rankType: {
      type: String,
      required: true
    },
    keyword: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      btnLocked: false
    }
  },
  computed: mapState(['apiOrgLocationPath']),
  methods: {
    cloneKeyword(type) {
      const url = `${this.apiOrgLocationPath}/keywords`
      this.btnLocked = true
      axios.patch(url, {
        ids: [this.keyword.id],
        keyword: {
          types: [type],
          check_frequency: this.keyword.checkFrequency
        }
      }).then(() => {
        this.$emit('cloneKeyword', type)
      }).catch(() => {
        toastr.error(DEFAULT_ERROR_MESSAGE)
      }).finally(() => {
        this.btnLocked = false
      })
    }
  }
}
</script>
