import { Buffer } from 'buffer/'

export const names = {
  local: 'Local',
  organic: 'Organic',
  organicMobile: 'Organic Mobile'
}

export const colors = {
  local: '#01c9a7',
  organic: '#f9ae34',
  organicMobile: '#dc443d'
}

export function getDiff(rank) {
  if (!rank) return null
  const lastPosition = rank[rank.length - 1].y
  if (!lastPosition) return null

  let diff = null
  for (let i = rank.length - 2; i >= 0; i--) {
    if (rank[i].y) {
      diff = rank[i].y - lastPosition
      break
    }
  }

  return diff
}

export function tooltipHeader(datetime) {
  const mnt = moment.utc(datetime).format('MMM D, YYYY')
  return `<span class="tooltip-title">${mnt}</span>`
}

export function tooltipBody(rank, label = 'Rank', isFakeValue = false) {
  return `<div class="tooltip-rank">${label}: <span class="ranks">${isFakeValue ? 'N/a' : (rank || 'N/a')}</span></div>`
}

export function tooltipPoint(point) {
  return '<div class="tooltip-line">' +
    `<span class="point" style="color:${point.color}">\u25CF</span>` +
    `<span class="date">${point.series.name}</span>` +
    `<span class="value">${point.point.isFakeValue ? 'N/a' : point.y}</span></div>`
}

const table = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_'
export function uule(name) {
  const len = name.length
  const key = table[len]
  return `w+CAIQICI${key}${(Buffer.from(name, 'ascii')).toString('base64')}`
}
