<template>
  <div>
    <FilterComponent ref="customFilter"
      class="mb-4"
      :fields="fields"
      :session-key="sessionKey"
      @onFilterChange="onFilterChange" />
    <TableAction v-if="partSelected.selectedCount !== 0"
      :part-selected="partSelected"
      :checked="checkAll"
      :configs="configs"
      :rows-total-count="totalConfigs"
      :tag-options="tagsFilterField.options"
      :only-deleted="filters.state === 'deleted'"
      :loading-csv="loadingCsv"
      @onChecked="checkConfigs"
      @runGeogrids="runGeogrids"
      @restoreGeogrids="restoreGeogrids"
      @deleteGeogrids="deleteGeogrids"
      @saveTags="saveTags"
      @downloadCsv="downloadCsv" />
    <VueSlimTable
      ref="table"
      :source="tableSource"
      :columns="showColumnsHeader"
      class="table table-light config-table m-0"
      :per-page="perPage">
      <template #head:tracked_business>
        <span class="d-inline-flex align-middle">
          <Checkbox
            :value="checkAll"
            :part-selected="partSelected.isPartSelected"
            @click.native.prevent.stop="checkConfigs(!checkAll)" />
          Tracked Business
        </span>
      </template>
      <template #row="{ row }">
        <TableRow
          :row="row"
          :api-configs-path="apiConfigsPath"
          :is-admin="isAdmin"
          @addTagToFilter="addTagToFilter"
          @onDeleteRow="reload"
          @restoreGeogrids="restoreGeogrids"
          @onRowCheckToggle="onRowCheckToggle" />
      </template>
      <template #pagination>
        <span>&nbsp;</span>
      </template>
    </VueSlimTable>
    <Pagination :page="currentPage" :per-page="perPage" :total-count="totalConfigs" @updatePage="updatePageNumber" />
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import axios from 'axios'
import toastr from 'toastr'
import snakeCaseKeys from 'snakecase-keys'
import { extractSessionStorage, insertSessionStorage } from 'common/helpers'
import FilterComponent from 'vue_widgets/components/filter'
import TableRow from './index/table_row'
import TableAction from './index/table_action'
import Pagination from '../../components/pagination'
import Checkbox from '../../components/checkbox'
import axiosTransform from '../../../common/axios'
import { fields } from '../filter_options'
import fetchFiltersMixin from '../mixins/fetchFiltersMixin.js'

export default {
  name: 'ScheduledGeogridsConfigs',
  mixins: [fetchFiltersMixin],
  components: {
    TableRow,
    Pagination,
    Checkbox,
    TableAction,
    FilterComponent
  },
  props: {
    apiConfigsPath: { type: String, require: true }
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      totalConfigs: 0,
      isAdmin: false,
      filters: {},
      configs: [],
      checkAll: false,
      fields,
      bulkCsvData: '',
      loadingCsv: false
    }
  },
  created() {
    this.filtersPath = this.apiConfigsPath
    this.baseApiPath = '/api/scheduled_geogrids_configs_bulk_actions'
    this.columns = [
      { key: 'tracked_business', title: 'Tracked Business', orderable: true },
      { key: 'grid', title: 'Grid', orderable: true },
      { key: 'schedule_formatted', title: 'Schedule', orderable: true },
      { key: 'last_generated', title: 'Last geogrid', orderable: true },
      { key: 'next_generation', title: 'Next creation', orderable: true },
      { key: 'total_geogrids', title: 'Geogrids', orderable: true },
      { key: 'actions', title: '' }
    ]

    this.sessionKey = `scheduled_geogrids_filter_${Cookies.get('user_email')}`
    this.sessionPageKey = `scheduled_geogrids_page_${Cookies.get('user_email')}`

    this.currentPage = extractSessionStorage(this.sessionPageKey) || 1
  },
  computed: {
    selectedRowIds() {
      return this.checkAll ? 'all' : this.$refs.table.rows.filter((row) => row.checked).map((el) => el.obfuscatedId)
    },
    showColumnsHeader() {
      return this.partSelected.selectedCount > 0 ? [] : this.columns
    },
    partSelected() {
      const selectedCount = this.checkAll ? this.totalConfigs : this.configs.filter((row) => row.checked).length
      return {
        selectedCount: selectedCount,
        length: this.totalConfigs,
        isPartSelected: selectedCount !== 0 && selectedCount !== this.totalConfigs,
        caption: `Selected ${selectedCount} ${this.$options.filters.pluralize('config', selectedCount)} of ${this.totalConfigs}`,
        captionAll: `Selected ${selectedCount} ${this.$options.filters.pluralize('config', selectedCount)}`
      }
    },
    tagsFilterField() {
      return this.fields.find((field) => field.key === 'selectedTags')
    }
  },
  methods: {
    addTagToFilter(tag) {
      if (this.$refs.customFilter.filterValues.selectedTags) {
        const { selectedTags } = this.$refs.customFilter.filterValues
        const tagIndex = selectedTags.indexOf(tag)
        if (tagIndex === -1) {
          this.$refs.customFilter.onFilterChange({ key: 'selectedTags' }, [...selectedTags, tag], 0, false)
        } else {
          selectedTags.splice(tagIndex, 1)
          this.$refs.customFilter.onFilterChange({ key: 'selectedTags' }, selectedTags, 0, false)
        }
      } else {
        this.$refs.customFilter.onFilterChange({ key: 'selectedTags' }, [tag], 0, false)
      }
    },
    tableSource(prms) {
      this.totalConfigs = 0
      const params = {
        order: [{ field: 'next_generation', direction: 'desc' }],
        ...prms,
        page: this.currentPage,
        ...snakeCaseKeys(this.filters)
      }

      return axios.get(
        this.apiConfigsPath,
        {
          params: params,
          paramsSerializer(json) { return qs.stringify(json, { arrayFormat: 'brackets' }) },
          ...axiosTransform
        }
      )
        .then((res) => {
          this.totalConfigs = res.data.totalConfigs
          this.isAdmin = res.data.isAdmin

          this.configs = res.data.configs.map((config) => ({ ...config, checked: this.checkAll }))
          return this.configs
        })
    },
    updatePageNumber(page) {
      this.currentPage = page
      insertSessionStorage(this.sessionPageKey, this.currentPage)
      this.reload()
    },
    onFilterChange(newFilterValues) {
      this.filters = newFilterValues
      this.updatePageNumber(1)
    },
    reload() {
      this.$refs.table?.refetch()
    },
    onRowCheckToggle({ configId, bool }) {
      this.configs.find((row) => row.obfuscatedId === configId).checked = bool
      this.checkAll = this.areConfigsChecked()
      this.selectedConfigIds = this.selectedRowIds
    },
    areConfigsChecked() {
      return !this.configs.find((row) => !row.checked) && this.totalConfigs <= this.perPage
    },
    checkConfigs(value) {
      this.checkAll = value
      this.$refs.table.rows.forEach((row) => {
        row.checked = value
      })
    },
    runGeogrids() {
      axios.post(`${this.baseApiPath}/on_demain_geogrid`, {
        configIds: this.selectedRowIds,
        ...this.filters
      }, axiosTransform)
        .then(() => {
          toastr.success('Processing launched.')
          this.reload()
        })
    },
    restoreGeogrids(ids = null) {
      axios.post(`${this.baseApiPath}/restore`, {
        configIds: ids || this.selectedRowIds,
        ...this.filters
      }, axiosTransform)
        .then(() => {
          toastr.success('Restored successfully.')
          this.reload()
        })
    },
    deleteGeogrids() {
      swal({
        text: 'This will also lead to deletion of all generated geogrids',
        icon: 'warning',
        buttons: ['Cancel', 'Delete'],
        dangerMode: true
      }).then((result) => {
        if (result) {
          axios.post(`${this.baseApiPath}/delete`, {
            configIds: this.selectedRowIds,
            ...this.filters
          }, axiosTransform)
            .then(() => {
              toastr.success('Deleted successfully.')
              this.reload()
            })
        }
      })
    },
    saveTags(tags) {
      axios.post(`${this.baseApiPath}/add_tag`, {
        tag: tags,
        configIds: this.selectedRowIds,
        ...this.filters
      }, axiosTransform)
        .then(() => {
          toastr.success(`Tag${tags.length === 1 ? '' : 's'} added.`)
          this.fetchFilters()
          this.reload()
        })
    },
    downloadCsv() {
      this.loadingCsv = true
      axios.put('/api/scheduled_geogrids_configs_bulk_actions/csv_export', {
        config_ids: this.selectedRowIds,
        ...snakeCaseKeys(this.filters)
      })
        .then((res) => {
          toastr.success('Your CSV is preparing.')
          window.open(`/downloads/${res.data}`)
        })
        .finally(() => {
          setTimeout(() => {
            this.loadingCsv = false
          }, 500)
        })
    }
  }
}
</script>
