<template>
  <div v-if="remoteDeleted" class='alert alert-warning answer-form-stub' role='alert'>
    This question was removed by Google so it may not be answered.
  </div>
  <form action="/" @submit.prevent="postReply" v-else>
    <b-form-group label='Reply' label-for='reply'>
      <b-form-textarea
        id="reply"
        type="text"
        required
        ref="reply"
        rows="3"
        max-rows="8"
        :value="text" />
    </b-form-group>
    <b-button class="btn" variant="primary" type="submit">Submit</b-button>
    <b-button class="btn" variant="secondary" type="button"
      v-if="$listeners.onCancel"
      @click.prevent="$emit('onCancel')">
      Cancel
    </b-button>
  </form>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    answer: Object
  },
  computed: {
    ...mapState(['currentQuestion']),
    text() { return this.answer ? this.answer.text : '' },
    remoteDeleted() { return !!this.currentQuestion.remote_deleted_at }
  },
  methods: {
    ...mapActions(['upsertAnswer']),
    postReply() {
      const $el = $(this.$el).toggleWrapper()
      this.upsertAnswer({
        question: this.currentQuestion,
        answer: this.answer,
        text: this.$refs.reply.$el.value,
        cleanWrapperCb: () => { $el.toggleWrapper({}, false) }
      })
    }
  }
}
</script>

<style scoped>
  .answer-form-stub {
    margin-left: 40px;
    padding: 10px;
    border-radius: 3px;
  }
</style>
