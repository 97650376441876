import VueReports from 'vue_widgets/reports/index'
import initReportsShow from './common/reports_show'

Styxie.Initializers.Reports = {
  index: (props) => {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-reports-index'),
        render: (h) => h(VueReports, { props })
      })
    })
  },
  show: initReportsShow
}
