<template>
  <div>
    <h2 class="mb-2 font-weight-semi-bold">Services</h2>
    <AuditDescription class="mb-4">
      See the services provided by your competitors and see how yours stack up against them.
    </AuditDescription>
    <h3 class="h4 mb-2 font-weight-semi-bold">Quantity of services</h3>
    <div class="competitors-chart-legend">
      <div class="competitors-chart-legend-item">
        <div class="point blue" />
        Unique services
      </div>
      <div class="competitors-chart-legend-item">
        <div class="point green" />
        Matched services
      </div>
    </div>
    <VueSlimTable
      ref="table"
      :source="() => competitorsServices"
      :columns="[]"
      class="responsive-table borderless-table audit-table posts-by-type average last-head-col-right-1 mt-3">
      <template #row="{ row }">
        <tr>
          <td @click="showContentSidebar(row, 'services')">
            <span class="text-primary cursor-pointer">{{ row.name }}</span>
          </td>
          <td>
            <RatioBar :parts="row.byType" :total="maxTotal" />
          </td>
          <td class="font-weight-bold" :class="{'text-grey': row.unique + row.matched === 0}">{{ row.unique + row.matched }}</td>
        </tr>
      </template>
    </VueSlimTable>
  </div>
</template>

<script>
import RatioBar from './blanks/ratio_bar'
import showContentSidebarMixin from './show_content_sidebar_mixin'

export default {
  components: {
    RatioBar
  },
  mixins: [showContentSidebarMixin],
  props: {
    sectionData: { type: Object, required: true }
  },
  created() {
    this.servicesTypesObj = {
      unique: { color: '#377DFF', title: 'Standard post' },
      matched: { color: '#29BA6C', title: 'Event' }
    }

    this.maxTotal = 1
    this.competitorsServices = [
      { competitor: 'My location', gbpServices: this.sectionData.current },
      ...this.sectionData.competitors
    ].map(({ competitor, gbpServices, ...rest }) => {
      const [unique, matched] = gbpServices
      const total = unique + matched
      if (this.maxTotal < total) this.maxTotal = total

      return {
        name: competitor,
        unique,
        matched,
        ...rest,
        byType: Object.keys(this.servicesTypesObj).reduce((mem, key, index) => {
          mem.push({
            color: this.servicesTypesObj[key].color,
            value: gbpServices[index]
          })

          return mem
        }, [])
      }
    })
  }
}
</script>
