<template>
  <button :disabled="disabled" :id="`buttonClipboard-${_uid}`" @click.prevent="doCopy" :class="classes">
    <span class='wrap-icon'>
      <i class="far fa-copy" />
    </span>
    <span v-if="textButton" class='text'>{{ textButton }}</span>
    <b-popover
      ref="tooltip"
      :target="`buttonClipboard-${_uid}`"
      :placement="placement"
      custom-class='geogrids-popover p-1'
      disabled>
      Copied!
    </b-popover>
    <b-popover
      ref='infoTooltip'
      :target="`buttonClipboard-${_uid}`"
      :placement="placement"
      custom-class='geogrids-popover p-1'
      triggers='hover'
      :disabled='!entityName'>
      Copy {{ entityName }} link
    </b-popover>
  </button>
</template>

<script>
import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
import { copyToClipboardWithAnimation } from 'common/copy_to_clipboard'

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

export default {
  name: 'ButtonCopyLinkToClipboard',
  props: {
    classes: String,
    placement: {
      type: String,
      default: 'bottom'
    },
    entityName: String,
    textButton: String,
    link: String,
    disabled: Boolean
  },
  methods: {
    doCopy(e) {
      copyToClipboardWithAnimation({ element: e.currentTarget, textToCopy: this.link })
      this.$refs.infoTooltip.$emit('close')
      if (this.entityName) {
        this.$refs.tooltip.$emit('enable')
        this.$refs.tooltip.$emit('open')
        setTimeout(() => {
          this.$refs.tooltip.$emit('close')
          this.$refs.tooltip.$emit('disable')
        }, 1000)
      }
    }
  }
}
</script>
