export const DEFAULT_ERROR_MESSAGE = `
  We're sorry, an unexpected error has occurred during processing your request.
  Please, contact our support if it happens continuously`

export const NOT_AUTHORIZED_MESSAGE = 'You are not authorized to perform this action.'

export const NOT_FOUND = 'We\'re sorry, requested data was not found'

export const NOT_ENOUGH_GEOGRID_CREDITS = `
  Please <a href="/billing/edit" target='_blank'><u>add credits</u> </a>
  or wait until the end of current billing period`

const LOCATION_FILTER_KEY_VERSION = '1'
export const LOCATION_FILTER_KEY = `locationsTableStateV${LOCATION_FILTER_KEY_VERSION}`
export const MLP_FILTER_KEY = 'mlpFilterKey'

export const ICONS_PATH = '/icons/'

export const FLATPICKR_WEEKDAYS_MAP = {
  sunday: 0,
  monday: 1
}
