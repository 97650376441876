p<template>
  <div class="dropdown-action dropdown">
    <div class="d-flex align-items-baseline gap-1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i v-if="titleFaIconClass" :class="titleFaIconClass" />
      {{ title }}
      <i class="far fa-chevron-down icon" />
    </div>
    <div v-if="actionsProxy" class="dropdown-box dropdown-menu" ref="dropdownBox" @click.stop>
      <div class="d-flex flex-column h-100">
        <div class="d-flex align-items-center justify-content-between gap-4 px-3 py-2 h4 m-0 font-weight-semi-bold text-dark d-lg-none">
          {{ title }}
          <i class="far fa-times" @click="closeDropdown" />
        </div>
        <div class="position-relative">
          <div v-if="defaultOptions.isHeadShown" class="dropdown-box-item head scroll ver visible">
            <div class="label">{{ defaultOptions.headLabel }}</div>
            <template v-if="actionsProxy.labels && actionsProxy.labels.length">
              <div class="label" v-for="(label, index) in actionsProxy.labels" :key="index">
                <span class="caption">{{ label }}</span>
              </div>
            </template>
          </div>
        </div>
        <div class="position-relative">
          <div v-if="defaultOptions.isCommonAction && actionsProxy.type === 'checkbox'" class="dropdown-box-item scroll ver visible">
            <div class="label">{{ defaultOptions.isCommonLabel }}</div>
            <div class="label" v-for="(value, key) in actionsProxy.actions[0].data" :key="key">
              <Checkbox :value="values[key]" :part-selected="partSelected(key)" @input="checkAll(key)" />
            </div>
          </div>
        </div>
        <div class="scroll ver visible">
          <template v-if="actionsProxy.type === 'checkbox'">
            <div class="dropdown-box-item" v-for="(action, index) in actionsProxy.actions" :key="index">
              <div class="label">{{ action.label }}</div>
              <div class="label" v-for="(item, key) in action.data" :key="key">
                <Checkbox :value="action.data[key]" @input="isActionsPropertyTrue($event, action.data, key)" />
              </div>
            </div>
          </template>
          <template v-if="actionsProxy.type === 'radio'">
            <Radio class="dropdown-box-item dropdown-item" v-for="{label, value} in actionsProxy.actions" :key="value"
              :item="value"
              :name="options.radioFormName"
              :id-name="`radio_${value}`"
              @input="setValue">
              {{ label }}
            </Radio>
          </template>
          <template v-if="actionsProxy.type === 'links'">
            <a v-for="(action, index) in actionsProxy.actions" :key="index"
              @click.prevent="saveSettings(action.href)"
              :href="action.href"
              class="dropdown-item dropdown-box-item">
              <div class="label">{{ action.label }}</div>
              <i :class="['label icon', action.iconClass]" />
            </a>
          </template>
        </div>
        <div class="confirm-box" v-if="actionsProxy.type !== 'links'">
          <slot name="description" />
          <button class="btn btn-primary btn-sm" @click="saveSettings">
            {{ defaultOptions.buttonCaption }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from './checkbox'
import Radio from './radio'

export default {
  components: { Checkbox, Radio },
  props: {
    actions: { type: Object, required: true },
    options: { type: Object, default: () => ({}) },
    title: { type: String, default: '' },
    titleFaIconClass: { type: String, default: '' }
  },
  data() {
    return {
      defaultOptions: {
        isHeadShown: true,
        headLabel: 'Actions',
        isCommonLabel: 'All actions',
        isCommonAction: true,
        buttonCaption: 'Apply'
      },

      values: {},
      actionsProxy: null
    }
  },
  mounted() {
    this.actionsProxy = { ...this.actions }
    this.defaultOptions = { ...this.defaultOptions, ...this.options }
  },
  methods: {
    isActionsPropertyTrue($event, data, property) {
      data[property] = $event
      const val = !this.actionsProxy.actions.find((action) => !action.data[property])
      this.values[property] = val
    },
    checkAll(property) {
      const toggle = !(this.partSelected(property) || this.values[property])

      this.values[property] = toggle
      this.actionsProxy.actions = this.actionsProxy.actions.map((action) => {
        action.data[property] = toggle
        return action
      })
    },
    setValue(value) {
      this.actionsProxy.actions.forEach((action) => {
        action.selected = action.value === value
      })
    },
    saveSettings(href) {
      this.closeDropdown()
      this.$emit('saveSettings', this.actionsProxy.type === 'links' ? href : this.actionsProxy.actions)
    },
    partSelected(key) {
      const arr = []

      for (let i = 0; i < this.actionsProxy.actions.length; i++) {
        const item = this.actionsProxy.actions[i].data
        if (!arr.includes(item[key])) arr.push(item[key])
        if (arr.length === 2) return true
      }

      return false
    },
    closeDropdown() {
      this.$refs.dropdownBox.classList.remove('show')
    }
  },
  watch: {
    actions: {
      deep: true,
      handler() {
        this.actionsProxy = { ...this.actions }
      }
    }
  }
}
</script>
