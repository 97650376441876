<template>
  <div class="form-group mb-3">
    <label :for="identifier" class="d-block">{{ label }}</label>
    <input
      type="text"
      :id="identifier"
      class="form-control"
      :class="{ 'is-invalid': imagePreviewErrors[identifier] }"
      :value="imageCurrentExifs[identifier]"
      :disabled='$store.state.exifState == "clean"'
      @input="changeCurrentExif({ identifier, value: $event.target.value })">
    <small class="form-text text-danger" v-if="imagePreviewErrors[identifier]">
      {{ imagePreviewErrors[identifier] }}
    </small>
    <small class="form-text text-muted" v-if="help">
      Format: {{ help }}
    </small>
    <small class="form-text text-muted" v-if="recommended">
      Recommended:
      <a href="#" class="dashed-link" @click.prevent="changeCurrentExif({ identifier, value: recommended })">{{ recommended }}</a>
    </small>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  props: ['identifier', 'label', 'recommended', 'help'],
  computed: mapState(['imageSourceExifs', 'imageCurrentExifs', 'imagePreviewExifs', 'imagePreviewErrors']),
  methods: mapActions(['changeCurrentExif'])
}
</script>
