<template>
  <div>
    <h2 class="content-title">Archive statistics</h2>
    <rangeDate />
    <div class="insights" v-if="insightsExists">
      <div class="widget">
        <div class="d-flex justify-content-between mb-4">
          <strong class="caption">
            Location listing stats
            <p class="text-muted mb-0">
              The number of times the listing was viewed on Maps and Search
            </p>
          </strong>
          <chartSwitcher
            name="mainStats"
            value-left="listingStats"
            value-right="fullChart"
            v-model="mainChartsType">
            <template #left-icon>
              <i class="icon far fa-chart-line" />
            </template>
            <template #right-icon>
              <i class="icon far fa-analytics" />
            </template>
          </chartSwitcher>
        </div>
        <chartLines
          v-show="mainChartsType === 'listingStats'"
          :chart-ids="['views_maps', 'views_search']"
          :chart-colors="['#f9ae34', '#01c9a7']"
          chart-direction="in-row" />

        <MainChartWithTiles
          v-show="mainChartsType === 'fullChart'" />
      </div>
      <div class="insights-part">
        <div class="widget">
          <div class="d-flex justify-content-between">
            <strong class="caption">Business contacts</strong>
            <chartSwitcher
              name="businessContacts"
              v-model="businessChartType">
              <template #left-icon>
                <i class="icon far fa-chart-bar" />
              </template>
            </chartSwitcher>
          </div>
          <p class="text-muted">
            The number of times the phone or call now link was clicked / website link was clicked / driving directions were requested
          </p>
          <chartColumn
            v-show="businessChartType === 'other'"
            chart-type="column"
            :chart-height="300"
            :chart-ids="businessChart.ids"
            :chart-colors="businessChart.colors" />
          <chartLines
            v-show="businessChartType === 'line'"
            :chart-ids="businessChart.ids"
            :chart-colors="businessChart.colors" />
        </div>
        <div class="widget">
          <div class="d-flex justify-content-between mb-4">
            <strong class="caption">Photos stats</strong>
            <chartSwitcher
              name="photosStats"
              v-model="photosChartsType" />
          </div>
          <chartBar
            v-show="photosChartsType === 'other'"
            v-for="{ ids, colors } in photosCharts"
            :key="`chartBar_${ids.join('')}`"
            :chart-ids="ids"
            :chart-colors="colors" />
          <chartLines
            v-show="photosChartsType === 'line'"
            v-for="{ ids, colors } in photosCharts"
            :key="ids.join('')"
            :chart-ids="ids"
            :chart-colors="colors" />
        </div>
      </div>
      <div class="insights-part">
        <div class="widget">
          <strong class="caption">Local Posts stats</strong>
          <p class="text-muted">
            The number of times GMB posts were viewed on search and the call to action button was clicked on Google.
          </p>
          <chartLines
            :chart-ids="localPostChart.ids"
            :chart-colors="localPostChart.colors" />
        </div>
        <div class="widget">
          <div class="d-flex justify-content-between mb-4">
            <strong class="caption">How customers search for a business</strong>
            <chartSwitcher
              name="searchingBusiness"
              v-model="searchingChartType">
              <template #left-icon>
                <i class="icon far fa-chart-pie" />
              </template>
            </chartSwitcher>
          </div>
          <chartPie
            v-show="searchingChartType === 'other'"
            chart-type="pie"
            :chart-height="300"
            :chart-ids="searchingChart.ids"
            :chart-colors="searchingChart.colors" />
          <chartLines
            v-show="searchingChartType === 'line'"
            :chart-ids="searchingChart.ids"
            :chart-colors="searchingChart.colors" />
        </div>
      </div>
    </div>
    <div class="insights" v-else>
      <div class="widget">
        <div class="empty-state text-center mt-3">
          <img src="~/no_data/insights.svg" alt="No Insights Img" height=100 width=150>
          <div class="text-center text-muted mb-2">
            <h3 class="mt-4 mb-4">You don't have any insights yet.</h3>
            Insights will be synced soon. Please wait.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import rangeDate from './range_date'
import chartLines from './chart_lines'
import chartColumn from './chart_column'
import chartPie from './chart_pie'
import chartBar from './chart_bar'
import chartSwitcher from './chart_switcher'
import MainChartWithTiles from './main_chart_with_tiles'

export default {
  components: {
    chartBar, chartLines, chartColumn, chartPie, rangeDate, chartSwitcher, MainChartWithTiles
  },
  computed: mapGetters(['insightsExists', 'chartData', 'compare']),
  data() {
    return {
      businessChartType: 'other',
      photosChartsType: 'other',
      searchingChartType: 'other',
      mainChartsType: 'listingStats'
    }
  },
  created() {
    this.businessChart = {
      ids: ['actions_phone', 'actions_website', 'actions_driving_directions'],
      colors: ['#01c9a7', '#f9ae34', '#377dff']
    }
    this.photosCharts = [
      { ids: ['photos_views_merchant', 'photos_views_customers'], colors: ['#f9ae34', '#377dff']},
      { ids: ['photos_count_merchant', 'photos_count_customers'], colors: ['#01c9a7', '#7f30f0']}
    ]
    this.localPostChart = {
      ids: ['local_post_views_search', 'local_post_actions_call_to_action'],
      colors: ['#dc443d', '#377dff']
    }
    this.searchingChart = {
      ids: ['queries_direct', 'queries_indirect', 'queries_chain'],
      colors: ['#01c9a7', '#f9ae34', '#377dff']
    }
  }
}
</script>
