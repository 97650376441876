<template>
  <div class="audit-card-post" ref="card">
    <div class="audit-card-post__top">
      <div class="audit-card-post__date">{{ post.date }}</div>
      <div class="audit-card-post__type">{{ post.type }}</div>
    </div>
    <div v-if="post.photoUrl"
      class="audit-card-post__image"
      :class="{'cursor-pointer': post.originalPhotoUrl }"
      @click="handleMediaClick">
      <img :src="post.photoUrl" alt="Post image">
    </div>
    <p class="audit-card-post__text">{{ post.text }}</p>
  </div>
</template>

<script>
import contentCardDateFormatMixin from './content_card_date_format_mixin'

export default {
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  mixins: [contentCardDateFormatMixin],
  computed: {
    post() {
      return {
        text: this.content.text,
        photoUrl: this.content.photoUrl,
        originalPhotoUrl: this.content.originalPhotoUrl,
        date: this.formatDate(this.content.timestamp),
        type: this.content.postTypeName
      }
    }
  },
  methods: {
    handleMediaClick() {
      if (this.post.originalPhotoUrl) {
        this.$emit('openGallery', {
          type: 'image',
          originalUrl: this.post.originalPhotoUrl
        })
      }
    }
  }
}
</script>
