import Cookies from 'js-cookie'
import calcGrid from './geo_direct'
import { ICONS_PATH } from '../../../common/constants'

export const MAP_PADDINGS = {
  top: 60, bottom: 60, left: 60, right: 60
}
const METERS_IN_MILE = 1609.34
const MAX_GRID_SIZE = 13 * 13

function countDistanceInMeters(distanceMeasure, distance) {
  if (distanceMeasure === 'miles') distance *= METERS_IN_MILE
  return distance
}

function iconUrl(idx, ranks, hightlightedPositions = [], newHightlightedPositions = []) {
  if (newHightlightedPositions?.includes(idx)) {
    return `${ICONS_PATH}${ranks[idx]}-medal.png`
  }

  if (hightlightedPositions?.includes(idx)) {
    return `${ICONS_PATH}${ranks[idx]}-medal-silver.png`
  }

  return `${ICONS_PATH}${ranks[idx]}.png`
}

export function createMarkers() {
  const markers = []
  for (let i = 0; i < MAX_GRID_SIZE; ++i) {
    markers.push(new google.maps.Marker({
      draggable: false,
      zIndex: 9,
      cursor: 'pointer'
    }))
  }
  return markers
}

export function getLastMapCenter(map) {
  const lat = Cookies.get('places_map_lat')
  const lng = Cookies.get('places_map_lng')

  if (lat && lng) {
    map.setCenter({ lat: parseFloat(lat, 10), lng: parseFloat(lng, 10) })
  } else if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((position) => {
      const { coords } = position
      map.setCenter({ lat: coords.latitude, lng: coords.longitude })
    })
  }
  return { lat: lat, lng: lng }
}

export function storeMapCenter(map) {
  map.addListener('center_changed', () => {
    const lat = map.center.lat()
    const lng = map.center.lng()
    if (lat && lng) {
      Cookies.set('places_map_lat', lat)
      Cookies.set('places_map_lng', lng)
    }
  })
}

export function fitBounds({ mapMarkers, bounds, map }, lastMarkerIndex, customPadding = MAP_PADDINGS) {
  if (mapMarkers[0] && mapMarkers[lastMarkerIndex]) {
    bounds = new google.maps.LatLngBounds()
    bounds.extend(new google.maps.LatLng(
      mapMarkers[0].position.lat(),
      mapMarkers[0].position.lng()
    ))
    bounds.extend(new google.maps.LatLng(
      mapMarkers[lastMarkerIndex].position.lat(),
      mapMarkers[lastMarkerIndex].position.lng()
    ))
    setTimeout(() => { map.fitBounds(bounds, customPadding) })
  }
  return bounds
}

export function showGridMarkers({
  gridCenterLat, gridCenterLng, gridSize, distanceInMeters
}, ranks, hightlightedPositions, newHightlightedPositions, mapMarkers, map, iconScaledSize, isCompetitorGeogrid) {
  const grid = calcGrid(gridCenterLat, gridCenterLng, gridSize, distanceInMeters)
  ranks = ranks.flat()

  mapMarkers.forEach((_val, idx, markers) => {
    if (idx < ranks.length) {
      markers[idx].setOptions({
        position: { lat: grid[idx][0], lng: grid[idx][1] },
        map: map,
        icon: {
          // Do not show highlighted icons for competitor geogrids
          url: isCompetitorGeogrid ? iconUrl(idx, ranks) : iconUrl(idx, ranks, hightlightedPositions, newHightlightedPositions),
          anchor: new google.maps.Point(16, 16),
          scaledSize: iconScaledSize
        },
        cursor: `${ranks[idx] === 'D' ? 'default' : 'pointer'}`,
        rank: ranks[idx],
        idx: idx + 1
      })
    } else {
      markers[idx].setMap(null)
    }
  })
}

export function showDummyMarkers({
  mapMarkers, map, iconScaledSize, mapCenterLat, mapCenterLng, gridSize, distanceMeasure, distance, draggableMarker, disabledPoints
}) {
  const grid = calcGrid(
    mapCenterLat,
    mapCenterLng,
    gridSize,
    countDistanceInMeters(distanceMeasure, distance)
  )

  const centerDotIndex = grid.length / 2 | 0

  mapMarkers.forEach((_val, idx, markers) => {
    if (idx < grid.length && idx !== centerDotIndex) {
      markers[idx].setOptions({
        position: { lat: grid[idx][0], lng: grid[idx][1] },
        map: map,
        icon: {
          url: `${ICONS_PATH}preview-${disabledPoints.has(idx + 1) ? 'disabled' : 'default'}.png`,
          anchor: new google.maps.Point(16, 16),
          scaledSize: iconScaledSize
        },
        cursor: 'default',
        rank: null
      })
    } else {
      markers[idx].setMap(null)
    }
  })

  draggableMarker.setOptions({
    position: { lat: parseFloat(mapCenterLat, 10), lng: parseFloat(mapCenterLng, 10) },
    map: map,
    draggable: true
  })
}
