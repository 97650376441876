<template>
  <div class="mv-map">
    <div v-show="validCoords" id="map" />
    <div v-if="!validCoords" class="mv-map-error">
      <i class="far fa-map-marked-alt" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MomentMap',
  props: {
    coords: { type: Object, required: true }
  },
  mounted() {
    this.setMap()
  },
  computed: {
    processedCoords() {
      return Object.entries(this.coords).reduce((mem, [key, coord]) => {
        mem[key] = parseFloat(coord, 10)
        return mem
      }, {})
    },
    validCoords() {
      return this.coords?.lat && this.coords?.lng
    }
  },
  methods: {
    setMap() {
      if (!this.validCoords) return
      if (this.map) {
        this.map.setCenter(this.processedCoords, 14)
        this.setMarker(this.processedCoords)
      } else {
        this.map = new google.maps.Map(document.getElementById('map'), {
          center: this.processedCoords,
          zoom: 18,
          streetViewControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
          gestureHandling: 'greedy'
        })
        this.setMarker(this.processedCoords)
      }
    },
    setMarker(latLng) {
      this.marker?.setMap(null)
      this.marker = new google.maps.Marker({
        position: latLng,
        map: this.map,
        draggable: false
      })
    }
  },
  watch: {
    coords: {
      deep: true,
      handler() {
        this.setMap()
      }
    }
  }
}
</script>
