<template>
  <div class="form-group date-time-picker">
    <div class="row">
      <label class="col-12 col-sm-3 col-form-label text-sm-right">Schedule {{ text }}?</label>

      <div class="col-12 col-sm-8 col-lg-6 pt-2">
        <div class="switch-button switch-button-success">
          <input type="checkbox" name="gmb_local_post[schedule_post]" id="gmb_local_post_schedule_post" v-model="scheduled">
          <span>
            <label for="gmb_local_post_schedule_post" />
          </span>
        </div>
      </div>
    </div>

    <transition name="slide-animation">
      <div class="row mt-2" v-show="scheduled">
        <label class="col-12 col-sm-3 col-form-label text-sm-right" for="schedule_start_Schedule at">Schedule at</label>

        <div class="col-12 col-sm-5 col-lg-4">
          <flat-pickr v-model="object.scheduledAt" class="form-control" :config="config" />
        </div>

        <div class="col-12 col-sm-2 col-lg-4">
          <input type="text" :value="timeZone || 'in location timezone'" readonly class="form-control-plaintext">
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import flatPickr from 'vue-flatpickr-component'
import { FLATPICKR_WEEKDAYS_MAP } from 'common/constants'

export default {
  props: {
    text: { type: String, required: true }
  },
  data() {
    return {
      checked: false
    }
  },
  created() {
    this.config = {
      enableTime: true,
      dateFormat: 'd/m/Y H:i',
      locale: {
        firstDayOfWeek: FLATPICKR_WEEKDAYS_MAP[Styxie.USER.beginningOfWeek]
      }
    }
  },
  computed: {
    ...mapState(['object', 'timeZone']),
    scheduled: {
      get() { return !!this.object.scheduledAt || this.checked },
      set(val) {
        if (!val) this.$store.state.object.scheduledAt = ''
        this.checked = val
        return val
      }
    }
  },
  components: { flatPickr }
}
</script>

<style scoped>
  .form-control-plaintext {
    outline: none;
  }
</style>
