<template>
  <div class="review-dashboard-stats">
    <div class="review-dashboard-stats__column">
      <p class="review-dashboard-stats__title">Reviews</p>
      <div class="review-dashboard-stats__line">
        <div class="review-dashboard-stats__value">
          {{ stats.currentPeriodReviewsCount }}
        </div>
        <div
          v-if="!isShowingForAllTime"
          class="review-dashboard-stats__diff"
          :class="{
            'review-dashboard-stats__diff--upwards': diffs.reviewCount > 0,
            'review-dashboard-stats__diff--downwards': diffs.reviewCount < 0
          }">
          <i class="fa-regular fa-arrow-up-right" />
          {{ diffs.reviewCount }}%
        </div>
      </div>
      <span v-if="isShowingForAllTime">
        Showing statistics for all time
      </span>
      <span v-else>Compared to the previous period</span>
    </div>
    <div
      class="review-dashboard-stats__column"
      :class="{'review-dashboard-stats__column--last': filterByResponseApplied }">
      <template v-if="!filterByResponseApplied">
        <p class="review-dashboard-stats__title">Unanswered</p>
        <div class="review-dashboard-stats__line">
          <div class="review-dashboard-stats__value">
            {{ stats.unansweredCount }}
          </div>
        </div>
      </template>
    </div>
    <div class="review-dashboard-stats__column">
      <template v-if="!filterByRatingApplied">
        <p class="review-dashboard-stats__title">Average rating</p>
        <div class="review-dashboard-stats__line">
          <div class="review-dashboard-stats__value">
            <i v-if="averagePeriodRating !== NA" class="fa-solid fa-star" />
            {{ averagePeriodRating }}
          </div>
        </div>
      </template>
    </div>
    <div class="review-dashboard-stats__column review-dashboard-stats__column--reverse">
      <template v-if="!filterByRatingApplied">
        <div
          v-for="({ percentage, entries }, index) in ratingDistribution"
          :key="index"
          class="review-dashboard-stats__distribution-line">
          <i class="fa-solid fa-star" />
          <p>{{ index + 1 }}</p>
          <div class="review-dashboard-stats__bar">
            <div :style="{ width: percentage }" />
          </div>
          <span>
            {{ percentage }}
            <template v-if="entries > 0">
              ({{ entries }})
            </template>
          </span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    stats: {
      type: Object,
      required: true
    },
    filterByRatingApplied: {
      type: Boolean,
      required: false
    },
    filterByResponseApplied: {
      type: String,
      required: false
    }
  },
  created() {
    this.NA = 'N/A'
  },
  computed: {
    isShowingForAllTime() {
      return !this.diffs
    },
    diffs() {
      const {
        currentPeriodReviewsCount: current,
        previousPeriodReviewsCount: previous
      } = this.stats

      if (typeof previous !== 'number') {
        return null
      }

      let delta = (current - previous) / previous * 100

      if (current > 0 && previous === 0) {
        delta = 100
      } else if (current === 0 && previous === 0) {
        delta = 0
      }

      return { reviewCount: delta.toFixed(1) }
    },
    averagePeriodRating() {
      if (this.stats.currentPeriodReviewsCount === 0) return this.NA

      const sum = Object.keys(this.stats.starCounts).reduce((acc, star) => {
        const rating = parseInt(star, 10)
        const entries = this.stats.starCounts[star]
        return acc + rating * entries
      }, 0)

      return `${(sum / this.stats.currentPeriodReviewsCount).toFixed(2)}`
    },
    ratingDistribution() {
      const total = this.stats.currentPeriodReviewsCount
      const distribution = Array(5).fill({
        percentage: '0%',
        entries: 0
      })

      if (total === 0) {
        return distribution
      }

      Object.keys(this.stats.starCounts).forEach((star) => {
        const entries = this.stats.starCounts[star]
        const percentage = (entries / total * 100).toFixed(1)
        distribution[star - 1] = {
          percentage: `${percentage}%`,
          entries
        }
      })

      return distribution
    }
  }
}
</script>
