<template>
  <div class="tab-pane active" role="tabpanel">
    <div class="form-group row">
      <label class="col-12 col-sm-3 col-form-label text-sm-right">Alert Type</label>
      <div class="col-12 col-sm-8 col-lg-6">
        <select class="form-control custom-select" v-model="object.alertType">
          <option value="COVID_19">COVID-19</option>
        </select>
      </div>
    </div>

    <FormCta />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import FormCta from './common/cta'

export default {
  components: { FormCta },
  computed: {
    ...mapState(['object'])
  }
}
</script>
