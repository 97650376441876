<template>
  <GroupMetrics v-if="hasPropertisData(parsedData, viewsBreakdownProps)" :properties="viewsBreakdownProps" :parsed-data="parsedData" :map-icons="mapIcons">
    <template #title>Views Breakdown</template>
  </GroupMetrics>
</template>

<script>
import { mapState } from 'vuex'
import GroupMetrics from './group_metrics'
import { viewsBreakdownProps, mapIcons, hasPropertisData } from './helpers'

export default {
  components: { GroupMetrics },
  created() {
    this.mapIcons = mapIcons
    this.viewsBreakdownProps = viewsBreakdownProps
  },
  computed: mapState(['parsedData']),
  methods: {
    hasPropertisData
  }
}
</script>
