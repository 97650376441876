<template>
  <b-tabs content-class="card-full-color-dark mb-4" nav-class="nav-tabs-dark" active-nav-item-class="border-0">
    <b-tab title="" disabled hidden
      title-item-class="display-none"
      no-body />
    <b-tab v-for="type in topicTypes"
      :title="type.toUpperCase()"
      :key="`key_${type}`"
      lazy
      :title-item-class="{ 'display-none': isHidden(type) }"
      :active="object.topicType === type"
      @click="object.topicType = type">
      <component :is="type" />
    </b-tab>
  </b-tabs>
</template>

<script>
import { mapState } from 'vuex'

import Standard from './tabs/standard'
import Event from './tabs/event'
import Offer from './tabs/offer'
import Alert from './tabs/alert'

const TOPIC_TYPES = ['standard', 'event', 'offer', 'alert']

export default {
  data() {
    return {
      topicTypes: TOPIC_TYPES
    }
  },
  computed: mapState(['object']),
  methods: {
    isHidden(type) {
      return this.object.obfuscatedId && type !== this.object.topicType
    }
  },
  components: {
    Standard, Event, Offer, Alert
  }
}
</script>

<style>
  .display-none {
    display: none;
  }

  .tab-pane.show.active {
    outline: none;
  }
</style>
