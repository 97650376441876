import Vue from 'vue'
import ReviewsList from '../../vue_widgets/gmb/reviews/list'

Styxie.Initializers.GmbReviews = {
  index: (props) => {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-reviews-list'),
        render: (h) => h(ReviewsList, {
          props
        })
      })
    })
  }
}
