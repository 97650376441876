export default class StripeForm {
  constructor(stripeKey, formId, async = false) {
    this.stripeKey = stripeKey
    this.form = document.getElementById(formId)
    this.async = async
  }

  init() {
    const that = this
    const stripe = Stripe(this.stripeKey)
    const elements = stripe.elements()
    const card = elements.create('card', {})

    card.mount('#card-element')

    card.addEventListener('change', (event) => {
      const displayError = document.getElementById('card-errors')
      if (event.error) {
        displayError.textContent = event.error.message
      } else {
        displayError.textContent = ''
        that.enableForm()
      }
    })

    this.form.addEventListener('submit', (event) => {
      event.preventDefault()

      if (that.async) {
        const tab = that.form.querySelector('input[type="radio"]:checked')
        if (tab && tab.id === 'paypal') return that.form.submit()
      }

      return stripe.createToken(card).then((result) => {
        if (result.error) {
          // Inform the user if there was an error
          const errorElement = document.getElementById('card-errors')
          errorElement.textContent = result.error.message
        } else {
          // Send the token to your server
          that.stripeTokenHandler(result.token)
        }
      })
    })
  }

  stripeTokenHandler(token) {
    const $input = $('<input type="hidden" name="stripe_token" />').val(token.id)
    $(this.form).prepend($input)
    this.form.submit()
  }

  enableForm() {
    $(this.form).find(':submit').removeAttr('disabled')
  }
}
