<template>
  <div class="audit-report">
    <ReportHead :report="report" :is-public="isPublic" :audit-report-url="auditReportUrl" />
    <ReportNavigation ref="navigation" :sections="sections" :section-in-view="sectionInView" @scrollTo="scrollToSection" />
    <div class="reports-content text-dark py-0">
      <!--<ReportQnas class="audit-report__section" :section-data="mockQna" @showContentSidebar="showContentSidebar" />-->
      <component v-for="section in sections"
        class="audit-report__section"
        ref="reportSections"
        :data-section-key="section.key"
        :key="section.key"
        :is="`report-${section.key}`"
        :section-data="section.data"
        :historical-data="historicalData[section.key]"
        @showContentSidebar="showContentSidebar" />
      <ReportContentSidebar
        v-if="contentSidebarBusiness"
        :preset-content="contentSidebarContent"
        :is-public="isPublic"
        :business="contentSidebarBusiness"
        :report-id="report.id"
        :content-type="contentSidebarType"
        @close="hideContentSidebar" />
    </div>
  </div>
</template>
<!-- draw something for incompleted section? -->

<script>
import axios from 'axios'

// report sections
import ReportHead from './report_head'
import ReportNavigation from './report_navigation'
import ReportGeogrid from './report_geogrid'
import ReportCategories from './report_categories'
import ReportTitleAndDescription from './report_title_and_description'
import ReportReviews from './report_reviews'
import ReportMedia from './report_media'
import ReportPosts from './report_posts'
import ReportJsonLd from './report_json_ld'
import ReportZipCodes from './report_zip_codes'
import ReportServiceAreas from './report_service_areas'
import ReportBusinessAttributes from './report_business_attributes'
import ReportPageSpeed from './report_page_speed'
import ReportGbpService from './report_services'
import ReportQnas from './report_qnas'

// sidebar
import ReportContentSidebar from './content_sidebar/report_content_sidebar'
import contentSidebarParentLogicMixin from './content_sidebar_parent_logic_mixin'

import navigationMixin from './navigation_mixin'

const BASE_SECTIONS_ORDER = [
  {
    title: 'Categories',
    key: 'categories'
  },
  {
    title: 'Title & Description',
    key: 'titleAndDescription'
  },
  {
    title: 'Reviews',
    key: 'reviews'
  },
  {
    title: 'Media posts',
    key: 'media'
  },
  {
    title: 'GBP posts',
    key: 'posts'
  },
  {
    title: 'Questions/Answers',
    key: 'qnas'
  },
  {
    title: 'Services',
    key: 'gbpService'
  },
  {
    title: 'Service areas',
    key: 'serviceAreas'
  },
  {
    title: 'Business attributes',
    key: 'businessAttributes'
  },
  {
    title: 'Zip Codes',
    key: 'zipCodes'
  },
  {
    title: 'PSI',
    key: 'pageSpeed'
  },
  {
    title: 'JSON-LD',
    key: 'jsonLd'
  }
]

export default {
  components: {
    ReportHead,
    ReportNavigation,
    ReportGeogrid,
    ReportCategories,
    ReportTitleAndDescription,
    ReportReviews,
    ReportMedia,
    ReportPosts,
    ReportJsonLd,
    ReportZipCodes,
    ReportServiceAreas,
    ReportBusinessAttributes,
    ReportPageSpeed,
    ReportGbpService,
    ReportQnas,
    ReportContentSidebar
  },
  props: {
    report: { type: Object, required: true },
    geogrid: { type: Object, required: true },
    googleMapApiKey: { type: String, required: true },
    isPublic: { type: Boolean, default: false },
    auditReportUrl: { type: String, default: '' }
  },
  mixins: [contentSidebarParentLogicMixin, navigationMixin],
  data() {
    return {
      historicalData: {}
    }
  },
  created() {
    this.sections = BASE_SECTIONS_ORDER.reduce((mem, { title, key }) => {
      const data = this.report.data.sections[key]
      if (data && data.sectionCompleted) {
        mem.push({
          key,
          name: title,
          data,
          component: `report-${key}`
        })
      }
      return mem
    }, [{
      key: 'geogrid',
      name: 'Geogrid',
      data: { geogrid: this.geogrid, googleMapApiKey: this.googleMapApiKey },
      component: 'report-geogrid'
    }])

    const historicalDataPath = `${this.isPublic ? '/stats' : ''}/api/audit_reports/${this.report.id}/historical_data`

    axios.get(historicalDataPath)
      .then((res) => {
        this.historicalData = res.data
      })
  }
}
</script>
