export default {
  data: () => ({
    contentSidebarBusiness: null,
    contentSidebarType: 'reviews',
    contentSidebarContent: null
  }),
  methods: {
    showContentSidebar({ business, contentType, content = null }) {
      let sourceBusiness = business
      let isCompetitor = true
      if (!sourceBusiness.id && !content) {
        // if 'business' argument doesn't have an id field, that actually means that
        // this is a main location of the audit, and therefore we know that it's not a competitor
        sourceBusiness = this.report.data.sections.location
        isCompetitor = false
      } else if (content) {
        sourceBusiness = business
      }

      const { id, name } = sourceBusiness

      this.contentSidebarBusiness = {
        id,
        name,
        isCompetitor
      }

      this.contentSidebarType = contentType
      this.contentSidebarContent = content
    },
    hideContentSidebar() {
      this.contentSidebarBusiness = null
      this.contentSidebarType = 'reviews'
      this.contentSidebarContent = null
    }
  }
}
