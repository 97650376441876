<template>
  <div>
    <h2 class="h3 m-0 mb-4 font-weight-semi-bold text-dark">{{ pageSourceName }} page link</h2>
    <slot name="additionalInfo" />
    <a
      class="btn btn-sm mb-4"
      :href="oauthUrl">
      <slot name="oauthButtonIcon">
        <i class="fa-brands fa-square-facebook mr-2" />
      </slot>
      <span>
        Connect {{ pageSourceName }} Account
      </span>
    </a>
    <form class="mb-5 narrow-form" @submit.prevent="update">
      <div class="d-flex gap-4 form-field pt-1">
        <multiselect
          v-model="selectedPage"
          :allow-empty="true"
          :options="options"
          :searchable="true"
          :show-labels="false"
          :max-height="540"
          label="text"
          placeholder="Search"
          track-by="value">
          <template #option="{option}">
            <i v-if="option.$isDisabled" class="text-warning fa-regular fa-circle-exclamation" />
            <span :class="{'text-muted': option.$isDisabled}">{{ option.text }}</span>
            <p v-if="option.$isDisabled" class="text-warning m-0">No access to the {{ pageSourceName }} page.</p>
          </template>
        </multiselect>
        <button type="submit" class="btn btn-outline rounded">Update</button>
      </div>
      <p v-if="selectedPageIsDisabled" class="mt-2 text-warning">Сheck {{ pageSourceName }} account settings - there's no access to the page</p>
    </form>
  </div>
</template>
<script>

import Multiselect from 'vue-multiselect'
import { DEFAULT_ERROR_MESSAGE } from 'common/constants'
import { subscribe, removeChannel } from 'vue_widgets/audit_report_configurations/helpers'

export default {
  components: { Multiselect },
  props: {
    pagesPath: { type: String, required: true },
    mobileBusinessPath: { type: String, required: false, default: '' },
    oauthUrl: { type: String, required: false, default: '#' },
    pageSourceName: { type: String, required: false, default: 'Facebook' },
    mobileBusinessFieldName: { type: String, required: false, default: 'facebook_page_link_id' }
  },
  data: () => ({
    selectedPage: null,
    options: []
  }),
  created() {
    this.getPages()

    this.channel = subscribe('AccountUpdateFbChannel', () => {
      this.getPages()
    })
  },
  beforeDestroy() {
    removeChannel(this.channel)
  },
  computed: {
    selectedPageId() {
      return this.selectedPage?.value
    },
    selectedPageIsDisabled() {
      return this.selectedPage?.$isDisabled
    }
  },
  methods: {
    getPages() {
      axios.get(this.pagesPath).then(({ data }) => {
        this.options = data.map((option) => ({
          $isDisabled: Boolean(option.disconnected_at),
          ...option
        }))
        this.selectedPage = this.options.find((pageLink) => pageLink.selected)
      })
    },
    update() {
      const formData = new FormData()

      formData.append(`mobile_business[${this.mobileBusinessFieldName}]`, this.selectedPageId)

      axios.patch(this.mobileBusinessPath, formData)
        .then(() => {
          toastr.success('Setting updated successfully')
        })
        .catch(() => {
          toastr.error(DEFAULT_ERROR_MESSAGE)
        })
    }
  }
}
</script>
