import Vuex from 'vuex'
import axios from 'axios'
import toastr from 'toastr'

import { DEFAULT_ERROR_MESSAGE } from 'common/constants'
import { airbrakeNotify } from 'common/airbrake'
import { runSwal } from 'common/delete_with_swal'

import makeImageSelectStore from '../../../components/image_select/store'

import {
  collectImageAndExifs, collectMultilocation, multilocationStore, submitUrl
} from './helpers'

export default function createStore(prms) {
  const imageSelectStore = makeImageSelectStore(prms)

  const store = new Vuex.Store({
    state: {
      previewError: null,
      plainErrors: null,

      ...prms,
      ...multilocationStore.state,
      ...imageSelectStore.state
    },
    getters: {
      ...multilocationStore.getters
    },
    actions: {
      ...multilocationStore.actions,
      ...imageSelectStore.actions,
      previewSummary({ commit, state, getters }, regenerateSpintax) {
        if (state.object.summarySource.includes('{') && getters.selectedSource) {
          axios.post('/api/gmb/preview/summary', {
            gmb_local_post: {
              summary_source: state.object.summarySource,
              spintax_seed: regenerateSpintax ? null : state.object.spintaxSeed
            },
            entity: getters.selectedSource.entity,
            entity_type: getters.selectedSource.type
          }).then(
            (res) => {
              commit('updateSummary', res.data.summary)
              if (regenerateSpintax) commit('updateSpintax', res.data.spintax_seed)
            },
            (err) => {
              commit('updatePreviewError', err.response.data.error)
            }
          )
        } else {
          commit('updateSummary', state.object.summarySource)
        }
      },
      onSubmit({ commit, state }, e) {
        $(e.target).toggleWrapper()
        commit('updatePlainErrors', null)

        const data = new FormData()

        data.append('gmb_local_post[summary_source]', state.object.summarySource)
        data.append('gmb_local_post[spintax_seed]', state.object.spintaxSeed)
        data.append('gmb_local_post[topic_type]', state.object.topicType)

        if (state.object.ctaType !== 'action_type_unspecified' && state.object.topicType !== 'offer') {
          data.append('gmb_local_post[cta_type]', state.object.ctaType)
          if (state.object.ctaType !== 'call') data.append('gmb_local_post[cta_link]', state.object.ctaLink)
        }

        if (state.object.topicType === 'event' || state.object.topicType === 'offer') {
          data.append('gmb_local_post[event_with_time]', state.object.event.withTime || 'f')
          data.append('gmb_local_post[shiftable_event_schedule]', state.object.shiftableEventSchedule || 'f')
          if (state.object.event.title) data.append('gmb_local_post[event_title]', state.object.event.title)
          if (state.object.event.startDate) data.append('gmb_local_post[event_start_date]', state.object.event.startDate)
          if (state.object.event.withTime && state.object.event.startTime) {
            data.append('gmb_local_post[event_start_time]', state.object.event.startTime)
          }
          if (state.object.event.endDate) data.append('gmb_local_post[event_end_date]', state.object.event.endDate)
          if (state.object.event.withTime && state.object.event.endTime) {
            data.append('gmb_local_post[event_end_time]', state.object.event.endTime)
          }
        }

        if (state.object.topicType === 'offer') {
          if (state.object.offer.couponCode) data.append('gmb_local_post[offer_coupon_code]', state.object.offer.couponCode)
          if (state.object.offer.redeemOnlineUrl) data.append('gmb_local_post[offer_redeem_online_url]', state.object.offer.redeemOnlineUrl)
          if (state.object.offer.termsConditions) data.append('gmb_local_post[offer_terms_conditions]', state.object.offer.termsConditions)
        }

        if (state.object.topicType === 'alert') {
          if (state.object.alertType) data.append('gmb_local_post[alert_type]', state.object.alertType)
        }

        if (state.object.scheduledAt) data.append('gmb_local_post[scheduled_at]', state.object.scheduledAt)
        if (state.object.recurrentDays) data.append('gmb_local_post[recurrent_days]', state.object.recurrentDays)
        if (state.object.recurrentExpireCount) data.append('gmb_local_post[recurrent_expire_count]', state.object.recurrentExpireCount)

        if (state.object.topicType !== 'alert') collectImageAndExifs(state, data, 'gmb_local_post')
        collectMultilocation(state, data)

        const url = submitUrl(state, 'local_posts', 'posts')

        let request
        if (state.object.obfuscatedId) {
          request = axios.patch(`${url}/${state.object.obfuscatedId}`, data)
        } else {
          request = axios.post(url, data)
        }

        request
          .then(
            (res) => {
              $(document).one('turbolinks:load', () => {
                if (res.data.notice) toastr.success(res.data.notice)
              })
              Turbolinks.visit(res.data.path)
            },
            (err) => {
              $(e.target).toggleWrapper({}, false)
              document.body.scrollIntoView({ behavior: 'smooth' })

              if (err.response && err.response.data) {
                const errData = err.response.data
                if (errData.errors) {
                  commit('updatePlainErrors', errData.errors)
                } else if (errData.error) {
                  toastr.error(errData.error)
                }
              } else {
                toastr.error(DEFAULT_ERROR_MESSAGE)
                airbrakeNotify({
                  error: `LocalPost submit ${err.message}`,
                  params: { request: err.request, response: err.response }
                })
              }
            }
          )
      },
      onDelete({ state }, e) {
        runSwal(() => {
          const $form = $(e.target.closest('form'))
          $form.toggleWrapper()
          axios.delete(e.target.href)
            .then(
              () => {
                $(document).one('turbolinks:load', () => toastr.success('Post was successfully destroyed'))
                Turbolinks.visit(`/gmb/locations/${state.object.remoteLocationId}/local_posts`)
              },
              (err) => {
                $form.toggleWrapper({}, false)
                let text = DEFAULT_ERROR_MESSAGE
                if (err.response && err.response.data && err.response.data.error) text = err.response.data.error
                toastr.error(text)
              }
            )
        })
      },
      stopRecurring({ state }, e) {
        const isLastPost = !state.object.recurrentDescendantId || state.object.recurrentDescendantId === state.object.obfuscatedId
        let question = 'This will delete the last post and no further posts will be created.'
        if (!isLastPost) {
          question += `\n To stop the chain after the next published post,
            click <a href="/gmb/locations/${state.object.remoteLocationId}/local_posts/${state.object.recurrentDescendantId}/edit">here</a>
            to view the post and uncheck the 'recurrent post' checkbox.`
        }

        runSwal(() => {
          const $form = $(e.target.closest('form'))
          $form.toggleWrapper()
          axios.post(e.target.href)
            .then(
              () => {
                if (isLastPost) {
                  $(document).one('turbolinks:load', () => toastr.success('Recursive chain was successfully stopped'))
                  Turbolinks.visit(`/gmb/locations/${state.object.remoteLocationId}/local_posts`)
                } else {
                  toastr.success('Recursive chain was successfully stopped')
                }
              },
              (err) => {
                let text = DEFAULT_ERROR_MESSAGE
                if (err.response && err.response.data && err.response.data.error) text = err.response.data.error
                toastr.error(text)
              }
            )
            .finally(() => $form.toggleWrapper({}, false))
        }, { content: question })
      },
      onBack({ _state }, _e) {
        window.history.back()
      }
    },
    mutations: {
      ...imageSelectStore.mutations,
      ...multilocationStore.mutations,

      updateSummarySource(state, txt) {
        state.object.summarySource = txt.replace(/\r/g, '')
      },
      updateSummary(state, txt) {
        state.previewError = null
        state.object.summary = txt
      },
      updateSpintax(state, seed) {
        state.object.spintaxSeed = seed
      },
      insertDrop(state, drop) {
        if (!state.object.summarySource.includes(drop)) {
          state.object.summarySource = `${state.object.summarySource || ''}\n${drop}`
        }
      },
      updatePreviewError(state, err) {
        state.previewError = err
      },
      updatePlainErrors(state, errors) {
        state.plainErrors = errors
      }
    }
  })

  // INFO: to render summary preview on scheduled posts
  if (prms.object.summarySource !== '' && prms.object.summary === '') {
    store.dispatch('previewSummary', false)
  }

  return store
}
