<template>
  <div class="scroll hor" ref="scheduledPublicationsTable">
    <table class="scheduled-publications-table">
      <thead>
        <th>
          <p>Locations</p>
        </th>
        <th v-for="(day, index) in columns" :key="day.id">
          <div class="scheduled-publications-table-date">
            <p :class="{ 'color-danger': day.weekend, 'today': day.today}">
              <template v-if="(index === 0 || index === columns.length - 1 || day.day === '1') && !day.today">
                {{ day.formattedDate }}
              </template>
              <template v-else>{{ day.day }}</template>
            </p>
          </div>
        </th>
      </thead>
      <tbody>
        <tr v-for="location in schedule.locations" :key="location.id" :class="{ 'removing-image': removingImage }">
          <td @dragover.prevent.stop="onDragOver($event)">
            <div class="scheduled-publications-table-location">
              <p>{{ location.name }}</p>
              <span>{{ location.address }}</span>
            </div>
          </td>
          <td v-for="day in columns"
            :key="day.id"
            :class="{ 'today-line': day.today }"
            :date="day.dateStr"
            :location-id="location.id"
            @wheel="horizontalScroll"
            @drop.prevent.stop="onDrop($event)"
            @dragover.prevent.stop="onDragOver($event)">
            <div class="schedule-day js-schedule-day">
              <template v-for="m in schedule.media">
                <a v-if="m.locationId === location.id && day.formattedDate === formatDate(m.scheduledAt, location.timeZoneOffset)"
                  :key="m.id"
                  :href="m.spinner ? '' : `/gmb/locations/${location.id}/media/${m.id}/edit`"
                  :class="imgWrapClass(m)"
                  :draggable="isElementDragable(m)"
                  @dragstart="setDataTransfer({
                    locationId: location.id,
                    media: m,
                    scheduleId: schedule.obfuscatedId
                  })">
                  <i v-if="m.syncErrors.message" class="far fa-circle" data-placement="right"
                    data-toggle="tooltip"
                    :title="formatErrors(m.syncErrors)" />
                  <i v-if="m.spinner" :key="m.id" class="far fa-spinner-third fa-spin" />
                  <img v-else :src="m.picture.preview.url"
                    :draggable="isElementDragable(m)"
                    :class="{ 'outdate': dateDiff(m.scheduledAt)}">
                </a>
              </template>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import moment from 'moment'

const DATE_FORMAT = 'MMM D'

export default {
  data() {
    return {
      columns: []
    }
  },
  computed: {
    ...mapState(['dataTransfer', 'schedule', 'removingImage'])
  },
  created() {
    this.getDates()
    document.body.addEventListener('dragover', this.onDragOver)
  },
  mounted() {
    $('[data-toggle="tooltip"]').tooltip({ html: true })
  },
  beforeDestroy() {
    document.body.removeEventListener('dragover', this.onDragOver)
  },
  methods: {
    ...mapMutations(['setDataTransfer']),
    ...mapActions(['replaceImage']),
    onDrop($event) {
      const $cell = $event.target.tagName === 'TD' ? $($event.target) : $($event.target).parents('td')
      const { media, locationId } = this.dataTransfer
      $('.js-schedule-day').removeClass('dragover')

      if (!this.dateDiff($cell.attr('date')) && locationId === $cell.attr('location-id')) {
        const location = this.schedule.locations.find(({ id }) => id === locationId)
        const scheduled = moment.parseZone(media.scheduledAt).utcOffset(location.timeZoneOffset / 3600, false)
        const cellDate = moment.utc($cell.attr('date')).utcOffset(scheduled.utcOffset(), true)

        scheduled.date(cellDate.date())
        scheduled.month(cellDate.month())
        scheduled.year(cellDate.year())

        $cell.append('<i class="far fa-spinner-third fa-spin" />')

        this.replaceImage(scheduled.toISOString()).then(() => $cell.children('i:first').remove())
      }

      this.setDataTransfer(null)
    },
    onDragOver($event) {
      const $cell = $event.target.tagName === 'TD' ? $($event.target) : $($event.target).parents('td')
      $('.js-schedule-day').removeClass('dragover')
      if (!this.dateDiff($cell.attr('date')) && this.dataTransfer?.locationId === $cell.attr('location-id')) {
        $cell.find('.js-schedule-day').addClass('dragover')
      }
    },
    isElementDragable(media) {
      return !media.transfering && (!this.dateDiff(media.scheduledAt) || media.failed)
    },
    imgWrapClass(media) {
      let classes = 'scheduled-publications-img-wrap'
      if (media.failed) classes += ' failed'
      if (media.transfering) classes += ' transfering'

      return classes
    },
    getDates() {
      const startIntervalDay = moment(this.schedule.startDay)
      const endIntervalDay = moment(this.schedule.intervalEndAt)
      const diff = endIntervalDay.diff(startIntervalDay, 'days') + 1

      for (let i = 0; i < diff; i++) {
        const day = moment.utc(this.schedule.startDay).add(i, 'days')
        this.columns.push({
          id: i,
          date: day,
          dateStr: day.format(),
          formattedDate: day.format(DATE_FORMAT),
          day: day.format('D'),
          weekend: day.day() === 0 || day.day() === 6,
          today: day.isSame(moment.utc(), 'day')
        })
      }
    },
    formatDate(date, offset) {
      return moment.utc(date).add(offset, 'seconds').format(DATE_FORMAT)
    },
    dateDiff(date) {
      const today = moment.utc().format(DATE_FORMAT)
      return moment.utc(today).diff(moment.utc(date).format(DATE_FORMAT), 'days') > 0
    },
    horizontalScroll(event) {
      const modifier = 2
      const table = this.$refs.scheduledPublicationsTable
      const startPos = table.scrollLeft

      if (event.deltaY !== 0) {
        table.scrollLeft += modifier * event.deltaY
      }

      if (event.deltaX !== 0) {
        table.scrollLeft += modifier * event.deltaX
      }

      if (startPos !== table.scrollLeft) {
        event.preventDefault()
      }
    },
    formatErrors(obj) {
      const details = Object.values(obj.details).map((el) => el[0].message)
      return [obj.message, ...details].join('<br>')
    }
  }
}
</script>
