/* eslint-disable no-undef */
import form from './media'
import { App } from '../../common/rails'

function updateRow(mediaId, data) {
  const $row = $(`tr[data-media-id='${mediaId}']`)
  Object.keys(data).forEach((key) => {
    $row.find(`[data-media-attribute='${key}']`).html(data[key])
  })
}

Styxie.Initializers.GmbMultiLocationMedia = {
  new: form,
  create: form,
  show() {
    $(() => {
      App.cable.subscriptions.create({ channel: 'Gmb::MediaChannel' }, {
        received: (data) => {
          updateRow(data.payload.media_id, data.payload)
        }
      })
    })
  }
}
/* eslint-enable no-undef */
