import axios from 'axios'
import toastr from 'toastr'
import { DEFAULT_ERROR_MESSAGE } from '../common/constants'

Styxie.Initializers.PlacesApis = {
  index: () => {
    $(() => {
      $('.js-enabled').change((e) => {
        const target = e.currentTarget
        axios.put(`/api/places_apis/${target.dataset.placesApiId}`, { places_api: { enabled: target.checked } })
          .then(() => { toastr.success('Key updated') })
          .catch((res) => {
            toastr.error(res.response.data.error || DEFAULT_ERROR_MESSAGE)
            target.checked = !target.checked
          })
      })
    })
  }
}
