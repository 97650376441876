<template>
  <div class="locations-search-terms" v-show="!connection.destroy">
    <div class="d-flex align-items-start gap-2">
      <strong class="color-dark font-weight-semi-bold">
        {{ connection.text }}
        <a :href="`/locations/${connection.orgLocationId}`" target="_blank">
          <i class="far fa-fw fa-arrow-up-right-from-square" />
        </a>
      </strong>
      <span class="locations-search-terms-counter ml-auto">{{ searchTermsChecked.length }}</span>
      <a href="#" class="color-dark px-1" @click.prevent="removeConnection(connection)">
        <i class="far fa-times" />
      </a>
    </div>
    <p v-if="connection.address" class="mb-0">
      <template v-if="connection.locationType !== 'sab'">Address:</template>
      {{ connection.address }}
    </p>
    <p v-if="connection.storeCode" class="mb-0">Store Code: {{ connection.storeCode }}</p>
    <input v-if="connection.id"
      type="hidden"
      name="report_configuration[locations_report_configurations_attributes][][id]"
      :value="connection.id">
    <input type="hidden"
      name="report_configuration[locations_report_configurations_attributes][][remote_location_id]"
      :value="connection.remoteId">
    <input v-if="connection.destroy"
      type="hidden"
      name="report_configuration[locations_report_configurations_attributes][][_destroy]"
      :value="true">

    <template v-if="geogridConfigs.length">
      <transition-expand>
        <div v-show="showList">
          <InputSearch v-model="filter" custom-classes="form-field-sm w-100 w-lg-50 mb-2" />
          <ul class="locations-search-terms-list">
            <li v-for="geogridConfig in geogridConfigs" v-show="visibleGeogridConfigs.has(geogridConfig.obfuscatedId)">
              <p class="mb-0">
                {{ geogridConfig.gridFormatted }}
                <a :href="`/scheduled_geogrids_show?config_id=${geogridConfig.obfuscatedId}`" target="_blank">
                  <i class="far fa-fw fa-arrow-up-right-from-square" />
                </a>
              </p>
              <label v-for="st in geogridConfig.searchTerms" :class="['checkbox-ui gap-2 no-icon', {
                'd-flex': visibleSearchTerms.has(st.obfuscatedId),
                'd-none': !visibleSearchTerms.has(st.obfuscatedId)
              }]">
                <input
                  v-model="st.checked"
                  type="checkbox"
                  :id="`st-${st.obfuscatedId}`"
                  :value="st.obfuscatedId"
                  :name="`report_configuration[locations_report_configurations_attributes][][search_term_ids][]`">
                {{ st.text }}
              </label>
            </li>
          </ul>

          <paginate
            v-if="geogridConfigsPages > 1"
            v-model="page"
            :page-count="geogridConfigsPages"
            container-class="pagination-ui mt-3 p-0"
            page-class="list-unstyled"
            page-link-class="pagination-ui__btn"
            prev-class="list-unstyled"
            prev-link-class="pagination-ui__btn"
            next-class="list-unstyled"
            next-link-class="pagination-ui__btn"
            prev-text="<"
            next-text=">" />
        </div>
      </transition-expand>

      <a href="#" class="d-inline-flex align-items-center gap-2" @click.prevent="updateConnectionShowList(connection)">
        <i :class="`far fa-chevron-${showList ? 'up' : 'down'}`" aria-hidden="true" />
        <span class="font-size-12">{{ showList ? 'Hide' : 'Show' }} Geogrid Configs ({{ connection.geogridConfigs.length }})</span>
      </a>
    </template>
    <span v-else class="font-size-12 text-muted">No Geogrid Configs for this location</span>
  </div>
</template>

<script>
import InputSearch from 'vue_widgets/components/input_search_ui'
import VuejsPaginate from 'vuejs-paginate'
import { mapMutations } from 'vuex'

export default {
  components: { paginate: VuejsPaginate, InputSearch },
  props: ['connection'],
  data() {
    return {
      filter: '',
      page: 1,
      perPage: 10
    }
  },
  computed: {
    searchTermsChecked() {
      return this.allSearchTerms.filter((searchTerm) => searchTerm.checked)
    },
    showList() {
      return this.connection.showList
    },
    geogridConfigs() {
      return this.connection.geogridConfigs
    },
    geogridConfigsWithVisibleSTs() {
      return this.connection.geogridConfigs.reduce((mem, geogridConfig) => {
        if (geogridConfig.searchTerms.some((searchTerm) => this.visibleSearchTerms.has(searchTerm.obfuscatedId))) {
          mem.add(geogridConfig.obfuscatedId)
        }

        return mem
      }, new Set())
    },
    geogridConfigsPages() {
      return Math.ceil(this.geogridConfigsWithVisibleSTs.size / this.perPage)
    },
    visibleGeogridConfigs() {
      const withVisibleSTs = this.geogridConfigsWithVisibleSTs

      const fromOffset = (this.page - 1) * this.perPage
      const toOffset = this.page * this.perPage

      return new Set([...withVisibleSTs].slice(fromOffset, toOffset))
    },
    allSearchTerms() {
      return this.connection.geogridConfigs.reduce((mem, { searchTerms }) => [...mem, ...searchTerms], [])
    },
    visibleSearchTerms() {
      const lowerCasedFilter = this.filter.toLowerCase()

      return this.allSearchTerms.reduce((mem, { obfuscatedId, text }) => {
        if (lowerCasedFilter === '' || text.toLowerCase().includes(lowerCasedFilter)) mem.add(obfuscatedId)

        return mem
      }, new Set())
    }
  },
  watch: {
    filter() {
      this.page = 1
    },
    'connection.destroy'(val) {
      if (val && this.showList) {
        this.updateConnectionShowList(this.connection)
        this.page = 1
      }
    }
  },
  methods: {
    ...mapMutations(['updateConnectionShowList']),
    removeConnection(connection) {
      this.$emit('removeConnection', connection)
    }
  }
}
</script>
