<template>
  <ul class="list-group mb-3">
    <li class="list-group-item list-group-item-primary">
      {{ category.displayName }}
    </li>

    <ServiceItem
      v-for="serviceItem in serviceItems.used"
      :key="serviceItemKey(serviceItem)"
      :service-item="serviceItem" />

    <NewServiceItem :available-service-items="serviceItems.available" :category="category" />
  </ul>
</template>

<script>
import { mapState } from 'vuex'
import ServiceItem from './service_item'
import NewServiceItem from './new_service_item'

export default {
  components: { ServiceItem, NewServiceItem },
  props: {
    category: { type: Object, required: true }
  },
  computed: {
    ...mapState(['serviceList']),
    serviceItems() {
      const { serviceItems } = this.serviceList

      const output = this.category.serviceTypes.reduce((memo, type) => {
        const item = serviceItems.find((el) => el.structuredServiceItem && el.structuredServiceItem.serviceTypeId === type.serviceTypeId)

        if (item) {
          memo.used.push(item)
        } else {
          memo.available.push(type)
        }

        return memo
      }, { used: [], available: []})

      serviceItems.forEach((item) => {
        if (item.freeFormServiceItem && item.freeFormServiceItem.categoryId === this.category.categoryId) output.used.push(item)
      })

      return output
    }
  },
  methods: {
    serviceItemKey({ structuredServiceItem, freeFormServiceItem }) {
      if (structuredServiceItem) return structuredServiceItem.serviceTypeId
      return JSON.stringify(freeFormServiceItem)
    }
  }
}
</script>
