<template>
  <div class="bg-light col-md-12 mt-4 pt-3 pb-1">
    <h4>Advanced</h4>

    <ExifInput v-for="i in inputs" :key="i.identifier" :identifier="i.identifier"
      :label="i.label"
      :recommended="i.recommended" />
  </div>
</template>

<script>
import ExifInput from './exif_input'

export default {
  components: { ExifInput },
  data() {
    return {
      inputs: [
        { identifier: 'artist', label: 'Artist' },
        { identifier: 'ownername', label: 'Owner name' },
        { identifier: 'copyright', label: 'Copyright', recommended: '{{ location.name }}' },
        { identifier: 'make', label: 'Camera Make' },
        { identifier: 'model', label: 'Camera Model' },
        { identifier: 'software', label: 'Software', recommended: Styxie.WLC.brand }
      ]
    }
  }
}
</script>
