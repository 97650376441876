import Highcharts from 'highcharts'
import { lineChartOptions, onChartCompete } from './helpers'

Highcharts.seriesTypes.line.prototype.drawLegendSymbol = Highcharts.seriesTypes.column.prototype.drawLegendSymbol

export default {
  props: {
    historicalData: { type: Array, required: false }
  },
  watch: {
    historicalData(val) {
      const series = val.map((el) => ({
        name: el.name || 'My Location',
        data: el.data.map(([x, y]) => ({ x, y }))
      }))

      this.chart = Highcharts.chart(
        this.$refs['audit-line-chart'],
        {
          ...lineChartOptions,
          series,
          colors: [
            '#377DFF',
            '#A5C4FF',
            '#29BA6C',
            '#94DDB6',
            '#FACA15'
          ]
        },
        onChartCompete
      )
    }
  }
}
