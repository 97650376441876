<template>
  <div class="form-group mb-3 col-6">
    <div class="input-group">
      <div class="input-group-prepend">
        <button class="btn btn-outline-secondary" type="button" @click="removeCustomInput">
          <i class="far fa-trash-alt" />
        </button>
      </div>
      <input type="text" :class="['form-control', { 'is-invalid': isInvalid }]" v-model="description" placeholder="Service Title">
    </div>
    <small :class="['float-right', { 'text-danger': isInvalid }]">{{ description.length }} / {{ maxLength }}</small>
  </div>
</template>

<script>
export default {
  props: {
    maxLength: { type: Number, required: true }
  },
  data() {
    return {
      description: ''
    }
  },
  computed: {
    isInvalid() {
      return this.description.length > this.maxLength
    }
  },
  methods: {
    removeCustomInput() {
      this.$emit('removeCustomInput')
    }
  }
}
</script>
