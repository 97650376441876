<template>
  <div class="moments-card-review" :class="{ selected }" @click="selectReview">
    <div class="moments-card-review__top">
      <div class="moments-card-review__author">
        {{ review.reviewer.displayName }}
      </div>
      <div class="moments-card-review__date">
        {{ formatDate(review.date) }}
      </div>
    </div>
    <div class="moments-card-review__rating">
      <i v-for="index in 5" class="fas fa-star moments-card-review__star"
        :key="index"
        :class="{ 'moments-card-review__star--yellow': index <= review.rating }" />
    </div>
    <p v-if="review.comment" ref="reviewTextWrapper" class="moments-card-review__text" :class="{'moments-card-review__text--full': expanded}">
      <span>{{ review.comment }}</span>
      <button v-if="isOverflowing" @click.stop="expanded = !expanded">
        <span v-if="!expanded" class="text-dark">... </span>
        <span>{{ expanded ? 'Less' : 'More' }}</span>
      </button>
    </p>
    <div v-if="review.reviewReply && Object.keys(review.reviewReply).length" class="moments-card-review__reply">
      <div class="moments-card-review__response-top">
        <p>Response from the owner</p>
        <span class="moments-card-review__date">{{ formatDate(review.reviewReply.updateTime) }}</span>
      </div>
      <p ref="reviewReplyTextWrapper"
        class="moments-card-review__text"
        :class="{'moments-card-review__text--full': expandedReply}">
        <span>{{ review.reviewReply.comment }}</span>
        <button v-if="isOverflowingReply" @click.stop="expandedReply = !expandedReply">
          <span v-if="!expandedReply" class="text-dark">... </span>
          <span>{{ expandedReply ? 'Less' : 'More' }}</span>
        </button>
      </p>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    selected: {
      type: Boolean,
      required: false
    },
    review: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    expanded: false,
    isOverflowing: false,
    expandedReply: false,
    isOverflowingReply: false
  }),
  methods: {
    selectReview() {
      const review = this.selected ? null : this.review

      this.$emit('selectReview', review)
    },
    setOverflowing() {
      if (!this.$refs.reviewTextWrapper) return

      this.isOverflowing = this.$refs.reviewTextWrapper.scrollHeight > this.$refs.reviewTextWrapper.clientHeight
    },
    setOverflowingReply() {
      if (!this.$refs.reviewReplyTextWrapper) return

      this.isOverflowingReply = this.$refs.reviewReplyTextWrapper.scrollHeight > this.$refs.reviewReplyTextWrapper.clientHeight
    },
    formatDate(date) {
      const jsDate = new Date(date)
      return moment(jsDate).format('MMM D, YYYY')
    }

  },
  mounted() {
    setTimeout(() => {
      this.setOverflowing()
      this.setOverflowingReply()
    })
  }
}
</script>
