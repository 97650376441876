import Vue from 'vue'
import camelCaseKeys from 'camelcase-keys-deep'
import employeeIndex from '../../vue_widgets/mobile/employees/index'
import employeeForm from '../../vue_widgets/mobile/employees/form/index'
import renewSubscription from './helpers'

Styxie.Initializers.MobileEmployees = {
  index: (params) => {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-employee-index'),
        render: (h) => h(employeeIndex, { props: camelCaseKeys(params) })
      })
      renewSubscription(params)
    })
  },
  new: (params) => {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-employee-form'),
        render: (h) => h(employeeForm, { props: camelCaseKeys(params) })
      })
      renewSubscription(params)
    })
  },
  edit: (params) => {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-employee-form'),
        render: (h) => h(employeeForm, { props: camelCaseKeys(params) })
      })
      renewSubscription(params)
    })
  }
}
