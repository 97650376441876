import Rails from 'rails-ujs'
import Turbolinks from 'turbolinks'
import ActionCable from 'actioncable'

import { DEFAULT_ERROR_MESSAGE } from './constants'

window.Rails = Rails
Rails.start()
Turbolinks.start()

/* eslint-disable import/prefer-default-export, no-use-before-define */
const App = { cable: ActionCable.createConsumer() }
export { App }
/* eslint-enable import/prefer-default-export, no-use-before-define */

export function collectErrors(hsh) {
  let text
  try {
    const errors = Object.keys(hsh).reduce((memo, key) => {
      memo.push(`${key}: ${hsh[key].join(', ')}`)
      return memo
    }, [])
    text = errors.join('\n')
  } catch (err) { // eslint-disable-line no-unused-vars
    text = DEFAULT_ERROR_MESSAGE
  }

  return text
}
