import Vue from 'vue'
import AuditReportConfigurationsIndex from '../vue_widgets/audit_report_configurations/index'
import AuditReportConfigurationsForm from '../vue_widgets/audit_report_configurations/form'
import createStore from '../vue_widgets/audit_report_configurations/store'

const auditForm = (props) => {
  $(() => {
    new Vue({ // eslint-disable-line no-new
      el: document.getElementById('vue-audit-report-configurations-form'),
      store: createStore(props),
      render: (h) => h(AuditReportConfigurationsForm, { props })
    })
  })
}

Styxie.Initializers.AuditReportConfigurations = {
  index: (props) => {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-audit-report-configurations-index'),
        render: (h) => h(AuditReportConfigurationsIndex, { props })
      })
    })
  },
  new: auditForm,
  edit: auditForm
}
