<template>
  <div class="range-date">
    <rangePreset :preset="preset" @setPreset="setPreset" />
    <div class="range-custom">
      <DatePicker
        mode="range"
        :max-date="maxDate"
        :min-date="minDate"
        v-model="range1"
        @input="setDateRanges" />
      <DatePicker v-if="insightsDateRanges.compare"
        mode="range"
        :max-date="maxDate"
        :min-date="minDate"
        v-model="range2"
        @input="setDateRanges" />
    </div>
    <checkbox
      :value="insightsDateRanges.compare"
      @input="onChange">
      Compare
    </checkbox>
    <div class="d-flex flex-column align-items-start gap-2 export-csv">
      <DropdownAction
        :actions="exportActions"
        :options="{
          isHeadShown: false,
          isCommonAction: true
        }"
        @saveSettings="exportCSV"
        title="Export" />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import rangePreset from './range_preset'
import DatePicker from '../../../components/datepicker'
import checkbox from '../../../components/checkbox'
import DropdownAction from '../../../components/dropdown_action'

export default {
  components: {
    checkbox,
    rangePreset,
    DatePicker,
    DropdownAction
  },
  data() {
    return {
      preset: 'month',
      range1: null,
      range2: null
    }
  },
  computed: {
    ...mapGetters([
      'insightsDateRanges',
      'csvPath',
      'csvTitle',
      'deprecatedInsightsCsvPath',
      'deprecatedInsightsCsvTitle',
      'minDate'
    ]),
    maxDate() {
      return moment.utc()
        .subtract(3, 'days')
        .toDate()
    },
    exportActions() {
      return {
        type: 'links',
        actions: [
          {
            label: 'Export Performance Metrics to CSV',
            href: this.csvPath,
            iconClass: 'far fa-file-csv'
          },
          {
            label: 'Export Deprecated Insights to CSV',
            href: this.deprecatedInsightsCsvPath,
            iconClass: 'far fa-file-csv'
          }
        ]
      }
    }
  },
  methods: {
    ...mapActions(['updateDateRanges']),
    onChange(value) {
      this.updateDateRanges({
        compare: value,
        ranges: this.insightsDateRanges.ranges
      })
    },
    setPreset({ preset, ranges }) {
      this.preset = preset
      this.range1 = [ranges[0].start, ranges[0].end]
      this.range2 = [ranges[1].start, ranges[1].end]
      this.updateDateRanges({
        compare: this.insightsDateRanges.compare,
        ranges
      })
    },
    setDateRanges() {
      this.preset = 'custom'
      this.updateDateRanges({
        compare: this.insightsDateRanges.compare,
        ranges: [{
          start: this.range1[0],
          end: this.range1[1]
        }, {
          start: this.range2[0],
          end: this.range2[1]
        }]
      })
    },
    exportCSV(href) {
      window.open(href, '_blank')
    }
  }
}
</script>
