<template>
  <div class="mb-5">
    <Schedule v-model="scheduleAttrs">
      <template #description>
        <p class="m-0 mb-2 text-muted font-size-14">
          Configure reports frequency and day of the week it'll send.
        </p>
      </template>
    </Schedule>
    <input type="hidden" name="report_configuration[schedule_attributes][periodicity]" :value="scheduleAttrs.periodicity">
    <input type="hidden" name="report_configuration[schedule_attributes][day_number]" :value="scheduleAttrs.dayNumber">
    <input type="hidden" name="report_configuration[schedule_attributes][week_number]" :value="scheduleAttrs.weekNumber">
    <input type="hidden" name="report_configuration[schedule_attributes][month_day]" :value="scheduleAttrs.monthDay">
  </div>
</template>

<script>
import Schedule from 'vue_widgets/audit_report_configurations/form/schedule'

export default {
  components: { Schedule },
  props: {
    schedule: { type: Object, required: true }
  },
  data() {
    return {
      scheduleAttrs: this.schedule
    }
  }
}
</script>
