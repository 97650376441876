<template>
  <div>
    <div class="d-flex align-items-center justify-content-between mb-4">
      <InputSearch v-model.trim="searchVal" custom-classes="form-field-md" />
      <div class="d-flex align-items-center">
        <button v-for="viewType in viewTypes" :key="viewType"
          :class="['btn btn-outline-grey btn-sm p-2 ml-2 rounded', { 'border-transparent text-primary': viewType === selectedView }]"
          @click="setView(viewType)">
          <i :class="`far fa-${viewTypeIcons[viewType]} m-0`" />
        </button>
      </div>
    </div>

    <component
      :is="selectedView"
      :key="selectedView"
      :get-business-link="getBusinessLink"
      :get-businesses="getBusinesses"
      :transform-location-data="transformLocationData"
      :search="searchVal" />
  </div>
</template>

<script>
import InputSearch from '../../components/input_search_ui'
import TableView from './index/table_view'
import CardView from './index/card_view'
import ListView from './index/list_view'

export default {
  components: {
    InputSearch,
    TableView,
    CardView,
    ListView
  },
  data() {
    return {
      searchVal: '',
      searchTimeout: null,
      selectedView: 'table-view'
    }
  },
  created() {
    this.selectedView = localStorage.getItem('mv-businesses-view') || 'table-view'
    this.viewTypes = ['table-view', 'list-view', 'card-view']
    this.viewTypeIcons = {
      'table-view': 'table',
      'list-view': 'list',
      'card-view': 'grip-horizontal'
    }
  },
  methods: {
    setView(view) {
      this.selectedView = view
      localStorage.setItem('mv-businesses-view', view)
    },
    getBusinesses(prms = {}) {
      return axios.get(
        '/gb/ajax/businesses',
        {
          params: {
            order: prms.order,
            search: this.searchVal
          },
          paramsSerializer(json) { return qs.stringify(json, { arrayFormat: 'brackets' }) }
        }
      ).then((res) => res.data)
    },
    getBusinessLink(id) {
      return `${window.location.pathname}/${id}`
    },
    transformLocationData(locationData) {
      return Object.values(locationData).filter((val) => Boolean(val)).join(', ')
    }
  }
}
</script>
