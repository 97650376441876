<template>
  <div class='d-flex flex-wrap gap-2 mb-2'>
    <div v-if='inputShown' class='tags-input form-field' id='tags-input'>
      <input
        ref='input'
        v-model='newTag'
        @keyup.enter='addTag(newTag)'
        @keyup.down='changeSelectedTag(1)'
        @keyup.up='changeSelectedTag(-1)'
        @keyup.esc='inputShown = false'
        class='mb-2'
        placeholder='New tag'>
      <div v-show='trimedNewTag && noTagSelected' class='tag active' @click='addTag(newTag)'>
        <span>{{ trimedNewTag }}</span>
        <i>↵</i>
      </div>
      <div
        v-for='(tag, idx) in suggestedTags'
        v-show='(selectedTagIndex === idx) || (tag != trimedNewTag)'
        :key='idx'
        :class="['tag', {active: selectedTagIndex === idx}]"
        @click='addTag(tag)'>
        <span>{{ tag }}</span>
        <i>↵</i>
      </div>
    </div>
    <div v-if='showEditButtons' class='tag' id='tags-button' @click='toggleInput'>
      <div class='init-button'>
        <span class='triange' />
        <i class='fa-circle far' aria-hidden='true' />
        <span>Tags</span>
      </div>
    </div>
    <div v-for='tag in tags' :key='tag' class='tag'>
      <div class='tag-text' @click='addTagToFilter(tag)' :class='{ clickable: searchableTags }'>
        <span>{{ tag }}</span>
      </div>
      <div @click='removeTag(tag)' v-if='showEditButtons' class='tag-icon'>
        <i class='far fa-times' />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Tags',
  props: {
    addTagPath: String,
    removeTagPath: String,
    searchableTags: {
      type: Boolean,
      default: false
    },
    showEditButtons: {
      type: Boolean,
      default: false
    },
    initialTags: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tags: [],
      newTag: '',
      selectedTagIndex: -1,
      inputShown: false,
      suggestedTags: []
    }
  },
  watch: {
    newTag(val) {
      if (val.length === 0) {
        this.selectedTagIndex = -1
      }
    },
    initialTags(val) {
      if (val) this.tags = val
    }
  },
  computed: {
    trimedNewTag() {
      return this.newTag.replace(/\s+/g, ' ').trim()
    },
    noTagSelected() {
      return this.selectedTagIndex === -1
    }
  },
  mounted() {
    this.tags = this.initialTags
  },
  methods: {
    renderTags(tags) {
      this.tags = tags
      this.suggestedTags = []
      if (this.$store.state.defaultTags) {
        this.$store.state.defaultTags.forEach((tag) => {
          if (this.tags.indexOf(tag) === -1) this.suggestedTags.push(tag)
        })
      }
    },
    addTagToFilter(tag) {
      if (this.searchableTags) {
        this.$emit('addTagToFilter', tag)
      }
    },
    removeTag(tagText) {
      const index = this.tags.indexOf(tagText)
      if (index === -1) return
      if (this.removeTagPath) {
        axios.delete(this.removeTagPath, { data: { tag: tagText } }).then(() => {
          this.tags.splice(index, 1)
          this.suggestedTags.push(tagText)
          this.$emit('updateRowWithNewTags', this.tags)
        })
      } else {
        this.tags.splice(index, 1)
        this.suggestedTags.push(tagText)
        this.$emit('updateRowWithNewTags', this.tags)
      }
    },
    changeSelectedTag(val) {
      if ((this.selectedTagIndex + val > -1) && (this.selectedTagIndex + val < this.suggestedTags.length)) {
        this.selectedTagIndex += val
        this.newTag = this.suggestedTags[this.selectedTagIndex]
      }
    },
    addTag(newTag) {
      const tag = newTag.replace(/\s+/g, ' ').trim()
      if (!tag || this.tags.indexOf(tag) !== -1) return
      if (this.addTagPath) {
        axios.post(this.addTagPath, { tag: tag }).then(() => {
          this.pushNewTag(tag)
          this.$emit('updateRowWithNewTags', this.tags)
        })
      } else {
        this.pushNewTag(tag)
      }
    },
    pushNewTag(tag) {
      this.tags.push(tag)
      this.newTag = ''
      this.selectedTagIndex = -1
      const index = this.suggestedTags.indexOf(tag)
      if (index !== -1) this.suggestedTags.splice(index, 1)
    },
    clickListener(el) {
      if (!el.target.closest('#tags-input') && !el.target.closest('#tags-button')) {
        this.inputShown = false
        document.removeEventListener('click', this.clickListener)
      }
    },
    toggleInput() {
      this.inputShown = !this.inputShown
      if (this.inputShown) {
        this.$nextTick(() => { this.$refs.input.focus() })
        document.addEventListener('click', this.clickListener)
      }
    }
  }
}
</script>
