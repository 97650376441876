import Vue from 'vue'
import VueProgressiveImage from 'vue-progressive-image'
import VueSlimTable from 'vue-slim-tables/src/js'
import { BButton, BTabs, BTab } from 'bootstrap-vue'

import TransitionExpand from './components/transition_expand'

Vue.use(VueProgressiveImage)
Vue.component('BButton', BButton)
Vue.component('BTabs', BTabs)
Vue.component('BTab', BTab)
Vue.component('TransitionExpand', TransitionExpand)
Vue.component('VueSlimTable', VueSlimTable)
