import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { DEFAULT_ERROR_MESSAGE } from '../../../common/constants'

Vue.use(Vuex)

export default function createStore(params) {
  return new Vuex.Store({
    state: {
      exampleLink: '',
      googleMapApiKey: params.googleMapApiKey,
      queryParams: params.queryParams,
      ...params.keywordsCommon
    },
    getters: {
      domain(state) { return state.includePath ? state.domainWithPath : state.domainWithoutPath }
    },
    actions: {
      async updateSettings({ state, commit }, options) {
        const { includePath, defaultCheckFrequency, searchLanguage } = options
        const url = state.apiOrgLocationPath
        const data = {
          organizations_location: {
            keywords_include_path: includePath,
            keywords_default_check_frequency: defaultCheckFrequency,
            keywords_search_language: searchLanguage,
            keywords_custom_search_location: state.formattedAddress,
            keywords_search_location_lat: state.lat,
            keywords_search_location_lng: state.lng
          }
        }
        await axios.patch(url, data)
          .then(() => {
            commit('updateSettings', options)
            window.location.reload()
          })
          .catch(() => {
            toastr.error(DEFAULT_ERROR_MESSAGE)
          })
      }
    },
    mutations: {
      updateSettings(state, { includePath, defaultCheckFrequency }) {
        state.includePath = includePath
        state.defaultCheckFrequency = defaultCheckFrequency
      },
      updateAddressComponents(state, payload) {
        if (payload) {
          state.formattedAddress = payload.formattedAddress
          state.lat = payload.lat
          state.lng = payload.lng
        }
      },
      generateExampleLink(state) {
        if (state.formattedAddress) {
          state.exampleLink = `https://${state.region}/maps/place/${state.formattedAddress.split(' ').join('+')}`
        } else {
          state.exampleLink = ''
        }
      }
    }
  })
}
