import Vue from 'vue'
import Index from '../vue_widgets/gallery/index'
import createStore from '../vue_widgets/gallery/store'

Styxie.Initializers.Galleries = {
  show(params) {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-gallery-index'),
        store: createStore(params),
        render: (h) => h(Index, { props: params })
      })
    })
  }
}
