<template>
  <div class='d-sm-inline-flex w-100'>
    <div class='mt-2'>
      <b-form-radio
        v-for='opt in placesDataSourceOptions'
        :value='opt.value'
        class='custom-control-inline'
        v-model='placesDataSource'
        :disabled='opt.disabled'
        stacked
        :ref='`${select2prefix}-${opt.value}`'
        :key='opt.value'>
        {{ opt.text }}
      </b-form-radio>
      <b-popover
        id='key-popover'
        v-if='placesApiKeys.length === 0'
        placement='bottom'
        :target='popoverTarget'
        triggers='click hover'>
        You <span class='text-danger'>don't have </span> any <strong> Places API Keys.</strong>
        Please <a :href='newKeyPath'> add </a> and
        <a :href='keysPath'> enable </a> at least one key to get started.
      </b-popover>
    </div>
    <div>
      <div v-show="placesDataSource === 'places_api'">
        <div class='geogrids' :class='{ danger: !selectedKey.enabled }'>
          <select :id='`${select2prefix}-places-api`' />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DataEngine',
  props: {
    newKeyPath: String,
    keysPath: String,
    placesDataSourceOptions: Array,
    placesApiKeysDefault: Array,
    select2prefix: String
  },
  data() {
    return {
      placesDataSource: 'places_api',
      selectedKey: { enabled: true },
      popoverTarget: '',
      placesApiKeys: []
    }
  },
  watch: {
    placesApiKeys() {
      if ((this.select2prefix === 'edit') || (this.placesApiKeys.some((key) => key.enabled))) {
        this.placesDataSourceOptions[0].disabled = false // eslint-disable-line vue/no-mutating-props
        this.placesDataSource = this.placesDataSourceOptions[0].value
      } else {
        this.placesDataSourceOptions[0].disabled = true // eslint-disable-line vue/no-mutating-props
        this.placesDataSource = this.placesDataSourceOptions[1].value
      }
    }
  },
  mounted() {
    this.placesApiKeys = this.placesApiKeysDefault
    this.buildSelect2(this.placesApiKeys)
    $(`#${this.select2prefix}-places-api`).on('select2:selecting', (val) => {
      this.selectedKey = val.params.args.data
    })

    this.popoverTarget = this.$refs[`${this.select2prefix}-places_api`][0].$el
  },
  methods: {
    renderForm(attributes) {
      if (attributes.placesDataSource === 'places_api') {
        this.$nextTick(() => {
          this.placesDataSource = 'places_api'
          this.placesDataSourceOptions[0].disabled = false // eslint-disable-line vue/no-mutating-props
        })
        if (attributes.placesApiId) {
          this.selectedKey = this.placesApiKeys.find((elem) => elem.id === attributes.placesApiId)
          $(`#${this.select2prefix}-places-api`).val(this.selectedKey.id).trigger('change')
        } else {
          this.buildSelect2(this.placesApiKeys, '- SELECTED KEY HAS BEEN DELETED -')
          $(`#${this.select2prefix}-places-api`).val(-1).trigger('change')
          this.selectedKey = { enabled: false }
        }
      } else {
        this.placesDataSource = 'crawl'
        this.selectedKey = this.placesApiKeys.find((elem) => elem.enabled) || { enabled: false }
        if (this.placesApiKeys.find((elem) => elem.enabled)) {
          $(`#${this.select2prefix}-places-api`).val(this.selectedKey.id).trigger('change')
        } else {
          this.placesDataSourceOptions[0].disabled = true // eslint-disable-line vue/no-mutating-props
        }
      }
    },
    disablePlaceApiKey(brokenKeyId) {
      const keyWithError = this.placesApiKeys.find((v) => {
        if (v.id === brokenKeyId) v.enabled = false
        return v.id === brokenKeyId
      })
      this.selectedKey = keyWithError
      this.buildSelect2(this.placesApiKeys)
      return keyWithError.text
    },
    getDataEngine() {
      if (this.placesDataSource === 'crawl') {
        return {
          placesDataSource: 'crawl'
        }
      } if (this.selectedKey.enabled) {
        return {
          placesApiId: $(`#${this.select2prefix}-places-api`).val(),
          placesDataSource: 'places_api'
        }
      }
      return { dataEngineFailed: true }
    },
    buildSelect2(keys, placeholder = null) {
      $(`#${this.select2prefix}-places-api`).select2({
        data: keys,
        minimumResultsForSearch: Infinity,
        width: '100%',
        allowEmpty: false,
        templateResult: this.buildSelect2Key,
        placeholder: {
          id: -1,
          text: placeholder
        }
      })
      keys.forEach((key) => {
        if (!key.enabled) {
          $(`#${this.select2prefix}-places-api option[value='${key.id}']`).prop('disabled', true)
        }
      })
      this.selectedKey = this.placesApiKeys.find((elem) => elem.enabled) || { enabled: false }
      if (!(this.placesApiKeys.some((key) => key.enabled))) {
        this.placesDataSourceOptions[0].disabled = true // eslint-disable-line vue/no-mutating-props
        this.placesDataSource = this.placesDataSourceOptions[1].value
      }
    },
    buildSelect2Key(item) {
      if (item.enabled) {
        return $(`<span> ${item.text} </span>`)
      } if (item.id === 0) {
        return $(`<span class='text-danger'> ${item.text} </span>`)
      }
      return $(`<span class='text-danger'> ${item.text} <small><i> (disabled key) </i></small></span>`)
    }
  }
}
</script>
