<template>
  <div class="widget">
    <div class="d-flex justify-content-between">
      <strong class="caption">Business contacts</strong>
      <ChartSwitcher
        v-if="!breakdownByMonths"
        v-model="businessChartType"
        :options="chartTypeOptions"
        name="businessContacts" />
    </div>
    <p class="mb-3 mt-2">
      Hover the mouse pointer over the bar chart to see exact values and deltas
    </p>
    <div class="d-flex flex-column gap-8" v-if="breakdownByMonths">
      <ChartBreakdown chart-id="business_conversations" chart-color="#377dff" :no-labels="true" />
      <ChartBreakdown chart-id="business_direction_requests" chart-color="#29BA6C" :no-labels="true" />
      <ChartBreakdown chart-id="call_clicks" chart-color="#F9AE34" :no-labels="true" />
      <ChartBreakdown chart-id="website_clicks" chart-color="#B04EDE" :no-labels="true" />
    </div>
    <template v-else>
      <ChartColumn
        v-show="businessChartType === 'column'"
        chart-type="column"
        :chart-height="300"
        :chart-ids="businessChart.ids"
        :chart-colors="businessChart.colors" />
      <ChartLines
        v-show="businessChartType === 'line'"
        :chart-ids="businessChart.ids"
        :chart-colors="businessChart.colors" />
    </template>
  </div>
</template>

<script>
import ChartSwitcher from './chart_switcher'
import ChartLines from './chart_lines'
import ChartColumn from './chart_column'
import ChartBreakdown from './chart_breakdown'

export default {
  components: {
    ChartSwitcher,
    ChartLines,
    ChartColumn,
    ChartBreakdown
  },
  props: {
    breakdownByMonths: { type: Boolean, default: false }
  },
  data() {
    return {
      businessChartType: 'column'
    }
  },
  created() {
    this.chartTypeOptions = [{
      value: 'column',
      iconClasses: 'far fa-chart-bar'
    }, {
      value: 'line',
      iconClasses: 'far fa-chart-line'
    }]

    this.businessChart = {
      ids: ['call_clicks', 'website_clicks', 'business_direction_requests', 'business_conversations'],
      colors: ['#377dff', '#29BA6C', '#F9AE34', '#B04EDE']
    }
  }
}
</script>
