<template>
  <div class="filter">
    <div class="form-field">
      <multiselect
        v-model="byState"
        :allow-empty="false"
        :options="byStateOptions"
        :searchable="true"
        :show-labels="false"
        :max-height="540"
        @input="collectFilter"
        label="label"
        placeholder="Search"
        track-by="value" />
    </div>
    <div class="form-field">
      <multiselect
        v-model="byCreationDate"
        :allow-empty="false"
        :options="byCreationDateOptions"
        :searchable="true"
        :show-labels="false"
        :max-height="540"
        @input="collectFilter"
        label="label"
        placeholder="Search"
        track-by="value" />
    </div>

    <div class="search-field">
      <InputSearch v-model.trim="search" placeholder="Business, search term, client…" />
    </div>
  </div>
</template>

<script>
import snakeCaseKeys from 'snakecase-keys'
import Multiselect from 'vue-multiselect'
import InputSearch from '../../components/input_search_ui'

const BY_STATE_OPTIONS = [
  { label: 'All statuses', value: '' },
  { label: 'In progress', value: 'processing' },
  { label: 'Done', value: 'finished' },
  { label: 'Error', value: 'error' }
]

const BY_CREATION_DATE_OPTIONS = [
  { label: 'Newest', value: 'desc' },
  { label: 'Oldest', value: 'asc' }
]

export default {
  components: { Multiselect, InputSearch },
  data() {
    return {
      search: '',
      searchTimeout: null,
      byState: BY_STATE_OPTIONS[0],
      byCreationDate: BY_CREATION_DATE_OPTIONS[0]
    }
  },
  watch: {
    search() {
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        this.collectFilter()
      }, 500)
    }
  },
  methods: {
    collectFilter() {
      const filters = ['byState', 'byCreationDate'].reduce((memo, key) => {
        if (this[key].value) memo[key] = this[key].value
        return memo
      }, {})
      if (this.search) filters.search = this.search
      this.$emit('applyFilter', snakeCaseKeys(filters))
    }
  },
  created() {
    this.byStateOptions = BY_STATE_OPTIONS
    this.byCreationDateOptions = BY_CREATION_DATE_OPTIONS
  }
}
</script>
