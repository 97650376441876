<template>
  <div>
    <input type="search" v-model="search" placeholder="search"
      class="form-control float-right mb-3"
      style="width: auto;">
    <VueSlimTable
      ref="table"
      :source="tableSource"
      :columns="columns"
      class="table"
      :per-page="25">
      <template #cell:contact="{ row }">
        <p class="mb-0">
          <b>Email:</b> {{ row.contactEmail }}
        </p>
        <p class="mb-0">
          <b>Phone:</b> {{ row.contactPhone }}
        </p>
      </template>

      <template #cell:agency="{ value }">
        {{ value ? 'Yes' : 'No' }}
      </template>

      <template #cell:user="{ value }">
        <a :href="`mailto:${value.email}`">{{ value.email }}</a>
      </template>
    </VueSlimTable>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: ''
    }
  },
  methods: {
    tableSource(params) {
      return axios.get(
        '/api/admin/private_instance_requests',
        { params: { ...params, search: this.search } }
      ).then((res) => res.data)
    }
  },
  watch: {
    search() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(this.$refs.table.refetch, 500)
    }
  },
  created() {
    this.columns = [
      { title: 'Contact', key: 'contact' },
      { title: 'Agency', key: 'agency' },
      { title: 'User', key: 'user' },
      { title: 'Created At', key: 'createdAt' }
    ]
  }
}
</script>
