<template>
  <div :class="['chart-contaner', chartDirection]">
    <div class="chart" ref="chart" :style="{height: `${chartHeight}px`}">CHART</div>
    <div class="chart-legend">
      <ChartLegeng v-for="(legend, index) in legends" :key="index"
        :color="legend.color"
        :name="legend.name"
        :values="legend.values"
        :percentage="legend.percentage"
        :compare="compare" />
    </div>
  </div>
</template>

<script>
import camelCaseKeys from 'camelcase-keys-deep'
import { mapGetters } from 'vuex'
import Highcharts from 'highcharts'
import { humanizeRangeDate } from '../../../../common/datepicker'
import {
  getValues, getPercentage, chartOptionsDefault, compileTooltip
} from './helpers'
import ChartLegeng from './chart_legend'

export default {
  components: { ChartLegeng },
  props: {
    chartType: {
      type: String,
      default: 'column'
    },
    chartIds: {
      type: Array,
      required: true
    },
    chartDirection: {
      type: String,
      default: ''
    },
    chartHeight: {
      type: Number,
      default: 300
    },
    chartColors: {
      type: Array,
      default() { return [] }
    }
  },
  data() {
    return {
      legends: []
    }
  },
  mounted() {
    if (this.stats && this.stats.length) {
      this.initChart(this.stats)
    }
  },
  methods: {
    initChart(data) {
      if (this.chart) this.chart.destroy()

      const { ranges } = this.insightsDateRanges
      let subtitle = humanizeRangeDate(ranges[0])
      if (this.compare) subtitle += ` vs ${humanizeRangeDate(ranges[1])}`

      const legends = []
      const columnChart = [
        {
          name: ranges[0],
          data: []
        },
        {
          name: ranges[1],
          data: [],
          pointPlacement: -0.01
        }
      ]
      let indexColor = 0
      data.forEach((element) => {
        if (!this.chartIds.includes(element.id)) return
        legends.push({
          name: element.name,
          color: this.chartColors[indexColor],
          values: getValues(element, this.compare),
          percentage: getPercentage(camelCaseKeys(element))
        })

        columnChart[0].data.push({
          name: element.name,
          y: element.sum,
          ranges: ranges[0],
          color: this.chartColors[indexColor]
        })

        columnChart[1].data.push({
          name: element.name,
          y: element.sum_compare,
          ranges: ranges[1],
          color: `${this.chartColors[indexColor]}80`
        })
        indexColor++
      })

      this.legends = legends

      if (!this.compare) {
        columnChart.splice(1, 1)
      }

      const tooltip = compileTooltip(true)
      this.chart = Highcharts.chart(this.$refs.chart, {
        ...chartOptionsDefault({
          subtitle,
          chartType: this.chartType,
          chartHeight: this.chartHeight
        }),
        plotOptions: {
          column: {
            states: {
              inactive: { opacity: 1 },
              hover: { enabled: false }
            },
            groupPadding: 0.1
          },
          series: {
            pointWidth: this.compare ? 28 : 56
          }
        },
        xAxis: {
          visible: false,
          min: -1,
          max: 3
        },
        series: columnChart,
        tooltip: tooltip
      })
    }
  },
  computed: mapGetters(['insightsDateRanges', 'stats', 'compare']),
  watch: {
    stats: 'initChart'
  }
}
</script>
