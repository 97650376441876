<template>
  <div>
    <h2 class="mb-2 font-weight-semi-bold">Local service areas</h2>
    <AuditDescription class="mb-4">
      The geographic areas where any given business is willing to offer its services.
    </AuditDescription>
    <div class="service-areas">
      <div class="service-areas__wrapper">
        <div class="service-areas__graph">
          <div
            v-for="graphPart in graphData"
            :key="graphPart.color"
            :class="['service-areas__part', `service-areas__part--${graphPart.color}`]"
            :style="{ width: `${graphPart.value / uniqueLocationsCount * 100}%` }">
            <span>{{ graphPart.value }}</span>
          </div>
        </div>
        <div class="competitors-chart-legend">
          <div v-for="point in graphData" :key="`legend-${point.color}`" class="competitors-chart-legend-item">
            <div :class="['point', point.color]" />
            {{ point.title }}
          </div>
        </div>
      </div>
      <div class="d-flex flex-column flex-hd-row justify-content-between gap-2 mt-auto mb-3">
        <div class="d-flex gap-2 w-100">
          <VueSlimTable
            :source="() => tableRows"
            :columns="columns"
            class="service-areas__table responsive-table borderless-table audit-table">
            <template #row="{ row, columns }">
              <tr>
                <td :column-caption="columns[0].title"
                  class="text-lg-left"
                  :class="{'text-grey': !row.name }">
                  {{ row.name || 'N/A' }}
                </td>
                <td :column-caption="columns[1].title"
                  :class="{'text-grey': row.serviceAreas?.length === 0 }">
                  <div class="service-areas__list">
                    <span v-for="(area, index) in row.serviceAreas"
                      class="service-areas__item"
                      :class="area.color ? `service-areas__item--${area.color}` : 'service-areas__item--grey'"
                      :key="`areas-${index}`">
                      {{ area.value }}
                    </span>
                    <span v-if="!row.serviceAreas?.length">N/A</span>
                  </div>
                </td>
              </tr>
            </template>
          </VueSlimTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sectionData: { type: Object, required: true }
  },
  created() {
    this.columns = [
      { key: 'name', title: 'Competitors', orderable: false },
      { key: 'serviceAreas', title: 'Locations', orderable: false }
    ]

    const allLocations = [
      this.sectionData.current,
      ...this.sectionData.competitors
    ]

    const allServiceAreas = allLocations.reduce((acc, area) => acc.concat(area.serviceAreas), [])

    const serviceAreaOccurenceMap = new Map()

    allServiceAreas.forEach((location) => {
      const currentLocationCount = serviceAreaOccurenceMap.get(location)

      if (currentLocationCount) {
        serviceAreaOccurenceMap.set(location, currentLocationCount + 1)
      } else {
        serviceAreaOccurenceMap.set(location, 1)
      }
    })

    const mainLocationAreas = new Set(this.sectionData.current.serviceAreas)

    const mainLocationMatch = []
    const mainLocationUnique = []
    const competitorsUnique = []
    const competitorsMatch = []

    this.sectionData.current.serviceAreas.forEach((serviceArea) => {
      const isMatching = serviceAreaOccurenceMap.get(serviceArea) > 1

      if (isMatching) {
        mainLocationMatch.push(serviceArea)
      } else {
        mainLocationUnique.push(serviceArea)
      }
    })

    this.tableRows = [{
      name: 'My location',
      serviceAreas: mainLocationMatch.map((area) => ({ value: area, color: 'green' }))
        .concat(mainLocationUnique
          .map((area) => ({ value: area })))
    }]

    this.sectionData.competitors.forEach((location) => {
      const locationMatchedServiceAreas = []
      const competitorMatchedServiceAreas = []
      const uniqueServiceAreas = []

      location.serviceAreas.forEach((serviceArea) => {
        const isMatching = serviceAreaOccurenceMap.get(serviceArea) > 1
        const isMainLocationArea = mainLocationAreas.has(serviceArea)

        if (!isMatching) {
          uniqueServiceAreas.push(serviceArea)
          return
        }

        if (isMainLocationArea) {
          locationMatchedServiceAreas.push(serviceArea)
        } else {
          competitorMatchedServiceAreas.push(serviceArea)
        }
      })

      competitorsMatch.push(...competitorMatchedServiceAreas)
      competitorsUnique.push(...uniqueServiceAreas)

      this.tableRows.push({
        name: location.name,
        // better than sort i guess?
        serviceAreas: locationMatchedServiceAreas.map((area) => ({ value: area, color: 'green' }))
          .concat(competitorMatchedServiceAreas
            .map((area) => ({ value: area, color: 'blue' })))
          .concat(uniqueServiceAreas
            .map((area) => ({ value: area })))
      })
    })

    this.graphData = [
      {
        title: 'Matched with my location',
        color: 'green',
        value: new Set(mainLocationMatch).size
      },
      {
        title: 'My location only',
        color: 'green-light',
        value: new Set(mainLocationUnique).size
      },
      {
        title: 'Competitors matched',
        color: 'blue',
        value: new Set(competitorsMatch).size
      },
      {
        title: 'One competitor only',
        color: 'blue-light',
        value: new Set(competitorsUnique).size
      }
    ].filter((item) => item.value !== 0)

    this.uniqueLocationsCount = this.graphData.reduce((acc, part) => acc + part.value, 0)
  }
}
</script>
