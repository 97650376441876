<template>
  <div class="main-wrapper sidebar-layout">
    <div class="layout-part-sidebar">
      <Sidebar />
    </div>
    <div class="layout-part-content p-0">
      <template v-if="!isDataLoading">
        <ReportHead />
        <MainProperties />

        <div class="reports-content">
          <ViewsBreakdown class="mb-5" />
          <DeviceBreakdown class="mb-5" />
          <LineChart data-type="reviews" class="mb-5">Total Review Count</LineChart>
          <LineChart data-type="local_posts" class="mb-5">Local Posts</LineChart>
          <ImagesCount class="mb-5" />
          <LineChart data-type="questions" class="mb-5">Questions</LineChart>
          <LineChart data-type="answered_questions" class="mb-5">Answered questions</LineChart>
          <SearchKeyword class="mb-5" />
          <MapsSearchGeogrids />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import Sidebar from './sidebar'
import ReportHead from './report_head'
import MainProperties from './main_properties'
import ViewsBreakdown from './views_breakdown'
import DeviceBreakdown from './device_breakdown'
import ImagesCount from './images_count'
import SearchKeyword from './search_keyword'
import MapsSearchGeogrids from './maps_search_geogrids'
import LineChart from './line_chart'

export default {
  components: {
    Sidebar,
    ReportHead,
    MainProperties,
    ViewsBreakdown,
    DeviceBreakdown,
    ImagesCount,
    SearchKeyword,
    MapsSearchGeogrids,
    LineChart
  },
  created() {
    this.fetchInsights()
  },
  computed: mapState(['isDataLoading']),
  methods: mapActions(['fetchInsights'])
}
</script>
