import Vuex from 'vuex'
import axios from 'axios'
import Turbolinks from 'turbolinks'
import axiosTransform from 'common/axios'
import { isEmpty } from 'common/helpers'
import { DEFAULT_ERROR_MESSAGE } from 'common/constants'

const isCoordsExist = (coords) => (coords?.lat && coords?.lng)

const isLocationDisabled = (location) => !(isCoordsExist(location.coords) && location.cid && location.placeId)

export default function createStore(prms) {
  return new Vuex.Store({
    state: {
      configuration: {},
      locationsList: [],
      whiteLabelList: [],
      competitorsList: [],
      isRequested: false,
      error: {},
      organizationLinda: false,
      ...prms
    },
    getters: {
    },
    mutations: {
      setState(state, payload) {
        state[payload.key] = payload.value
      },
      setConfigurationState(state, payload) {
        state.configuration = {
          ...state.configuration,
          ...payload
        }
      }
    },
    actions: {
      getLocationList({ state, commit }, params = {}) {
        return axios.get(state.apiLocationsPath, {
          params,
          ...axiosTransform
        }).then((res) => {
          let locationsList = res.data.rows.map((l) => ({ ...l, $isDisabled: isLocationDisabled(l), hasCoords: isCoordsExist(l.coords) }))
          if (params.page && params.page !== 1) {
            locationsList = [...state.locationsList, ...locationsList]
          }
          commit('setState', { key: 'locationsList', value: locationsList })
        })
      },
      getWhiteLabelList({ state, commit }, params = {}) {
        return axios.get(state.apiWhiteLabelsPath, {
          params,
          ...axiosTransform
        }).then((res) => {
          const whiteLabelList = res.data.map((w) => ({ id: w.id, domain: w.domain, publicSubdomain: w.publicSubdomain }))
          commit('setState', { key: 'whiteLabelList', value: whiteLabelList })
        })
      },
      validateConfiguration({ state, commit }, config) {
        commit('setState', { key: 'error', value: {} })

        if (Array.isArray(config.keywords) && config.keywords.length === 0 ||
          typeof config.keywords === 'string' && config.keywords.trim() === '') {
          commit('setState', {
            key: 'error',
            value: {
              ...state.error,
              keywords: ['can\'t be blank']
            }
          })
        }

        if (!config.locationId && !config.location?.id) {
          commit('setState', {
            key: 'error',
            value: {
              ...state.error,
              location_id: ['can\'t be blank']
            }
          })
        }

        return isEmpty(state.error)
      },
      async saveConfiguration({ state, commit, dispatch }, { config, closeOnSave }) {
        const isConfigValid = await dispatch('validateConfiguration', config)
        if (!isConfigValid) return

        commit('setState', { key: 'isRequested', value: true })

        const requestType = state.configuration?.id ? 'put' : 'post'
        const url = state.configuration?.id ? `${state.apiBasePath}/${state.configuration.id}` : state.apiBasePath

        axios[requestType](url, {
          ...config,
          locationId: config.location?.id || config.locationId,
          keyword: config.keywords,
          keywords: Array.isArray(config.keywords) ? config.keywords : [config.keywords],
          gridSize: config.gridSize?.id || config.gridSize,
          gridDistanceMeasure: config.distanceMeasure?.value || config.distanceMeasure,
          gridPointDistance: config.pointDistance?.id || config.pointDistance
        }, axiosTransform).then((res) => {
          if (closeOnSave) Turbolinks.visit(state.basePath)
          else if (res.data.status === 'scheduled') {
            Turbolinks.visit(state.basePath)
          } else {
            commit('setConfigurationState', res.data)
            commit('setState', { key: 'isRequested', value: false })
          }
        }).catch((err) => {
          commit('setState', { key: 'isRequested', value: false })
          if (err.response.status === 422 && err.response.data.type === 'validation') {
            if (typeof err.response.data.text === 'string') {
              toastr.error(err.response.data.text)
            } else {
              commit('setState', { key: 'error', value: err.response.data.text })
            }
          } else {
            toastr.error(DEFAULT_ERROR_MESSAGE)
          }
        })
      },
      getCompetitorsList({ state, commit }) {
        commit('setState', { key: 'isRequested', value: true })
        return axios.get(`${state.apiBasePath}/${state.configuration.id}/known_competitors`, axiosTransform)
          .then((res) => {
            commit('setState', { key: 'competitorsList', value: res.data })
          }).finally(() => {
            commit('setState', { key: 'isRequested', value: false })
          })
      },
      getConfigState({ state }) {
        return axios.get(`${state.apiBasePath}/${state.configuration.id}/config_state`, axiosTransform)
      }
    }
  })
}
