<template>
  <div>
    <div class="keyword-show-head">
      <KeywordCommon :keywords-common="keywordsCommon" />
      <div class="keyword-show-control">
        <a :href="keywordNewUrl" class="btn btn-primary gap-3 rounded">
          <i class="far fa-circle-plus fa-fw" aria-hidden="true" />
          <span>Keywords</span>
        </a>
        <button @click="toggleSettingsPannel" class="btn btn-primary only-icon rounded">
          <i class="far fa-cog" />
        </button>
      </div>
    </div>
    <SlidingPanel :show-panel="showSettingsPanel" @closeSlidingPanel="toggleSettingsPannel">
      <KeywordSettings @closeSlidingPanel="toggleSettingsPannel" />
    </SlidingPanel>
    <KeywordFullChart />
    <KeywordFilter @setFilter="setFilter" />
    <KeywordHead v-if="!tableColumns.length"
      :selected-all="selectedAll"
      :keywords="keywords"
      :part-selected="partSelected"
      @onSelected="selectAll"
      @exportKeywords="exportKeywords"
      @deleteKeywords="deleteKeywords"
      @changeFrequency="changeFrequency"
      :keywords-common="keywordsCommon"
      @updateManualRanks="updateManualRanks" />
    <VueSlimTable
      :source="tableSource"
      :columns="tableColumns"
      :per-page="perPage"
      class="keywords-table responsive-table"
      ref="keywordsTable">
      <template #head:searchTerm="{ column }">
        <Checkbox @click.native.stop :value="selectedAll" @input="selectAll">{{ column.title }}</Checkbox>
      </template>
      <template #row="{ row }">
        <KeywordRow
          :row="row"
          :key="row.id"
          :table-locked="tableLocked"
          @onRowCheckToggle="onRowCheckToggle"
          @refetch="refetch"
          :keywords-common="keywordsCommon"
          :is-loading-spinner="isLoadingSpinner[row.id]" />
      </template>
    </VueSlimTable>
  </div>
</template>

<script>
import qs from 'qs'
import { mapState } from 'vuex'
import { runSwal } from '../../../common/delete_with_swal'
import KeywordRow from './keyword_row'
import KeywordFilter from './keyword_filter'
import KeywordCommon from './keyword_common'
import KeywordHead from './keyword_head'
import KeywordSettings from './keyword_settings'
import Checkbox from '../../components/checkbox'
import SlidingPanel from '../../components/sliding_panel'
import KeywordFullChart from './keyword_full_chart'
import { DEFAULT_ERROR_MESSAGE } from '../../../common/constants'

export default {
  components: {
    KeywordRow,
    KeywordHead,
    KeywordFilter,
    KeywordCommon,
    KeywordSettings,
    Checkbox,
    SlidingPanel,
    KeywordFullChart
  },
  props: {
    keywordsCommon: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      search: '',
      keywords: [],
      selectedAll: false,
      rowsTotalCount: 0,
      tableLocked: false,
      showSettingsPanel: false,
      isLoadingSpinner: {}
    }
  },
  watch: {
    search() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(this.$refs.keywordsTable.refetch, 300)
    }
  },
  computed: {
    ...mapState(['orgLocationPath', 'apiKeywordsPath', 'keywordPath']),
    filters() {
      return {
        search: this.search
      }
    },
    tableColumns() {
      return this.keywords.find((row) => row.selected) ? [] : this.columns
    },
    selectedRowIds() {
      return this.selectedAll ? undefined : this.$refs.keywordsTable.rows.filter((row) => row.selected).map((el) => el.id)
    },
    partSelected() {
      const selectedCount = this.selectedAll ? this.rowsTotalCount : this.keywords.filter((row) => row.selected).length
      return {
        selectedCount: selectedCount,
        length: this.rowsTotalCount,
        isPartSelected: selectedCount !== 0 && selectedCount !== this.rowsTotalCount,
        caption: `Selected ${selectedCount} keyword${selectedCount !== 1 ? 's' : ''} from ${this.rowsTotalCount}`,
        captionAll: `Selected ${selectedCount} keyword${selectedCount !== 1 ? 's' : ''}`
      }
    }
  },
  created() {
    this.keywordNewUrl = `${this.keywordPath}/new`
    this.perPage = 50
    this.columns = [
      { title: 'Keyword', key: 'searchTerm', orderable: true },
      { title: 'Local Rank', key: 'localRank', orderable: true },
      { title: 'Organic Rank', key: 'organicRank', orderable: true },
      { title: 'Organic Mobile Rank', key: 'organicMobileRank', orderable: true },
      { title: 'Last Checked', key: 'syncedAt', orderable: true },
      { title: 'Check Frequency', key: 'checkFrequency', orderable: true }
    ]
  },
  methods: {
    tableSource(params) {
      return axios.get(
        this.apiKeywordsPath,
        {
          params: { ...params, ...this.filters },
          paramsSerializer(json) { return qs.stringify(json, { arrayFormat: 'brackets' }) }
        }
      ).then((res) => {
        res.data.rows.forEach((row) => {
          row.selected = this.selectedAll
        })
        this.rowsTotalCount = res.data.totalCount
        this.keywords = res.data.rows
        if (this.keywordsCommon.forceCheckEnabled) {
          this.keywords.forEach((keyword) => {
            if (keyword.forceCheckTriggeredAt !== null) {
              this.$set(this.isLoadingSpinner, keyword.obfuscatedId, true)
            } else {
              this.$set(this.isLoadingSpinner, keyword.obfuscatedId, false)
            }
          })
        }
        return this.keywords
      })
    },
    setFilter(value) {
      this.search = value
      this.selectAll(false)
    },
    selectAll(bool) {
      this.selectedAll = bool
      this.$refs.keywordsTable.rows.forEach((row) => {
        row.selected = bool
      })
    },
    areKeywordsSelected() {
      return !this.keywords.find((row) => !row.selected) && this.rowsTotalCount <= this.perPage
    },
    onRowCheckToggle({ keywordId, bool }) {
      this.keywords.find((row) => row.id === keywordId).selected = bool
      this.selectedAll = this.areKeywordsSelected()
    },
    refetch() {
      this.$refs.keywordsTable.refetch()
    },
    exportKeywords(exportType) {
      const iframe = document.createElement('iframe')
      iframe.style = 'display: none'
      iframe.src = `${this.keywordPath}/${exportType}?${qs.stringify({ ids: this.selectedRowIds, ...this.filters }, { arrayFormat: 'brackets' })}`
      document.body.appendChild(iframe)
    },
    deleteKeywords(types) {
      runSwal(() => {
        this.tableLocked = true
        axios.delete(this.apiKeywordsPath, { params: { ids: this.selectedRowIds, types: types, ...this.filters } })
          .then(() => {
            this.refetch()
          }).catch(() => {
            toastr.error(DEFAULT_ERROR_MESSAGE)
          }).finally(() => {
            this.tableLocked = false
          })
      })
    },
    changeFrequency(frequency) {
      const data = new FormData()
      data.append('keyword[check_frequency]', frequency)
      axios.patch(this.apiKeywordsPath, data, { params: { ids: this.selectedRowIds, ...this.filters } })
        .then(() => {
          this.refetch()
        }).catch(() => {
          toastr.error(DEFAULT_ERROR_MESSAGE)
        })
    },
    toggleSettingsPannel() {
      this.showSettingsPanel = !this.showSettingsPanel
    },
    updateManualRanks() {
      axios.post(`${this.apiKeywordsPath}/update_ranks`, { ids: this.selectedRowIds })
        .then(() => {
          if (this.selectAll) {
            this.selectAll(false)
          }
          this.refetch()
        }).catch(() => {
          toastr.error(DEFAULT_ERROR_MESSAGE)
        })
    }
  }
}
</script>
