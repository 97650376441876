<template>
  <div v-if="chartData.length" class="color-dark">
    <h2 class="h4 m-0 mb-3 font-weight-semi-bold">
      <slot />
    </h2>
    <div ref="lineChart" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Highcharts from 'highcharts'
import moment from 'moment'
import { differenceClass } from 'common/helpers'
import { hasPropertisData } from './helpers'

const tooltipPoint = (data, diff) => (
  '<div class="tooltip-line">' +
    `<span class="dash dash--solid" style="border-bottom-color:${data.color}"></span>` +
    `<span class="date">${moment.utc(data.x).format('D MMM YYYY')}</span>` +
    `<span class="value">${data.y}</span>` +
    `<span class="difference ${differenceClass(diff)}">${Math.abs(diff)}</span></div>`
)

export default {
  props: {
    dataType: { type: String, required: true }
  },
  computed: {
    ...mapState(['parsedData']),
    chartData() {
      if (!hasPropertisData(this.parsedData, [this.dataType])) {
        return []
      }

      return this.parsedData[this.dataType].chartData.map((data) => {
        data.marker = {
          enabled: false,
          state: {
            hover: {
              enabled: true
            }
          },
          symbol: 'circle'
        }
        return data
      })
    }
  },
  mounted() {
    if (!this.chartData.length) return

    const tooltip = {
      backgroundColor: '#535c65',
      borderRadius: 4,
      borderWidth: 0,
      padding: 0,
      style: {
        fontSize: '14px',
        color: '#fff'
      },
      useHTML: true,
      shadow: false
    }
    tooltip.formatter = function formatter() {
      const i = this.series.data.indexOf(this.point)
      const memo = []
      this.series.chart.series.reduceRight((prev, series) => {
        if (series.data[i].y !== null) {
          const diff = prev.length !== 0 ? (series.data[i].y - prev.y) : 0
          memo.push(tooltipPoint(series.data[i], diff))
        }
        return series.data[i]
      }, [])

      return memo.reverse().join('')
    }

    if (this.chart) this.chart.destroy()

    this.chart = Highcharts.chart(this.$refs.lineChart, {
      alignTicks: false,
      title: false,
      chart: {
        height: 280,
        spacing: 0
      },
      credits: { enabled: false },
      legend: {
        enabled: true,
        align: 'left',
        labelFormatter() {
          const start = moment.utc(this.xData[0]).format('MMM D, YYYY')
          const end = moment.utc(this.xData[this.xData.length - 1]).format('MMM D, YYYY')

          return `${start} — ${end}`
        },
        title: {
          style: {
            fontSize: '.875rem',
            fontWeight: 600
          }
        }
      },
      xAxis: [
        { type: 'datetime' },
        { type: 'datetime', opposite: true }
      ],
      yAxis: [{
        title: { enabled: false },
        gridLineDashStyle: 'Dash'
      }],
      time: { useUTC: true },
      series: this.chartData,
      tooltip: tooltip
    })
  }
}
</script>
