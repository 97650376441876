<template>
  <table class="table mt-4" v-if="bulks.length">
    <thead>
      <tr>
        <th>Created</th>
        <th>State</th>
        <th>Processed rows</th>
        <th>Posted Q&A</th>
        <th>File</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="bulk in bulks">
        <td>
          <a :href="`${current_path}/${bulk.obfuscated_id}`">{{ bulk.created_at }}</a>
        </td>
        <td>
          <span class="badge badge-default">
            {{ bulk.state }}
          </span>
        </td>
        <td>{{ bulk.processed_count }}</td>
        <td>{{ bulk.questions_count }} / {{ bulk.answers_count }}</td>
        <td>{{ bulk.file_name }}</td>
        <td>
          <a href="#" v-if="bulk.state === 'finished' || bulk.state === 'error'" @click.prevent="deleteBulk(bulk)">
            <i class="far fa-xmark" />
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      current_path: window.location.pathname
    }
  },
  computed: mapGetters(['bulks']),
  methods: mapActions(['deleteBulk'])
}
</script>
