<template>
  <div :class="['chart-contaner', chartDirection]">
    <div class="chart" ref="chart" />
    <div class="chart-legend">
      <ChartLegeng
        v-for="legend in legends"
        :key="legend.id"
        :color="legend.color"
        :name="legend.name"
        :description="legend.description"
        :values="legend.values"
        :percentage="legend.percentage"
        :compare="compare" />
    </div>
  </div>
</template>

<script>
import camelCaseKeys from 'camelcase-keys-deep'
import { mapGetters } from 'vuex'
import Highcharts from 'highcharts#isolated'
import ChartLegeng from './chart_legend'
import {
  getValues, getPercentage, chartOptionsDefault, compileSubtitle
} from './helpers'

const DESCRIPTIONS = {
  photos_views_customers: 'Of views on media items uploaded by customers.',
  photos_views_merchant: 'Of views on media items uploaded by the GMB listing owner(s).',
  photos_count_merchant: 'Of media items that are currently live that have been uploaded by the GMB owner(s).',
  photos_count_customers: 'Of media items that are currently live that have been uploaded by customers.'
}

const initReset = Highcharts.Pointer.prototype.reset

function reset() {
  return undefined
}

function highlight(event) {
  event = this.series.chart.pointer.normalize(event)
  this.onMouseOver()
  this.series.chart.tooltip.refresh(this)
  this.series.chart.xAxis[0].drawCrosshair(event, this)
}

function compileLegend({ serie, stat }, compare) {
  return {
    color: serie.color,
    id: serie.id,
    name: serie.name,
    values: getValues(stat, compare),
    percentage: getPercentage(camelCaseKeys(stat)),
    description: DESCRIPTIONS[serie.id]
  }
}

export default {
  components: { ChartLegeng },
  props: {
    chartIds: {
      type: Array,
      required: true
    },
    chartDirection: {
      type: String,
      default: ''
    },
    chartHeight: {
      type: Number,
      default: 150
    },
    chartColors: {
      type: Array,
      default() { return ['#377dff'] }
    }
  },
  data() {
    return {
      legends: [],
      charts: []
    }
  },
  computed: mapGetters(['chartData', 'stats', 'compare', 'insightsDateRanges']),
  methods: {
    initChart(data) {
      if (this.charts.length) {
        this.charts.forEach((chart) => {
          chart.destroy()
        })
        this.charts = []
      }

      const subtitle = compileSubtitle({ type: 'range', ranges: this.insightsDateRanges.ranges }, this.compare)

      this.chartStat = this.stats.filter((stat) => this.chartIds.includes(stat.id))
      const series = data.filter((chart) => this.chartIds.includes(chart.id))

      const legends = []
      const chartData = []
      series.forEach((serie, index) => {
        serie.data[0].color = this.chartColors[index]
        if (serie.data[1]) {
          serie.data[1].color = `${this.chartColors[index]}80`
          serie.data[1].xAxis = 1
          serie.data[1].visible = this.compare
        }

        serie.data.forEach((d) => {
          if (d.data.length === 1) {
            d.marker = { enabled: true }
          }
        })

        chartData.push(serie.data)

        legends.push(compileLegend({
          serie: serie.data[0],
          stat: this.chartStat.find((cs) => cs.id === serie.id)
        }, this.compare))
      })
      this.legends = legends

      chartData.forEach((dataSet) => {
        const chartDiv = document.createElement('div')
        this.$refs.chart.appendChild(chartDiv)

        this.charts.push(Highcharts.chart(chartDiv, {
          ...chartOptionsDefault({
            subtitle,
            chartType: this.chartType,
            chartHeight: this.chartHeight
          }),
          series: dataSet,
          time: { useUTC: false },
          plotOptions: {
            line: {
              states: {
                inactive: { opacity: 1 }
              }
            }
          },
          xAxis: [{ crosshair: true, visible: false }, { visible: false }]
        }))
      })
    }
  },
  mounted() {
    Highcharts.Point.prototype.highlight = highlight
    Highcharts.Pointer.prototype.reset = reset

    this.initChart(this.chartData)
    const mouseEvents = ['mousemove', 'mouseleave']
    mouseEvents.forEach((mouseEvent) => {
      this.$refs.chart.addEventListener(mouseEvent, (e) => {
        const legends = []
        this.charts.forEach((chart) => {
          const event = chart.pointer.normalize(e)
          const point = chart.series[0].searchPoint(event, true)

          if (point) {
            if (mouseEvent === 'mousemove') {
              point.highlight(e)
              point.setState('hover')

              const comparedPoint = chart.series[1].points[point.index]
              const subtitle = compileSubtitle({
                type: 'date',
                datetime: [
                  point.x,
                  comparedPoint && comparedPoint.x
                ]
              }, this.compare)
              chart.setTitle(null, { text: subtitle })

              legends.push(compileLegend({
                serie: chart.series[0].options,
                stat: {
                  sum: point.y,
                  sum_compare: comparedPoint && comparedPoint.y || 0
                }
              }, this.compare))
            } else {
              point.setState('')

              const subtitle = compileSubtitle({ type: 'range', ranges: this.insightsDateRanges.ranges }, this.compare)
              chart.setTitle(null, { text: subtitle })
              chart.xAxis[0].hideCrosshair()

              legends.push(compileLegend({
                serie: chart.series[0].options,
                stat: this.chartStat.find((cs) => cs.id === chart.series[0].options.id)
              }, this.compare))
            }
          }
        })
        this.legends = legends
      })
    })
  },
  watch: {
    chartData: 'initChart'
  },
  beforeDestroy() {
    Highcharts.Pointer.prototype.reset = initReset
  }
}
</script>
