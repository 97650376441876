<template>
  <div class="popup-geogrid-share" :class="{ active }" @click="closePopup">
    <div class="popup-geogrid-share__wrapper scroll ver" @click.stop>
      <button class="btn btn-pretender popup-geogrid-share__close" @click="closePopup">
        <i class="far fa-xmark" />
      </button>
      <h3 class="popup-geogrid-share__title">
        Share Geogrid
      </h3>
      <div v-if="latestGeogridUrl" class="popup-geogrid-share__type">
        <RadioPanels v-model="selectedShareOption" name="geogrid_sharing_options" :values="shareOptions" />
      </div>
      <div class="popup-geogrid-share__buttons">
        <button class="btn btn-primary popup-geogrid-share__button" @click="copyPublicLink">
          <i class="far fa-copy" />
          <span>Copy Public Link</span>
        </button>
        <a :href="activeUrl" target="_blank" class="btn btn-primary popup-geogrid-share__button">
          <i class="far fa-globe" />
          <span>Open Public Link</span>
        </a>
      </div>
      <div class="popup-geogrid-share__embed">
        <h4 class="popup-geogrid-share__embed-title">Embedding code</h4>
        <div class="popup-geogrid-share__code">
          <pre ref="code">
&lt;iframe width="600" height="500" src="{{ activeUrl }}?layout=minimal"
  title="Geogrid"
  frameborder="0"
  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen&gt; &lt;/iframe&gt;
          </pre>
          <button class="popup-geogrid-share__copy button-copy" @click="copyEmbedCode">
            <i class="far fa-fw fa-copy" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import hotkeys from 'hotkeys-js'
import { copyToClipboardWithAnimation } from 'common/copy_to_clipboard'
import RadioPanels from '../../components/radio_panels.vue'

export default {
  components: {
    RadioPanels
  },
  props: {
    active: {
      type: Boolean,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    publicConfigUrl: {
      type: String,
      required: false,
      default: ''
    },
    searchTermId: {
      type: String,
      required: false
    }
  },
  data: () => ({
    selectedShareOption: 'current',
    shareOptions: [
      {
        value: 'current',
        text: 'This geogrid'
      },
      {
        value: 'last',
        text: 'Last geogrid for a search term'
      }
    ]
  }),
  computed: {
    latestGeogridUrl() {
      if (!this.publicConfigUrl || !this.searchTermId) return null
      return `${this.publicConfigUrl}/search_terms/${this.searchTermId}/latest_geogrid`
    },
    activeUrl() {
      const urls = {
        current: this.url,
        last: this.latestGeogridUrl
      }
      return urls[this.selectedShareOption]
    }
  },
  methods: {
    closePopup() {
      this.$emit('close')
      this.selectedShareOption = 'current'
    },
    handleEsc(event, handler) {
      if (handler.key === 'esc') {
        event.preventDefault()
        this.closePopup()
      }
    },
    copyPublicLink(e) {
      copyToClipboardWithAnimation({ element: e.currentTarget, textToCopy: this.activeUrl })
    },
    copyEmbedCode(e) {
      copyToClipboardWithAnimation({ element: e.currentTarget, textToCopy: this.$refs.code.innerText.trim() })
    }
  },
  mounted() {
    hotkeys('esc', this.handleEsc)
  },
  destroyed() {
    hotkeys.unbind('esc', this.handleEsc)
  },
  latestGeogridUrl(newUrl) {
    if (!newUrl) {
      this.selectedShareOption = 'current'
    }
  }
}
</script>
