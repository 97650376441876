<template>
  <div class="overlay" ref="preview">
    <div id="geogrid_widget_preview" />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import injectScript from 'common/inject_script'

export default {
  data() {
    return {
      widgetClass: null
    }
  },
  computed: mapState(['geogridWidget', 'kinds', 'scriptUrl']),
  watch: {
    geogridWidget: {
      handler() { this.updatePreview() },
      deep: true
    }
  },
  mounted() {
    injectScript(this.scriptUrl)
      .then(() => {
        this.widgetClass = window.GeogridWidget
        this.widgetClass.start('preview')
        this.updatePreview()
      }).catch((error) => {
        toastr.error('Unexpected error has occurred during Widget Preview initialization.')
        throw error
      })
  },
  methods: {
    updatePreview() {
      this.widgetClass.previewUpdate({ ...this.geogridWidget })
    }
  }
}
</script>

<style lang="scss" scoped>
  .overlay {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1000;
    }
  }
</style>
