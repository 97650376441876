<template>
  <div class="card-search-term" :class="{'card-search-term--current': isCurrent }">
    <div class="card-search-term__top">
      <div class="card-search-term__text">
        <span class="card-search-term__created-at">{{ st.lastGeogrid.createdAtFormatted || 'not created yet' }}</span>
        <p class="card-search-term__name">
          <img v-if="st.newTop3SinceLastRun" :src="require('top3_geogrid_blue.svg')" alt="" :id="`search-term-top-3-${st.text}`">
          <img v-if="st.brandNewTop3" :src="require('top3_geogrid_green.svg')" alt="" :id="`search-term-top-3-second-${st.text}`">
          <b-popover :target="`search-term-top-3-${st.text}`" triggers="hover" placement="bottom">
            At least one GeoGrid point for this search term has reached Top 3 rank for the first time
          </b-popover>
          <b-popover :target="`search-term-top-3-second-${st.text}`" triggers="hover" placement="bottom">
            At least one GeoGrid point for this search term has reached Top 3 rank for the second time
          </b-popover>
          <span>{{ st.text }}</span>
        </p>
      </div>
      <div class="card-search-term__geogrid">
        <GeogridPreview :ranks="st.lastGeogrid.gridRanks" />
      </div>
    </div>

    <SearchTermBottomCharts :st="st" :chart-metric="chartMetric" @selectChartMetric="selectChartMetric" />

    <div v-if="isCurrent && st.geogridsCount === 0"
      class="card-search-term__buttons">
      <button @click.stop="runNow" :disabled="isRequesting" class="btn btn-sm">
        <span class="pr-1">
          Run now
        </span>
        <i class="fa-regular fa-play" />
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { DEFAULT_ERROR_MESSAGE, NOT_ENOUGH_GEOGRID_CREDITS } from 'common/constants'
import GeogridPreview from '../../components/geogrid_preview'
import SearchTermBottomCharts from './search_term_bottom_charts'

export default {
  name: 'St',
  components: { GeogridPreview, SearchTermBottomCharts },
  props: {
    st: Object,
    canDrawChart: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isRequesting: false,
      chartMetric: 'agr' // agr | atgr | solv
    }
  },
  computed: {
    ...mapState(['config', 'searchTerm', 'geogrid']),
    isCurrent() {
      return this.searchTerm.text === this.st.text
    }
  },
  methods: {
    selectChartMetric(metricName) {
      if (!this.isCurrent) return

      this.chartMetric = metricName
    },
    runNow() {
      this.isRequesting = true
      axios.post(
        this.searchTerm.onDemainGeogridLink,
        { keyword: this.searchTerm.text }
      ).then((response) => {
        if (response.data.started) {
          toastr.success(
            'It will take a few minutes, please check back a bit later',
            'We started preparing your report'
          )
        } else if (response.data.error === 'not_enough_credits') {
          toastr.error(NOT_ENOUGH_GEOGRID_CREDITS, 'Not enough geogrid credits')
        } else {
          toastr.error(DEFAULT_ERROR_MESSAGE)
        }
      }).catch((_error) => {
        toastr.error(DEFAULT_ERROR_MESSAGE)
      }).finally(() => {
        this.isRequesting = false
      })
    }
  }
}
</script>
