<template>
  <div class="address-autocomplete">
    <InputSearch
      v-show="!foundAddress"
      :is-disabled="disabled"
      v-model="search"
      :placeholder="placeholder"
      ref="googleAddressAutocomplete"
      @input="checkEmptyValue"
      @click.stop />
    <div v-if="foundAddress" :class="['formatted-address', { disabled }]">
      <i class="far fa-map-marker-alt text-primary" />
      <span>{{ foundAddress.formattedAddress }}</span>
      <i class="far fa-trash ml-auto remove-address-btn" @click="clearInput" />
    </div>
  </div>
</template>

<script>
import { ensureGoogleMap } from '../../common/map_helpers'
import InputSearch from './input_search_ui'

export default {
  components: { InputSearch },
  props: {
    googleMapApiKey: { type: String, required: true },
    options: { type: Object, default: () => ({}) },
    initAddress: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    placeholder: { type: String, required: false, default: 'Input address' }
  },
  data() {
    return {
      isEditing: this.alwaysShow,
      search: this.initAddress,
      foundAddress: null
    }
  },
  mounted() {
    if (this.initAddress) {
      this.foundAddress = {
        formattedAddress: this.initAddress
      }
    }
    ensureGoogleMap({ googleMapApiKey: this.googleMapApiKey, lang: this.options.lang }, this.initAutocomplete)
  },
  watch: {
    initAddress(val) {
      this.search = val
    }
  },
  methods: {
    initAutocomplete() {
      const autocomplete = new google.maps.places.Autocomplete(this.$refs.googleAddressAutocomplete.$el.querySelector('input'), this.options)
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace()
        const location = place.geometry?.location
        if (location) {
          this.foundAddress = {
            addressComponents: place.address_components,
            formattedAddress: place.formatted_address,
            lat: location.lat(),
            lng: location.lng()
          }
          this.search = place.formatted_address
          this.$emit('updateAddress', this.foundAddress)
        }
      })
    },
    checkEmptyValue(value) {
      if (value === '') {
        this.clearInput()
      }
    },
    clearInput() {
      this.search = ''
      this.foundAddress = null
      this.$emit('updateAddress', this.foundAddress)
    }
  }
}
</script>
