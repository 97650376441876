import 'flatpickr'
import { updateSearchParams } from '../../common/search_params'

Styxie.Initializers.AdminPosts = {
  index: () => {
    $(() => {
      $("[data-use='turboselect']")
        .turboSelect({ width: '100%', dropdownAutoWidth: 'true' })
        .on('change', (e) => {
          const $el = $(e.currentTarget)
          const h = {}
          h[$el.prop('name')] = $el.val()
          updateSearchParams(h)
        })

      $("[data-use='select2']").each(function chooser() {
        const $this = $(this)
        $this.select2({
          width: '100%',
          minimumInputLength: 3,
          allowClear: true,
          placeholder: 'All',
          ajax: {
            url: $this.data('source'),
            data: (prms) => ({ q: prms.term, page: prms.page || 1 }),
            processResults: (data) => ({
              results: data.results,
              pagination: {
                more: (data.page * 1) < data.count_filtered
              }
            })

          }
        }).on('change', (e) => {
          const $el = $(e.currentTarget)
          const h = {}
          h[$el.prop('name')] = $el.val()
          updateSearchParams(h)
        })
      })

      $('.js-daterangepicker').each(function choser() {
        const $this = $(this)
        $this.flatpickr({
          mode: 'range',
          dateFormat: 'Y-m-d',
          onClose: (selectedDates, _dateStr, _instance) => {
            const [startDate, endDate] = selectedDates
            const h = {}
            h[$this[0].id] = {}
            h[$this[0].id].start_date = startDate
            h[$this[0].id].end_date = endDate
            updateSearchParams(h)
          },
          onReady: (dateObj, dateStr, instance) => {
            const $cal = $(instance.calendarContainer)
            if ($cal.find('.flatpickr-clear').length < 1) {
              $cal.append('<div class="flatpickr-clear">Clear</div>')
              $cal.find('.flatpickr-clear').on('click', () => {
                instance.clear()
                instance.close()
              })
            }
          }
        })
      })
    })
  }
}
