<template>
  <div class="payment row py-3 p-lg-4">
    <div class="col-md-12 col-xl-5 pt-4">
      <div class="text-center">
        <img v-if="appCodename == 'lv'" class="payment-logo" :src="require('lv/lv_logo.png')" alt="">
        <img v-else-if="appCodename == 'lbm'" class="payment-logo lbm" :src="require('lbm/lbm_logo.png')" alt="">
        <div class="payment-image d-none d-xl-block">
          <img v-if="appCodename == 'lv'" :src="require('lv/lv_payment.png')" alt="">
          <img v-else-if="appCodename == 'lbm'" :src="require('lbm/lbm_payment.png')" alt="">
        </div>
      </div>
    </div>
    <div class="col-xl-5 col-md-12">
      <PaymentForm :stripe-key="stripeKey" :current-plan="currentPlan" :plans="allPlans"
        :app-codename="appCodename"
        :paypal-subscriber="paypalSubscriber" />
    </div>
  </div>
</template>

<script>
import snakeCaseKeys from 'snakecase-keys'
import PaymentForm from './index/payment_form'

export default {
  components: { PaymentForm },
  props: {
    paypalSubscriber: { type: Boolean, required: true },
    stripeKey: { type: String, required: true },
    currentPlan: { type: String, required: true },
    appCodename: { type: String, required: true },
    plans: { type: Object, required: true }
  },
  created() {
    this.allPlans = snakeCaseKeys(this.plans)
  }
}
</script>
