<template>
  <div class="bg-light col-md-12 mt-4 pt-3 pb-1">
    <h4>Date</h4>

    <ExifInput v-for="i in inputs" :key="i.identifier" :identifier="i.identifier"
      :label="i.label"
      :recommended="i.recommended" :help="i.help" />
  </div>
</template>

<script>
import ExifInput from './exif_input'

export default {
  components: { ExifInput },
  data() {
    return {
      inputs: [
        {
          identifier: 'datetime', label: 'Date', recommended: '{{ "now" | date: "%Y:%m:%d %H:%M:%S" }}', help: 'YYYY:MM:DD HH:MM:SS'
        },
        {
          identifier: 'datetimeoriginal', label: 'Date Original', recommended: '{{ "now" | date: "%Y:%m:%d %H:%M:%S" }}', help: 'YYYY:MM:DD HH:MM:SS'
        },
        {
          identifier: 'datetimedigitized', label: 'Date Digitized', recommended: '{{ "now" | date: "%Y:%m:%d %H:%M:%S" }}', help: 'YYYY:MM:DD HH:MM:SS'
        }
      ]
    }
  }
}
</script>
