export const mainProps = ['call_clicks', 'website_clicks', 'business_direction_requests', 'business_conversations']
export const viewsBreakdownProps = ['business_impressions_desktop_maps', 'business_impressions_desktop_search']
export const deviceBreakdownProps = ['business_impressions_mobile_search', 'business_impressions_mobile_maps']
export const imagesProperties = ['images']

export const mapIcons = {
  actions_driving_directions: 'far fa-compass',
  actions_phone: 'far fa-phone',
  queries_direct: 'far fa-dot-circle-o',
  queries_indirect: 'far fa-compass',
  business_impressions_desktop_maps: 'far fa-location-dot',
  business_impressions_desktop_search: 'fab fa-google',
  business_impressions_mobile_maps: 'far fa-mobile',
  business_impressions_mobile_search: 'far fa-desktop',
  business_conversations: 'far fa-map-location',
  business_direction_requests: 'far fa-arrow-pointer',
  call_clicks: 'far fa-phone',
  website_clicks: 'far fa-window-flip'
}

export function hasPropertisData(data, properties) {
  if (!data || Object.keys(data).length === 0) return false

  for (let i = 0; i < properties.length; i++) {
    if (data[properties[i]]) {
      return true
    }
  }

  return false
}

export default {}
