<template>
  <div>
    <h2 class="h3 m-0 mb-2 font-weight-semi-bold text-dark">Add new moments category</h2>
    <form class="narrow-form mb-4" @submit.prevent="createCategory">
      <div class="d-flex gap-4 form-field pt-1">
        <input v-model="categoryTitle" placeholder="Add category" type="text">
        <button class="btn btn-outline rounded">Add</button>
      </div>
    </form>

    <div class="mv-list-tag categories narrow-form wide mb-5">
      <div v-for="({ id, title }) in categoriesLocal" class="mv-list-tag-item">
        {{ title }}
        <i class="far fa-times icon" @click="removeCategory(id)" />
      </div>
    </div>
  </div>
</template>

<script>
import { DEFAULT_ERROR_MESSAGE } from '../../../../common/constants'

function handleError(error) {
  if (error.response.status === 422) {
    const errMessages = error.response.data.errors
    if (errMessages) {
      return Object.keys(errMessages).map((key) => {
        const temp = `${key.replace('_', ' ')}: ${errMessages[key].join(', ')}`
        const message = temp.charAt(0).toUpperCase() + temp.substring(1)

        return message
      })
    }

    return DEFAULT_ERROR_MESSAGE
  }
  if (error.response.status === 413) {
    return 'Images size exceeds limits.'
  }

  return DEFAULT_ERROR_MESSAGE
}

export default {
  props: {
    categories: { type: Array, required: true },
    mobileAjaxCategoriesPath: { type: String, default: '' },
    businessId: { type: String, required: true }
  },
  data() {
    return {
      categoryTitle: '',
      categoriesLocal: this.categories,
      iscategoryLocked: false
    }
  },
  methods: {
    removeCategory(catId) {
      axios.delete(`${this.mobileAjaxCategoriesPath}/${catId}?business_id=${this.businessId}`)
        .then(() => {
          this.categoriesLocal.splice(this.categoriesLocal.findIndex((cat) => cat.id === catId), 1)
          toastr.success('Category deleted successfully')
        })
        .catch(() => {
          toastr.error(DEFAULT_ERROR_MESSAGE)
        })
    },
    createCategory() {
      this.iscategoryLocked = true
      const formData = new FormData()

      formData.append('mobile_category[title]', this.categoryTitle)

      axios.post(`${this.mobileAjaxCategoriesPath}?business_id=${this.businessId}`, formData)
        .then((res) => {
          this.categoryTitle = ''
          if (!this.categoriesLocal.find((cat) => cat.id === res.data.id)) {
            this.categoriesLocal.push({
              id: res.data.id,
              title: res.data.title
            })
            toastr.success('Category added successfully')
          }
        })
        .catch((res) => {
          handleError(res).forEach((errorText) => {
            toastr.error(errorText)
          })
        })
        .finally(() => {
          this.iscategoryLocked = false
        })
    }
  }
}
</script>
