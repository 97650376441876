<template>
  <div class="radio_ui">
    <input type="radio"
      :checked="value === item"
      :value="item"
      :id="idName"
      :name="name"
      @change="onChange">
    <label :for="idName">
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  props: {
    value: [Number, String],
    item: [String, Number],
    idName: String,
    name: {
      type: String,
      default: ''
    }
  },
  methods: {
    onChange() {
      this.$emit('input', this.item)
    }
  }
}
</script>
