<template>
  <ul v-if="currentQuestion.answers.length">
    <answer
      v-for="answer in currentQuestion.answers"
      :key="answer.remote_id"
      :answer="answer" />
  </ul>
  <h3 v-else class="text-muted text-center">No Answers yet</h3>
</template>

<script>
import { mapState } from 'vuex'
import Answer from './answer'

export default {
  computed: mapState(['currentQuestion']),
  components: { Answer }
}
</script>
