<template>
  <TransitionExpand>
    <div class="form-field m-0" v-show="geogridWidget.placesDataSource === 'places_api'">
      <span class="caption">Maps API Key</span>
      <multiselect
        v-model="model"
        :allow-empty="false"
        :options="mapsApiKeys"
        :searchable="false"
        :show-labels="false"
        :max-height="540"
        label="text" />
      <div class="invalid-feedback" v-if="errors.placesApisId">
        {{ errors.placesApisId.join(', ') }}
      </div>
    </div>
  </TransitionExpand>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapState } from 'vuex'

export default {
  components: { Multiselect },
  computed: {
    ...mapState(['geogridWidget', 'mapsApiKeys']),
    errors() { return this.$store.state.geogridWidget.errors },
    model: {
      get() {
        return this.mapsApiKeys.filter((key) => key.id === this.$store.state.geogridWidget.placesApisId)
      },
      set(val) { this.$store.commit('changeGeogridWidgetKey', { key: 'placesApisId', val: val.id }) }
    }
  }
}
</script>
