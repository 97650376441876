<template>
  <div>
    <div class="d-flex justify-content-between gap-2">
      <template v-if="ranks && ranks.length">
        <div class="d-flex flex-column">
          <span class="last">{{ ranks[ranks.length - 1].y ? ranks[ranks.length - 1].y : 'N/a' }}</span>
          <span v-if="getDiff(ranks) !== null" :class="['delta', differenceClass(getDiff(ranks))]">{{ Math.abs(getDiff(ranks)) }}</span>
        </div>
        <div class="d-flex flex-column">
          <a v-if="serpHtml" :href="serpHtml" target="_blank">
            <i class="far fa-cloud-download font-size-12" />
          </a>
        </div>
        <ChartLines :data-set="dataSet" />
      </template>
      <template v-else>
        Pending
      </template>
    </div>
  </div>
</template>

<script>
import ChartLines from './keyword_chart'
import { getDiff } from './helpers'
import { differenceClass } from '../../../common/helpers'

export default {
  components: { ChartLines },
  props: {
    ranks: {
      type: [Array, Object],
      default() {
        return null
      }
    },
    serpHtml: {
      type: String,
      default: ''
    },
    obfuscatedId: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: ''
    }
  },
  created() {
    this.dataSet = [{
      data: this.ranks,
      color: '#01c9a7',
      name: 'Rank'
    }]
  },
  methods: {
    getDiff,
    differenceClass
  }
}
</script>
