<template>
  <div class="settings-form">
    <div class="settings-form-box pb-2">
      <div class="settings-form-head">
        <h4>Keyword tracking options</h4>
      </div>
    </div>
    <div class="settings-form-box scroll ver h-100">
      <div class="form-field mb-3">
        <span class="caption">URL to track</span>
        <Radio v-for="{ label, value } in urlOptions" :key="value"
          v-model="selectedUrlOption"
          :item="value"
          :id-name="value"
          name="keyword_url_settings">
          {{ label }}
        </Radio>
      </div>
      <div class="form-field mb-3">
        <span class="caption">Default check frequency</span>
        <Multiselect
          v-model="selectedCheckFrequency"
          :options="frequencyOptions"
          :searchable="false"
          :close-on-select="true"
          :show-labels="false"
          :allow-empty="false"
          label="label"
          track-by="value" />
      </div>
      <div class="form-field mb-4">
        <span class="caption">
          Search language
          <i class="far fa-info-circle ml-1"
            data-toggle="tooltip"
            data-placement="top"
            ref="searchLanguageTip"
            :title="searchLanguageTipText" />
        </span>
        <Multiselect
          v-model="selectedLanguage"
          :options="languageOptions"
          :close-on-select="true"
          :show-labels="false"
          :allow-empty="false"
          label="label"
          track-by="value" />
      </div>
      <div class="form-field mb-3">
        <span class="caption">Search location</span>
        <GoogleAutocomplete
          :google-map-api-key="googleMapApiKey"
          :options="optionAutocomplete"
          :init-address="formattedAddress"
          @updateAddress="updateAddress" />
      </div>

      <div v-if="exampleLink.length" class="form-field mb-3">
        <span class="caption">Link to the search location in Google</span>
        <a :href="exampleLink"
          class="link text-break"
          target="_blank">
          {{ exampleLink }}
        </a>
      </div>
    </div>

    <div class="settings-form-control">
      <button class="btn btn-primary-outline" @click="closeSlidingPanel">Cancel</button>
      <button :disabled="sending" :class="['btn btn-primary', {'with-icon': sending}]" @click="submit">
        <i v-if="sending" class="far fa-spinner-third fa-spin" />
        Set
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import Multiselect from 'vue-multiselect'
import GoogleAutocomplete from '../../components/google_address_autocomplete'
import Radio from '../../components/radio'

const SEARCH_LANGUAGES = [
  ['lang_ar', 'Arabic'],
  ['lang_hy', 'Armenian'],
  ['lang_be', 'Belarusian'],
  ['lang_bg', 'Bulgarian'],
  ['lang_ca', 'Catalan'],
  ['lang_hr', 'Croatian'],
  ['lang_cs', 'Czech'],
  ['lang_da', 'Danish'],
  ['lang_nl', 'Dutch'],
  ['lang_en', 'English'],
  ['lang_eo', 'Esperanto'],
  ['lang_et', 'Estonian'],
  ['lang_tl', 'Filipino'],
  ['lang_fi', 'Finnish'],
  ['lang_fr', 'French'],
  ['lang_de', 'German'],
  ['lang_el', 'Greek'],
  ['lang_iw', 'Hebrew'],
  ['lang_hu', 'Hungarian'],
  ['lang_is', 'Icelandic'],
  ['lang_id', 'Indonesian'],
  ['lang_it', 'Italian'],
  ['lang_ja', 'Japanese'],
  ['lang_ko', 'Korean'],
  ['lang_lv', 'Latvian'],
  ['lang_lt', 'Lithuanian'],
  ['lang_no', 'Norwegian'],
  ['lang_fa', 'Persian'],
  ['lang_pl', 'Polish'],
  ['lang_pt', 'Portuguese'],
  ['lang_ro', 'Romanian'],
  ['lang_ru', 'Russian'],
  ['lang_sr', 'Serbian'],
  ['lang_sk', 'Slovak'],
  ['lang_sl', 'Slovenian'],
  ['lang_es', 'Spanish'],
  ['lang_sv', 'Swedish'],
  ['lang_th', 'Thai'],
  ['lang_tr', 'Turkish'],
  ['lang_uk', 'Ukrainian'],
  ['lang_vi', 'Vietnamese'],
  ['lang_zh-CN', 'Chinese Simplified'],
  ['lang_zh-TW', 'Chinese Traditional']
].sort((l1, l2) => l1[1].localeCompare(l2[1]))

export default {
  components: { Multiselect, Radio, GoogleAutocomplete },
  data() {
    const frequencyOptions = [
      { label: '48 hours', value: 'two_days' },
      { label: 'One week', value: 'one_week' },
      { label: 'Two weeks', value: 'two_weeks' },
      { label: 'One month', value: 'one_month' }
    ]
    const languageOptions = SEARCH_LANGUAGES.map(([value, label]) => ({ label: label, value: value }))
    languageOptions.unshift({ label: 'Default', value: null })
    const selectedCheckFrequency = frequencyOptions.find(({ value }) => value === this.$store.state.defaultCheckFrequency)
    const selectedLanguage = languageOptions.find(({ value }) => value === this.$store.state.searchLanguage)

    return {
      urlOptions: [
        { label: 'Entire Website URL (including domain and path parts)', value: 'withPath' },
        { label: 'Only Website URL domain', value: 'withoutPath' }
      ],
      frequencyOptions: frequencyOptions,
      languageOptions: languageOptions,
      selectedUrlOption: this.$store.state.includePath ? 'withPath' : 'withoutPath',
      selectedCheckFrequency: selectedCheckFrequency,
      selectedLanguage: selectedLanguage,
      sending: false
    }
  },
  created() {
    this.optionAutocomplete = {
      lang: 'en',
      fields: ['formatted_address', 'geometry'],
      types: ['geocode']
    }
    this.generateExampleLink()
    this.searchLanguageTipText = `
      <div class="text-left">
        Change only if the business name language differs from the business country language.
        Otherwise the search results might be inaccurate.
      </div>
    `
  },
  mounted() {
    $(this.$refs.searchLanguageTip).tooltip({
      delay: { show: 100, hide: 200 },
      html: true
    })
  },
  computed: mapState(['googleMapApiKey', 'formattedAddress', 'exampleLink']),
  methods: {
    ...mapActions(['updateSettings', 'getKeywordsAddress']),
    ...mapMutations(['updateAddressComponents', 'generateExampleLink']),
    closeSlidingPanel() {
      this.$emit('closeSlidingPanel')
    },
    async submit() {
      try {
        this.sending = true
        await this.updateSettings({
          includePath: this.selectedUrlOption === 'withPath',
          defaultCheckFrequency: this.selectedCheckFrequency.value,
          searchLanguage: this.selectedLanguage.value
        })
        this.closeSlidingPanel()
      } finally {
        this.sending = false
      }
    },
    updateAddress(address) {
      const NO_ADDRESS = { lat: null, lng: null, formattedAddress: '' }
      this.updateAddressComponents(address ?? NO_ADDRESS)
      this.generateExampleLink()
    }
  }
}
</script>
