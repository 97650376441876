<template>
  <div class="audit-card-service" ref="card">
    <div v-if="content.category" class="audit-card-service__category">{{ content.category }}</div>
    <div class="audit-card-service__title">{{ content.name }}</div>
    <div v-if="content.matched" class="audit-card-service__matched" />
    <div class="audit-card-service__description">{{ content.description }}</div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      required: true
    }
  }
}
</script>
