<template>
  <button :disabled="inProgress" ref="button">
    <i v-if="inProgress" class="far fa-spinner fa-spin fa-fw" :style="{ width }" />
    <slot v-else />
  </button>
</template>

<script>
export default {
  props: {
    inProgress: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    // keep button width
    this.width = `${this.$refs.button.clientWidth - this.$refs.button.computedStyleMap().get('padding-left').value * 2}px`
  }
}
</script>
