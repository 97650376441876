<template>
  <div v-if="pageCount > 1" class="pagination-ui">
    <button class="pagination-ui__btn" type="button" :disabled="page === 1" @click="setPage(1)">
      <svg width="6" height="10" viewBox="0 0 6 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.69733 9.56647C5.38491 9.87889 4.87837 9.87889 4.56596 9.56647L0.000269833 5.00078L4.56596
            0.435096C4.87838 0.122676 5.38491 0.122676 5.69733 0.435096C6.00975 0.747515 6.00975 1.25405
            5.69733 1.56647L2.26301 5.00078L5.69733 8.4351C6.00975 8.74752 6.00975 9.25405 5.69733 9.56647Z" />
      </svg>
      <svg width="6" height="10" viewBox="0 0 6 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.69733 9.56647C5.38491 9.87889 4.87837 9.87889 4.56596 9.56647L0.000269833 5.00078L4.56596
            0.435096C4.87838 0.122676 5.38491 0.122676 5.69733 0.435096C6.00975 0.747515 6.00975 1.25405
            5.69733 1.56647L2.26301 5.00078L5.69733 8.4351C6.00975 8.74752 6.00975 9.25405 5.69733 9.56647Z" />
      </svg>
    </button>
    <button class="pagination-ui__btn" type="button" :disabled="page === 1" @click="setPage(page - 1)">
      <svg width="6" height="10" viewBox="0 0 6 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.69733 9.56647C5.38491 9.87889 4.87837 9.87889 4.56596 9.56647L0.000269833 5.00078L4.56596
            0.435096C4.87838 0.122676 5.38491 0.122676 5.69733 0.435096C6.00975 0.747515 6.00975 1.25405
            5.69733 1.56647L2.26301 5.00078L5.69733 8.4351C6.00975 8.74752 6.00975 9.25405 5.69733 9.56647Z" />
      </svg>
    </button>

    <template v-if="pageCount <= maxBtn + 2">
      <button :class="['pagination-ui__btn', { active: page === n }]" type="button" v-for="n in pageCount"
        :key="n"
        @click="setPage(n)">
        {{ n }}
      </button>
    </template>

    <template v-else-if="page === 1">
      <button :class="['pagination-ui__btn', { active: page === n }]" v-for="n in maxBtn" :key="n"
        type="button"
        @click="setPage(n)">
        {{ n }}
      </button>
    </template>

    <template v-else-if="page === pageCount">
      <button class="pagination-ui__btn" type="button" @click="setPage(page - 2)">{{ page - 2 }}</button>
      <button class="pagination-ui__btn" type="button" @click="setPage(page - 1)">{{ page - 1 }}</button>
      <button class="pagination-ui__btn active" type="button">{{ page }}</button>
    </template>

    <template v-else>
      <button class="pagination-ui__btn" type="button" @click="setPage(page - 1)">{{ page-1 }}</button>
      <button class="pagination-ui__btn active" type="button">{{ page }}</button>
      <button class="pagination-ui__btn" type="button" @click="setPage(page + 1)">{{ page+1 }}</button>
    </template>

    <button class="pagination-ui__btn" type="button" :disabled="page === pageCount" @click="setPage(page + 1)">
      <svg width="6" height="10" viewBox="0 0 6 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.302674 0.433533C0.615093 0.121114 1.12163 0.121114 1.43404 0.433534L5.99973 4.99922L1.43404
            9.5649C1.12162 9.87732 0.615092 9.87732 0.302672 9.5649C-0.00974706 9.25249 -0.00974697 8.74595
            0.302672 8.43353L3.73699 4.99922L0.302674 1.5649C-0.00974566 1.25248 -0.00974557 0.745953 0.302674 0.433533Z" />
      </svg>
    </button>
    <button class="pagination-ui__btn" type="button" :disabled="page === pageCount" @click="setPage(pageCount)">
      <svg width="6" height="10" viewBox="0 0 6 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.302674 0.433533C0.615093 0.121114 1.12163 0.121114 1.43404 0.433534L5.99973 4.99922L1.43404
            9.5649C1.12162 9.87732 0.615092 9.87732 0.302672 9.5649C-0.00974706 9.25249 -0.00974697 8.74595
            0.302672 8.43353L3.73699 4.99922L0.302674 1.5649C-0.00974566 1.25248 -0.00974557 0.745953 0.302674 0.433533Z" />
      </svg>
      <svg width="6" height="10" viewBox="0 0 6 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.302674 0.433533C0.615093 0.121114 1.12163 0.121114 1.43404 0.433534L5.99973 4.99922L1.43404
            9.5649C1.12162 9.87732 0.615092 9.87732 0.302672 9.5649C-0.00974706 9.25249 -0.00974697 8.74595
            0.302672 8.43353L3.73699 4.99922L0.302674 1.5649C-0.00974566 1.25248 -0.00974557 0.745953 0.302674 0.433533Z" />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    page: { type: Number, default: 1 },
    perPage: { type: Number, default: 25 },
    totalCount: { type: Number, required: true }
  },
  data() {
    return {
      pageCount: 0
    }
  },
  created() {
    this.maxBtn = 3
  },
  methods: {
    setPage(page) {
      if (this.page === page) return
      this.$emit('updatePage', page)
    }
  },
  watch: {
    totalCount: {
      immediate: true,
      handler() {
        this.pageCount = Math.ceil(this.totalCount / this.perPage)
      }
    }
  }
}
</script>
