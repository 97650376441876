import moment from 'moment'

export const lineChartOptions = {
  chart: {
    type: 'line',
    // "One way of working around that is to increase the chart height in export."
    // https://api.highcharts.com/highcharts/legend.navigation
    height: '300px',
    spacing: [24, 16, 24, 16],
    style: {
      fontFamily: '"Nunito", sans-serif'
    }
  },
  credits: { enabled: false },
  colors: ['#377DFF', '#D34138', '#29BA6C', '#FF8E3D', '#f9ae34', '#7f30f0'],
  title: { text: '' },
  tooltip: {
    shared: true,
    formatter() {
      // return this.points.reduce((s, point) => `${s}<br/>${point.series.name}: ${point.y}`, `<strong>${this.x}</strong>`)
      return this.points.reduce((s, point) => `${s}<br/>${point.series.name}: ${point.y}`, `<strong>${moment(this.x).format('LL')}</strong>`)
    }
  },
  legend: {
    enabled: true,
    align: 'left',
    margin: 24,
    symbolHeight: 12,
    symbolPadding: 8,
    symbolRadius: 8,
    symbolWidth: 12,
    symbol: 'circle',
    itemStyle: {
      color: '#3d454d',
      fontSize: '14px'
    }
  },
  plotOptions: {
    series: {
      marker: {
        symbol: 'circle',
        enabled: false
      }
    }
  },
  xAxis: {
    type: 'datetime',
    labels: {
      formatter() {
        // return this.value
        return moment(this.value).format('ll')
      },
      style: {
        color: '#77838F',
        fontSize: '12px'
      },
      rotation: 0,
      overflow: 'allow'
    },
    crosshair: {
      color: '#77838F'
    }
  },
  yAxis: {
    title: { text: undefined },
    labels: {
      style: {
        color: '#BDC5D1',
        fontSize: '12px'
      }
    },
    gridLineDashStyle: 'Dash',
    gridLineColor: '#D5DAE2'
  }
}

export const onChartCompete = (chart) => {
  chart.update({
    xAxis: {
      tickPositioner() {
        return this.chart.series[0].points.map(({ x }, index, points) => {
          const k = Math.round(points.length / 10)
          return index % k === 0 ? x : null
        })
      }
    }
  })
}

export default {
  lineChartOptions,
  onChartCompete
}
