import Vue from 'vue'

import createFormStore from '../vue_widgets/exif_templates/form/store'
import FormWidget from '../vue_widgets/exif_templates/form/index'

function form(params) {
  $(() => {
    new Vue({ // eslint-disable-line no-new
      el: document.getElementById('vue-exif-template-form'),
      store: createFormStore(params),
      render: (h) => h(FormWidget)
    })
  })
}

Styxie.Initializers.ExifTemplates = {
  new: form,
  edit: form
}

export default form
