<template>
  <div class="audit-section-description">
    <div class="audit-section-description__icon">
      <i class="fa-solid fa-circle-info" />
    </div>
    <div class="audit-section-description__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {}
</script>
