<template>
  <table class="responsive-table">
    <thead>
      <tr>
        <th>Rank</th>
        <th class="position-cell">Current Position</th>
        <th class="position-cell">The Best Position</th>
        <th class="position-cell">Total Checks</th>
        <th>Last Checked</th>
        <th class="action-cell">Action</th>
      </tr>
    </thead>
    <tbody>
      <TableRow v-for="type in types" :key="type" :rank-type="type" />
    </tbody>
  </table>
</template>

<script>
import TableRow from './keyword_show_row'

export default {
  components: { TableRow },
  created() {
    this.types = ['local', 'organic', 'organicMobile']
  }
}
</script>
