<template>
  <div class="geobooster-widgets-filters">
    <div class="geobooster-widgets-filters__top d-flex gap-4">
      <p class="geobooster-widgets-filters__title">Page path</p>
      <div class="geobooster-widgets-filters__tooltip">
        <i id="geobooster-widgets-filters__tooltip-trigger" class="color-info far fa-exclamation-circle" />
        <BTooltip target="geobooster-widgets-filters__tooltip-trigger" title="Widget page path without the domain.
E.g. if the page URL is https://mysite.com/pages/roofing, put /pages/roofing here." />
      </div>
    </div>
    <div class="geobooster-widgets-filters__pages pb-2">
      <!--      <div-->
      <!--        class="geobooster-widgets-filters__item d-flex align-items-center justify-content-between"-->
      <!--        :class="{ active: activePageFilterIndex === 0, selected: lastSelectedIndex === 0 }"-->
      <!--        @click="openFilters(0)">-->
      <!--        <span class="pr-4">Default filter</span>-->
      <!--      </div>-->
      <div v-for="(page, index) in nonDefaultFilters"
        class="geobooster-widgets-filters__item d-flex align-items-center justify-content-between"
        :class="{ active: activePageFilterIndex === index + 1,
                  selected: lastSelectedIndex === index + 1,
                  error: page.error,
                  'flex-wrap': editPathMode && activePageFilterIndex === index + 1 }"
        :id="page.pagePath"
        @click="openFilters(index + 1)">
        <BTooltip
          v-if="tooltipIndexes.includes(index + 1)"
          :target="page.pagePath" :title="page.pagePath" :noninteractive="true"
          :delay="0"
          :no-fade="true"
          placement="right" />
        <form class="d-flex align-items-center justify-content-between"
          v-if="editPathMode && activePageFilterIndex === index + 1"
          @submit.prevent="savePath">
          <CustomInput
            class="geobooster-widgets-filters__path"
            ref="pathInput"
            v-model="mutablePagePath"
            :error="editInputError" />
          <div class="geobooster-widgets-filters__path-buttons gap-2 d-flex">
            <button
              class="btn rounded btn--rem"
              type="submit">
              Save
            </button>
            <button
              type="button"
              class="btn rounded btn--rem"
              @click.stop="disableEditPathMode">
              Cancel
            </button>
          </div>
        </form>
        <template v-else>
          <!--    it's here on purpose      -->
          <span :ref="`pagePathContainer${index + 1}`" class="pr-4">{{ page.pagePath }}</span>
          <div v-if="activePageFilterIndex === index + 1" class="geobooster-widgets-filters__buttons gap-2">
            <button class="btn btn-pretender gap-2" @click.stop="editPathMode = true">
              <i class="far fa-edit" />
              <span>edit</span>
            </button>
            <button class="btn btn-pretender" @click.stop="removePageFilter(undefined)">
              <i class="far fa-trash" />
            </button>
          </div>
        </template>
        <div v-if="editInputError && activePageFilterIndex === index + 1" class="text-danger mt-2">{{ editInputErrorText }}</div>
      </div>
    </div>
    <button v-if="!activePageFilter || activePageFilter && !activePageFilter.new"
      class="geobooster-widgets-filters__add btn btn-pretender btn-sm gap-2 px-1" @click="addNewPageFilter">
      <i class="far fa-plus" />
      <span>Add filters for a new page</span>
    </button>
    <div
      v-if="activePageFilter"
      class="geobooster-widgets-filters__filter"
      :class="{ active: showPanel }">
      <div
        :class="{ active: showContent }"
        class="geobooster-widgets-filters__form geobooster-widgets-edit-form fade-content scroll ver">
        <div
          class="geobooster-widgets-edit-form__line">
          <CustomSelect
            :value="activePageFilter.country"
            :values="countryInput.values"
            :key="countryInput.name"
            :error="countryInput.error"
            :error-text="countryInput.errorText"
            :label="countryInput.label"
            :name="countryInput.name"
            :disabled="countryInput.disabled"
            :allow-empty="countryInput.allowEmpty"
            :open-direction="countryInput.openDirection"
            :content-min-height="countryInput.contentMinHeight"
            @input="$emit('updateFilters', { type: 'country', newSelectedFilters: $event })" />
          <button
            v-if="activePageFilter.country"
            class="geobooster-widgets-edit-form__clear btn btn-pretender"
            @click="clearFilter('country')">
            <i class="far fa-eraser" />
            <span>Clear country</span>
          </button>
        </div>
        <FilterInput
          :input="fields.administrativeAreas"
          :filter-name="'administrativeAreas'"
          :filter-text="'states'"
          :selected-filters="activePageFilter.administrativeAreas"
          :disable-add-button="disableAddButton('administrativeAreas')"
          @addFilter="addFilter('administrativeAreas')"
          @removeFilter="removeFilter('administrativeAreas', $event)"
          @clearFilter="clearFilter('administrativeAreas')" />
      </div>
      <div
        :class="{ active: showContent }"
        class="geobooster-widgets-filters__form geobooster-widgets-edit-form fade-content scroll ver">
        <FilterInput
          :input="fields.cities"
          :filter-name="'cities'"
          :filter-text="'cities'"
          :selected-filters="activePageFilter.cities"
          :disable-add-button="disableAddButton('cities')"
          @addFilter="addFilter('cities')"
          @removeFilter="removeFilter('cities', $event)"
          @clearFilter="clearFilter('cities')" />
        <FilterInput
          :input="fields.categories"
          :filter-name="'categories'"
          :filter-text="'categories'"
          :selected-filters="activePageFilter.categories"
          :disable-add-button="disableAddButton('categories')"
          @addFilter="addFilter('categories')"
          @removeFilter="removeFilter('categories', $event)"
          @clearFilter="clearFilter('categories')" />
      </div>
      <div
        :class="{ active: showContent }"
        class="geobooster-widgets-filters__bottom w-100 d-flex align-items-center justify-content-end fade-content">
        <button class="btn btn-pretender gap-2 px-4" @click="closeFilters">
          <i class="far fa-long-arrow-left" />
          <span>Collapse</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { BTooltip } from 'bootstrap-vue'
import CustomInput from '../../../components/custom_input'
import FilterInput from '../blanks/filter_input'
import CustomSelect from '../../../components/custom_select'
import { runSwal } from '../../../../common/delete_with_swal'

export default {
  components: {
    BTooltip,
    CustomSelect,
    CustomInput,
    FilterInput
  },
  props: {
    fields: {
      type: Object,
      required: true
    },
    filters: {
      type: Array,
      required: true
    },
    activePageFilter: {
      type: Object,
      required: false
    },
    activePageFilterIndex: {
      type: Number,
      required: false
    },
    lastSelectedIndex: {
      type: Number,
      required: false,
      default: null
    }
  },
  data: () => ({
    editPathMode: false,
    showContent: false,
    showPanel: false,
    animationTimeout: 300,
    mutablePagePath: '',
    editInputError: false,
    editInputErrorText: '',
    tooltipIndexes: []
  }),
  methods: {
    addFilter(type) {
      const filterToAdd = this.fields[type].value.trim()

      if (!filterToAdd) {
        return
      }

      const selectedFilters = this.activePageFilter[type]

      if (!this.isTypeAdded(type)) {
        const newSelectedFilters = [...selectedFilters, filterToAdd]
        this.$emit('updateFilters', { type, newSelectedFilters, clearInput: true })
      }
    },
    removeFilter(type, index) {
      const newSelectedFilters = [...this.activePageFilter[type]]
      newSelectedFilters.splice(index, 1)
      this.$emit('updateFilters', { type, newSelectedFilters })
    },
    clearFilter(type) {
      this.$emit('updateFilters', { type, newSelectedFilters: type === 'country' ? '' : []})
    },
    isTypeAdded(type) {
      const lowercaseFilter = this.activePageFilter[type].map((item) => item.toLowerCase())

      return lowercaseFilter.includes(this.fields[type].value.trim().toLowerCase())
    },
    disableAddButton(type) {
      const relatedField = this.fields[type]
      return Boolean(this.isTypeAdded(type) || relatedField.value.length === 0 || relatedField.disabled)
    },
    openFilters(index) {
      if (this.editPathMode && index === this.activePageFilterIndex) {
        return
      }

      if (!this.validatePath()) {
        this.disableEditPathMode()

        return
      }

      this.setActivePageFilterIndex(index)

      setTimeout(() => {
        this.showPanel = true

        setTimeout(() => {
          this.showContent = true
        }, this.animationTimeout)
      })
    },
    closeFilters() {
      this.showContent = false

      setTimeout(() => {
        this.showPanel = false

        setTimeout(() => {
          this.setActivePageFilterIndex(null)
        }, this.animationTimeout)
      }, this.animationTimeout)
    },
    setActivePageFilterIndex(index) {
      this.$emit('setActivePageFilterIndex', index)
    },
    addNewPageFilter() {
      this.editPathMode = false

      this.$nextTick(() => {
        this.$emit('addNewPageFilter')

        this.$nextTick(() => {
          this.openFilters(this.activePageFilterIndex)
          this.$nextTick(() => {
            this.editPathMode = true
          })
        })
      })
    },
    validatePath() {
      this.clearErrors()

      const { filters } = this

      if (!this.editPathMode) {
        return true
      }

      const pagePaths = filters.filter((filter) => !!filter.pagePath).map((filter) => filter.pagePath)
      // regexes are here to check if we're adding the same path but with/without a trailing slash
      const samePaths = pagePaths.filter((path) => path.replace(/\/$|$/, '') === this.mutablePagePath.replace(/\/$|$/, ''))
      // this can be altered later if we want
      const isPathCorrect = this.mutablePagePath.startsWith('/')
      const isPathEmpty = !this.mutablePagePath
      const samePathError = (samePaths.length > 0 && pagePaths.indexOf(this.mutablePagePath) !== this.activePageFilterIndex)

      const error = samePathError || isPathEmpty || !isPathCorrect

      if (!error) {
        this.editPathMode = false
        return true
      }

      this.editInputError = true

      if (samePathError) {
        this.editInputErrorText = 'The path you\'ve entered already exists'
      } else if (isPathEmpty) {
        this.editInputErrorText = 'The path cannot be empty'
      } else {
        this.editInputErrorText = 'The path should start with "/" character'
      }
      return false
    },
    savePath() {
      if (this.validatePath()) {
        let newPath = this.mutablePagePath.replace(/\/$|$/, '').trim()
        if (!newPath) {
          newPath = '/'
        }

        this.$emit('updatePath', { index: this.activePageFilterIndex, newPath })
      }
    },
    clearErrors() {
      this.editInputError = false
      this.editInputErrorText = ''
    },
    setTooltipIndexes() {
      const newIndexes = []

      new Array(this.filters.length).fill(0).forEach((_, index) => {
        const relatedInput = this.$refs[`pagePathContainer${index}`]

        if (relatedInput?.length && relatedInput[0].scrollWidth > relatedInput[0].clientWidth) {
          newIndexes.push(index)
        }
      })

      this.tooltipIndexes = newIndexes
    },
    disableEditPathMode() {
      if (this.activePageFilter?.new) {
        const confirmationPopupText = 'You didn\'t save your new page filter. Are you sure you want to cancel?'
        runSwal(() => {
          this.editPathMode = false
        }, { content: confirmationPopupText, buttons: ['No', 'Yes, i want to cancel']})
      } else {
        this.editPathMode = false
      }
    },
    removePageFilter(oldIndex) {
      this.$emit('setLastSelectedPageFilterIndex', null)
      this.$emit('removePageFilter', oldIndex)
    }
  },
  computed: {
    filtersLayout() {
      return {
        cities: this.fields.cities,
        administrativeAreas: this.fields.administrativeAreas
      }
    },
    countryInput() {
      return this.fields.country
    },
    nonDefaultFilters() {
      return this.filters.filter((_filter, index) => index > 0)
    }
  },
  watch: {
    activePageFilterIndex(newIndex, oldIndex) {
      // i love js
      if (typeof newIndex !== 'undefined' && newIndex !== null && newIndex >= 0) {
        this.$emit('setLastSelectedPageFilterIndex', newIndex)
      }

      this.clearErrors()
      this.editPathMode = false

      if (this.filters[oldIndex]?.new) {
        this.removePageFilter(oldIndex)
      }

      this.$nextTick(() => {
        this.setTooltipIndexes()
      })
    },
    editPathMode(newValue) {
      this.clearErrors()

      if (newValue) {
        this.mutablePagePath = this.activePageFilter.pagePath
        // this.$refs.pathInput
        this.$nextTick(() => {
          this.$refs.pathInput[0]?.focus()
        })
      } else {
        this.mutablePagePath = ''

        if (this.activePageFilter?.new) {
          this.removePageFilter()
        }
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.setTooltipIndexes()
    })
    window.addEventListener('resize', this.setTooltipIndexes)
  },
  destroyed() {
    window.removeEventListener('resize', this.setTooltipIndexes)
  }
}
</script>
