import FiltersStep from '../../edit_steps/geobooster_widgets_filters'
import { runSwal } from '../../../../../common/delete_with_swal'

export default {
  components: {
    FiltersStep
  },
  data: () => ({
    steps: {
      filters: {
        fields: {
          country: {
            component: 'CustomSelect',
            name: 'country',
            value: '',
            values: [],
            label: 'Country',
            error: false,
            errorText: '',
            allowEmpty: true,
            preselectFirst: false,
            openDirection: 'bottom',
            contentMinHeight: true
          },
          administrativeAreas: {
            component: 'CustomInput',
            name: 'administrativeAreas',
            value: '',
            label: 'State',
            error: false,
            errorText: '',
            placeholder: 'Enter state and press "Add" button to add it as a filter option'
          },
          cities: {
            component: 'CustomInput',
            name: 'cities',
            value: '',
            label: 'City',
            error: false,
            errorText: '',
            placeholder: 'Enter city and press "Add" button to add it as a filter option'
          },
          categories: {
            component: 'CustomSelect',
            name: 'categories',
            value: '',
            values: [],
            label: 'Categories',
            error: false,
            errorText: '',
            placeholder: 'Select category',
            allowEmpty: true,
            preselectFirst: false,
            openDirection: 'bottom',
            contentMinHeight: true
          }
        },
        filters: [],
        activePageFilterIndex: null,
        lastSelectedPageFilterIndex: null
      }
    }
  }),
  methods: {
    setActivePageFilterIndex(newIndex) {
      this.steps.filters.activePageFilterIndex = newIndex
    },
    setLastSelectedPageFilterIndex(newIndex) {
      this.steps.filters.lastSelectedPageFilterIndex = newIndex
    },
    addNewPageFilter() {
      this.steps.filters.filters.push({
        pagePath: '',
        categories: [],
        cities: [],
        administrativeAreas: [],
        country: '',
        error: false,
        new: true
      })

      this.setActivePageFilterIndex(this.steps.filters.filters.length - 1)
    },
    removePageFilter(index = this.steps.filters.activePageFilterIndex) {
      if (!index) {
        return
      }

      const removeFilter = () => {
        this.steps.filters.filters.splice(index, 1)
        this.saveConfig()
        this.setActivePageFilterIndex(null)
      }

      if (this.steps.filters.filters[index].new) {
        // remove new filters without confirmation because confirmation is handled
        // in the component
        removeFilter()
        return
      }

      const confirmationText =
        `Are you sure you want to delete filter for page path "${this.steps.filters.filters[index].pagePath}"? This action cannot be undone.`

      runSwal(removeFilter, { content: confirmationText })
    },
    setPageFilters({ type, newSelectedFilters, clearInput = false }) {
      this.activePageFilter[type] = newSelectedFilters

      if (clearInput) {
        this.steps.filters.fields[type].value = ''
      }
    },
    validateFilters() {
      this.clearFiltersErrors()

      const { filters } = this.steps.filters
      const pagePaths = filters.filter((filter) => !!filter.pagePath).map((filter) => filter.pagePath)
      const filtered = pagePaths.filter((v, i) => pagePaths.indexOf(v) !== i)
      const filtersWithEmptyPaths = filters.filter((filter) => !filter.pagePath)

      const errors = {}

      filtersWithEmptyPaths.forEach((filter) => {
        filter.error = true
      })
      filters.forEach((filter) => { filter.error = filtered.includes(filter.pagePath) })

      if (filtersWithEmptyPaths.length) {
        errors.empty_filter_path = ['Some of your page paths in filters tab are empty.']
      }

      if (filtered.length) {
        errors.repeating_filter_path = ['Some of your page paths are repeated. Page paths should be unique.']
      }

      this.setErrors(errors, this.tabsMap.filters)

      return !filtersWithEmptyPaths.length && !filtered.length
    },
    clearFiltersErrors() {
      this.steps.filters.filters.forEach((filter) => {
        filter.error = false
      })
    },
    updatePagePath({ index, newPath }) {
      if (this.steps.filters.filters[index]) {
        this.steps.filters.filters[index].pagePath = newPath
        this.steps.filters.filters[index].new = false
      }
    },
    addDefaultFilter() {
      this.steps.filters.filters.unshift({
        pagePath: '*',
        categories: [],
        cities: [],
        administrativeAreas: [],
        country: ''
      })
    }
  },
  computed: {
    activePageFilter() {
      return this.steps.filters.filters[this.steps.filters.activePageFilterIndex]
    },
    previewPageFilter() {
      return this.activePageFilter ? {
        ...this.activePageFilter,
        new: undefined
      } : undefined
    }
  },
  watch: {
    'steps.filters.activePageFilterIndex': {
      handler(newVal) {
        if (!newVal) {
          return
        }
        setTimeout(() => {
          const { fields } = this.steps.filters
          fields.administrativeAreas.value = ''
          fields.cities.value = ''
          fields.categories.value = ''
        })
      }
    }
  }
}
