export const GEOGRID_CREATED = 'geogrid.created'
export const REVIEW_CREATED = 'gmb/review.created'
export const AUDIT_REPORT_CREATED = 'audit_report.created'
export const AUDIT_REPORT_FAILED = 'audit_report.failed'
export const LOCATION_UPDATED = 'gmb/location.updated'
export const LOCATION_VERIFIED = 'gmb/location.verified'
export const LOCATION_REACTIVATED = 'gmb/location.reactivated'
export const LOCATION_DEACTIVATED = 'gmb/location.deactivated'
export const GOOGLE_ACCOUNT_DISCONNECTED = 'google_account.disconnected'

export const mappings = {
  [GEOGRID_CREATED]: 'Geogrid Report Generated',
  [AUDIT_REPORT_CREATED]: 'Audit Report Generated',
  [AUDIT_REPORT_FAILED]: 'Audit Report Failed',
  [REVIEW_CREATED]: 'New GMB Review',
  [LOCATION_UPDATED]: 'GMB Location Updated',
  [LOCATION_VERIFIED]: 'GMB Location Verified',
  [LOCATION_DEACTIVATED]: 'GMB Location Deactivated',
  [LOCATION_REACTIVATED]: 'GMB Location Reactivated',
  [GOOGLE_ACCOUNT_DISCONNECTED]: 'Google Account Disconnected'
}
