<template>
  <div class="row">
    <div class="col-md-12">
      <div class='empty-state mt-6 mb-4' v-if="questions.length === 0">
        <div class='text-center text-muted'>
          <img :src="noQuestions" alt="No Questions Img" height=100>
          <h3 class='mt-4'>This location doesn't have any questions yet.</h3>
        </div>
      </div>
      <question-form />
    </div>
    <questions />
    <questionThread />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import noQuestions from 'no_data/questions.svg'
import Questions from './questions'
import QuestionThread from './question_thread'
import QuestionForm from './question_form'

export default {
  data() {
    return { noQuestions }
  },
  computed: mapState(['questions']),
  components: { Questions, QuestionThread, QuestionForm }
}
</script>
