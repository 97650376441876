import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

export default function createStore(hsh) {
  hsh.locationsConnections = hsh?.locationsConnections?.map((connection) => ({
    showList: false,
    ...connection,
    geogridConfigs: connection.geogridConfigs.map((geogridConfig) => ({
      ...geogridConfig,
      searchTerms: geogridConfig.searchTerms.map((searchTerm) => ({
        ...searchTerm,
        checked: connection.searchTermIds.includes(searchTerm.obfuscatedId)
      }))
    }))
  }))

  return new Vuex.Store({
    state: {
      locationsConnections: [],
      ...hsh
    },
    actions: {
      addLocation({ commit }, payload) {
        axios.get(`/api/organizations_locations/${payload.orgLocationId}/geogrid_configs`)
          .then((res) => {
            commit('addLocation', {
              ...payload,
              showList: false,
              geogridConfigs: res.data
            })
          })
      }
    },
    mutations: {
      addLocation(state, location) {
        const connection = state.locationsConnections.find((l) => l.remoteId === location.remoteId)
        if (connection) {
          connection.destroy = false
        } else {
          state.locationsConnections.unshift({ ...location })
        }
      },
      removeLocation(state, payload) {
        const connection = state.locationsConnections.find((l) => l.remoteId === payload.remoteId)
        if (connection.id) {
          Vue.set(connection, 'destroy', true)
        } else {
          state.locationsConnections = state.locationsConnections.filter((l) => l.remoteId !== payload.remoteId)
        }
      },
      updateConnectionShowList(state, payload) {
        state.locationsConnections.forEach((connection) => {
          if (connection.remoteId === payload.remoteId) {
            connection.showList = !connection.showList
          }
        })
      }
    }
  })
}
