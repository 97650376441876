<template>
  <div class="form-size">
    <div v-if="configuration.status === 'searching_competitors'" class="loader">
      <i class="far fa-spinner-third fa-spin" />
    </div>
    <div v-else-if="competitorsList.length === 0 && !isRequested" class="mb-5">
      No competitors found. Please choose a different keyword.
    </div>
    <CompetitorsList v-else
      class="mb-5"
      :competitors-list="competitorsList"
      :chosen-competitors="configuration.chosenCompetitors"
      @onSelectCompetitors="onSelectCompetitors" />

    <div class="d-flex justify-content-between flex-wrap col-gap-6 row-gap-4 mb-5">
      <button
        type="button"
        class="btn btn-primary rounded"
        :disabled="configuration.status === 'searching_competitors' || !selectedCompetitors.length || isRequested"
        @click="createAudit">
        Finish
      </button>

      <button type="button" class="btn gap-2 rounded" @click="setConfigurationState({ status: 'initialized' })">
        <i class="far fa-long-arrow-left" />
        Back to configurations
      </button>

      <a :href="basePath" class="btn gap-2 rounded ml-auto">
        <i class="far fa-times" />
        Cancel
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import CompetitorsList from './competitors_list'
import { subscribe, removeChannel } from '../helpers'

export default {
  components: { CompetitorsList },
  data() {
    return {
      selectedCompetitors: []
    }
  },
  computed: mapState([
    'configuration',
    'basePath',
    'isRequested',
    'error',
    'competitorsList'
  ]),
  created() {
    this.channel = subscribe('AuditReportsChannel', {
      disconnected: this.backupGettingCompetitors,
      rejected: this.backupGettingCompetitors,
      received: (msg) => {
        if (msg?.config?.status) {
          this.getCompetitorsList().then(() => {
            this.setConfigurationState({ status: msg.config.status })
          })
        }
      }
    })

    if (this.configuration.status === 'choosing_competitors') {
      this.getCompetitorsList()
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
    removeChannel(this.channel)
  },
  methods: {
    ...mapMutations(['setConfigurationState']),
    ...mapActions(['getCompetitorsList', 'saveConfiguration', 'getConfigState']),
    onSelectCompetitors(competitors) {
      this.selectedCompetitors = competitors
    },
    createAudit() {
      this.setConfigurationState({ competitors: this.selectedCompetitors.map((c) => c.id) })
      this.saveConfiguration({ config: this.configuration })
    },
    backupGettingCompetitors() {
      if (this.configuration.status !== 'searching_competitors') return

      removeChannel(this.channel)
      this.interval = setInterval(() => {
        this.getConfigState().then((res) => {
          if (res.data.status !== 'searching_competitors') {
            this.setConfigurationState(res.data)
            this.getCompetitorsList()
            clearInterval(this.interval)
          }
        })
      }, 5000)
    }
  }
}
</script>
