<template>
  <div class="onboarding-content">
    <h1>Welcome to the Local Viking GMB Management Platform</h1>
    <p>
      Our platform allows you to create & schedule posts for the locations,
      track GMB rankings, and get notifications when your location's attributes are changed.
      To get started, you need to take several important steps.
    </p>

    <AppAccordion>
      <template #title>
        Set up a CNAME for your private instance domain
      </template>
      <template #body>
        <div>
          <p>
            Set up a white label domain or subdomain.<br> Set a CNAME record for that domain to wl.localbusinessreporting.com.
          </p>
          <p>
            Here are the instructions to set your CNAME up for
            <a href="https://www.godaddy.com/help/add-a-cname-record-19236">Godaddy</a>,
            <a href="https://www.namecheap.com/support/knowledgebase/article.aspx/9646/2237/how-to-create-a-cname-record-for-your-domain/">NameCheap</a>,
            <a href="https://support.cloudflare.com/hc/en-us/articles/360020348832-Understanding-a-CNAME-Setup">Cloudflare</a>, and
            <a href="https://support.squarespace.com/hc/en-us/articles/360002101888-Adding-custom-DNS-records-to-your-Squarespace-domain">Squarespace</a>. If
            you are with another registrar, please go to <a href="https://google.com">Google</a> and search “how to add a CNAME for "Insert Name of Registrar"”.
          </p>
          <p>
            Please note, after adding a CNAME record, it can take up to 2-3 hours while the DNS records refresh (although 20 minutes is more typical).
            To check if the DNS settings refreshed, click the icon with looped arrows next to the “White label domain” input.
          </p>
        </div>
      </template>
    </AppAccordion>

    <AppAccordion>
      <template #title>
        Google My Business API Request
      </template>
      <template #body>
        <div>
          <ol>
            <li>
              Fill out the GMB API Request Form
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSfC_FKSWzbSae_5rOpgwFeIUzXUF1JCQnlsZM_gC1I2UHjA3w/viewform" target="_blank">here</a>.
              <ol type="a">
                <li>When you apply, use an email for an allowlist request form with your domain name on your website.</li>
                <li>
                  We advise leaving the project in Google Console without a logo:
                  adding a logo means that the project must undergo a Google verification again.
                </li>
              </ol>
            </li>
            <li>
              Wait for the approval email from Google, confirming your access to the GMB API. Approval can take up to two weeks.
            </li>
          </ol>
          <img :src="require('devise/accordion_file_api.png')" alt="" class="img-fluid">
        </div>
      </template>
    </AppAccordion>

    <AppAccordion>
      <template #title>
        Enable your Google APIs and configure your OAuth client
      </template>
      <template #body>
        <div>
          <p>
            Once you receive the email from Google saying you were approved, enable your Google API.
            Follow the "Basic setup" link from the confirmation email, then click the menu button to the left of the "Google Cloud Platform" logo,
            and select "APIs & Services" -> "Library". On the "Library" page click the "Private" link in the sidebar,
            then the "Google My Business API" button, and finally - the "Enable" button.
          </p>
          <img :src="require('devise/accordion_google_api_1.gif')" alt="" class="img-fluid" width="100%">
          <p class="mt-4">Return to the "Library" page again, find and activate 5 more APIs:</p>
          <img :src="require('devise/accordion_google_api_2.png')" alt="" class="img-fluid">
          <ul class="mt-4">
            <li>My Business Business Information API</li>
            <li>My Business Notifications API</li>
            <li>My Business Account Management API</li>
            <li>My Business Q&A API</li>
            <li>My Business Verifications API</li>
            <li>Business Profile Performance API</li>
          </ul>
          <p class="mt-4">
            Click on each API and hit the “Enable” button if the API is disabled.
            After activating all APIs, please wait for at least 10 minutes while Google is tweaking the APIs on their side.
          </p>
          <p class="mb-0">
            Next, click the menu button to the left of the "Google Cloud Platform" logo again, and select "APIs & Services" -> "Credentials".
            Then click the "Create credentials" button and select the "OAuth Client ID" option from the dropdown menu.
            The window "Create OAuth client ID" opens. Set the “Application type” field value to "Web application",
            set the "Name" field to any name you like, or leave it as is, scroll down to the "Authorized redirect URIs" section,
            and click the "Add URI" button.
          </p>
          <img :src="require('devise/accordion_google_api_3.png')" alt="" class="img-fluid">
          <p>
            Paste <code>https://{{ domain }}/callbacks/oauth/google</code> into the “URIs” field and click the “Create” button.
            The window “OAuth client created" opens.
            Copy the Client ID and the Client Secret from that window and paste them into the "Google App ID" and "Google App Secret" fields on this page.
          </p>
        </div>
      </template>
    </AppAccordion>

    <AppAccordion>
      <template #title>
        Connect Google Account
      </template>
      <template #body>
        <div>
          Click the “Connect Google Account” button and follow the instructions.
          <br>
          Note: make sure, you’ve given permission (marked the checkbox) “See, edit, create and delete your Google business listings.”
        </div>
      </template>
    </AppAccordion>
  </div>
</template>

<script>
import AppAccordion from './content/accordion'

export default {
  components: { AppAccordion },
  props: {
    domain: {
      type: String,
      default: ''
    }
  }
}
</script>
