import Vue from 'vue'
import camelCaseKeys from 'camelcase-keys-deep'
import BusinessIndex from '../../vue_widgets/mobile/business/index'
import MomentsDashboard from '../../vue_widgets/mobile/dashboard/index'
import NoBusiness from '../../vue_widgets/mobile/checkins/no_business'
import BusinessForm from '../../vue_widgets/mobile/business/form'
import BusinessSettings from '../../vue_widgets/mobile/business/settings'
import BusinessReporting from '../../vue_widgets/mobile/business/reporting.vue'
import renewSubscription from './helpers'

Styxie.Initializers.MobileBusinesses = {
  index: (params) => {
    $(() => {
      const renderedComponent = params.noBusinesses ? NoBusiness : BusinessIndex
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-business-index'),
        render: (h) => h(renderedComponent, { props: camelCaseKeys(params) })
      })
    })
  },
  dashboard: () => {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-moments-dashboard'),
        render: (h) => h(MomentsDashboard, {})
      })
    })
  },
  new: (params) => {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-business-form'),
        render: (h) => h(BusinessForm, { props: camelCaseKeys(params) })
      })
    })
  },
  edit: (params) => {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-business-settings'),
        render: (h) => h(BusinessSettings, { props: camelCaseKeys(params) })
      })
      renewSubscription(params)
    })
  },
  show: (params) => {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-business-reporting'),
        render: (h) => h(BusinessReporting, { props: camelCaseKeys(params) })
      })

      renewSubscription(params)
    })
  }
}
