import Qs from 'qs'
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys-deep'

import datepicker, {
  humanizeRangeDate, setRangeToCookie, TIME_FORMAT
} from '../../../../common/datepicker'
import { getPercentage } from './helpers'

const DEFAULT_STAT = 'business_impressions_desktop_maps'
const SECONDS_IN_DAY = 1000 * 60 * 60 * 24

Vue.use(Vuex)

function withDiff(state) {
  return {
    difference: getPercentage(camelCaseKeys(state)),
    ...state
  }
}

export function prefilData(hsh, el, secondStartDate) {
  const xy = (_, i) => {
    const data = { x: (el.data[0] && el.data[0].x + SECONDS_IN_DAY * i) || secondStartDate, y: null }
    return el.data[i] || data
  }
  return hsh.data.map(xy)
}

function collectCompareChartData(state) {
  const compareChartData = []

  state.allChartData.forEach((chartData) => {
    const chartCompareData = state.allChartCompareData.find((el) => el.id === chartData.id)
    const { ranges } = state.insightsDateRanges

    if (state.insightsDateRanges.compare) {
      if (chartData.data.length > chartCompareData.data.length) {
        chartCompareData.data = prefilData(chartData, chartCompareData, ranges[1].start)
      } else {
        chartData.data = prefilData(chartCompareData, chartData, ranges[0].start)
      }
    }

    compareChartData.push({
      id: chartData.id,
      name: chartData.name,
      data: [chartData, chartCompareData].map((el, i) => ({
        ...el,
        dates: humanizeRangeDate(ranges[i]),
        marker: { enabled: false }
      }))
    })
  })

  return compareChartData
}

export default function createStore(data) {
  return new Vuex.Store({
    state: {
      insightsPath: data.insightsPath,
      insightsExists: data.insightsExists,
      insightsCsvPath: data.insightsCsvPath,
      chartData: [],
      allChartData: [],
      allChartCompareData: [],
      stats: data.enabledStats || [],
      currentStat: data.enabledStats ? data.enabledStats[0] : DEFAULT_STAT,
      responseKey: data.responseKey,
      httpParams: data.httpParams,
      insightsDateRanges: data.insightsDateRanges || { compare: false, ranges: datepicker() },
      minDate: data.firstEnabledAt || null
    },
    getters: {
      compare(state) { return state.insightsDateRanges.compare },
      insightsPath(state) { return state.insightsPath },
      insightsExists(state) { return state.insightsExists },
      insightsCsvPath(state) { return state.insightsCsvPath },
      chartData(state) { return state.chartData },
      stats(state) { return state.stats },
      currentStat(state) { return state.currentStat },
      responseKey(state) { return state.responseKey },
      httpParams(state) { return state.httpParams },
      csvPath(state) {
        const { start, end } = state.insightsDateRanges.ranges[0]
        const prms = Qs.stringify({ start_date: start, end_date: end })
        return `${state.insightsCsvPath}?${prms}`
      },
      csvTitle(state) {
        const { start, end } = state.insightsDateRanges.ranges[0]
        return `Export Insights for (${start} - ${end}) to CSV`
      },
      deprecatedInsightsCsvPath(state) {
        const { start, end } = state.insightsDateRanges.ranges[0]
        const prms = Qs.stringify({ start_date: start, end_date: end, old_insights: true })
        return `${state.insightsCsvPath}?${prms}`
      },
      deprecatedInsightsCsvTitle(state) {
        const { start, end } = state.insightsDateRanges.ranges[0]
        return `Export Deprecated Insights for (${start} - ${end}) to CSV`
      },
      currentTooltip(state) {
        const { start, end } = state.insightsDateRanges.ranges[0]
        return `${start} - ${end}`
      },
      compareTooltip(state) {
        const { start, end } = state.insightsDateRanges.ranges[1]
        return `${start} - ${end}`
      },
      insightsDateRanges(state) { return state.insightsDateRanges },
      minDate(state) {
        if (state.minDate) {
          return moment.utc(state.minDate).subtract(1.5, 'years').format(TIME_FORMAT)
        }
        return moment.utc(state.minDate).format(TIME_FORMAT)
      }
    },
    actions: {
      updateDateRanges({ commit, dispatch }, payload) {
        commit('updateDateRanges', payload)
        dispatch('fetchInsights')
      },
      fetchInsights({ commit, state }) {
        const { ranges } = state.insightsDateRanges
        let request

        if (data.httpParams) {
          request = axios.get(...data.httpParams)
        } else {
          const params = {
            by_period: {
              start: ranges[0].start,
              end: ranges[0].end
            }
          }
          params.by_compare_period = {
            start: ranges[1].start,
            end: ranges[1].end
          }

          request = axios.get(state.insightsPath, {
            params,
            paramsSerializer(prm) {
              return Qs.stringify(prm, { arrayFormat: 'brackets' })
            }
          })
        }

        commit('resetData')
        request.then((res) => {
          const resData = state.responseKey ? res.data[state.responseKey] : res.data
          commit('setData', resData)
        })
      }
    },
    mutations: {
    /* eslint-disable no-param-reassign */
      setData(state, newData) {
        if (!newData.chart_data?.length) return

        state.allChartData = newData.chart_data
        state.allChartCompareData = newData.chart_compare_data
        state.chartData = collectCompareChartData(state)
        state.stats = newData.stats.length ? newData.stats.map(withDiff) : []
      },
      resetData(state) {
        state.allChartData = []
        state.allChartCompareData = []
        state.chartData = []
        state.stats = []
      },
      updateDateRanges(state, payload) {
        state.insightsDateRanges = payload
        setRangeToCookie(state.insightsDateRanges.ranges[0])
      },
      setCurrentStat(state, statId) {
        const { compare } = state.insightsDateRanges

        if (compare) {
          state.currentStat = statId
          state.chartData = collectCompareChartData(state)
        }
      }
    /* eslint-enable no-param-reassign */
    }
  })
}
