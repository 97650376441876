<template>
  <div class='d-flex justify-content-center text-md-right'>
    <div class='pr-2'>AGR:<br>ATGR:<br>SoLV:</div>
    <div class='text-dark'><b>{{ agr || 'N/A' }}<br>{{ atgr || 'N/A' }}<br>{{ solvPercent }}</b></div>
  </div>
</template>
<script>
export default {
  name: 'GeogridAverageRanks',
  props: {
    agr: String,
    atgr: String,
    solv: String
  },
  computed: {
    solvPercent() {
      if (Number.isNaN(parseFloat(this.solv))) return 'N/A'

      return `${parseInt(this.solv * 100, 10)}%`
    }
  }
}
</script>
