<template>
  <div v-if="searchTerms && searchTerms.length" class="report-search-keyword">
    <div class="d-flex align-items-center gap-4 mb-4">
      <h2 class="h4 m-0 font-weight-semi-bold">Search Keyword</h2>
      <button type="button" class="btn btn-sm btn-link gap-2 noprint" @click="changeSortDirection">
        <template v-if="sortDirection === 1">
          <i class="far fa-arrow-down-wide-short" />
          <span class="font-size-12">Descending</span>
        </template>
        <template v-else>
          <i class="far fa-arrow-up-wide-short" />
          <span class="font-size-12">Ascending</span>
        </template>
      </button>
      <!-- <a href="#" class="btn btn-sm btn-semi-primary gap-2 ml-auto">
        <i class="far fa-file-csv" />
        <span class="font-size-12">Export</span>
      </a> -->
    </div>
    <ul class="report-search-keyword-list">
      <li v-for="({ id, text, newRank }, index) in sortedList" :key="id">
        <div class="report-search-keyword-list-index">{{ index + 1 }}</div>
        <div class="font-weight-semi-bold">{{ text }}</div>
        <div class="ml-auto">{{ newRank || '-' }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    // 1 - high to low
    // -1 - low to high
    return { sortDirection: 1 }
  },
  computed: {
    ...mapState(['searchTerms']),
    sortedList() {
      return this.searchTerms.map((st) => ({
        ...st,
        newRank: Number.isInteger(parseInt(st.newRank, 10)) ? st.newRank : 0
      })).sort((a, b) => (b.newRank - a.newRank) * this.sortDirection)
    }
  },
  methods: {
    changeSortDirection() {
      this.sortDirection *= -1
    }
  }
}
</script>
