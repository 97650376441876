<template>
  <div class="color-dark">
    <h2 class="h4 m-0 mb-3 font-weight-semi-bold">
      <slot name="title" />
    </h2>

    <div :class="['group-linear-charts', { reverse }]">
      <div class="group-linear-charts-tiles">
        <MetricTile v-for="property in existedPropertiesStats" :key="parsedData[property].id" :property="parsedData[property]" :map-icons="mapIcons" />
      </div>
      <div class="d-flex flex-column gap-4">
        <GroupChart :chart-data="groupChartData" />
        <div class="d-flex align-items-start justify-content-between col-gap-8 row-gap-4">
          <div v-for="(property, index) in existedPropertiesStats" :key="parsedData[property].id" class="group-linear-charts-legend">
            <div class="font-weight-bold">{{ parsedData[property].name }}</div>
            <div class="d-flex align-items-center col-gap-4 row-gap-2">
              <div class="d-flex align-items-center gap-2 font-size-12">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="2" viewBox="0 0 16 2">
                  <path d="M0 1H16" :stroke="colors[index]" stroke-width="2" />
                </svg>
                {{ getRangeText(parsedData[property].chartData[0]) }}
              </div>
              <div class="d-flex align-items-center gap-2 font-size-12">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="2" viewBox="0 0 16 2">
                  <path d="M0 1H16" :stroke="colors[index]" stroke-width="2" stroke-dasharray="3 3" />
                </svg>
                {{ getRangeText(parsedData[property].chartData[1]) }}
              </div>
            </div>
          </div>
        </div>
        <p class="m-0 text-muted small">Insights might slightly differ because the report time zone is always UTC</p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import MetricTile from './metric_tile'
import GroupChart from './group_chart'

export default {
  components: { MetricTile, GroupChart },
  props: {
    properties: { type: Array, required: true },
    parsedData: { type: Object, required: true },
    mapIcons: { type: Object, default: () => ({}) },
    reverse: { type: Boolean, default: false }
  },
  created() {
    this.colors = ['#377dff', '#29ba6c']
    this.existedPropertiesStats = this.properties.filter((property) => Object.keys(this.parsedData).includes(property))
  },
  computed: {
    groupChartData() {
      return this.existedPropertiesStats.map((property, index) => {
        const { chartData } = this.parsedData[property]
        chartData.forEach((data, i) => {
          data.color = this.colors[index]
          data.dashStyle = i % 2 === 0 ? 'Solid' : 'Dash'
          data.marker = { enabled: false }
        })

        return chartData
      }).flat()
    }
  },
  methods: {
    getRangeText(chartData) {
      const { data } = chartData

      return `${moment.utc(data[0].x).format('MMM D, YYYY')} — ${moment.utc(data[data.length - 1].x).format('MMM D, YYYY')}`
    }
  }
}
</script>
