import axiosTransform from 'common/axios'

const patchServiceList = (serviceList) => axios.patch(
  `/api${window.location.pathname}`,
  { serviceList },
  axiosTransform
)

export default function createStore({ locationCategories }) {
  return new Vuex.Store({
    state: {
      categoriesWithServiceItems: {},
      serviceList: {}
    },
    getters: {
      serviceItemsMap(state) {
        const hsh = {}

        state.categoriesWithServiceItems.forEach((category) => {
          category.serviceTypes.forEach(({ displayName, serviceTypeId }) => {
            hsh[serviceTypeId] = displayName
          })
        })

        return hsh
      }
    },
    actions: {
      fetchBase({ commit }) {
        const promises = [axios.get(`/api${window.location.pathname}`, axiosTransform)]
        locationCategories.forEach(({ categoryId }) => {
          promises.push(axios.get(`/api${window.location.pathname}/available_service_items?category_id=${categoryId}`, axiosTransform))
        })

        return Promise.all(promises)
          .then(([currentServiceListRes, ...categoriesRes]) => {
            commit('changeCategoriesWithServiceItems', categoriesRes.map((res) => res.data.categories[0]))
            commit('changeServiceList', currentServiceListRes.data)
          })
      },
      removeItem({ state, commit }, item) {
        return patchServiceList({
          name: state.serviceList.name,
          serviceItems: state.serviceList.serviceItems.filter((el) => el !== item)
        }).then((res) => commit('changeServiceList', res.data))
      },
      updateItem({ state, commit }, { item, price, description }) {
        item.price = price

        if (item.freeFormServiceItem) {
          item.freeFormServiceItem.label.description = description
        } else if (item.structuredServiceItem) {
          item.structuredServiceItem.description = description
        }

        return patchServiceList({
          name: state.serviceList.name,
          serviceItems: state.serviceList.serviceItems
        }).then((res) => {
          commit('changeServiceList', res.data)
          return res
        })
      },
      addItems({ state, commit }, newItems) {
        return patchServiceList({
          name: state.serviceList.name,
          serviceItems: [...state.serviceList.serviceItems, ...newItems]
        }).then((res) => {
          commit('changeServiceList', res.data)
          return res
        })
      }
    },
    mutations: {
      changeCategoriesWithServiceItems(state, items) {
        state.categoriesWithServiceItems = items
      },
      changeServiceList(state, list) {
        state.serviceList = list
      }
    }
  })
}
