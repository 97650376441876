<template>
  <div class="settings-form">
    <h2 class="page-title text-break">Set business location to start tracking keywords</h2>
    <p>We will not be able to calculate ranks without it.</p>
    <div>
      <div class="form-field mb-3">
        <span class="caption">Search location</span>
        <GoogleAutocomplete
          :google-map-api-key="googleMapApiKey"
          :options="optionAutocomplete"
          placeholder="Address or service region"
          @updateAddress="updateAddress" />
        <span v-if="validationError" class="text-danger">Please, select location</span>
      </div>
      <div v-if="exampleLink && exampleLink.length" class="form-field mb-3">
        <span class="caption">Link to the search location in Google</span>
        <a :href="exampleLink"
          class="link text-break"
          target="_blank">
          {{ exampleLink }}
        </a>
        <img v-if="searchLocationMapUrl" :src="searchLocationMapUrl" class="block mt-4 max-w-full h-auto">
      </div>
      <div class="map" ref="map" />
    </div>
    <div>
      <button :disabled="sending" :class="['btn btn-primary', {'with-icon': sending}]" @click="submit">
        <i v-if="sending" class="far fa-spinner-third fa-spin" />
        Set location
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import GoogleAutocomplete from '../../components/google_address_autocomplete'

export default {
  components: { GoogleAutocomplete },
  data() {
    return {
      validationError: false,
      sending: false,
      searchLocationMapUrl: ''
    }
  },
  created() {
    this.optionAutocomplete = {
      lang: 'en',
      fields: ['formatted_address', 'geometry'],
      types: ['geocode']
    }
  },
  computed: mapState([
    'googleMapApiKey',
    'formattedAddress',
    'exampleLink',
    'includePath',
    'defaultCheckFrequency',
    'searchLanguage',
    'lat',
    'lng'
  ]),
  methods: {
    ...mapActions(['updateSettings', 'getKeywordsAddress']),
    ...mapMutations(['updateAddressComponents', 'generateExampleLink']),
    async submit() {
      if (!this.lat || !this.lng) {
        this.validationError = true
        return
      }

      try {
        this.sending = true
        await this.updateSettings({
          includePath: this.includePath,
          defaultCheckFrequency: this.defaultCheckFrequency,
          searchLanguage: this.searchLanguage
        })
      } finally {
        this.sending = false
      }
    },
    updateAddress(address) {
      const NO_ADDRESS = { lat: null, lng: null, formattedAddress: '' }
      this.setStaticMap(address)
      this.updateAddressComponents(address ?? NO_ADDRESS)
      this.generateExampleLink()
    },
    generateStaticMapUrl({ lat, lng }) {
      return `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}
    &zoom=14&size=584x254&maptype=roadmap&markers=color:red%7C${lat},${lng}&key=${this.googleMapApiKey}&language=en`
    },
    setStaticMap(location) {
      if (!location || !location?.lat || !location?.lng) {
        this.searchLocationMapUrl = ''
        return
      }

      const { lat, lng } = location

      this.searchLocationMapUrl = this.generateStaticMapUrl({
        lat,
        lng
      })
    }

  },
  watch: {
    formattedAddress: {
      handler() {
        this.validationError = false
      }
    }
  }
}
</script>
