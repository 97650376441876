import Vuex from 'vuex'
import axios from 'axios'
import toastr from 'toastr'
import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys-deep'
import { DEFAULT_CENTER, DEFAULT_ZOOM, MAP_CONTROLS_SETTINGS } from '../../common/map_helpers'
import {
  createMarkers, getLastMapCenter, storeMapCenter, fitBounds, showGridMarkers, showDummyMarkers, MAP_PADDINGS
} from './map/helpers'
import {
  DEFAULT_ERROR_MESSAGE, NOT_FOUND, NOT_AUTHORIZED_MESSAGE, ICONS_PATH
} from '../../common/constants'
import { searchParams } from '../../common/search_params'
import axiosTransform from '../../common/axios'

const SPOT_ERROR_MESSAGE = 'An error occurred during processing of this geogrid point. Please try again later or contact support if it occurs again.'

function splitCurrentPath() {
  // any show page should be availble to load with path /geogrids, so compute splitted path:
  if (window.location.pathname === '/geogrids') {
    const params = camelCaseKeys(searchParams())
    if (params.configId) return ['scheduled', 'geogrids', 'show']
    if (params.geogridId) return ['live', 'geogrids', 'show']
    return ['scheduled', 'geogrids', 'index']
  }
  return window.location.pathname.substr(1).split('_')
}

function switchDisableSpotState(state, marker, index) {
  if (state.disabledPoints.has(index + 1)) state.disabledPoints.delete(index + 1)
  else state.disabledPoints.add(index + 1)

  marker.setOptions({
    icon: {
      url: `${ICONS_PATH}preview-${state.disabledPoints.has(index + 1) ? 'disabled' : 'default'}.png`,
      anchor: new google.maps.Point(16, 16),
      scaledSize: state.iconScaledSize
    }
  })
}

function clickOnSpot(state, marker, index) {
  if (marker.rank) { // a show-page of geogrid: only created/processed geogrid-sets (markers) have ID
    if (marker.rank === 'D') {
      toastr.info('This spot was disabled')
    } else if (marker.rank === '0') {
      toastr.info('No results found')
    } else if (marker.rank === '!') {
      swal({ title: 'Point processing error', text: SPOT_ERROR_MESSAGE, dangerMode: true })
    } else {
      const spotsPath = `api/geogrids/${state.geogrid.obfuscatedId}/geogrid_sets/${marker.idx}`
      axios.get(spotsPath, axiosTransform).then((response) => { state.geogridSet = response.data })
    }
  } else { // a new-geogrid page
    switchDisableSpotState(state, marker, index)
  }
}

function findSlidersPosition({ geogrid, searchTerm }) {
  const position = searchTerm.geogrids.findIndex((el) => el.obfuscatedId === geogrid.obfuscatedId)
  if (position === -1) return searchTerm.geogrids.length - 1
  return position
}

function changeGeogridsPageUrl(state) {
  let path = `${state.pageState}_geogrids_${state.pageTab}`
  const selectedPlaceId = camelCaseKeys(searchParams()).placeId
  const params = {}
  if (state.config.obfuscatedId) params.configId = state.config.obfuscatedId
  if (state.geogrid.obfuscatedId) params.geogridId = state.geogrid.obfuscatedId
  if (selectedPlaceId && state.pageTab === 'show') params.placeId = selectedPlaceId
  if (Object.keys(params).length) path += `?${$.param(snakeCaseKeys(params))}`
  window.history.pushState('', document.title, path)
}

export default function createStore(data) {
  const store = new Vuex.Store({
    state: {
      paths: data.paths,
      geogrid: { attrs: {} },
      newGeogrid: { attrs: {} },
      newConfig: { attrs: {} },
      config: { attrs: {} },
      searchTerm: { geogrids: []},
      slider: null,
      dummyGeogrid: { attrs: {}, labels: []},
      deletedGeogridId: null,
      loader: 0,
      pageState: '',
      pageTab: 'index',
      map: {},
      mapId: data.mapId,
      mapMarkers: [],
      businessMarker: new google.maps.Marker({
        animation: google.maps.Animation.DROP,
        icon: {
          url: `${ICONS_PATH}gmb_marker.png`,
          scaledSize: new google.maps.Size(70, 70)
        }
      }),
      mapAlertMessage: '',
      distance: 100,
      distanceMeasure: 'meters',
      gridSize: 13,
      shouldNotify: false,
      localLanguageEnabled: 'Global',
      mapCenterLat: 0,
      mapCenterLng: 0,
      iconScaledSize: new google.maps.Size(30, 30),
      draggableMarker: new google.maps.Marker({
        zIndex: 10,
        optimized: false,
        icon: {
          url: `${ICONS_PATH}preview-draggable.png`,
          anchor: new google.maps.Point(15, 15),
          scaledSize: new google.maps.Size(30, 30),
          draggable: true
        }
      }),
      disabledPoints: new Set(),
      defaultTags: data.tags,
      geogridSet: null,
      showOnMapComponents: true,
      linda: data.linda,
      bounds: new google.maps.LatLngBounds(),
      isCompetitorGeogrid: false,
      chosenTimeZone: ''
    },
    mutations: {
      setMap(state, divId) {
        state.map = new google.maps.Map(document.getElementById(divId), {
          center: DEFAULT_CENTER,
          zoom: DEFAULT_ZOOM,
          // For some reason, webpack freaks out if we move this to MAP_CONTROLS_SETTINGS object
          streetViewControlOptions: {
            position: google.maps.ControlPosition.RIGHT_CENTER
          },
          ...MAP_CONTROLS_SETTINGS
        })

        storeMapCenter(state.map)
        const mapCenter = getLastMapCenter(state.map)

        state.mapCenterLat = mapCenter.lat
        state.mapCenterLng = mapCenter.lng

        state.mapMarkers = createMarkers()

        state.mapMarkers.forEach((marker, index) => {
          marker.addListener('click', () => { clickOnSpot(state, marker, index) })
        })

        state.draggableMarker.addListener('drag', (el) => {
          state.mapCenterLat = el.latLng.lat()
          state.mapCenterLng = el.latLng.lng()
          showDummyMarkers(state)
        })

        state.draggableMarker.addListener('dragend', () => {
          showDummyMarkers(state)
          state.bounds = fitBounds(state, state.gridSize * state.gridSize - 1)
        })
      },
      fitBounds(state, paddings = MAP_PADDINGS) {
        state.map.fitBounds(state.bounds, paddings)
      },
      zoom(state, val) {
        state.map.setZoom(state.map.zoom + val)
      },
      toggleOnMapComponents(state) {
        state.showOnMapComponents = !state.showOnMapComponents
      },
      toggleBusinessMarker(state, { attrs, show }) {
        if (typeof show !== 'boolean') {
          show = !state.businessMarker.map
        }

        if (!show) {
          state.businessMarker.setMap(null)
        } else {
          const latLng = {
            lat: parseFloat(attrs.businessLocationLat, 10),
            lng: parseFloat(attrs.businessLocationLng, 10)
          }
          state.businessMarker.setOptions({ position: latLng, map: state.map })
          state.bounds.extend(latLng)
          this.state.map.fitBounds(state.bounds, MAP_PADDINGS)
        }
      },
      toggleGeogridMarkers(state, show) {
        state.mapMarkers.forEach((marker) => {
          marker.setMap(show ? state.map : null)
        })
      },
      hideMarkers(state) {
        state.geogrid = { attrs: {}, ranks: []}
        state.mapMarkers.map((marker) => marker.setMap(null))
        state.businessMarker.setMap(null)
        state.draggableMarker.setMap(null)
      },
      renderGeogrid(state, geogrid) {
        state.disabledPoints = new Set(geogrid.disabledPoints)

        let newHightlightedPositions = []
        if (state.searchTerm.geogrids.length &&
          state.searchTerm.geogrids[state.searchTerm.geogrids.length - 1].obfuscatedId === geogrid.obfuscatedId) {
          newHightlightedPositions = state.searchTerm.newHightlightedPositions
        }

        showGridMarkers(
          geogrid.attrs,
          geogrid.ranks,
          geogrid.hightlightedPositions,
          newHightlightedPositions,
          state.mapMarkers,
          state.map,
          state.iconScaledSize,
          state.isCompetitorGeogrid
        )

        state.mapAlertMessage = ''
        state.geogrid = geogrid
        state.businessMarker.setMap(null)
        state.draggableMarker.setMap(null)
        state.bounds = fitBounds(state, geogrid.ranks.length ** 2 - 1)
      },
      renderDummyGrid(state, options) {
        if (options.gridSize) state.gridSize = options.gridSize
        if (options.gridPointDistance) state.distance = options.gridPointDistance
        if (options.gridDistanceMeasure) state.distanceMeasure = options.gridDistanceMeasure
        if (options.gridCenterLat) state.mapCenterLat = options.gridCenterLat
        if (options.gridCenterLng) state.mapCenterLng = options.gridCenterLng
        if (options.disabledPoints) state.disabledPoints = new Set(options.disabledPoints)
        if (!options.saveDisabledPoints) state.disabledPoints.clear()
        showDummyMarkers(state)
        state.bounds = fitBounds(state, state.gridSize * state.gridSize - 1)
        state.mapAlertMessage = ''
      },
      repeatGeogrid(state, geogrid) {
        state.disabledPoints = new Set(geogrid.attrs.disabledPoints)
        state.newGeogrid = geogrid
        state.geogrid = { attrs: {}, ranks: []}
        state.searchTerm = { geogrids: []}
        state.config = { attrs: {} }
        state.pageTab = 'new'
        state.pageState = 'live'
        changeGeogridsPageUrl(state)
      },
      repeatConfig(state) {
        state.newConfig = state.config
        state.geogrid = { attrs: {}, ranks: []}
        state.searchTerm = { geogrids: []}
        state.config = { attrs: {} }
        state.pageTab = 'new'
        state.pageState = 'scheduled'
        changeGeogridsPageUrl(state)
      },
      updateNewGifData(state, { url, date }) {
        state.searchTerm.geogridsHistoryTimestamp = date
        state.searchTerm.getAnimationLink = url
      },
      setGeogridImage(state, val) { state.geogrid.imagePublicPath = val },
      showLoader(state) { state.loader++ },
      hideLoader(state) { state.loader-- },
      slider(state, val) { state.slider = val },
      geogrid(state, val) { state.geogrid = val },
      config(state, val) { state.config = val },
      pageState(state, val) { state.pageState = val },
      pageTab(state, val) { state.pageTab = val },
      shouldNotify(state, val) { state.shouldNotify = val },
      localLanguageEnabled(state, val) { state.localLanguageEnabled = val },
      setUpConfigShowPage(state) {
        if (!state.searchTerm) state.mapAlertMessage = 'No search terms created'
        if (!camelCaseKeys(searchParams()).placeId) state.geogrid = state.searchTerm.geogrids[findSlidersPosition(state)] || { attrs: {}, ranks: []}
        if (!state.searchTerm.geogrids.length) state.mapAlertMessage = 'No geogrids created yet'
      },
      setAttrsPresets(state, attrs) {
        state.gridSize = parseInt(attrs.gridSize, 10)
        state.distance = parseInt(attrs.gridPointDistance, 10)
        state.distanceMeasure = attrs.gridDistanceMeasure
        state.shouldNotify = attrs.shouldNotify === 'true'
        state.localLanguageEnabled = attrs.localLanguageEnabled === 'true' ? 'Local' : 'Global'
      }
    },
    actions: {
      async deleteGeogrid({ state, dispatch }, id) {
        state.loader++
        axios.delete(`${state.paths.apiGeogridsPath}/${id}`).then(async() => {
          toastr.success('Geogrid successfully deleted.')
          state.deletedGeogridId = id
          if (!state.config.obfuscatedId && state.geogrid.obfuscatedId === id) {
            state.pageTab = 'index'
          }
          if (state.config.obfuscatedId && state.pageTab === 'show') {
            state.searchTerm.geogrids.splice(findSlidersPosition(state), 1)
            if (!state.searchTerm.geogrids.length) {
              dispatch('setMapAlertMessage', 'No geogrids created yet')
            }
            await dispatch('loadConfigShowPageData', {
              configId: state.config.obfuscatedId,
              keyword: state.searchTerm.text
            })
            dispatch('goTo', 'show')
          } else state.geogrid = { attrs: {}, ranks: []}
        }).catch((_error) => {
          toastr.error(DEFAULT_ERROR_MESSAGE)
        }).finally(() => {
          state.loader--
        })
      },
      async loadConfigShowPageData({ state, dispatch }, { configId, keyword }) {
        if (configId) await dispatch('getConfig', configId)
        if (state.config.obfuscatedId) {
          const searchTerm = state.config.searchTerms.find((st) => st.text === keyword) || state.config.searchTerms[0]
          if (searchTerm) await dispatch('getSearchTerm', searchTerm)
        }
      },
      async parseUrlAndGo({ state, dispatch }) {
        const [pageState, middlePathWord, pageTab] = splitCurrentPath()

        if (middlePathWord === 'geogrids') {
          const params = camelCaseKeys(searchParams())

          if (params.geogridId) await dispatch('getGeogrid', params.geogridId)
          if (params.configId) await dispatch('loadConfigShowPageData', { configId: params.configId, keyword: state.geogrid.searchTerm })

          state.pageState = pageState
          dispatch('setPageTab', pageTab)
        }

        if (searchParams().place_id) {
          const hrefUrl = new URL(window.location.href)
          const urlParams = new URLSearchParams(hrefUrl.search)
          urlParams.delete('place_id')
          window.history.pushState(null, document.title, `${window.location.pathname}?${urlParams}`)
        }
      },
      async getGeogrid({ state }, id) {
        state.loader++

        let url = `${state.paths.apiGeogridsPath}/${id}`
        const params = camelCaseKeys(searchParams())
        if (params.placeId) url += `?place_id=${params.placeId}`

        await axios.get(url, axiosTransform).then((res) => {
          state.geogrid = res.data
        }).catch((error) => {
          if (error.response.status === 404) toastr.error(NOT_FOUND)
          else if (error.response.status === 503) toastr.error(NOT_AUTHORIZED_MESSAGE)
          else toastr.error(DEFAULT_ERROR_MESSAGE)
        }).finally(() => { state.loader-- })
      },
      async getSearchTerm({ state }, searchTerm) {
        if (state.searchTerm.text === searchTerm.text && state.searchTerm.configId === state.config.obfuscatedId) return
        state.loader++

        await axios.get(
          state.config.geogridsPath,
          {
            params: { searchTerm: searchTerm.text },
            paramsSerializer(json) {
              return qs.stringify(snakeCaseKeys(json))
            },
            ...axiosTransform
          }
        )
          .then((res) => {
            state.searchTerm = { ...searchTerm, ...res.data.keyword, geogrids: res.data.geogrids }
          }).catch((_error) => {
            state.searchTerm = { geogrids: []}
            toastr.error(DEFAULT_ERROR_MESSAGE)
          }).finally(() => {
            state.loader--
          })
      },
      async getConfig({ state, commit }, id) {
        state.loader++
        await axios.get(`${state.paths.apiConfigsPath}/${id}`, axiosTransform).then((res) => {
          commit('config', res.data)
        }).catch((error) => {
          if (error.response.status === 404) toastr.error(NOT_FOUND)
          else if (error.response.status === 503) toastr.error(NOT_AUTHORIZED_MESSAGE)
          else toastr.error(DEFAULT_ERROR_MESSAGE)
        }).finally(() => { state.loader-- })
      },
      async showGeogrid({ state, dispatch }, { id, geogrid, isCompetitor = false }) {
        state.isCompetitorGeogrid = isCompetitor

        const params = camelCaseKeys(searchParams())
        if (id || params.placeId) await dispatch('getGeogrid', id || geogrid.obfuscatedId)
        else state.geogrid = camelCaseKeys(geogrid)
        if (state.geogrid.state !== 'finished') return
        dispatch('goTo', 'show')
      },
      async showConfig({ dispatch }, id) {
        await dispatch('loadConfigShowPageData', { configId: id })
        dispatch('goTo', 'show')
      },
      async showConfigsEditTab({ dispatch }, id) {
        await dispatch('loadConfigShowPageData', { configId: id })
        dispatch('goTo', 'edit')
      },
      async showSearchTerm({ state, dispatch }, keyword) {
        if (keyword === state.searchTerm.text) return
        await dispatch('loadConfigShowPageData', { keyword: keyword })
        dispatch('goTo', 'show')
      },
      prepareTab({ state, commit }, tab) {
        if (state.config.obfuscatedId) commit('setUpConfigShowPage')
        if (tab === 'show' && state.geogrid.obfuscatedId) commit('renderGeogrid', state.geogrid)
        else if (tab === 'new' || tab === 'index') {
          state.geogrid = { attrs: {}, ranks: []}
          state.config = { attrs: {} }
          state.searchTerm = { geogrids: []}
          commit('hideMarkers')
        }
      },
      setPageTab({ state, dispatch }, tab) {
        if (tab === 'show' || tab === 'edit') {
          if (state.pageState === 'live' && !state.geogrid.obfuscatedId) return
          if (state.pageState === 'scheduled' && !state.config.obfuscatedId) return
        }
        dispatch('prepareTab', tab)
        state.pageTab = tab
      },
      goTo({ state, dispatch }, tab) {
        dispatch('setPageTab', tab)
        changeGeogridsPageUrl(state)
      },
      setMapAlertMessage({ state, commit }, message) {
        state.mapAlertMessage = message
        commit('hideMarkers')
      },
      connectWithLocation({ state, commit }) {
        const path = [state.paths.apiConfigsPath, state.config.obfuscatedId, 'connect_with_location'].join('/')

        axios.post(path, null, axiosTransform)
          .then((res) => {
            commit('config', res.data)
          }).catch(() => {
            toastr.error(DEFAULT_ERROR_MESSAGE)
          })
      }
    }
  })
  return store
}
