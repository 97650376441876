import Vue from 'vue'
import createFormStore from '../../vue_widgets/gmb/local_posts/form/store'
import FormWidget from '../../vue_widgets/gmb/local_posts/form/index'
import { updateSearchParams } from '../../common/search_params'
import { toDataUrl } from '../../common/helpers'

function form(params) {
  $(() => {
    new Vue({ // eslint-disable-line no-new
      el: document.getElementById('vue-localpost-form'),
      store: createFormStore(params),
      render: (h) => h(FormWidget)
    })
  })
}

Styxie.Initializers.GmbLocalPosts = {
  new: form,
  edit: form,
  clone: form,
  index: () => {
    $(() => {
      $('.js-posts-sort-select, .js-posts-filter-select')
        .turboSelect({ width: '100%', dropdownAutoWidth: 'true' })
        .on('change', (e) => {
          const $el = $(e.currentTarget)
          const h = {}
          h[$el.prop('name')] = $el.val()
          updateSearchParams(h)
        })

      $('.js-download-file').on('click', (e) => {
        e.preventDefault()
        toDataUrl(e.currentTarget.href, (myBase64) => {
          const link = document.createElement('a')
          link.href = myBase64
          link.download = e.currentTarget.download
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
      })
    })
  }
}

export default form
