export default function equalPrimitiveObjects(obj1, obj2) {
  if (!obj1 || !obj2) {
    return false
  }

  const keys = Object.keys(obj1)

  if (keys.length !== Object.keys(obj2).length) return false

  for (let i = 0; i < keys.length; i++) {
    if (obj1[keys[i]] !== obj2[keys[i]]) return false
  }

  return true
}
