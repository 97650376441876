import Vue from 'vue'
import UsersTable from '../../vue_widgets/admin/organizations_table.vue'

Styxie.Initializers.AdminUsers = {
  index: () => {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-active-organizations-table'),
        render: (h) => h(UsersTable, { props: { kind: 'active' } })
      })

      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-inactive-organizations-table'),
        render: (h) => h(UsersTable, { props: { kind: 'inactive' } })
      })
    })
  }
}
