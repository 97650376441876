const defaultErrorHandler = (e) => {
  throw e
}

export default function copyToClipboard(text, action = () => {}, errorHandler = defaultErrorHandler) {
  navigator.clipboard.writeText(text)
    .then(action)
    .catch(errorHandler)
}

export function copyToClipboardWithAnimation({
  element, textToCopy, timeOut = 1500, initialIconClass = 'fa-copy'
}) {
  if (element.classList.contains('animate')) return

  const icon = element.querySelector('i')
  const textContainer = element.querySelector('.text')
  let initialText

  icon.classList.replace(initialIconClass, 'fa-check')
  element.classList.add('animate')
  if (textContainer) {
    initialText = textContainer.textContent
    const initialWidth = element.offsetWidth
    textContainer.textContent = 'Copied'
    element.style.width = `${initialWidth}px`
  }

  setTimeout(() => {
    icon.classList.replace('fa-check', initialIconClass)
    element.classList.remove('animate')
    if (textContainer) textContainer.textContent = initialText
  }, timeOut)

  copyToClipboard(textToCopy)
}

export function bindClickToCopyAttr(selector, attrName) {
  const elements = Array.from(document.querySelectorAll(selector))

  elements.forEach((element) => {
    element.addEventListener('click', () => {
      copyToClipboardWithAnimation({ element, textToCopy: element.dataset[attrName] })
    })
  })
}
