<template>
  <div class="btn-settings form-field">
    <span class="caption">Button </span>
    <div class="d-flex flex-wrap">
      <input
        class="btn-view"
        type="text"
        v-model.lazy="model"
        :style="{color: geogridWidget['btnTextColor'], backgroundColor: geogridWidget['btnBgColor']}">
      <FormColorPicker label="Button Color" field="btnBgColor" />
      <FormColorPicker label="Button Text Color" field="btnTextColor" />
    </div>
    <div class="invalid-feedback d-block" v-if="errors[field]">
      {{ errors[field].join(', ') }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import camelCaseKeys from 'camelcase-keys-deep'
import FormColorPicker from './color_picker'

export default {
  components: { FormColorPicker },
  created() {
    this.field = 'btnText'
    if (!this.geogridWidget[this.field]) {
      this.$store.commit('changeGeogridWidgetKey', { key: this.field, val: 'Submit' })
    }
  },
  computed: {
    ...mapState(['geogridWidget']),
    errors() { return camelCaseKeys(this.geogridWidget.errors) },
    model: {
      get() { return this.geogridWidget[this.field] },
      set(val) { this.$store.commit('changeGeogridWidgetKey', { key: this.field, val }) }
    }
  }
}
</script>
