<template>
  <div>
    <div class="form-group row">
      <label class="col-12 col-sm-3 col-form-label text-sm-right">Add Button (optional)</label>
      <div class="col-12 col-sm-8 col-lg-6">
        <select class="form-control custom-select" v-model="object.ctaType">
          <option v-for="(v, k) in types" :value="k">{{ v }}</option>
        </select>
      </div>
    </div>

    <transition name="slide-animation">
      <div class="form-group row" ref="ctaLinkWrapper" v-show="showLink">
        <label class="col-12 col-sm-3 col-form-label text-sm-right" for="Link_for_your_button">Link for your button</label>
        <div class="col-12 col-sm-8 col-lg-6">
          <input class="form-control" type="text" v-model="object.ctaLink">
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      types: {
        action_type_unspecified: 'Add a button (optional)',
        book: 'Book',
        order: 'Order online',
        shop: 'Buy',
        learn_more: 'Learn more',
        sign_up: 'Sign up',
        call: 'Call now'
      }
    }
  },
  computed: {
    ...mapState(['object']),
    showLink() {
      return this.object.ctaType !== 'action_type_unspecified' && this.object.ctaType !== 'call'
    }
  }
}
</script>
