<template>
  <div>
    <h3 class="h4 mb-2 font-weight-semi-bold">
      <span class="align-middle">Questions/Answers</span>
      <span class="badge badge-primary rounded">beta</span>
    </h3>
    <div class="competitors-chart-legend">
      <div v-for="legendItem in qaTypesObj" :key="legendItem.color" class="competitors-chart-legend-item">
        <div class="point" :style="{backgroundColor: legendItem.color}" />
        {{ legendItem.title }}
      </div>
    </div>
    <VueSlimTable
      ref="table"
      :source="() => competitorsQa"
      :columns="[]"
      class="responsive-table borderless-table audit-table posts-by-type average last-head-col-right-1 mt-3">
      <template #row="{ row }">
        <tr>
          <td @click="showContentSidebar(row, 'qa')">
            <span class="text-primary cursor-pointer">{{ row.name }}</span>
          </td>
          <td>
            <RatioBar :parts="row.byType" :total="maxTotal" />
          </td>
          <td class="font-weight-bold" :class="{'text-grey': row.answered + row.unanswered === 0}">{{ row.answered + row.unanswered }}</td>
        </tr>
      </template>
    </VueSlimTable>
  </div>
</template>
<script>
import RatioBar from './blanks/ratio_bar'
import showContentSidebarMixin from './show_content_sidebar_mixin'

export default {
  mixins: [showContentSidebarMixin],
  components: {
    RatioBar
  },
  props: {
    sectionData: { type: Object, required: true }
  },
  created() {
    this.qaTypesObj = {
      answered: { color: '#29BA6C', title: 'Answered' },
      unanswered: { color: '#377DFF', title: 'Unanswered' }
    }

    this.maxTotal = 1
    this.competitorsQa = [
      { competitor: 'My location', ...this.sectionData.current },
      ...this.sectionData.competitors
    ].map(({
      competitor, questions: total, answered, ...rest
    }) => {
      const unanswered = total - answered
      if (this.maxTotal < total) this.maxTotal = total
      const qa = {
        answered,
        unanswered
      }

      return {
        name: competitor,
        answered,
        unanswered,
        ...rest,
        byType: Object.keys(this.qaTypesObj).reduce((mem, key) => {
          mem.push({
            color: this.qaTypesObj[key].color,
            value: qa[key]
          })

          return mem
        }, [])
      }
    })
  }
}
</script>
