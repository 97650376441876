import GeogridWidgetForm from '../vue_widgets/geogrid_widgets/form/index'
import createStore from '../vue_widgets/geogrid_widgets/form/store'

import GeogridWidgetCode from '../vue_widgets/geogrid_widgets/show/code'

function form(prms) {
  $(() => {
    // eslint-disable-next-line no-new
    new Vue({
      el: document.getElementById('vue-geogrid-widget-form'),
      store: createStore(prms),
      render: (h) => h(GeogridWidgetForm)
    })
  })
}

Styxie.Initializers.GeogridWidgets = {
  new: form,
  edit: form,
  show(props) {
    $(() => {
      // eslint-disable-next-line no-new
      new Vue({
        el: document.getElementById('vue-widget-code'),
        render: (h) => h(GeogridWidgetCode, { props })
      })
    })
  }
}
