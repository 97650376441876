<template>
  <div class="tab-pane scroll ver pr-1">
    <div class="form-group">
      <label for="place-autocomplete">Location name</label>
      <input type="new-password" class="form-control" id="place-autocomplete" ref="placeAutocomplete">
    </div>

    <div v-if="selectedPlace" class="place-card">
      <h1>{{ selectedPlace.name }}</h1>
      <div class="place-attribute">
        <div class="attribute-label">Time zone:</div>
        {{ selectedPlace.utc_offset_minutes / 60 }}
      </div>

      <div class="place-attribute">
        <div class="attribute-label">Name:</div>
        {{ selectedPlace.name }}
      </div>

      <!-- eslint-disable vue/no-v-html -->
      <div class="place-attribute">
        <div class="attribute-label">Google place types:</div>
        <div v-html="selectedPlace.types.join('<br>')" />
      </div>

      <div v-if='selectedPlace.opening_hours' class="place-attribute">
        <div class="attribute-label">Regular hours:</div>
        <div v-html="selectedPlace.opening_hours.weekday_text.join('<br>')" />
      </div>
      <!-- eslint-enable vue/no-v-html -->

      <div class="place-attribute"><div class="attribute-label">Phones:</div> {{ selectedPlace.international_phone_number }}</div>
      <div class="place-attribute" v-if="selectedPlace.website && selectedPlace.website.length">
        <div class="attribute-label">Websites:</div> <a :href="selectedPlace.website">link</a>
      </div>
    </div>

    <div v-else class="place-not-selected">
      <p>After entering and selecting a location, basic information about it will be displayed here</p>
    </div>

    <div class="text-right">
      <button type="button" class="btn btn-link mr-3" @click.prevent="toggleSlidePannel">Cancel</button>
      <ProgressButton
        class="btn btn-primary"
        :disabled="!selectedPlace"
        @click.native="onSubmit"
        :in-progress="submitInProgress">
        Confirm
      </ProgressButton>
    </div>
  </div>
</template>

<script>
import ProgressButton from 'vue_widgets/components/progress_button'
import { DEFAULT_ERROR_MESSAGE } from 'common/constants'
import trasformRequest from 'common/axios'

export default {
  components: { ProgressButton },
  props: {
    googeSessionToken: Object
  },
  data() {
    return {
      selectedPlace: null,
      submitInProgress: false
    }
  },
  inject: ['toggleSlidePannel'],
  methods: {
    onSubmit() {
      this.submitInProgress = true
      axios.post(
        '/api/google_places',
        {
          googlePlace: {
            name: this.selectedPlace.name,
            formattedAddress: this.selectedPlace.formatted_address,
            api_address: this.selectedPlace.address_components,
            phone: this.selectedPlace.international_phone_number,
            rating: this.selectedPlace.rating,
            placeId: this.selectedPlace.place_id,
            lat: this.selectedPlace.geometry.location.lat(),
            lng: this.selectedPlace.geometry.location.lng(),
            mapsUrl: this.selectedPlace.url,
            website: this.selectedPlace.website
          }
        },
        trasformRequest
      ).then(() => {
        toastr.success(`Syncing data for ${this.selectedPlace.name}...`)
        this.toggleSlidePannel(true)
      }).catch(() => {
        toastr.error(DEFAULT_ERROR_MESSAGE)
      }).finally(() => {
        this.submitInProgress = false
      })
    }
  },
  mounted() {
    const autocomplete = new google.maps.places.Autocomplete(
      this.$refs.placeAutocomplete,
      {
        types: ['establishment'],
        sessionToken: this.googeSessionToken
      }
    )
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace()
      this.selectedPlace = place.name.length ? place : null

      if (this.selectedPlace) this.selectedPlace.selected = true
      const coords = this.selectedPlace.geometry.location
      this.$emit('placesChanged', [{ lat: coords.lat(), lng: coords.lng() }])
    })
  }
}
</script>
