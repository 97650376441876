<template>
  <div class="my-5">
    <div class="form-group row align-items-center mb-3">
      <div class="col-12 col-sm-3 text-sm-right">
        Webhook URL
      </div>
      <div class="col-12 col-lg-4 col-md-6 col-sm-9 form-field">
        <input disabled type="text" :value="webhookUrl">
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-3" />
      <div class="col-12 col-md-6 col-sm-9">
        <span class="text-warning d-block mb-3">
          You are currently using legacy webhook format. <br>
          This webhook will work till 30&nbsp;Sep&nbsp;2024. <br>
          Please, consider moving to a new webhook format.
        </span>
        <button class="btn btn-primary" @click="$emit('clearLegacyWebhook')">Disable this webhook and move to a new format</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    webhookUrl: {
      type: String,
      required: true
    }
  }
}
</script>
