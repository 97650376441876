<template>
  <label class='checkbox-toggle'>
    <input type='checkbox' :disabled="disabled" :checked="value" @change="onChange">
    <div class="checkbox-toggle__toggler" :class="{ active: value }">
      <div />
    </div>
    <span class='caption'>
      <slot>
        {{ label }}
      </slot>
    </span>
  </label>
</template>

<script>
export default {
  props: {
    value: [Boolean, Number],
    disabled: Boolean,
    label: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    onChange(e) {
      this.$emit('input', e.target.checked)
    }
  }
}
</script>
