<template>
  <div class="bg-light col-md-12 pt-3 pb-1">
    <ExifInput v-for="i in inputs" :key="i.identifier" :identifier="i.identifier"
      :label="i.label"
      :recommended="i.recommended" />
  </div>
</template>

<script>
import ExifInput from './exif_input'

export default {
  components: { ExifInput },
  data() {
    return {
      inputs: [
        { identifier: 'xptitle', label: 'Title', recommended: '{{ location.name }}' },
        { identifier: 'imagedescription', label: 'Description', recommended: '{{ location.description }}' },
        { identifier: 'xpcomment', label: 'User Comment' },
        { identifier: 'xpkeywords', label: 'Keywords' },
        { identifier: 'xpsubject', label: 'Subject' }
      ]
    }
  }
}
</script>
