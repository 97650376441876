import Vue from 'vue'

import VueKeywordsTable from '../../vue_widgets/gmb/keywords/keyword_table'
import VueKeywordShow from '../../vue_widgets/gmb/keywords/keyword_show'
import VueKeywordNew from '../../vue_widgets/gmb/keywords/keyword_new'
import keywordsSettingsStore from '../../vue_widgets/gmb/keywords/settings_store'
import keywordStore from '../../vue_widgets/gmb/keywords/store'

function initKeywords(params) {
  if (params.hasKeywords) {
    new Vue({ // eslint-disable-line no-new
      el: document.getElementById('vue-keywords-table'),
      store: keywordsSettingsStore(params),
      render: (h) => h(VueKeywordsTable, {
        ref: 'keywordsTable',
        props: params
      })
    })
  }
}

function showKeyword({ keyword, keywordsCommon }) {
  new Vue({ // eslint-disable-line no-new
    el: document.getElementById('vue-keyword-show'),
    store: keywordStore({ keyword, keywordsCommon }),
    render: (h) => h(VueKeywordShow, {
      props: { keyword, keywordsCommon }
    })
  })
}

function newKeyword({
  googleMapApiKey,
  keywordsCommon
}) {
  new Vue({ // eslint-disable-line no-new
    el: document.getElementById('vue-keyword-new'),
    store: keywordsSettingsStore({ googleMapApiKey, keywordsCommon }),
    render: (h) => h(VueKeywordNew)
  })
}

Styxie.Initializers.Keywords = {
  index: (params) => {
    $(() => initKeywords(params))
  },
  show: (params) => {
    $(() => showKeyword(params))
  },
  new: (params) => {
    $(() => newKeyword(params))
  }
}
