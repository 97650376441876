<template>
  <div class='alert alert-danger alert-dismissible' role='alert' v-if="plainErrors && plainErrorsArr[0]">
    <div class='message'>
      <p v-for="(err, index) in plainErrorsArr" :key="index">{{ err }}</p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['plainErrors']),
    plainErrorsArr() {
      return Array.isArray(this.plainErrors) ? this.plainErrors : [this.plainErrors]
    }
  }
}
</script>
