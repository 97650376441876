import axiosCaseTransormators from 'common/axios'
import { DEFAULT_ERROR_MESSAGE } from 'common/constants'

export default function createStore(prms) {
  prms.geogridWidget.errors = {}

  return new Vuex.Store({
    state: {
      ...prms
    },
    actions: {
      onSubmit({ state, commit }) {
        commit('changeGeogridWidgetKey', { key: 'errors', val: {} })
        // eslint-disable-next-line no-unused-vars
        const { errors, ...hsh } = state.geogridWidget

        let request
        if (hsh.id) {
          request = axios.patch(`/api/geogrid_widgets/${hsh.id}`, { geogrid_widget: hsh }, axiosCaseTransormators)
        } else {
          request = axios.post('/api/geogrid_widgets', { geogrid_widget: hsh }, axiosCaseTransormators)
        }

        request.then(
          () => {
            $(document).one('turbolinks:load', () => {
              toastr.success(`Geogrid widget was successfully ${hsh.id ? 'updated' : 'created'}`)
            })
            Turbolinks.visit('/geogrid_widgets')
          },
          (err) => {
            if (err.response.data && err.response.data.type === 'validation') {
              commit('changeGeogridWidgetKey', { key: 'errors', val: err.response.data.errors })
              Vue.nextTick(() => {
                document.querySelector('.invalid-feedback').parentElement.scrollIntoView({ behavior: 'smooth' })
              })
            } else {
              toastr.error(DEFAULT_ERROR_MESSAGE)
            }
          }
        )
      }
    },
    mutations: {
      changeGeogridWidgetKey(state, { key, val }) {
        state.geogridWidget[key] = val
      }
    }
  })
}
