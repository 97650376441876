<template>
  <div>
    <div class="container">
      <h2> Choose interval </h2>
      <a class="badge badge-info" href="#" @click.prevent="drawChart({interval: '1 hour', frequency: '1 minute'})">
        1 hour
      </a>
      <a class="badge badge-info" href="#" @click.prevent="drawChart({interval: '3 hour', frequency: '3 minute'})">
        3 hour
      </a>
      <a class="badge badge-info" href="#" @click.prevent="drawChart({interval: '1 day', frequency: '1 hour'})">
        1 day
      </a>
      <a class="badge badge-info" href="#" @click.prevent="drawChart({interval: '1 week', frequency: '12 hour'})">
        1 week
      </a>
    </div>
    <div class="chart-container">
      <div class="chart" ref="chart" />
    </div>
    <br>
    <div v-if="totalCount!== 0">
      <h2> Processing geogrids total count: {{ totalCount }} </h2>
    </div>
    <br>
    <div>
      <h2> Top 10 organizations with slow geogrids processing:</h2>
      <ul>
        <li v-for="organization in organizationsWithSlowGeogrids">
          <a :key="organization.id" :href="'/admin/organizations/' + organization.id"> {{ organization.title }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Highcharts from 'highcharts'
import axiosTransform from '../../../common/axios'

export default {
  name: 'GeogridsStatisticsChart',
  data() {
    return {
      organizationsWithSlowGeogrids: [],
      totalCount: 0
    }
  },
  methods: {
    fetchStatistics(qParams) {
      return axios.get(
        '/api/admin/geogrids_statistics',
        {
          params: qParams,
          ...axiosTransform
        }
      )
    },
    initChart() {
      return Highcharts.chart(this.$refs.chart, {
        chart: {
          zoomtype: 'xy'
        },
        title: {
          text: 'Live geogrids monitoring'
        },
        xAxis: [
          {
            categories: this.aggregations.date || [new Date().toLocaleString()],
            crosshair: true
          }
        ],
        yAxis: [
          {
            labels: {
              format: '{value} sec',
              style: {
                color: Highcharts.getOptions().colors[1]
              }
            },
            title: {
              text: 'Processing time',
              style: {
                format: '{value} sec',
                color: Highcharts.getOptions().colors[0]
              }
            }
          },
          {
            labels: {
              format: '{value:.0f}',
              style: {
                color: Highcharts.getOptions().colors[0]
              }
            },
            title: {
              text: 'In processing',
              style: {
                color: Highcharts.getOptions().colors[0]
              }
            },
            opposite: true
          }
        ],
        tooltip: {
          shared: true
        },
        legend: {
          layout: 'vertical',
          align: 'left',
          x: 120,
          verticalAlign: 'top',
          y: 50,
          floating: true,
          backgroundColor: Highcharts.defaultOptions.legend.backgroundColor
        },
        series: [
          {
            name: 'Processing',
            type: 'column',
            yAxis: 1,
            data: this.processingGeogridsCount.count || [0],
            tooltip: {
              valueSuffix: ' unit'
            }
          },
          {
            name: 'Maximum time',
            type: 'spline',
            data: this.aggregations.maxTime || [0],
            tooltip: {
              valueSuffix: ' sec'
            }
          },
          {
            name: 'Minimum time',
            type: 'spline',
            data: this.aggregations.minTime || [0],
            tooltip: {
              valueSuffix: ' sec'
            }
          },
          {
            name: 'Average time',
            type: 'spline',
            data: this.aggregations.avgTime || [0],
            tooltip: {
              valueSuffix: ' sec'
            }
          }
        ]
      })
    },
    async drawChart(qParams, firstDraw = false) {
      const statistics = (await this.fetchStatistics(qParams)).data
      this.aggregations = statistics.aggregations
      this.processingGeogridsCount = statistics.processingGeogridsCount
      this.organizationsWithSlowGeogrids = statistics.organizationsWithSlowGeogrids

      if (this.processingGeogridsCount.count) {
        this.totalCount = this.processingGeogridsCount.count.reduce((a, b) => a + b, 0)
      }

      if (firstDraw) {
        this.chart = this.initChart()
        return
      }

      this.chart.xAxis[0].update({ categories: this.aggregations.date || [new Date().toLocaleString()]})
      this.chart.series[0].update({ data: this.processingGeogridsCount.count })
      this.chart.series[1].update({ data: this.aggregations.maxTime })
      this.chart.series[2].update({ data: this.aggregations.minTime })
      this.chart.series[3].update({ data: this.aggregations.avgTime })
      this.chart.redraw()
    }
  },
  async created() {
    const initialParams = { interval: '1 hour', frequency: '1 minute' }
    this.drawChart(initialParams, true)
  }
}
</script>
