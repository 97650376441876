<template>
  <div class="audit-card-review" ref="card">
    <div class="audit-card-review__top">
      <div v-if="review.author" class="audit-card-review__author">
        <div v-if="review.author.avatar" class="audit-card-review__avatar">
          <img :src="review.author.avatar" :alt="`${review.author.name} avatar`">
        </div>
        <div class="audit-card-review__wrapper">
          <a v-if="review.author.homepage" :href="review.author.homepage" target="_blank">{{ review.author.name }}</a>
          <p v-else>{{ review.author.name }}</p>
          <span v-if="review.author.rank">{{ review.author.rank }}</span>
        </div>
      </div>
    </div>
    <div class="audit-card-review__body">
      <div class="audit-card-review__rating">
        <i v-for="index in 5" class="fas fa-star audit-card-review__star"
          :key="index"
          :class="{ 'audit-card-review__star--yellow': index <= review.stars }" />
        <span class="audit-card-review__date">{{ review.date }}</span>
      </div>
      <p v-if="review.text" class="audit-card-review__text">{{ review.text }}</p>
      <div v-if="review.response" class="audit-card-review__response">
        <div class="audit-card-review__response-top">
          <p>Response from the owner</p>
          <span class="audit-card-review__date">{{ review.response.date }}</span>
        </div>
        <p class="audit-card-review__text">{{ review.response.text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import contentCardDateFormatMixin from './content_card_date_format_mixin'

export default {
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  mixins: [contentCardDateFormatMixin],
  computed: {
    review() {
      const {
        body, stars, maxStars, timestamp, author, responseBody, responseTimestamp
      } = this.content

      return {
        text: body,
        stars: Math.round(stars / (maxStars / 5)),
        date: this.formatDate(timestamp),
        author,
        response: responseBody && responseTimestamp ? {
          text: responseBody,
          date: this.formatDate(responseTimestamp)
        } : null
      }
    }
  }
}
</script>
