var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('div',{staticClass:"row"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"col-12 col-sm-8 col-lg-6"},[(!_vm.videoUrl)?_c('label',{class:['btn', { disabled: _vm.object.obfuscatedId }]},[_vm._v("\n        Select Video\n        "),_c('input',{staticClass:"d-none",attrs:{"type":"file","id":"video_file","accept":_vm.acceptTypes},on:{"change":_vm.uploadVideo}})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"d-flex align-items-center gap-4"},[(_vm.videoUrl)?_c('button',{staticClass:"btn",attrs:{"disabled":_vm.state.type === 'text-primary',"type":"button"},on:{"click":_vm.resetControl}},[_vm._v("\n          Remove video\n        ")]):_vm._e(),_vm._v(" "),(_vm.state.text)?_c('span',{class:['d-block small mt-1', _vm.state.type]},[(_vm.state.type === 'text-primary')?_c('i',{staticClass:"far fa-spinner-third fa-spin mr-1"}):_vm._e(),_vm._v("\n          "+_vm._s(_vm.state.text)+"\n        ")]):_vm._e()])])]),_vm._v(" "),(_vm.videoUrl)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-4 offset-sm-3 col-lg-6"},[_c('video',{staticClass:"mt-2 mw-100",attrs:{"src":_vm.videoUrl,"controls":""}}),_vm._v(" "),_c('div',{staticClass:"alert alert-warning m-0 mb-2"},[_vm._v("\n        Please note: Google might review videos up to 48 hours.\n      ")]),_vm._v(" "),(!_vm.object.topicType)?_c('TextareaWithLimit',{attrs:{"limit":1500,"input-attrs":{
          name: 'media-description',
          id: 'media-description',
          class: 'form-control',
          placeholder: 'Media Description'
        },"value":_vm.mediaDescription},on:{"change":_vm.changeMediaDescription}}):_vm._e()],1)]):(_vm.object.mediaFormat === 'video')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-4 offset-sm-3 col-lg-6"},[(_vm.imageSrc)?_c('img',{staticClass:"img-thumbnail d-block my-2",attrs:{"src":_vm.imageSrc}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"alert alert-warning m-0"},[_vm._v("\n        Preview is unavailable for videos uploaded to GBP with third party applications.\n      ")])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"col-12 col-form-label col-sm-3 d-flex gap-2 justify-content-end align-items-center mt-n2",attrs:{"for":"video_file"}},[_vm._v("\n      Video\n      "),_c('span',{staticClass:"badge badge-primary rounded"},[_vm._v("beta")])])
}]

export { render, staticRenderFns }