<template>
  <div class="form-field">
    <span class="caption">Notification Email(s)</span>
    <input
      type="text"
      v-model.lazy="model">
    <small class="text-muted d-block">Multiple emails should be separated by comma.</small>
    <small class="text-muted">The email(s) that will be notified when a Geogrid is requested from this widget</small>
    <div class="invalid-feedback" v-if="errors['notificationEmails']">
      {{ errors[field].join(', ') }}
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    errors() { return this.$store.state.geogridWidget.errors },
    model: {
      get() { return this.$store.state.geogridWidget.notificationEmails },
      set(val) { this.$store.commit('changeGeogridWidgetKey', { key: 'notificationEmails', val: val.split(',').map((str) => str.trim()) }) }
    }
  }
}
</script>
