import Turbolinks from 'turbolinks'

export default function turbolinksReload(onLoad) {
  window.turbolinksScrollPosition = [window.scrollX, window.scrollY]

  $(document).one('turbolinks:load', () => {
    if (window.turbolinksScrollPosition) {
      window.scrollTo(...window.turbolinksScrollPosition)
      window.turbolinksScrollPosition = null

      if (typeof onLoad === 'function') {
        onLoad()
      }
    }
  })
  Turbolinks.visit(window.location.toString(), { action: 'replace' })
}
