<template>
  <div class="audit-card-media" ref="card">
    <div class="audit-card-media__date">{{ media.date }}</div>
    <div class="audit-card-media__preview cursor-pointer" @click="handleMediaClick">
      <img :src="media.previewUrl" alt="Audit content media preview">
      <div v-if="media.type === 'video'" class="audit-card-media__play">
        <i class="far fa-play" />
      </div>
    </div>
  </div>
</template>

<script>
import contentCardDateFormatMixin from './content_card_date_format_mixin'

export default {
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  mixins: [contentCardDateFormatMixin],
  computed: {
    media() {
      return {
        date: this.formatDate(this.content.timestamp),
        previewUrl: this.content.previewUrl,
        originalUrl: this.content.originalUrl ?? this.content.previewUrl,
        type: this.content.mediaTypeName
      }
    }
  },
  methods: {
    handleMediaClick() {
      this.$emit('openGallery', this.media)
    }
  }
}
</script>
