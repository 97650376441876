<template>
  <div class="form-field">
    <div class="radio-buttons d-inline-flex">
      <template v-for="kind in kinds">
        <input
          type="radio"
          name="kind"
          :value="kind.id"
          :key="kind.id"
          :id="kind.id"
          :disabled="kind.id !== 'form'"
          v-model="model">
        <label
          :class="['radio-btn', {}]" :for="kind.id" :key="JSON.stringify(kind)">{{ kind.text }}</label>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['geogridWidget', 'kinds']),
    model: {
      get() { return this.$store.state.geogridWidget.kind },
      set(val) { this.$store.commit('changeGeogridWidgetKey', { key: 'kind', val }) }
    }
  }
}
</script>
