import Index from '../vue_widgets/api_tokens'

Styxie.Initializers.ApiTokens = {
  index({ baseDomain }) {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-geogrid-api-keys'),
        render: (h) => h(Index, { props: { baseDomain } })
      })
    })
  }
}
