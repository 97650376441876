<template>
  <div class="payment-form col-xl-11">
    <div v-if='!paypalSubscriber'>
      <h3 class="h3 text-primary font-weight-normal mb-0">Congratulations!</h3>
      <p class="mt-2">
        You have successfully set up your private instance of Local Viking.
        Please, enter your payment data to start managing your GMBs.
      </p>
    </div>
    <div v-else>
      <h3 class="h3 text-primary font-weight-normal mb-0">We have updated our billing system</h3>
      <p class="mt-2">
        If you want to continue using Local Viking, please resubscribe with PayPal or Stripe.
        If your old PayPal subscription for Local Viking is still active, please log in to PayPal,
        navigate to <a href="https://www.paypal.com/myaccount/autopay/" target="_blank">the recurrent subscriptions page</a>,
        and unsubscribe from LocalViking. See
        <a href="https://www.paypal.com/ca/smarthelp/article/what-is-an-automatic-payment-faq2254" target="_blank">
          the PayPal help center
        </a>
        for detailed instructions.
        <span class="d-block">You will be grandfathered into your legacy pricing plan.</span>
      </p>
    </div>

    <form :action="formAction" method="post" id="payment_data">
      <input type="hidden" name="authenticity_token" :value="csrf">

      <div v-if="appCodename == 'lv'" class="btn-group mt-5" role="group">
        <input v-model="tab" type="radio" class="btn-check"
          name="tab"
          id="stripe" autocomplete="off" value="stripe"
          checked>
        <label class="btn btn-outline-primary" for="stripe">Credit Card (Stripe)</label>

        <input v-model="tab" type="radio" class="btn-check"
          name="tab"
          id="paypal" autocomplete="off" value="paypal">
        <label class="btn btn-outline-primary" for="paypal">PayPal</label>
      </div>

      <div class="form-group mt-5">
        <div class="d-flex align-items-center justify-content-between flex-wrap mb-4 gap-2">
          <strong class="h4 m-0">Select billing plan</strong>
          <label class="switcher">
            <input type="checkbox" v-model="planDurationVal">
            <span class="caption">Monthly</span>
            <i class="toogle-switch" />
            <span class="caption">Yearly</span>
          </label>
        </div>

        <div class="payment-plans">
          <div v-for="(value, key) in filteredPlans" :key="key" :value="key" class="payment-plans-item">
            <input type="radio"
              :id="key"
              :value="key"
              :name="formPlanName"
              v-model="plan">
            <label :for="key">
              <span>{{ value.title.split(' ').slice(0, value.title.split(' ').length-1).join(' ') }}</span>
              <strong>${{ value.price }}</strong>
              <small v-if="planDurationVal">${{ yearlyPriceByMonth(value.price) }} / month</small>
            </label>
          </div>
          <div v-if="isSubscriptionDebug && !planDurationVal" value="debugplan_monthly" class="payment-plans-item">
            <input type="radio"
              id="debugplan_monthly"
              value="debugplan_monthly"
              :name="formPlanName"
              v-model="plan">
            <label for="debugplan_monthly">
              <span>Debug</span>
              <strong>$1</strong>
            </label>
          </div>
        </div>
      </div>

      <div v-show="tab === 'stripe'">
        <input v-if="tab === 'stripe'" name="_method" type="hidden" value="put">
        <div id='js-stripe-form' class="mt-5">
          <label for='card-element'>
            Credit or debit card
          </label>
          <div id='card-element'>
            <!-- a Stripe Element will be inserted here. -->
          </div>

          <!-- Used to display Element errors -->
          <div id='card-errors' role='alert' />
        </div>

        <div class="form-group mt-5">
          <input type="text"
            v-if="tab === 'stripe'"
            class="form-control discount"
            placeholder="Discount coupon"
            name="stripe_coupon"
            v-model="coupon">
        </div>
      </div>

      <input v-if="tab === 'paypal'" type="hidden" name="paypal_subscription[kind]" value="subscription">

      <div class="actions d-flex justify-content-between align-items-center">
        <a rel="nofollow" data-method="delete" href="/sign_out">Log Out</a>

        <input type="submit" value="Submit" class="btn btn-primary rounded">
      </div>
    </form>
  </div>
</template>

<script>
import StripeForm from 'common/stripe_form'

export default {
  props: {
    paypalSubscriber: { type: Boolean, required: true },
    stripeKey: { type: String, required: true },
    currentPlan: { type: String, required: true },
    appCodename: { type: String, required: true },
    plans: { type: Object, required: true }
  },
  data() {
    return {
      tab: 'stripe',
      plan: this.currentPlan,
      coupon: '',
      planDurationVal: false
    }
  },
  computed: {
    formAction() {
      return this.tab === 'paypal' ? '/paypal/subscriptions' : '/payment_data'
    },
    formPlanName() {
      return this.tab === 'paypal' ? 'paypal_subscription[plan_name]' : 'stripe_plan'
    },
    isSubscriptionDebug() {
      const debugSubscription = new URL(window.location).searchParams.get('debug_subscription')
      return debugSubscription && debugSubscription.length
    },
    filteredPlans() {
      const filter = this.planDurationVal ? 'yearly' : 'monthly'
      return Object.keys(this.plans).filter((key) => key.includes(filter)).reduce((memo, key) => {
        memo[key] = this.plans[key]
        return memo
      }, {})
    }
  },
  methods: {
    yearlyPriceByMonth(price) {
      return Math.round(((price / 12) + Number.EPSILON) * 100) / 100
    }
  },
  created() {
    this.csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

    $.getScript('https://js.stripe.com/v3/').done(() => {
      new StripeForm(this.stripeKey, 'payment_data', true).init()
    })

    const url = new URL(window.location)
    const chosenPlan = url.searchParams.get('stripe_plan')
    if (chosenPlan && chosenPlan.length) {
      $(`.js-paypal-plan [data-plan-id=${chosenPlan}]`).prop('selected', true)
    }
  }
}
</script>
