import Vue from 'vue'
import Transactions from '../../vue_widgets/profile/transactions'

Styxie.Initializers.ProfileKeywordTransactions = {
  index: (params) => {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-keyword-transactions'),
        render: (h) => h(Transactions, { props: { creditType: 'keyword', ...params } })
      })
    })
  }
}
