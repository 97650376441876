<template>
  <div class="geogrid-form">
    <template v-if="geogridWidget.id">
      <h2 class="geogrid-form-title">Code to insert</h2>
      <div class="d-flex flex-column-reverse flex-lg-row">
        <div class="settings">
          <FormOutput :geogrid-widget="geogridWidget" :script-url="scriptUrl" />
        </div>
        <div class="description mt-0">
          <i class="icon far fa-code" />
          <small class="text-muted">
            Paste this code in the part of the page where you want to see the form.
          </small>
        </div>
      </div>
      <hr>
    </template>
    <form action="/"
      @submit.prevent="onSubmit">
      <h2 class="geogrid-form-title">General Settings</h2>
      <div class="d-flex flex-column-reverse flex-lg-row">
        <div class="settings">
          <FormTextInput
            field="name"
            label="Widget name"
            description="Your internal name for this specific Geogrid widget " />
          <div class="formgrid d-flex flex-wrap align-items-end">
            <FormGridSize />
            <FormGridDistance />
            <FormGridMeasure />
          </div>
          <div class="form-field">
            <p>Select the Geogrid size and distance settings for grids generated by this widget. </p>
          </div>
          <AutocompleteApiKey field="autocompleteApiKey" label="Autocomplete API key" />
          <FormMapsApiKey />
          <div class="form-field">
            <small class="text-muted">
              GeoGrid widget engine runs on our infrastructure and uses your LV GeoGrid credits.
              Autocomplete employs Google paid features, thus you will be charged a fee by Google for using it.
            </small>
          </div>
        </div>
        <div class="description" />
      </div>
      <hr>
      <div class="settings appearance">
        <div class="d-flex justify-content-between w-100">
          <h2 class="geogrid-form-title">Appearance Settings</h2>
          <label class="switcher">
            <input type="checkbox" v-model="isPreview">
            <span class="caption">Preview</span>
            <i class="toogle-switch" />
          </label>
        </div>
        <div class="description" />
      </div>

      <div class="d-none flex-column-reverse flex-lg-row">
        <div class="settings appearance">
          <FormWidgetKind />
        </div>
        <div class="description" />
      </div>
      <div class="d-flex flex-column-reverse flex-lg-row">
        <div :class="['settings form', { preview: isPreview }]">
          <FormPreview v-if="isPreview" />
          <template v-else>
            <FormTextInput
              field="sectionTitle"
              :border-white="true"
              placeholder="Section Title"
              label="Section Title" />
            <FormTextInput
              type="textarea"
              field="sectionDescription"
              :border-white="true"
              placeholder="Section Description"
              label="Section Description" />

            <hr class="mt-0">

            <FormTextInput
              field="businessTabTitle"
              :border-white="true"
              placeholder="Enter Business Tab Title"
              label="Business Tab Title" />
            <FormTextInput
              field="businessPlaceholder"
              :border-white="true"
              placeholder="Enter Placeholder"
              label="Business Name Autocomplete Placeholder" />

            <hr class="mt-0">

            <FormTextInput
              field="sabTabTitle"
              :border-white="true"
              placeholder="Enter SAB Tab Title"
              label="SAB Tab Title" />
            <FormTextInput
              field="sabPlaceholder"
              :border-white="true"
              placeholder="Enter Placeholder"
              label="SAB Address Autocomplete Placeholder" />
            <FormTextInput
              field="businessNamePlaceholder"
              :border-white="true"
              placeholder="Enter Placeholder"
              label="SAB Business Name Placeholder" />

            <hr class="mt-0">

            <FormTextInput
              field="keywordPlaceholder"
              :border-white="true"
              placeholder="Enter Placeholder"
              label="Keyword" />

            <FormTextInput
              field="emailPlaceholder"
              :border-white="true"
              placeholder="Enter Placeholder"
              label="Email" />

            <hr class="mt-0">

            <FormAdditionalExtrafields />

            <hr class="mt-0">

            <FormButtonSetting />
          </template>
        </div>
        <div class="description">
          <i class="icon far fa-info-square" />
          <p>
            Choose which fields should be required and select which widget type you’d like to use.
          </p>
        </div>
      </div>
      <hr>
      <h2 class="geogrid-form-title">Sending Settings</h2>
      <div class="d-flex flex-column-reverse flex-lg-row">
        <div class="settings">
          <FormTextInput field="emailSubject" label="Client Email Subject" />
          <FormTextInput field="emailText" label="Client Email Text" type="textarea" />
          <FormNotificationEmails />
          <FormTextInput
            field="webhookUrl"
            label="Webhook URL"
            description="Using the webhook URL allows you to programmatically
              export Geogrid widget lead data to any platform
              that accepts webhooks (like Zapier)." />
          <FormWhiteLabelConfiguration />
          <FormTextInput
            field="note"
            label="Note"
            type="textarea"
            description="Personalize the content within the PDF that gets sent out with Geogrid results. " />
          <FormTextInput
            label="Redirect Url"
            field="redirectUrl"
            description="Choose a custom URL to redirect form submits to (like your own thank you page). " />
        </div>
        <div class="description">
          <p>
            Configure the appearance and content of the email and PDF file that will be sent to the person with the Geogrid
          </p>
          <a href="/geogrid_widget_example.pdf" target="_blank" class="pdf-example">
            <i class="icon far fa-file-pdf" />
            <div class="pdf-example-description">
              <span class="caption">example.pdf</span>
              <span class="weight">207 Kb</span>
            </div>
          </a>
        </div>
      </div>

      <div class="form-field row">
        <div class="col">
          <button class="btn btn-primary rounded" type="submit">{{ geogridWidget.id ? 'Save changes' : 'Create Widget' }}</button>
          <a href="/geogrid_widgets" class="btn rounded">Cancel</a>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import FormGridMeasure from './components/grid_distance_measure'
import FormGridDistance from './components/grid_distance'
import FormGridSize from './components/grid_size'
import FormMapsApiKey from './components/maps_api_key'
import FormWidgetKind from './components/widget_kind'
import FormPreview from './components/preview'
import FormOutput from '../show/code'
import FormNotificationEmails from './components/notification_emails'
import FormWhiteLabelConfiguration from './components/white_label_configuration'

import FormTextInput from './components/text_input'
import FormAdditionalExtrafields from './components/additional_extra_fields'
import FormButtonSetting from './components/button_settings'
import AutocompleteApiKey from './components/autocomplete_api_key'

export default {
  computed: mapState([
    'geogridWidget',
    'scriptUrl',
    'geogridWidget',
    'pointDistances'
  ]),
  data() {
    return {
      isPreview: false
    }
  },
  methods: mapActions(['onSubmit']),
  components: {
    FormGridMeasure,
    FormGridDistance,
    FormGridSize,
    FormMapsApiKey,
    FormWidgetKind,
    FormPreview,
    FormOutput,
    FormNotificationEmails,
    FormWhiteLabelConfiguration,
    FormTextInput,
    FormAdditionalExtrafields,
    FormButtonSetting,
    AutocompleteApiKey
  }
}
</script>
