<template>
  <div class="scheduled-config-aside scroll ver">
    <Config v-if="showFullConfig"
      class="scheduled-config-aside__config"
      @hideConfigDetails="toggleFullConfig(false)" />
    <template v-else>
      <ConfigPreview :business-location-visible="businessLocationVisible"
        @showConfigDetails="toggleFullConfig(true)"
        @toggleShowBusinessLocation="toggleShowBusinessLocation" />

      <div>
        <div class="w-100 mb-1 btn-group btn-group-toggle" data-toggle="buttons">
          <label :class="['btn btn-xs', { 'btn-primary': stsOrAvgState === STS_OR_AVG.SEARCH_TERMS }]">
            <input type="radio" name="stsOrAvgState" v-model="stsOrAvgState" :value="STS_OR_AVG.SEARCH_TERMS">
            <i class="fa fa-fw fa-list mr-2" />
            Search terms
          </label>
          <label :class="['btn btn-xs', { 'btn-primary': stsOrAvgState === STS_OR_AVG.AVERAGES }]">
            <input type="radio" name="stsOrAvgState" v-model="stsOrAvgState" :value="STS_OR_AVG.AVERAGES">
            <i class="fa fa-fw fa-chart-mixed mr-2" />
            Metric charts
          </label>
        </div>

        <ConfigHistory v-if="stsOrAvgState === STS_OR_AVG.AVERAGES" :historical-data="config.histories" />
        <SearchTermsList v-else-if="stsOrAvgState === STS_OR_AVG.SEARCH_TERMS" class="scheduled-config-aside__search-terms" />
      </div>
    </template>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import ConfigPreview from './config_preview'
import Config from './config'
import SearchTermsList from './search_terms_list'
import ConfigHistory from './config_history'

export default {
  components: {
    ConfigPreview,
    SearchTermsList,
    Config,
    ConfigHistory
  },
  data: () => ({
    showFullConfig: false,
    stsOrAvgState: 'SEARCH_TERMS',
    businessLocationVisible: false
  }),
  computed: {
    ...mapState(['config'])
  },
  methods: {
    ...mapMutations([
      'toggleBusinessMarker',
      'toggleGeogridMarkers',
      'repeatConfig'
    ]),
    toggleFullConfig(state) {
      this.showFullConfig = state
      this.toggleShowBusinessLocation(state)
      this.toggleGeogridMarkers(!state)
    },
    toggleShowBusinessLocation(show) {
      if (!this.config.attrs.businessLocationLat) return

      this.toggleBusinessMarker({ attrs: this.config.attrs, show })
      if (typeof show === 'boolean') {
        this.businessLocationVisible = show
      } else {
        this.businessLocationVisible = !this.businessLocationVisible
      }
    }
  },
  watch: {
    showFullConfig(newVal) {
      this.$emit('setShowFullConfig', newVal)
    }
  },
  created() {
    this.STS_OR_AVG = {
      SEARCH_TERMS: 'SEARCH_TERMS',
      AVERAGES: 'AVERAGES'
    }
  }
}
</script>
