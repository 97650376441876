<template>
  <div class="form-field">
    <span class="caption">White label configuration</span>
    <multiselect
      v-model="model"
      :allow-empty="true"
      :options="whiteLabelConfigurations"
      :searchable="false"
      :show-labels="false"
      :max-height="540"
      label="text" />
    <small class="text-muted">Optionally whitelabel the results and email that this Geogrid widget produce. </small>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapState } from 'vuex'

export default {
  components: { Multiselect },
  computed: {
    ...mapState(['geogridWidget', 'whiteLabelConfigurations']),
    model: {
      get() {
        return this.whiteLabelConfigurations.filter((config) => config.id === this.$store.state.geogridWidget.whiteLabelConfigurationId)
      },
      set(val) {
        this.$store.commit('changeGeogridWidgetKey', { key: 'whiteLabelConfigurationId', val: val?.id ?? '' })
      }
    }
  }
}
</script>
