<template>
  <div ref="groupChart" />
</template>

<script>
import Highcharts from 'highcharts'
import moment from 'moment'

const tooltipPoint = (data, dashStyle) => (
  '<div class="tooltip-line">' +
  `<span class="dash dash--${dashStyle.toLowerCase()}" style="border-bottom-color:${data.color}"></span>` +
  `<span class="date">${moment.utc(data.x).format('D MMM YYYY')}</span>` +
  `<span class="value">${data.y}</span></div>`
)

export default {
  props: {
    chartData: { type: Array, default: () => []}
  },
  mounted() {
    const tooltip = {
      backgroundColor: '#535c65',
      borderRadius: 4,
      borderWidth: 0,
      padding: 0,
      style: {
        fontSize: '14px',
        color: '#fff'
      },
      useHTML: true,
      shadow: false
    }

    tooltip.formatter = function formatter() {
      const i = this.series.data.indexOf(this.point)
      const memo = this.series.chart.series.reduce((acc, series) => {
        if (series.data[i].y !== null) {
          acc.push(tooltipPoint(series.data[i], series.options.dashStyle))
        }
        return acc
      }, [])

      return memo.join('')
    }

    if (this.chart) this.chart.destroy()

    this.chart = Highcharts.chart(this.$refs.groupChart, {
      alignTicks: false,
      title: false,
      chart: { height: 250 },
      credits: { enabled: false },
      legend: { enabled: false },
      xAxis: [
        { type: 'datetime' },
        { type: 'datetime', opposite: true }
      ],
      yAxis: [{
        title: { enabled: false }
      }],
      time: { useUTC: true },
      series: this.chartData.map((data) => {
        data.marker = {
          enabled: false,
          state: {
            hover: {
              enabled: true
            }
          },
          symbol: 'circle'
        }
        return data
      }),
      tooltip: tooltip
    })
  }
}
</script>
