<template>
  <div class="form-field-color">
    <div :id="field" class="field-color" :style="{ backgroundColor: model.hex }" />
    <b-popover :target="field" triggers="hover" placement="right" custom-class="popover-without-padding">
      <chrome-picker v-model="model" :disable-alpha="true" />
    </b-popover>
    <span>{{ label }}</span>
  </div>
</template>

<script>
import { Chrome } from 'vue-color'

export default {
  components: { 'chrome-picker': Chrome },
  props: ['field', 'label'],
  computed: {
    model: {
      get() { return { hex: this.$store.state.geogridWidget[this.field] } },
      set(val) { this.$store.commit('changeGeogridWidgetKey', { key: this.field, val: val.hex }) }
    }
  }
}
</script>
