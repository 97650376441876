<template>
  <div class="selected-competitor-card">
    <div class="selected-competitor-card__top">
      <div class="selected-competitor-card__image">
        <img :src="competitor.imageSrc || require('no-image.png')" :alt="`${competitor.name} image`">
      </div>
      <span class="selected-competitor-card__order">{{ competitor.order }}</span>
    </div>
    <div class="selected-competitor-card__content">
      <a :href="competitor.mapsUrl"
        class="selected-competitor-card__name pb-4"
        target="_blank">{{ competitor.name }}</a>
      <div class="selected-competitor-card__line">
        <p>Place ID</p>
        <span>{{ competitor.placeId }}</span>
      </div>
      <div class="selected-competitor-card__line">
        <p>Address</p>
        <span>{{ competitor.address ?? 'Service Area Business' }}</span>
      </div>
      <div v-if="competitor.categories?.length" class="selected-competitor-card__line">
        <p>Categories</p>
        <span>{{ competitor.categories.join('; ') }}.</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    competitor: {
      type: Object,
      required: true
    }
  }
}
</script>
