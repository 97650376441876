<template>
  <div class="location-selector-dropdown" @click.stop>
    <div class="px-4 py-3">
      <div class="form-field location-selector-search">
        <input type="text" v-model="query">
        <i class="far fa-search" />
        <div class="search-control">
          <i :class="['far fa-tag', { 'active': isTagsShown }]" @click="toggleTagsPanel" />
        </div>
      </div>
    </div>

    <div v-if="!isTagsShown" class="dropdown-list">
      <div class="dropdown-list-head">
        <Checkbox
          :value="allChecked"
          :part-selected="partSelected && selectedLocationsCount !== 0"
          @input="checkAll(partSelected)">
          Selected {{ selectedLocationsCount }} of {{ locations.length }}
        </Checkbox>
        <button class="btn btn-link ml-2" @click="checkAll(true)">Select All</button>
        <button class="btn btn-link ml-2" @click="checkAll(false)">Unselect</button>
      </div>
      <div class="dropdown-list-box scroll ver">
        <div v-for="location in locations" :key="location.id" class="dropdown-list-item">
          <Checkbox v-model="location.checked" class="location-item">
            <p class="text-truncate m-0">{{ location.name }}</p>
            <p class="text-truncate text-muted m-0">{{ location.address }}</p>
          </Checkbox>
        </div>
        <div v-if="locationsLoading" class="dropdown-list-loading">
          <i class="far fa-spinner-third fa-spin" aria-hidden="true" />
        </div>
        <div v-else-if="locations.length === 0" class="px-4 py-3">
          Locations list is empty
        </div>
        <div v-observe-visibility="reachedEndOfLocationList" style="height: 1px;" />
      </div>
      <div class="dropdown-list-footer">
        <p v-if="selectedTagsCount" class="m-0">
          Also {{ selectedTagsCount }} tags selected
        </p>
        <button class="btn btn-sm btn-primary ml-auto"
          :disabled="!(selectedLocationsCount || selectedTagsCount)"
          @click="addLocationsTags">
          Add Locations
        </button>
      </div>
    </div>

    <div v-else class="dropdown-list">
      <div class="dropdown-list-head">
        <strong class="dropdown-list-caption">Tags</strong>
      </div>
      <div class="dropdown-list-box scroll ver">
        <div v-for="tag in tags" :key="tag.tag" class="dropdown-list-item d-flex justify-content-between">
          <Checkbox v-model="tag.checked">
            <p class="text-truncate m-0">{{ tag.tag }}</p>
          </Checkbox>
        </div>
        <div v-if="tagsLoading" class="dropdown-list-loading">
          <i class="far fa-spinner-third fa-spin" aria-hidden="true" />
        </div>
        <div v-else-if="tags.length === 0" class="px-4 py-3">
          Tags list is empty
        </div>
        <div v-observe-visibility="reachedEndOfTagList" style="height: 1px;" />
      </div>
      <div class="dropdown-list-footer">
        <p v-if="selectedLocationsCount" class="m-0">
          Also {{ selectedLocationsCount }} locations selected
        </p>
        <button class="btn btn-sm btn-primary ml-auto"
          :disabled="!(selectedLocationsCount || selectedTagsCount)"
          @click="addLocationsTags">
          Add Locations
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ObserveVisibility } from 'vue-observe-visibility'
import { mapState } from 'vuex'
import Checkbox from '../checkbox'

Vue.directive('observe-visibility', ObserveVisibility)

export default {
  components: { Checkbox },
  created() {
    this.searchLocation()
    this.searchTags()
  },
  data() {
    return {
      locations: [],
      locationsPage: 1,
      endOfLocationList: false,
      locationsLoading: false,
      tags: [],
      tagsPage: 1,
      endOfTagList: false,
      tagsLoading: false,
      isTagsShown: false,
      query: '',
      timeout: null
    }
  },
  computed: {
    ...mapState(['apiOrgLocationsPath', 'indexOrgTagsPath']),
    selectedLocationsCount() {
      return this.locations.filter((location) => location.checked).length
    },
    allChecked() {
      return this.selectedLocationsCount === this.locations.length && this.locations.length !== 0
    },
    partSelected() {
      return this.selectedLocationsCount !== this.locations.length
    },
    selectedTagsCount() {
      return this.tags.filter((tag) => tag.checked).length
    }
  },
  methods: {
    toggleTagsPanel() {
      this.isTagsShown = !this.isTagsShown
    },
    searchLocation(query = '', page = 1) {
      this.locationsLoading = true
      this.locationsPage = page
      if (page === 1) {
        this.locations = []
      }
      axios.get(`${this.apiOrgLocationsPath}/index_for_select`, { params: { search: query, page } })
        .then((res) => {
          res.data.forEach((location) => {
            location.checked = false
          })
          this.locations = [...this.locations, ...res.data]
          if (!res.data.length) {
            this.endOfLocationList = true
          }
        })
        .finally(() => {
          this.locationsLoading = false
        })
    },
    searchTags(query = '', page = 1) {
      this.tagsLoading = true
      this.tagsPage = page
      if (page === 1) {
        this.tags = []
      }
      axios.get(this.indexOrgTagsPath, { params: { search: query, page } })
        .then((res) => {
          this.tags = [...this.tags, ...res.data.map((tag) => ({
            tag: tag,
            checked: false
          }))]
          if (!res.data.length) {
            this.endOfTagList = true
          }
        })
        .finally(() => {
          this.tagsLoading = false
        })
    },
    checkAll(flag) {
      this.locations.forEach((location) => {
        location.checked = flag
      })
    },
    addLocationsTags() {
      this.$emit('addLocationsTags', {
        locations: this.locations.filter((location) => location.checked),
        tags: this.tags.filter((tag) => tag.checked).map((tag) => tag.tag)
      })
    },
    reachedEndOfTagList(isVisible) {
      if (!isVisible || this.endOfTagList || this.tagsLoading) return

      this.searchTags(this.query, this.tagsPage + 1)
    },
    reachedEndOfLocationList(isVisible) {
      if (!isVisible || this.endOfLocationList || this.locationsLoading) return

      this.searchLocation(this.query, this.locationsPage + 1)
    }
  },
  watch: {
    query() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.endOfTagList = false
        this.endOfLocationList = false
        this.searchLocation(this.query)
        this.searchTags(this.query)
      }, 500)
    }
  }
}
</script>
