import Vue from 'vue'
import toastr from 'toastr'
import { App } from '../common/rails'
import WhiteLabelConfigurationForm from '../vue_widgets/white_label_configuration_form'

function initForms(params) {
  $(() => {
    const el = document.getElementById('js-white-label-configuration-form')
    const props = {
      data: JSON.parse(params.whiteLabelConfiguration),
      url: params.apiUrl,
      publicDomain: params.publicDomain,
      emailDomain: params.emailDomain
    }
    /* eslint-disable no-new */
    new Vue({
      el: el,
      render: (h) => h(WhiteLabelConfigurationForm, { ref: 'whiteLabelConfigurationForm', props: props })
    })
  })
}

function initDomainTools() {
  const sub = App.cable.subscriptions.create({ channel: 'WhiteLabelConfigurationChannel' }, {
    received: (data) => {
      if (data.status === 'success') {
        toastr.success(data.message)
      } else {
        toastr.warning(data.message)
      }
      setTimeout(() => {
        Turbolinks.visit()
      }, 2000)
    }
  })

  $(document).one('turbolinks:visit', () => {
    App.cable.subscriptions.remove(sub)
  })

  $(document, '.js-domain-check').on('ajax:complete', (e) => {
    $(e.originalEvent.target).addClass('disabled')
    $(e.originalEvent.target).find('.js-domain-check-spin-icon').addClass('fa-spin')
  })
}

Styxie.Initializers.WhiteLabelConfigurations = {
  edit: initForms,
  index: initDomainTools
}
