<template>
  <div class="d-flex flex-wrap">
    <div class="scheduled-publications-info-item ">
      <p>GMB Media: {{ showMediaCount }}</p>
      <p>GMB Locations: {{ schedule.locationsCount }}</p>
      <p>Category: {{ schedule.categoryName }}</p>
    </div>
    <div class="scheduled-publications-info-item">
      <p>Days of publications: {{ schedule.days }}</p>
      <p>Start day: {{ formatStartDate(schedule.startDay) }}</p>
      <span v-if="!schedule.onlyWorkingDays" class="scheduled-publications-info-weekend">Publish on weekends</span>
    </div>
    <div class="scheduled-publications-info-item">
      <p>
        EXIF:
        <template v-if="schedule.exifState === 'keep'">Original</template>
        <template v-else-if="schedule.exifState === 'edit'">Override</template>
        <template v-else>Clean</template>
      </p>
      <p>
        Filename:
        <template v-if="schedule.keepOriginalFilename">Original</template>
        <template v-else>Optimize for SEO</template>
      </p>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['schedule']),
    showMediaCount() {
      return this.schedule.processStatus === 'processing' ? this.schedule.media.length : this.schedule.mediaCount
    }
  },
  methods: {
    formatStartDate(date) {
      return moment(date).format('LL')
    }
  }
}
</script>
