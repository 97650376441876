import Vue from 'vue'

import createFormStore from '../../vue_widgets/gmb/media/form/store'
import FormWidget from '../../vue_widgets/gmb/media/form/index'
import { toDataUrl } from '../../common/helpers'

function form(params) {
  $(() => {
    new Vue({ // eslint-disable-line no-new
      el: document.getElementById('vue-media-form'),
      store: createFormStore(params),
      render: (h) => h(FormWidget)
    })
  })
}

function clone(params) {
  params.object = { ...params.object, currentExifs: params.object.exifs }
  form(params)
}

Styxie.Initializers.GmbMedia = {
  new: form,
  edit: form,
  clone: clone,
  index() {
    $(() => {
      $('.js-download-file').on('click', (e) => {
        e.preventDefault()
        toDataUrl(e.currentTarget.href, (myBase64) => {
          const link = document.createElement('a')
          link.href = myBase64
          link.download = e.currentTarget.download
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
      })
    })
  }
}

export default form
