<template>
  <div>
    <div v-if="!selectedImages.length" class="media-gallery-heading">
      <div class="d-flex align-items-end">
        <h1>Gallery</h1>
        <a href="#" @click.prevent="onOrder">
          {{ sortingLabel }}
          <span :class="['sortable-icon ml-2', order]" />
        </a>
      </div>
      <template v-if="!loading">
        <button class="btn btn-primary d-md-none gap-3" @click="upload">
          <i class="far fa-plus-circle" />
          Add image
        </button>
        <button class="btn btn-drag-drop btn-sm d-none d-md-inline-flex" @click="upload">
          Drag and drop images or
          <i class="far fa-plus-circle rounded" />
        </button>
      </template>
      <div v-else class="d-flex align-items-center">
        <p class="m-0 m-r-1">
          {{ uploadedFiles }} out of {{ queuededFiles }} images uploaded
        </p>
        <div class="media-gallery-circle">
          <AppProgress
            v-if="totalProgress"
            :data-dasharray="[totalLoading, totalProgress]"
            :radius="10"
            :stroke-width="2" />
        </div>
      </div>
    </div>
    <div v-else class="media-gallery-actions">
      <div class="d-flex align-items-center">
        <Checkbox
          class="mr-3"
          :value="!!selectedImages"
          :part-selected="images.length !== selectedImages.length"
          @input="selectAll(images.length !== selectedImages.length)">
          {{ selectedImages.length }}
          {{ "image" | pluralize(selectedImages.length) }}
        </Checkbox>
        <button
          v-if="images.length !== selectedImages.length"
          class="btn btn-link d-none d-md-inline-flex"
          @click="selectAll(true)">
          Select All
        </button>
        <button v-else class="btn btn-link d-none d-md-inline-flex" @click="selectAll(false)">
          Unselect All
        </button>
      </div>
      <div class="d-flex justify-content-end gap-1">
        <CustomDropdownTags class="d-none d-md-inline-flex mr-2" :tags="imageTags" :elements="selectedImageObjs" @updateTags="updateTags" />
        <button @click="runSwal(removeSelected)" class="btn btn-sm btn-danger rounded">
          <span class="d-none d-xlg-block">Delete</span>
          <i class="far fa-trash d-xlg-none" />
        </button>
        <button @click="toggleExportPannel" class="btn btn-sm btn-primary rounded">
          <span class="d-none d-xlg-block">Export</span>
          <i class="far fa-cloud-download-alt d-xlg-none" />
        </button>
        <button @click="toggleScheduledPannel" class="btn btn-sm btn-primary rounded">
          <span class="d-none d-xlg-block">Schedule</span>
          <i class="far fa-calendar-alt d-xlg-none" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapState, mapGetters, mapActions, mapMutations
} from 'vuex'
import CustomDropdownTags from './custom_dropdown_tags.vue'
import Checkbox from '../components/checkbox'
import AppProgress from './progress'
import { runSwal } from '../../common/delete_with_swal'

export default {
  components: { Checkbox, AppProgress, CustomDropdownTags },
  computed: {
    totalProgress() {
      return this.queuededFiles * 100
    },
    ...mapState([
      'selectedImages',
      'loading',
      'uploadedFiles',
      'queuededFiles',
      'totalLoading',
      'images',
      'imageTags',
      'order'
    ]),
    ...mapGetters(['isSelected', 'showPagination', 'selectedImageObjs']),
    sortingLabel() {
      return this.order === 'desc' ? 'Newest' : 'Oldest'
    }
  },
  methods: {
    upload() {
      this.$parent.$refs.droparea.$refs.file.click()
    },
    ...mapActions(['removeSelected', 'fetchImages', 'updateTags']),
    ...mapMutations(['selectImage', 'selectAll', 'toggleScheduledPannel', 'toggleExportPannel', 'setOrder', 'setPage']),
    runSwal,
    onOrder() {
      if (this.order === 'asc') {
        this.setOrder('desc')
      } else {
        this.setOrder('asc')
      }
      this.setPage(0)
      this.fetchImages()
    }
  }
}
</script>
