import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import toastr from 'toastr'

import { App, collectErrors } from '../../../common/rails'
import { runSwal } from '../../../common/delete_with_swal'

Vue.use(Vuex)

export default function createStore(bulks) {
  const apiPath = `/api${window.location.pathname.replace('questions/', '')}`

  const store = new Vuex.Store({
    state: {
      bulks: bulks
    },
    getters: {
      bulks(state) { return state.bulks }
    },
    actions: {
      submitForm({ commit }, payload) {
        const formData = new FormData()
        formData.append('gmb_bulk_qna[file]', payload.file)
        axios.post(
          apiPath,
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        ).then((res) => {
          toastr.success('Processing new CSV started')
          commit('addBulk', res.data)
        }).catch((res) => {
          toastr.error(collectErrors(res.response.data.errors))
        })
      },
      deleteBulk(_, payload) {
        runSwal(() => {
          axios.delete(`${apiPath}/${payload.obfuscated_id}`)
            .then(() => {
              payload.state = 'delete_in_progress'
            })
        })
      }
    },
    mutations: {
      addBulk(state, bulk) {
        state.bulks.unshift(bulk)
      },
      updateBulk(state, bulk) {
        const current = state.bulks.findIndex((el) => el.obfuscated_id === bulk.obfuscated_id)
        const sbulks = state.bulks
        bulks.splice(current, 1, bulk)
        state.bulks = sbulks
      },
      destroyBulk(state, id) {
        state.bulks = state.bulks.filter((el) => el.obfuscated_id !== id)
      }
    }
  })

  App.cable.subscriptions.create({ channel: 'Gmb::BulkQnaChannel' }, {
    received(action) {
      switch (action.type) {
        case 'destroyed':
          return store.commit('destroyBulk', action.payload.obfuscated_id)
        case 'updated':
          return store.commit('updateBulk', action.payload)
        default: return false
      }
    }
  })

  return store
}
