<template>
  <form class="mv-business-form" @submit.prevent="createBusiness">
    <input type="hidden"
      name="mobile_business[organizations_location_id]"
      :value="selectedBusiness ? selectedBusiness.id : ''">
    <div class="form-field mb-2">
      <Multiselect
        v-model="selectedBusiness"
        :multiple="false"
        :options="businesses"
        :searchable="true"
        :show-labels="false"
        :max-height="540"
        :loading="isBusinessesLoading"
        label="name"
        placeholder="Search business"
        track-by="id"
        @search-change="getOptions">
        <template slot="option" slot-scope="props">
          <div>
            <span class="d-block text-truncate">{{ props.option.name }}</span>
            <span class="d-block font-size-14 text-truncate">{{ props.option.address || props.option.keywordsCustomSearchLocation }}</span>
            <span class="d-block font-size-14 text-truncate">{{ props.option.storeCode }}</span>
          </div>
        </template>
      </Multiselect>
    </div>

    <div class="form-field mb-3">
      <label>Business country: <strong>{{ countryName }}</strong></label>
    </div>

    <div class="form-field mb-2">
      <label class="mb-0">
        City
      </label>
      <input
        id="businessAddress"
        v-model="formattedAddress"
        ref="businessAddress"
        required="true"
        type="text"
        placeholder="Enter address"
        :disabled="!countryShort"
        @keydown.enter.prevent>
    </div>

    <div>
      <span class='hint'>As your moment might be out-of-town, specify the city closest to it</span>
    </div>

    <div class="d-flex align-items-center mt-4 pt-3">
      <a href="/gb/businesses" class="btn rounded mr-3">Cancel</a>
      <button type="submit" :class="['btn btn-primary rounded', { 'with-icon': isSent }]" :disabled="submitDisabled">
        <i v-if="isSent" class="far fa-circle-notch fa-spin" aria-hidden="true" />
        <template v-if="freeSubscriptionAvailable">
          Enable your free {{ gbName }} location
        </template>
        <template v-else>
          Enable {{ gbName }} for this location
        </template><span v-if="!freeSubscriptionAvailable" class="text-danger">*</span>
      </button>
    </div>
    <p v-if="!freeSubscriptionAvailable" class="mt-3">
      <span class="text-danger">*</span> enabling {{ gbName }} will add a $99/mo subscription
    </p>
  </form>
</template>

<script>
import toastr from 'toastr'
import Multiselect from 'vue-multiselect'
import axiosTransform from '../../../common/axios'
import { ensureGoogleMap } from '../../../common/map_helpers'
import { DEFAULT_ERROR_MESSAGE } from '../../../common/constants'
import { searchParams } from '../../../common/search_params'

export default {
  components: { Multiselect },
  props: {
    formAction: { type: String, required: true },
    googleMapApiKey: String,
    freeSubscriptionAvailable: { type: Boolean, default: false }
  },
  data() {
    return {
      selectedBusiness: null,
      businesses: [],
      isBusinessesLoading: false,
      isSent: false,
      formattedAddress: null,
      addressComponents: null,
      show: false
    }
  },
  created() {
    this.getOptions()
  },
  methods: {
    getOptions(query = '') {
      this.isBusinessesLoading = true
      this.businesses = []
      clearTimeout(this.timeout)

      this.timeout = setTimeout(() => {
        axios.get(`/gb/ajax/businesses/index_for_select?search=${query}`).then((res) => {
          this.businesses = res.data.sort((a, b) => Number(b.bool) - Number(a.bool)).map((obj) => ({ ...obj }))
        }).finally(() => {
          this.isBusinessesLoading = false
        })
      }, 500)
    },
    createBusiness() {
      this.isSent = true
      const prms = {
        mobileBusiness: {
          organizationsLocationId: this.selectedBusiness.id
        }
      }
      if (searchParams().coupon) {
        prms.coupon = searchParams().coupon
      }

      if (this.addressComponents && Array.isArray(this.addressComponents)) {
        prms.mobileBusiness.addressComponents = this.addressComponents
      }

      axios.post(this.formAction, prms, axiosTransform)
        .then((res) => {
          $(document).one('turbolinks:load', () => {
            toastr.success(`${this.gbName} has been enabled successfully`)
          })
          Turbolinks.visit(res.data.redirectTo)
        })
        .catch((err) => {
          this.isSent = false
          toastr.error(err.response?.data?.error || DEFAULT_ERROR_MESSAGE)
        })
    },
    initAutocomplete() {
      const autocomplete = new google.maps.places.Autocomplete(this.$refs.businessAddress, this.optionAutocomplete)
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace()
        if (place) {
          this.formattedAddress = place.formatted_address
          this.addressComponents = place.address_components
        }
      })

      this.$nextTick(() => {
        const geocoder = new google.maps.Geocoder()
        geocoder.geocode({ address: this.formattedAddress }, (results) => {
          if (results.length) {
            this.formattedAddress = results[0].formatted_address
            this.addressComponents = results[0].address_components
          }
        })
      })
    },
    typeForCountry(country) {
      if (['AU', 'PH', 'ID', 'MY'].includes(country)) return 'colloquial_area'
      if (['GB', 'IE'].includes(country)) return 'postal_town'
      if (['IL'].includes(country)) return 'administrative_area_level_3'

      return '(cities)'
    }
  },
  computed: {
    countryName() {
      if (!this.selectedBusiness) return null

      return this.selectedBusiness.countryName
    },
    countryShort() {
      if (!this.selectedBusiness) return null

      return this.selectedBusiness.countryCode
    },
    cityName() {
      if (!this.selectedBusiness) return null

      return this.selectedBusiness.cityName
    },
    isAllFieldsFilled() {
      return (this.selectedBusiness && this.addressComponents)
    },
    submitDisabled() {
      return !this.isAllFieldsFilled || this.isSent
    },
    gbName() {
      return Styxie.WLC.gbName
    }
  },
  watch: {
    selectedBusiness() {
      this.addressComponents = null
      this.formattedAddress = this.cityName

      if (this.countryShort) {
        this.optionAutocomplete = {
          lang: 'en',
          types: [this.typeForCountry(this.countryShort)],
          componentRestrictions: { country: this.countryShort }
        }

        ensureGoogleMap({ googleMapApiKey: this.googleMapApiKey }, () => {
          this.initAutocomplete()
        })
      }
    },
    formattedAddress(newVal, oldVal) {
      if (!oldVal || !newVal) return
      if (newVal.length < oldVal.length) {
        this.addressComponents = null
      }
    }
  }
}
</script>
