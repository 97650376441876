<template>
  <code>
    <pre ref="code">
&lt;script type="text/javascript" id="geogrid_widget_{{ geogridWidget.id }}"&gt;
  var WIDGET_ID = "{{ geogridWidget.id }}";
  var script = document.createElement("script");
  script.type  = "text/javascript";
  script.async = true;
  script.src = "{{ scriptUrl }}";
  script.addEventListener('load', function() {
    GeogridWidget.start(WIDGET_ID);
  });
  document.head.appendChild(script);
&lt;/script&gt;</pre>
    <button class="button-copy" @click="onCopy">
      <i class="far fa-fw fa-copy" />
    </button>
  </code>
</template>

<script>
import { copyToClipboardWithAnimation } from 'common/copy_to_clipboard'

export default {
  props: {
    geogridWidget: Object,
    scriptUrl: String
  },
  methods: {
    onCopy(e) {
      copyToClipboardWithAnimation({ element: e.currentTarget, textToCopy: this.$refs.code.innerText })
    }
  }
}
</script>
