<template>
  <div class="form-group row">
    <label class="col-12 col-sm-3 col-form-label text-sm-right" for="tags">Tags</label>

    <div class="col-12 col-sm-8 col-lg-6">
      <select name="" id="tags" ref="select" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

const PER_PAGE = 10

export default {
  computed: mapState(['indexOrgTagsPath']),
  mounted() {
    const $select = $(this.$refs.select)
    $select.turboSelect({
      width: '100%',
      dropdownAutoWidth: 'true',
      multiple: true,
      ajax: {
        url: this.indexOrgTagsPath,
        dataType: 'json',
        delay: 250,
        data(params) {
          return {
            search: params.term,
            page: params.page || 1
          }
        },
        processResults(results) {
          return {
            results: results.map((el) => ({ id: el, text: el })),
            pagination: { more: results.length === PER_PAGE }
          }
        }
      }
    }).change(() => {
      this.$emit('selectChanged', $select.select2('data').map((el) => el.id))
    })
  }
}
</script>
