<template>
  <div>
    <VueSlimTable
      ref="table"
      :source="getFBAccounts"
      :columns="columns"
      class="responsive-table borderless-table m-0">
      <template #row="{ row }">
        <TableRow :row="row" />
      </template>
    </VueSlimTable>
  </div>
</template>

<script>
import toastr from 'toastr'
import axios from 'axios'
import { subscribe, removeChannel } from 'vue_widgets/audit_report_configurations/helpers'
import TableRow from './table_row'
import { DEFAULT_ERROR_MESSAGE } from '../../common/constants'

export default {
  components: { TableRow },
  props: {
    facebookAccountsPath: { type: String, required: true }
  },
  created() {
    this.columns = [
      { key: 'profileName', title: 'Profile Name' },
      { key: 'facebookPages', title: 'Permissions' },
      { key: 'syncLastAt', title: 'Last Sync At' },
      { key: 'disconnectedAt', title: 'Disconnected At' },
      { key: 'actions', title: '' }
    ]

    this.channel = subscribe('AccountUpdateFbChannel', () => {
      this.$refs.table?.refetch()
    })
  },
  beforeDestroy() {
    removeChannel(this.channel)
  },
  methods: {
    async getFBAccounts() {
      let result = []

      try {
        result = await axios.get(this.facebookAccountsPath).then((res) => res.data)
      } catch {
        toastr.error(DEFAULT_ERROR_MESSAGE)
      }

      return result.map(({ facebookPages, instagramAccounts, ...rest }) => ({
        ...rest,
        permissions: [
          {
            pages: facebookPages?.filter((page) => !page.disconnectedAt),
            icon: 'fa-brands fa-facebook'
          },
          {
            pages: instagramAccounts?.filter((user) => !user.disconnectedAt),
            icon: 'fa-brands fa-instagram'
          }
        ].filter((permissionGroup) => permissionGroup.pages?.length)
      }))
    }
  }
}
</script>
