import Vue from 'vue'
import axios from 'axios'
import toastr from 'toastr'
import CancelationForm from '../vue_widgets/subscription_cancelations/cancelation_form'

Styxie.Initializers.SubscriptionCancelations = {

  show: (params) => {
    $(() => {
      $('.js-cancel-subscription-cancelation').on('click', () => {
        const url = `/subscription_cancelations/${params.subscriptionCancelationId}/cancel`

        axios.post(url)
          .then(() => { window.location = '/billing/edit' })
          .catch(() => { toastr.error('There has been an error.') })
      })

      /* eslint-disable no-new */
      new Vue({
        el: document.getElementById('cancelation-form'),
        render: (h) => h(CancelationForm, { ref: 'cancelationForm', props: params })
      })
      /* eslint-enable no-new */
    })
  }
}
