<template>
  <div
    class="tag-form"
    @click.stop>
    <div class="d-flex align-items-center justify-content-between gap-4 px-3 py-2 h4 m-0 font-weight-semi-bold text-dark d-lg-none">
      Tags
      <i class="far fa-times" @click.stop="closeDropdown" />
    </div>
    <div class="tag-searching">
      <input
        v-model.trim="search"
        type="text"
        placeholder="Enter tag name"
        maxlength="30"
        @keyup.enter="addToTags(search)">
    </div>
    <div class="tag-list scroll ver">
      <Checkbox v-for="(tag, index) in foundTags"
        :key="index"
        :value="tag.selected"
        @input="(isSelected) => selectTag(tag, isSelected)">
        {{ tag.tag }}
      </Checkbox>
      <Checkbox
        class="new-item"
        v-if="canCreateTag"
        @input="() => addToTags(search)">
        <span>{{ search }}</span>
        <small>(NEW)</small>
      </Checkbox>
    </div>
    <div v-if="showButton" class="confirm-box">
      <p v-if="text" class="text-muted">{{ text }}</p>
      <button class="btn btn-primary btn-sm" :disabled="areTagsUnchecked" @click="applyTags">Add</button>
    </div>
  </div>
</template>

<script>
import Checkbox from '../../../components/checkbox'

export default {
  components: {
    Checkbox
  },
  props: {
    tags: {
      type: Array,
      default: () => []
    },
    selectedTags: {
      type: Array,
      default: () => []
    },
    showButton: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      search: '',
      tagsInternal: []
    }
  },
  computed: {
    foundTags() {
      const search = this.search.toLowerCase()

      return this.tagsInternal.filter((item) => item.tag.toLowerCase().includes(search))
    },
    canCreateTag() {
      return this.search && !this.tagsInternal.find((item) => item.tag.toLowerCase() === this.search.toLowerCase())
    },
    areTagsUnchecked() {
      return !this.tagsInternal.find((item) => item.selected)
    }
  },
  mounted() {
    const merged = new Set([...this.tags, ...this.selectedTags])
    this.tagsInternal = Array.from(merged).map((item) => ({
      tag: item,
      selected: this.selectedTags.includes(item)
    }))
  },
  methods: {
    addToTags(tag) {
      if (!this.canCreateTag) return
      this.search = ''
      this.tagsInternal.unshift({
        tag: tag,
        selected: true
      })
      if (!this.showButton) {
        this.$emit('saveTags', this.tagsInternal.filter((item) => item.selected).map((item) => item.tag))
      }
    },
    selectTag(tag, isSelected) {
      tag.selected = isSelected
      if (!this.showButton) {
        this.$emit('saveTags', this.tagsInternal.filter((item) => item.selected).map((item) => item.tag))
      }
    },
    applyTags() {
      this.$emit('saveTags', this.tagsInternal.filter((item) => item.selected).map((item) => item.tag))
    },
    closeDropdown() {
      this.$emit('closeDropdown')
    }
  }
}
</script>
