const LV = 'LocalViking'
const LBM = 'LocalBrandManager'
const DEFAULT_CTA_TEXT = 'Send us a message'

const reverseBrand = (brand) => (brand === LV ? LBM : LV)

function cancelationReasons(brand) {
  return {
    bugs: {
      imageUrl: '/covid_image.jpg',
      headline: 'Something not working how it should be?',
      paragraph: 'We have a very active development team and aim to resolve any software issues as quickly as possible.\n' +
      'Select the feature(s) you had trouble with and let us know in the comment section on this form.',
      ctaText: DEFAULT_CTA_TEXT,
      inputLabel: 'I had an issue with functionality',
      checkboxes: [
        {
          value: 'geogrids'
        },
        {
          value: 'locations'
        },
        {
          value: 'post'
        },
        {
          value: 'keywords'
        },
        {
          value: 'reports'
        },
        {
          value: 'audits'
        },
        {
          value: 'other'
        }
      ]
    },
    functionality: {
      imageUrl: '/covid_image.jpg',
      headline: 'We are constantly developing new features',
      paragraph: 'Are we missing something you need?' +
        ' Check out our product <a href="https://localviking.com/feature-roadmap" target="_blank">roadmap</a> to see if we are currently working on it,' +
        ' or message us and let us know what you need.',
      ctaText: DEFAULT_CTA_TEXT,
      inputLabel: 'Lack of functionality'
    },
    cost: {
      imageUrl: '/covid_image.jpg',
      headline: 'Talk to us - we may be able to help',
      paragraph: 'We know sometimes you have to make tough decisions for your business, but at' +
        ` ${brand}, we're committed to helping our customers in times of need.`,
      ctaText: DEFAULT_CTA_TEXT,
      inputLabel: 'Out of budget or downsizing'
    },
    support: {
      imageUrl: '/covid_image.jpg',
      headline: "Customer service didn't meet your expectations?",
      paragraph: 'We take great pride in helping our customers as quickly as possible and' +
        ' delivering an excellent customer support experience. If we fell short of that goal,' +
        ' we\'d love to have a quick chat with you and see how we can do better.',
      ctaText: DEFAULT_CTA_TEXT,
      inputLabel: 'I had issues with customer support'
    },
    complicated: {
      imageUrl: '/covid_image.jpg',
      headline: 'Tell us how we can make things simpler',
      paragraph: `We try to make ${brand} as easy to use as possible,` +
        ' so if you find something that is confusing or complicated, let us know.',
      ctaText: DEFAULT_CTA_TEXT,
      inputLabel: 'It was too complicated for me'
    },
    time: {
      imageUrl: '/covid_image.jpg',
      headline: 'Not finding enough time to use the software?',
      paragraph: 'We know you\'re busy running your business,' +
        ` and ${brand} was built to make things easier and automated.` +
      ' Let us know the problem(s) you ran into so we can get to work on the solution(s)',
      ctaText: DEFAULT_CTA_TEXT,
      inputLabel: 'I had no time to use the software'
    },
    closed: {
      imageUrl: '/covid_image.jpg',
      headline: 'We are sad to see you go',
      paragraph: 'We know running a business of any size can be difficult, and we\'re sad to see you go.' +
        ' If you have any feedback, we would love for you to leave a comment on this page below.',
      ctaText: DEFAULT_CTA_TEXT,
      inputLabel: 'My business is closing'
    },
    changing_platform: {
      imageUrl: '/covid_image.jpg',
      headline: `I’ve got an account in ${reverseBrand(brand)}`,
      paragraph: 'We appreciate your response and are happy to accommodate you on our other platform.' +
        ' Please let us know if there\'s anything we can do to assist your migration. We\'ll see you on the other side.',
      ctaText: DEFAULT_CTA_TEXT,
      inputLabel: `Get an account in ${reverseBrand(brand)}`
    },
    other: {
      imageUrl: '/covid_image.jpg',
      headline: 'I have my own reason',
      paragraph: 'We work to make our product better and more convenient every day.' +
        ' Let us know what you had a problem with so we’ll know where to focus our efforts.',
      ctaText: DEFAULT_CTA_TEXT,
      inputLabel: 'Other'
    }
  }
}

export default cancelationReasons
