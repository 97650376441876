<template>
  <div class="mt-3 text-center gallery-images">
    <GalleryTags
      :gallery-tags="galleryTags"
      :selected-gallery-tags="selectedGalleryTags"
      @toggleGalleryTag="toggleGalleryTag" />

    <div v-for="img in galleryImages"
      class="img-thumbnail rounded mr-2 mb-2 d-inline-block"
      style="width: 220px; height: 220px"
      @click="changeSelectedImage({ ...img, source: 'gallery' })">
      <progressive-background :src="img.previewUrl" />
    </div>

    <div v-if="showMore && !galleryImagesFetching" class="row">
      <b-button class="btn btn-default mx-auto" @click="fetchGalleryImages(page++)">Load More</b-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import GalleryTags from 'vue_widgets/gallery/gallery_filter'

export default {
  components: { GalleryTags },
  data() {
    return {
      page: 1
    }
  },
  computed: {
    ...mapState(['galleryImages', 'galleryImagesFetching', 'galleryTags', 'selectedGalleryTags']),
    showMore() {
      return this.galleryImages.length > 0 && this.galleryImages.length % 10 === 0
    }
  },
  methods: {
    ...mapActions(['fetchGalleryImages', 'changeSelectedImage', 'toggleGalleryTag'])
  },
  mounted() {
    if (!this.galleryImages.length) {
      this.fetchGalleryImages(this.page++)
    }
  }
}
</script>
