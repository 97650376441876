<template>
  <tr :class="{'geogrid-competitors-score__row--selected': row.yourCompany}">
    <td class="geogrid-competitors-score__column geogrid-competitors-score__column--image">
      <img :src="row.imageSrc || require('no-image.png')"
        :alt="`Image of the '${row.name} location'`">
    </td>
    <td class="geogrid-competitors-score__column geogrid-competitors-score__column--name">
      <div class="geogrid-competitors-score__info">
        <a class="geogrid-competitors-score__name" :href="row.mapsUrl" target="_blank">
          {{ row.name }}
        </a>
        <div class="geogrid-competitors-score__address">
          <template v-if="row.address">
            {{ row.address }}
          </template>
          <template v-else>
            Service Area Business
          </template>
        </div>
        <div v-if="row.categories?.length"
          class="geogrid-competitors-score__categories">
          <span v-for="category in row.categories"
            :key="category"
            class="geogrid-competitors-score__category">{{ category }}</span>
        </div>
      </div>
    </td>
    <td class="geogrid-competitors-score__column geogrid-competitors-score__column--agr">
      {{ row.geogrid?.agr ? row.geogrid.agr.toFixed(2) : 'N/A' }}
    </td>
    <td class="geogrid-competitors-score__column geogrid-competitors-score__column--atgr">
      {{ row.geogrid?.atgr ? row.geogrid.atgr.toFixed(2) : 'N/A' }}
    </td>
    <td class="geogrid-competitors-score__column geogrid-competitors-score__column--solv">
      {{ row.geogrid?.solv ? `${Math.round(row.geogrid.solv * 100)}%` : '0%' }}
    </td>
    <td class="geogrid-competitors-score__column geogrid-competitors-score__column--rating">
      {{ row.rating?.toFixed(1) ?? 'N/A' }}
    </td>
    <td class="geogrid-competitors-score__column geogrid-competitors-score__column--geogrid">
      <GeogridPreview v-if="row.geogrid"
        class="geogrid-competitors-score__geogrid-preview cursor-pointer"
        :ranks="row.geogrid.ranks"
        @click.native="$emit('showCompetitorGeogrid', row)" />
    </td>
  </tr>
</template>

<script>
import GeogridPreview from '../components/geogrid_preview'

export default {
  components: {
    GeogridPreview
  },
  props: {
    row: {
      type: Object,
      required: true
    }
  }
}
</script>
