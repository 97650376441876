<template>
  <div class="preview-images" @click="close">
    <video v-show="!loading && media[index].type === 'video'"
      :src="media[index].url"
      class="preview-images-img"
      controls
      autoplay
      @click.stop
      @loadeddata="loadImg" />
    <img v-show="!loading && media[index].type !== 'video'"
      :src="media[index].url"
      alt=""
      class="preview-images-img"
      @click.stop
      @load="loadImg">
    <div v-show="loading" class="preview-images-img">
      <i class="far fa-spinner-third fa-spin" />
    </div>
    <i v-if="index !== 0" class="control arrow left far fa-chevron-left" @click.stop="next(-1)" />
    <i v-if="index !== media.length - 1" class="control arrow right far fa-chevron-right" @click.stop="next(1)" />
    <i class="control close-preview far fa-times" />
  </div>
</template>

<script>
import hotkeys from 'hotkeys-js'

export default {
  props: {
    media: {
      type: Array,
      default: () => []
    },
    currentMediaIndex: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      index: 0,
      loading: true
    }
  },
  mounted() {
    this.index = this.currentMediaIndex
    hotkeys('left,right,esc', this.handleHotkeys)
  },
  destroyed() {
    hotkeys.unbind('left,right,esc', this.handleHotkeys)
  },
  methods: {
    close() {
      this.$emit('close')
    },
    next(direction) {
      if (this.media.length === 1) return
      this.loading = true
      const next = this.index + direction
      if (next > this.media.length - 1) {
        this.index = 0
      } else if (next < 0) {
        this.index = this.media.length - 1
      } else {
        this.index += direction
      }
    },
    handleHotkeys(event, handler) {
      event.preventDefault()
      if (handler.key === 'left') {
        this.next(-1)
      }
      if (handler.key === 'right') {
        this.next(1)
      }
      if (handler.key === 'esc') {
        this.close()
      }
    },
    loadImg() {
      this.loading = false
    }
  }
}
</script>
