const emptyCalendarTemplate = `
<div class="simple-calendar" data-fetch-error="Unable to fetch calendar data">
  <table>
    <thead>
      <tr>
        <th xs-label="Mon" label="Monday"></th>
        <th xs-label="Tue" label="Tuesday"></th>
        <th xs-label="Wed" label="Wednesday"></th>
        <th xs-label="Thu" label="Thursday"></th>
        <th xs-label="Fri" label="Friday"></th>
        <th xs-label="Sat" label="Saturday"></th>
        <th xs-label="Sun" label="Sunday"></th>
      </tr>
    </thead>
    <tbody>
      <tr class="week">
        <td class="day wday-1"><span class="date-day"></span></td>
        <td class="day wday-2"><span class="date-day"></span></td>
        <td class="day wday-3"><span class="date-day"></span></td>
        <td class="day wday-4"><span class="date-day"></span></td>
        <td class="day wday-5"><span class="date-day"></span></td>
        <td class="day wday-6"><span class="date-day"></span></td>
        <td class="day wday-0"><span class="date-day"></span></td>
      </tr>
      <tr class="week">
        <td class="day wday-1"><span class="date-day"></span></td>
        <td class="day wday-2"><span class="date-day"></span></td>
        <td class="day wday-3"><span class="date-day"></span></td>
        <td class="day wday-4"><span class="date-day"></span></td>
        <td class="day wday-5"><span class="date-day"></span></td>
        <td class="day wday-6"><span class="date-day"></span></td>
        <td class="day wday-0"><span class="date-day"></span></td>
      </tr>
      <tr class="week">
        <td class="day wday-1"><span class="date-day"></span></td>
        <td class="day wday-2"><span class="date-day"></span></td>
        <td class="day wday-3"><span class="date-day"></span></td>
        <td class="day wday-4"><span class="date-day"></span></td>
        <td class="day wday-5"><span class="date-day"></span></td>
        <td class="day wday-6"><span class="date-day"></span></td>
        <td class="day wday-0"><span class="date-day"></span></td>
      </tr>
      <tr class="week">
        <td class="day wday-1"><span class="date-day"></span></td>
        <td class="day wday-2"><span class="date-day"></span></td>
        <td class="day wday-3"><span class="date-day"></span></td>
        <td class="day wday-4"><span class="date-day"></span></td>
        <td class="day wday-5"><span class="date-day"></span></td>
        <td class="day wday-6"><span class="date-day"></span></td>
        <td class="day wday-0"><span class="date-day"></span></td>
      </tr>
      <tr class="week">
        <td class="day wday-1"><span class="date-day"></span></td>
        <td class="day wday-2"><span class="date-day"></span></td>
        <td class="day wday-3"><span class="date-day"></span></td>
        <td class="day wday-4"><span class="date-day"></span></td>
        <td class="day wday-5"><span class="date-day"></span></td>
        <td class="day wday-6"><span class="date-day"></span></td>
        <td class="day wday-0"><span class="date-day"></span></td>
      </tr>
    </tbody>
  </table>
</div>
`

export default emptyCalendarTemplate
