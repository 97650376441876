<template>
  <div class="col-md-4">
    <ul class="questions">
      <question
        v-for="question in questions"
        :key="question.remote_id"
        :question="question"
        :show-delete="false" />
    </ul>
    <div class="text-center">
      <button v-show="isShowMore" class="btn btn-primary" @click="fetchQuestions">Show more</button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Question from './question'

export default {
  computed: mapState(['questions', 'isShowMore']),
  methods: mapActions(['fetchQuestions']),
  components: { Question }
}
</script>

<style scoped lang="scss">
  .questions {
    margin: 0;
    padding: 0;

    .question {
      cursor: pointer;
      &.active {
        background: #ccc;
      }
      &.removed {
        text-decoration: line-through;
      }
    }
  }
</style>
