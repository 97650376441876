<template>
  <form action="#" @submit.prevent="onFormSubmit">
    <div class="row mt-2 mb-4">
      <div class="col-md-3">
        <label class="float-right" for="gmb_bulk_qna_file">CSV File</label>
      </div>
      <div class="col-md-3">
        <input class="form-control-file" required="required" type="file"
          id="gmb_bulk_qna_file"
          ref="fileInput">
      </div>
      <div class="col-md-3">
        <input type="submit" name="commit" class="btn-secondary" value="Upload">
      </div>
    </div>
  </form>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions(['submitForm']),
    onFormSubmit() {
      this.submitForm({
        file: this.$refs.fileInput.files[0]
      })

      this.$refs.fileInput.value = ''
    }
  }
}
</script>
