<template>
  <div class="moment-destinations">
    <h3 class="moment-destinations__title">
      <span>Publish in</span>
      <i id="moment-destination-info" class="fa-regular fa-info-circle" />
      <b-popover
        placement='top'
        target="moment-destination-info"
        custom-class='geogrids-popover p-1'
        triggers='hover'>
        Default publication settings are managed on the business settings page.
        However, feel free to set up publication destinations for each moment individually.
      </b-popover>
    </h3>
    <p v-if="!noDestinationsAvailable" class="moment-destinations__notice">
      <i class="fa-regular fa-circle-info" />
      Destinations will fallback to selected business defaults when you change business
    </p>
    <div class="moment-destinations__toggles">
      <CustomCheckbox v-for="(toggle, toggleKey) in shownToggles"
        v-model="toggle.value"
        class="moment-destinations__toggle"
        :disabled="!canEditDestinations"
        :key="toggleKey">
        <span>
          {{ toggle.text }}
        </span>
      </CustomCheckbox>
      <p v-if="noDestinationsAvailable" class="text-warning m-0">
        <i class="fa-regular fa-circle-info" />
        You don't have any destinations set up at the moment.
        Please visit the <a :href="`/gb/businesses/${selectedBusiness.id}/edit`" target="_blank">business settings</a> page to configure them.<br>
        For now, moments will only be displayed in the widget.
      </p>
    </div>
  </div>
</template>

<script>
import CustomCheckbox from 'vue_widgets/components/checkbox'

export default {
  components: {
    CustomCheckbox
  },
  props: {
    canEditDestinations: {
      type: Boolean,
      required: false,
      default: true
    },
    checkin: {
      type: Object,
      required: true
    },
    selectedBusiness: {
      type: Object,
      required: true
    },
    igEnabled: {
      type: Boolean,
      required: true
    },
    fbEnabled: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      toggles: {
        publishGmb: {
          value: false,
          text: 'GBP',
          businessConditionKey: 'canPostToGoogle'
        },
        publishWebhook: {
          value: false,
          text: 'Webhook',
          businessConditionKey: 'hasWebhook'
        },
        publishFacebook: {
          value: false,
          text: 'Facebook',
          featureFlag: 'fbEnabled',
          businessConditionKey: 'hasFbLink'
        },
        publishInstagram: {
          value: false,
          text: 'Instagram',
          featureFlag: 'igEnabled',
          businessConditionKey: 'hasIgLink'
        }
      }
    }
  },
  methods: {
    setInitToggleValues() {
      const isNewCheckin = !this.checkin.createdAt
      const initialToggleValuesSource = isNewCheckin ? this.selectedBusiness : this.checkin

      this.setToggleValues(initialToggleValuesSource)
    },
    setToggleValues(valuesSource) {
      Object.keys(this.toggles).forEach((toggleName) => {
        const toggle = this.toggles[toggleName]
        const featureFlagRequired = toggle.featureFlag
        const featureFlagEnabled = this[toggle.featureFlag]
        if (featureFlagRequired && !featureFlagEnabled) {
          toggle.value = false
          return
        }
        const businessCondition = toggle.businessConditionKey
        const satisfiesBusinessCondition = this.selectedBusiness[businessCondition]
        if (businessCondition && !satisfiesBusinessCondition) {
          toggle.value = false
          return
        }

        toggle.value = valuesSource[toggleName]
      })

      this.emitNewTogglesState()
    },
    emitNewTogglesState() {
      if (!this.canEditDestinations) return
      this.$emit('updateMomentDestinations', this.formattedToggles)
    }
  },
  created() {
    this.setInitToggleValues()
  },
  computed: {
    shownToggles() {
      const toggles = {}
      Object.keys(this.toggles).forEach((toggleName) => {
        const toggle = this.toggles[toggleName]

        const featureFlagRequired = toggle.featureFlag
        const featureFlagEnabled = this[toggle.featureFlag]
        if (featureFlagRequired && !featureFlagEnabled) return

        const businessCondition = toggle.businessConditionKey
        const satisfiesBusinessCondition = this.selectedBusiness[businessCondition]
        const showToggleAlways = toggle.value && !this.canEditDestinations
        if (!showToggleAlways && businessCondition && !satisfiesBusinessCondition) return

        toggles[toggleName] = toggle
      })
      return toggles
    },
    noDestinationsAvailable() {
      return Object.keys(this.shownToggles).length === 0
    },
    formattedToggles() {
      const formattedToggles = {}

      Object.keys(this.toggles).forEach((toggleName) => {
        formattedToggles[toggleName] = this.toggles[toggleName].value
      })

      return formattedToggles
    }
  },
  watch: {
    formattedToggles: {
      handler() {
        this.emitNewTogglesState()
      },
      deep: true
    },
    selectedBusiness: {
      handler(newBusiness) {
        if (!this.canEditDestinations) return
        this.setToggleValues(newBusiness)
      },
      deep: true
    }
  }
}
</script>
