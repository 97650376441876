<template>
  <div>
    <CustomSelect
      :value="value"
      class="w-100"
      label="Location"
      placeholder="Search by name, address, place id or store code"
      :track-by="'id'"
      :allow-empty="true"
      :values="locations"
      @input="updateLocation" />
  </div>
</template>
<script>
import CustomSelect from 'vue_widgets/components/custom_select'

export default {
  components: {
    CustomSelect
  },
  props: {
    value: {
      type: String,
      required: true
    },
    locations: {
      type: Array,
      required: true
    }
  },
  methods: {
    updateLocation(event) {
      this.$emit('input', event)
    }
  }
}
</script>
