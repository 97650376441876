import moment from 'moment'
import { differenceClass } from '../../../../common/helpers'
import { humanizeRangeDate } from '../../../../common/datepicker'

function shortValue(value) {
  if (!value) return '0'
  return value >= 1000 ? `${(value / 1000).toFixed(1)}k` : value.toString()
}

export function getValues(stat, compare) {
  if (compare) return [shortValue(stat.sum_compare), shortValue(stat.sum)].join(' / ')
  return shortValue(stat.sum)
}

export function getPercentage({ sum, sumCompare }) {
  if (sumCompare === sum) {
    return 0
  } if (sumCompare === 0 && sum > 0) {
    return 100
  } if (sumCompare > 0 && sum === 0) {
    return -100
  }
  return Math.round(sum * 100 / sumCompare) - 100
}

export function tooltipHeader(name) {
  return `<span class="tooltip-title">${name}</span>`
}

export function tooltipPoint(data, diff, isStats = false) {
  const date = isStats ?
    `${moment(data.ranges.start).format('MMM D, YYYY')} - ${moment(data.ranges.end).format('MMM D, YYYY')}` :
    `${moment(data.x).format('MMM D, YYYY')}`
  return '<div class="tooltip-line">' +
    `<span class="point" style="color:${data.color}">\u25CF</span>` +
    `<span class="date">${date}</span>` +
    `<span class="value">${data.y}</span>` +
    `<span class="difference ${differenceClass(diff)}">${Math.abs(diff)}</span></div>`
}

export function chartOptionsDefault({ subtitle, chartHeight, chartType }) {
  return {
    alignTicks: false,
    allowDecimals: false,
    credits: { enabled: false },
    chart: {
      height: chartHeight,
      type: chartType,
      style: {
        fontFamily: '"Nunito", sans-serif'
      }
    },
    legend: { enabled: false },
    subtitle: {
      text: subtitle,
      verticalAlign: 'bottom',
      useHTML: true,
      style: {
        fontSize: '13px',
        fontFamity: 'Arial',
        color: '#828e9a',
        textAlign: 'center'
      }
    },
    title: false,
    tooltip: { enabled: false },
    yAxis: {
      labels: {
        formatter() {
          return `<span class='y-axis-label'>${this.value}</span>`
        },
        useHTML: true,
        style: {
          fontSize: '14px'
        }
      },
      title: { enabled: false },
      softMax: 10
    }
  }
}

export const tooltipOptions = {
  backgroundColor: '#535c65',
  borderRadius: 4,
  borderWidth: 0,
  crosshairs: true,
  hideDelay: 0,
  outside: true,
  padding: 0,
  shadow: false,
  style: {
    fontSize: '14px',
    color: '#fff'
  },
  useHTML: true
}

export function compileTooltip(isStats = false) {
  return {
    ...tooltipOptions,
    formatter() {
      const i = this.series.data.indexOf(this.point)
      const memo = []
      const title = this.point.name || this.series.name
      memo.push(tooltipHeader(title))
      this.series.chart.series.reduceRight((prev, serieschart) => {
        if (serieschart.data[i].y !== null) {
          const diff = prev.length !== 0 ? (serieschart.data[i].y - prev.y) : 0
          memo.push(tooltipPoint(serieschart.data[i], diff, isStats))
        }
        return serieschart.data[i]
      }, [])
      return memo.join('')
    }
  }
}

export function compileSubtitle(date, compare) {
  let subtitle = ''
  if (date.type === 'range') {
    subtitle = humanizeRangeDate(date.ranges[0])
    if (compare) subtitle = `${humanizeRangeDate(date.ranges[1])} vs ${subtitle}`
  } else {
    subtitle = moment.utc(date.datetime[0]).format('MMM D, YYYY')
    if (compare) { subtitle = `${moment.utc(date.datetime[1]).format('MMM D, YYYY')} vs ${subtitle}` }
  }

  return subtitle
}
