<template>
  <div class="col-md-8">
    <div v-if="question">
      <ul>
        <question :question="question" :show-delete="true" />
      </ul>
      <answers />
      <answerForm
        v-show="needForm"
        ref="form" />
    </div>
    <h3 v-else class="text-muted text-center mt-5" v-show="questions.length">Select Question from the left side</h3>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import Question from './question'
import Answers from './answers'
import AnswerForm from './answer_form'

export default {
  data() {
    return {
      needForm: false
    }
  },
  computed: mapState({
    question: 'currentQuestion',
    questions: 'questions',
    apiConnection: 'apiConnection'
  }),
  watch: {
    'question.answers'() {
      if (this.question && this.apiConnection) {
        this.$nextTick(() => {
          const types = this.question.answers.map((a) => a.author.type)
          this.needForm = !types.includes('MERCHANT')
          if (this.needForm) this.$nextTick(() => { this.$refs.form.$refs.reply.$el.value = '' })
        })
      } else {
        this.needForm = false
      }
    }
  },
  methods: {},
  components: { Question, Answers, AnswerForm }
}
</script>
