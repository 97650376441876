<template>
  <button class="btn btn-pretender" @click="print">
    <i class="far fa-exclamation-circle" />
    <span class="pl-2">Press Cmd/Ctrl+P to print/save as PDF.</span>
  </button>
</template>

<script>
export default {
  methods: {
    print() {
      window.print()
    }
  }
}
</script>
