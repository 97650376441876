/* eslint-disable no-param-reassign, func-names */
(($) => {
  $.fn.toggleWrapper = function toggleWrapper(args = {}, bool = true) {
    const defaults = {
      className: 'js-toggle-wrapper',
      backgroundColor: '#fff',
      zIndex: 1100
    }

    args = { ...defaults, ...args }

    const $offset = this.offset()
    const $width = this.outerWidth(false)
    const $height = this.outerHeight(false)

    if (bool) {
      $('<div>', {
        class: args.className,
        css: {
          width: $width,
          height: $height,
          top: args.parentClass ? 0 : $offset.top,
          left: args.parentClass ? 0 : $offset.left,
          position: 'absolute',
          zIndex: args.zIndex,
          backgroundColor: args.backgroundColor,
          opacity: 0.4,
          display: 'flex',
          'justify-content': 'center',
          'align-items': 'center'
        },
        html: $('<i>', { class: 'far fa-spinner fa-pulse fa-fw fa-3x' })
      }).appendTo(args.parentClass || 'body')
    } else {
      $(`.${args.className}`).remove()
    }

    return this
  }
})($)
/* eslint-enable no-param-reassign, func-names */
