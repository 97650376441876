import Vue from 'vue'

const counts = ['k', 'm', 'b', 'T', 'Q']

function round(num, precision = 2) {
  return parseFloat(num.toFixed(precision), 10)
}

Vue.filter('compactInteger', (num) => {
  if (num === undefined || num < 1000) { return num }
  const i = Math.floor(Math.log(num) / Math.log(1000))
  return round(num / (1000 ** i), 1) + (counts[i - 1] || '')
})
