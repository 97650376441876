<template>
  <div v-if="hasPropertisData(parsedData, properties)" class="report-metrics">
    <MetricTile v-for="property in properties" :key="property" :property="parsedData[property]" :map-icons="mapIcons">
      <template #chart>
        <PropertyChart class="report-metrics-tile__chart" :chart-data="parsedData[property].chartData" />
      </template>
    </MetricTile>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import MetricTile from './metric_tile'
import PropertyChart from './property_chart'
import { mainProps, mapIcons, hasPropertisData } from './helpers'

export default {
  components: { MetricTile, PropertyChart },
  computed: mapState(['parsedData']),
  created() {
    this.properties = mainProps
    this.mapIcons = mapIcons
  },
  methods: {
    hasPropertisData
  }
}

</script>
