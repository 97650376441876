<template>
  <div class="form-group">
    <input class="inputfile" type="file" name="image-upload"
      id="image-upload"
      accept=".png, .jpeg, .jpg"
      @change="uploadImage">
    <label class="btn btn-primary" for="image-upload">
      <i class="icon far fa-arrow-up-from-square" />
      <span>Browse files...</span>
    </label>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

const IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'png']
const MIN_FILE_BYTES = 10240

export default {
  methods: {
    ...mapActions(['changeSelectedImage']),
    uploadImage(e) {
      const [file] = e.target.files
      e.target.value = ''

      if (file.size < MIN_FILE_BYTES) {
        toastr.error('Image size too small. Should be larger than 10KB')
        return
      }

      const nameArr = file.name.split('.')
      if (!IMAGE_EXTENSIONS.includes(nameArr[nameArr.length - 1].toLowerCase())) {
        toastr.error(`Image should have ${IMAGE_EXTENSIONS.join(', ')} extensions`)
        return
      }

      this.changeSelectedImage({ file, source: 'upload' })
    }
  }
}
</script>
