import Vue from 'vue'
import QnAwidget from '../../vue_widgets/gmb/questions/index'
import createStore from '../../vue_widgets/gmb/questions/store'

Styxie.Initializers.GmbQuestions = {
  index: (prms) => {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-qna-widget'),
        store: createStore(prms),
        render: (h) => h(QnAwidget)
      })
    })
  }
}
