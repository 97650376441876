<template>
  <div class="map-container">
    <div id="map" class="widget-map" />
    <div class="coords-map-form" @click.stop>
      <InputSearch class='w-100' v-model="address" placeholder="Specify address"
        ref="search"
        @click.stop />
    </div>
  </div>
</template>

<script>
import { ensureGoogleMap } from '../../../../common/map_helpers'
import InputSearch from '../../../components/input_search_ui'

export default {
  components: { InputSearch },
  props: {
    googleMapApiKey: {
      type: String,
      default: null
    },
    initialCoords: {
      type: Object,
      default: () => ({ lat: null, lng: null })
    }
  },
  data() {
    return {
      map: null,
      marker: null,
      geocoder: null,
      address: '',
      markerCoords: { lat: '', lng: '' },
      location: null
    }
  },
  mounted() {
    ensureGoogleMap({ googleMapApiKey: this.googleMapApiKey }, this.initMap)
    this.$nextTick(() => {
      this.$refs.search.$el.querySelector('input').focus()
    })
  },
  methods: {
    initMap() {
      const self = this
      self.geocoder = new google.maps.Geocoder()

      self.markerCoords.lat = self.initialCoords.lat || 39.3827589 // USA coords
      self.markerCoords.lng = self.initialCoords.lng || -101.875141

      self.map = new google.maps.Map(document.getElementById('map'), {
        center: self.markerCoords,
        zoom: 5,
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        gestureHandling: 'greedy'
      })

      if (self.initialCoords.lat && self.initialCoords.lng) self.map.setZoom(17)
      self.setMarker(self.markerCoords)

      const autocomplete = new google.maps.places.Autocomplete(this.$refs.search.$el.querySelector('input'))
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace()
        if (place && place.geometry) {
          self.$emit('setupGeodata', {
            formatted_address: place.formatted_address,
            address_components: place.address_components,
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
          })
          self.map.setCenter(place.geometry.location)
          self.setMarker(place.geometry.location)
        }
      })
    },
    setMarker(latLng) {
      this.marker?.setMap(null)
      this.marker = new google.maps.Marker({
        position: latLng,
        map: this.map,
        draggable: true
      })
      const self = this
      this.marker.addListener('dragend', () => {
        self.geocodeLocation(self.marker.position)
      })
    },
    geocodeLocation(latLng) {
      this.markerCoords.lat = latLng.lat()
      this.markerCoords.lng = latLng.lng()
      const self = this
      this.geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === 'OK' && results[0]) {
          [self.location] = results
          self.$emit('setupGeodata', {
            formatted_address: self.location.formatted_address,
            address_components: self.location.address_components,
            ...self.markerCoords
          })
        } else {
          self.$emit('setupGeodata', self.markerCoords)
        }
      })
    }
  },
  beforeDestroy() {
    this.marker?.setMap(null)
    document.body.classList.remove('scroll')
  }
}
</script>
