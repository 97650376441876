<template>
  <div :class="['media-gallery-tags scroll ver', { 'flex-wrap': tagsShown }]">
    <a v-for="tag in galleryTags"
      :key="tag"
      :class="['tag-item', { active: tagIncludes(tag) }]"
      href="#"
      @click.prevent="selectTag(tag)">
      {{ tag }}
    </a>
    <div class="tag-item open-tags-btn" title="rearrange tags" @click="toggleTags">
      <i class="far fa-ellipsis-h" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    galleryTags: { type: Array, required: true, default: () => []},
    selectedGalleryTags: { type: Array, required: true }
  },
  data() {
    return {
      tagsShown: false
    }
  },
  methods: {
    tagIncludes(tag) {
      return this.selectedGalleryTags.includes(tag)
    },
    selectTag(tag) {
      this.$emit('toggleGalleryTag', tag)
    },
    toggleTags() {
      this.tagsShown = !this.tagsShown
    }
  }
}
</script>
