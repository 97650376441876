<template>
  <div>
    <h1 class="h2 m-0 mb-4 font-weight-semi-bold color-dark">Audit Reports</h1>

    <FilterComponent class="mb-4 pb-2"
      ref="audit-filter"
      :fields="fields"
      :disabled="isLoading"
      @onFilterChange="onFilterChange" />

    <VueSlimTable
      v-if="showTable"
      ref="table"
      :source="getReports"
      :columns="columns"
      class="responsive-table borderless-table m-0 table-of-audits">
      <template #head:actions>
        <div>Action</div>
      </template>
      <template #row="{ row }">
        <TableRow :row="row" :api-base-path="apiAuditReportsPath" @reload="reload" />
      </template>
      <template #pagination>
        <Pagination class="pb-0"
          :page="currentPage"
          :per-page="perPage"
          :total-count="totalCount"
          @updatePage="updatePageNumber" />
      </template>
    </VueSlimTable>
  </div>
</template>

<script>
import axiosTransform from 'common/axios'
import FilterComponent from 'vue_widgets/components/filter'
import Pagination from 'vue_widgets/components/pagination'
import { subscribe, removeChannel, AUDIT_REPORT_CONFIG_ID_KEY } from 'vue_widgets/audit_report_configurations/helpers'
import { extractSessionStorage } from 'common/helpers'
import TableRow from './audit_row'

export default {
  components: {
    FilterComponent, TableRow, Pagination
  },
  props: {
    apiAuditReportsPath: { type: String, required: true },
    locations: { type: Array, default: () => []},
    configurations: { type: Array, default: () => []}
  },
  data() {
    return {
      /*
      * We need to hide slim-table until we check if we're applying filters.
      * This is necessary because otherwise, if we are applying filters,
      * slim table will try to fetch twice, and there is a possibility that its first request
      * (which is called in created hook) will simply overwrite the second one if it's so happens
      * that this first request will take longer to response
      *  */
      showTable: false,
      isLoading: false,
      isRequested: false,
      currentPage: 1,
      perPage: 20,
      totalCount: 0,
      filter: {},
      fields: [{
        type: 'select',
        key: 'config',
        title: 'Audit configuration',
        classes: 'w-100',
        placeholder: 'All',
        options: this.configurations,
        trackBy: 'id',
        label: 'name',
        alternateLabel: 'title',
        searchable: true,
        showLabels: false,
        allowEmpty: true,
        maxHeight: 540
      }, {
        type: 'select',
        key: 'by_location',
        title: 'Location',
        classes: 'w-100',
        placeholder: 'All',
        options: this.locations,
        trackBy: 'id',
        label: 'name',
        searchable: true,
        showLabels: false,
        allowEmpty: true,
        multiple: true,
        maxHeight: 540
      }, {
        type: 'search',
        key: 'by_keyword',
        title: 'Keyword search',
        classes: 'w-100',
        placeholder: 'Type a keyword'
      }]
    }
  },
  created() {
    this.columns = [
      { key: 'configurationName', title: 'Name', orderable: false },
      { key: 'location', title: 'Location', orderable: false },
      { key: 'keyword', title: 'Keyword', orderable: false },
      { key: 'competitors', title: 'Competitors', orderable: false },
      { key: 'createdAt', title: 'Created At', orderable: false },
      { key: 'status', title: 'Status', orderable: false },
      { key: 'actions', title: 'Action' }
    ]

    this.channel = subscribe('AuditReportsChannel', ({ event, message }) => {
      if (event === 'update_status') {
        this.$refs.table.rows.forEach((row) => {
          if (row.id === message.audit_id) {
            row.pendingReportSources = message.pending_report_sources
          }
        })
      }

      if (event === 'audit_finished') {
        toastr.success('Audit successfully completed.')
        this.reload()
      }
    })
  },
  mounted() {
    const auditConfigId = extractSessionStorage(AUDIT_REPORT_CONFIG_ID_KEY)
    if (auditConfigId) {
      const config = this.configurations.find((c) => c.id === auditConfigId)
      this.$refs['audit-filter'].onFilterChange({ key: 'config', trackBy: 'id' }, config, 0, true)
      sessionStorage.removeItem(AUDIT_REPORT_CONFIG_ID_KEY)
    } else {
      this.showTable = true
    }
  },
  beforeDestroy() {
    removeChannel(this.channel)
  },
  methods: {
    getReports() {
      this.isLoading = true
      return axios.get(this.apiAuditReportsPath, {
        params: {
          ...this.filter,
          page: this.currentPage
        },
        ...axiosTransform
      }).then((res) => {
        this.totalCount = parseInt(res.headers['total-count'], 10)
        return res.data
      }).finally(() => {
        setTimeout(() => {
          this.isLoading = false
        }, 100)
      })
    },
    updatePageNumber(page) {
      this.currentPage = page
      this.reload()
    },
    onFilterChange(newFilterValues) {
      this.filter = newFilterValues
      this.currentPage = 1
      if (!this.showTable) {
        // no need to reload since slim-table will
        // fetch in created hook anyway
        this.showTable = true
      } else {
        this.$nextTick(() => {
          this.reload()
        })
      }
    },
    reload() {
      this.$refs.table.reload()
    }
  }
}
</script>
