<template>
  <div>
    <div class="form-group row">
      <label class="col-12 col-sm-3 col-form-label text-sm-right" for="post_event">{{ title }}</label>
      <div class="col-12 col-sm-8 col-lg-6">
        <input class="form-control" type="text" id="post_event" v-model="object.event.title">
      </div>
    </div>

    <div class="form-group row mt-3">
      <label class="col-12 col-sm-3 col-form-label text-sm-right">Add Event Time</label>
      <div class="col-12 col-sm-8 col-lg-6 pt-2">
        <div class="switch-button switch-button-success">
          <input type="checkbox" name="event_add_event_time" id="event_add_event_time" v-model="object.event.withTime">
          <span>
            <label for="event_add_event_time" />
          </span>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-12 col-sm-3 col-form-label text-sm-right" for="schedule_start">Start date</label>

      <div class="col-12 col-sm-5 col-lg-4">
        <flat-pickr class="form-control" v-model="object.event.startDate" :config="dateConfig" ref="picker1" />
      </div>
      <div class="col-12 col-sm-3 col-lg-2" v-if="object.event.withTime">
        <flat-pickr class="form-control" v-model="object.event.startTime" :config="timeConfig" ref="picker1" />
      </div>
    </div>

    <div class="form-group row">
      <label class="col-12 col-sm-3 col-form-label text-sm-right" for="schedule_end">End date</label>

      <div class="col-12 col-sm-5 col-lg-4">
        <flat-pickr class="form-control" v-model="object.event.endDate" :config="dateConfig" ref="picker2" />
      </div>

      <div class="col-12 col-sm-3 col-lg-2" v-if="object.event.withTime">
        <flat-pickr class="form-control" v-model="object.event.endTime" :config="timeConfig" ref="picker1" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import FlatPickr from 'vue-flatpickr-component'
import { FLATPICKR_WEEKDAYS_MAP } from 'common/constants'

export default {
  props: {
    title: { type: String, required: true }
  },
  created() {
    this.dateConfig = {
      dateFormat: 'd/m/Y',
      locale: {
        firstDayOfWeek: FLATPICKR_WEEKDAYS_MAP[Styxie.USER.beginningOfWeek]
      }
    }
    this.timeConfig = {
      enableTime: true,
      noCalendar: true,
      dateFormat: 'H:i'
    }
  },
  computed: mapState(['object']),
  components: { FlatPickr }
}
</script>
