<template>
  <div class="bg-light col-md-12 mt-4 pt-3 pb-1">
    <h4>GPS</h4>

    <ExifInput v-for="i in inputs" :key="i.identifier" :identifier="i.identifier"
      :label="i.label"
      :recommended="i.recommended" />
  </div>
</template>

<script>
import ExifInput from './exif_input'

export default {
  components: { ExifInput },
  data() {
    return {
      inputs: [
        { identifier: 'latitude', label: 'Latitude', recommended: '{{ location.latitude }}' },
        { identifier: 'longitude', label: 'Longitude', recommended: '{{ location.longitude }}' }
      ]
    }
  }
}
</script>
