import toastr from 'toastr'
import ImportedPostsTable from 'vue_widgets/gmb/bulk_posts/show/imported_posts_table'
import { App } from '../../common/rails'

function updateRow(bulkPostId, data) {
  const $row = $(`tr[data-bulk-post-id='${bulkPostId}']`)
  Object.keys(data).forEach((key) => {
    $row.find(`[data-bulk-post-attribute='${key}']`).html(data[key])
  })
}

function renderNotification(data, params) {
  toastr.options = { closeButton: true }
  const toastrMapping = { finished: 'success', error: 'error' }

  if (data.state === 'parsing_error') {
    const message = `Upload error: ${data.message}`
    toastr.error(message)
    return
  }

  const link = document.createElement('a')
  link.setAttribute('href', `${params.bulkPostsPath}/${data.bulk_post_id}`)
  link.setAttribute('class', 'text-warning')
  link.innerHTML = 'Details'

  const message = `${data.message} ${link.outerHTML}`

  toastr[toastrMapping[data.state]](message)
}

Styxie.Initializers.GmbBulkPosts = {
  index(params) {
    $(() => {
      const $form = $('#new_gmb_bulk_post')
      $form.on('ajax:beforeSend', () => {
        $form.toggleWrapper()
        $('#gmb_bulk_post_file').val('')
      })

      App.cable.subscriptions.create({ channel: 'Gmb::BulkPostChannel' }, {
        received: (data) => {
          updateRow(data.bulk_post_id, data)
          if (data.state !== 'processing') {
            $form.toggleWrapper({}, false)
            renderNotification(data, params)
          }
        }
      })
    })
  },
  show() {
    $(() => {
      const el = document.getElementById('vue-imported-posts-table')

      if (el) {
        new Vue({ // eslint-disable-line no-new
          el,
          render: (h) => h(ImportedPostsTable)
        })
      }
    })
  }
}
