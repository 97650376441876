import toastr from 'toastr'
import { App } from '../common/rails'
import turbolinksReload from '../common/turbolinks_reload'

import GoogleAccountsIndex from '../vue_widgets/google_accounts/index'

import LocationsTable from '../vue_widgets/google_accounts/show/locations_table'

function bindSyncChannel() {
  const sub = App.cable.subscriptions.create({ channel: 'SyncAccountChannel' }, {
    received: (data) => {
      const $accountItem = $(`.js-account-item[data-account-id='${data.payload.account_id}']`)
      $accountItem.find('.js-spinner').hide()
      $accountItem.find('.js-actions').removeAttr('hidden')
    }
  })

  $(document).one('turbolinks:visit', () => {
    App.cable.subscriptions.remove(sub)
  })
}

function toggleAllLocations(btnClass, successMessage) {
  $(btnClass).on('click', (e) => {
    e.preventDefault()

    const $wrapper = $(e.currentTarget.parentElement)

    $.ajax2({
      method: 'POST',
      url: e.currentTarget.getAttribute('href'),
      beforeSend: () => { $wrapper.toggleWrapper() },
      error: () => {
        toastr.warning('There was a problem mass editing your locations. Please contact support.')
      },
      success: () => {
        turbolinksReload(() => { toastr.success(successMessage) })
      },
      complete: () => {
        $wrapper.toggleWrapper({}, false)
      }
    })

    return false
  })
}

Styxie.Initializers.GoogleAccounts = {
  index: () => {
    $(() => {
      toggleAllLocations(
        '.js-enable-all-locations-btn',
        'All locations within your account limits have been enabled.'
      )
      toggleAllLocations(
        '.js-disable-all-locations-btn',
        'All of your locations have been disabled.'
      )
      bindSyncChannel()

      const el = document.getElementById('vue-google-accounts')
      if (el) {
        // eslint-disable-next-line no-new
        new Vue({
          el: el,
          render: (h) => h(GoogleAccountsIndex)
        })
      }
    })
  },
  show: (params) => {
    $(() => {
      const el = document.getElementById('vue-locations-table')
      if (el) {
        // eslint-disable-next-line no-new
        new Vue({
          el: document.getElementById('vue-locations-table'),
          render: (h) => h(LocationsTable, { props: params })
        })
      }
    })
  }
}
