<template>
  <div class="d-flex align-items-end gap-6">
    <CustomInput
      v-model.trim="search"
      class="w-100"
      placeholder="Search" />
  </div>
</template>
<script>
import CustomInput from 'vue_widgets/components/custom_input'

export default {
  components: {
    CustomInput
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    search: '',
    defaultFilters: {}
  }),
  computed: {
    selectedFilters() {
      const { search } = this
      return { search }
    }
  },
  watch: {
    selectedFilters: {
      handler() {
        this.$emit('input', this.selectedFilters)
      },
      deep: true,
      immediate: true
    }
  }
}

</script>
