<template>
  <div :class="['form-field', { 'border-white' : borderWhite }]">
    <span v-if="!hiddenLabel && label" class="caption">{{ label }}</span>
    <textarea
      v-if="type === 'textarea'"
      v-model.lazy="model"
      :class="{ 'is-invalid': errors[field] }"
      :name="field"
      rows="5" />
    <input
      v-else
      :type="type"
      v-model.lazy="model"
      :class="{ 'is-invalid': errors[field] }"
      :placeholder="placeholder">
    <small v-if="description.length" class="text-muted">{{ description }}</small>
    <div class="invalid-feedback d-block" v-if="errors[field]">
      {{ errors[field].join(', ') }}
    </div>
  </div>
</template>

<script>
import camelCaseKeys from 'camelcase-keys-deep'

export default {
  props: {
    field: { type: String, required: true },
    label: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    hiddenLabel: { type: Boolean, default: false },
    description: { type: String, default: '' },
    type: { type: String, default: 'text' },
    borderWhite: { type: Boolean, default: false }
  },
  computed: {
    errors() { return camelCaseKeys(this.$store.state.geogridWidget.errors) },
    model: {
      get() { return this.$store.state.geogridWidget[this.field] },
      set(val) { this.$store.commit('changeGeogridWidgetKey', { key: this.field.trim(), val }) }
    }
  }
}
</script>
