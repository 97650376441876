<template>
  <div class="pl-3">
    <h3>Preview</h3>
    <hr>
    <template v-if="selectedSource">
      <p v-for="(v, k) in exifs" :key="k">
        <span :class="{ 'text-danger': imagePreviewErrors[k], 'text-muted': !imagePreviewErrors[k] }">{{ v }}:</span> {{ imagePreviewExifs[k] || '-' }}
      </p>
    </template>
    <div v-else role="alert" class="alert alert-warning">
      <div class="message">
        Preview values will not work until you choose at least one location from select.
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  data() {
    return {
      exifs: {
        xptitle: 'Title',
        imagedescription: 'Description',
        xpcomment: 'User Comment',
        xpkeywords: 'Keywords',
        xpsubject: 'Subject',
        datetime: 'Date',
        datetimeoriginal: 'Date Original',
        datetimedigitized: 'Date Digitized',
        artist: 'Artist',
        ownername: 'Owner Name',
        copyright: 'Copyright',
        make: 'Camera Make',
        model: 'Camera Model',
        software: 'Software',
        latitude: 'Latitude',
        longitude: 'Longitude'
      }
    }
  },
  computed: {
    ...mapState(['imagePreviewExifs', 'imagePreviewErrors']),
    ...mapGetters(['selectedSource'])
  }
}
</script>
