import Vue from 'vue'
import GeogridsChart from '../../vue_widgets/admin/geogrids_statistics/chart'

Styxie.Initializers.AdminGeogridsStatistics = {
  index: (data) => {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-geogrids-chart'),
        render: (h) => h(GeogridsChart, { props: data })
      })
    })
  }
}
