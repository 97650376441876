<template>
  <div class="audit-card-title-and-description">
    <template v-if="content.title || content.description">
      <h3 v-if="content.title">{{ content.title }}</h3>
      <p class="pre-wrap" v-if="content.description">{{ content.description }}</p>
    </template>
    <span v-else>
      No title or description found
    </span>
  </div>
</template>
<script>
export default {
  props: {
    content: {
      type: Object,
      required: true
    }
  }
}
</script>
