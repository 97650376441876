import Vue from 'vue'
import snakeCaseKeys from 'snakecase-keys'
import { App } from '../common/rails'
import createStore from '../vue_widgets/geogrids/store'

import { ensureGoogleMap } from '../common/map_helpers'
/* eslint-disable import/no-unresolved */
import GeogridsWidget from '../vue_widgets/geogrids/main_widget'
/* eslint-enable import/no-unresolved */
function buildSourceOptions(data) {
  data.placesDataSources[0].disabled = (data.placesApiKeys.length === 0)
  return data.placesDataSources
}

function initGeogridsWidget(data) {
  const props = {
    geogrids: data.geogrids,
    totalGeogrids: data.totalGeogrids,
    cable: App.cable,
    tags: data.tags,
    newKeyPath: data.paths.newKeyPath,
    keysPath: data.paths.keysPath,
    spotsPath: data.paths.spotsPath,
    geogridsPath: data.paths.geogridsPath,
    apiGeogridsPath: data.paths.apiGeogridsPath,
    gmbLocationsPath: data.paths.gmbLocationsPath,
    apiConfigsPath: data.paths.apiConfigsPath,
    apiTotalConfigsPath: data.paths.apiTotalConfigsPath,
    pointDistances: data.pointDistances,
    periodicityOptions: snakeCaseKeys(data.periodicityOptions),
    dayNumberOptions: data.dayNumberOptions,
    weekNumberOptions: data.weekNumberOptions,
    placesDataSourceDefaultOptions: buildSourceOptions(data),
    distanceMeasureOptions: data.distanceMeasures,
    allLocations: data.allLocations,
    defaultPlacesApiKeys: data.placesApiKeys,
    restoreGeogridFormState: data.restoreGeogridFormState,
    userTimeZone: data.userTimeZone,
    timeZones: data.timeZones
  }

  /* eslint-disable no-new */
  new Vue({
    el: document.getElementById('geogrids-widget'),
    store: createStore(data),
    render: (h) => h(GeogridsWidget, { ref: 'searchWidget', props: props })
  })
  /* eslint-enable no-new */
}
Styxie.Initializers.Geogrids = {
  index: (data) => {
    ensureGoogleMap(data, (data1) => {
      $(document).ready(() => {
        initGeogridsWidget(data1)
      })
    })
  }
}
