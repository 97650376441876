import Vue from 'vue'
import show from '../vue_widgets/media_schedule/show'
import createStore from '../vue_widgets/media_schedule/store'

Styxie.Initializers.MediaSchedules = {
  show: ({ params }) => {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-media-schedule'),
        store: createStore(params),
        render: (h) => h(show)
      })
    })
  }
}
