<template>
  <div class="chart" ref="chart" />
</template>

<script>
import Highcharts from 'highcharts'
import { tooltipOptions } from '../locations/insights/helpers'
import { tooltipHeader, tooltipBody } from './helpers'

export default {
  props: {
    dataSet: {
      type: Array,
      required: true
    },
    revertY: {
      type: Boolean,
      default: true
    },
    tooltipTitle: {
      type: String,
      default: 'Rank'
    },
    yAxisMax: {
      type: Number,
      default: 100
    }
  },
  mounted() {
    const { tooltipTitle } = this
    const dataSetMaxValue = Math.max(...this.dataSet[0].data.map(({ y }) => y))
    const fakeNAValue = (dataSetMaxValue + 10 > 100) ? this.yAxisMax : (dataSetMaxValue + 10)
    const modifiedDataSet = this.dataSet.map((series) => {
      series.data = series.data.map((data) => ({
        ...data,
        y: data.y ? data.y : fakeNAValue,
        isFakeValue: !data.y
      }))

      return series
    })
    const isOver = this.yAxisMax && this.dataSet[0].data.find((set) => set.y > this.yAxisMax)
    const color = '#01c9a7'
    this.chart = Highcharts.chart(this.$refs.chart, {
      alignTicks: false,
      allowDecimals: false,
      credits: { enabled: false },
      chart: {
        width: 80,
        height: 48,
        spacingLeft: 0,
        spacingRight: 0,
        backgroundColor: 'transparent',
        style: {
          display: 'block',
          fontFamily: '"Nunito", sans-serif'
        }
      },
      title: false,
      legend: { enabled: false },
      subtitle: { enabled: false },
      xAxis: {
        type: 'datetime',
        visible: false,
        crosshair: {
          width: 8,
          color: `${color}30`
        }
      },
      yAxis: {
        visible: false,
        reversed: this.revertY,
        max: isOver ? this.yAxisMax : undefined
      },
      time: { useUTC: false },
      plotOptions: {
        series: {
          states: {
            hover: {
              enabled: false
            }
          }
        },
        line: {
          marker: {
            radius: 1,
            color: color
          }
        }
      },
      series: modifiedDataSet,
      tooltip: {
        ...tooltipOptions,
        formatter() {
          return [tooltipHeader(this.x), tooltipBody(this.y, tooltipTitle, this.point.isFakeValue)].join('')
        }
      }
    })
  },
  beforeDestroy() {
    this.chart?.tooltip?.container?.remove()
  }
}
</script>
