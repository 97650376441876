var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"report-metrics-tile"},[_c('div',{staticClass:"d-flex align-items-start gap-4 flex-1"},[_c('div',{staticClass:"report-metrics-tile__icon"},[_c('i',{class:_vm.mapIcons[_vm.property.id]})]),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column h-100"},[_c('span',{staticClass:"font-size-18 font-weight-semi-bold"},[_vm._v(_vm._s(_vm.property.name))]),_vm._v(" "),_c('div',{staticClass:"d-flex align-items-center gap-2 mt-auto"},[_c('span',{staticClass:"report-metrics-tile__value report-metrics-tile__value--prev"},[_vm._v(_vm._s(_vm.property.sumCompare))]),_vm._v(" "),_c('div',{class:[
          'report-metrics-tile__difference',
          {
            'report-metrics-tile__difference--upwards': _vm.property.difference > 0,
            'report-metrics-tile__difference--downwards': _vm.property.difference < 0
          }
        ]},[(_vm.property.difference > 0)?_c('i',{staticClass:"fa-regular fa-arrow-up-right"}):_vm._e(),_vm._v(" "),(_vm.property.difference < 0)?_c('i',{staticClass:"fa-regular fa-arrow-down-right"}):_vm._e(),_vm._v(" "),(_vm.property.difference === 0)?_c('span',[_vm._v("-")]):_vm._e(),_vm._v("\n          "+_vm._s(_vm.property.difference > 0 ? `+${_vm.property.difference}` : _vm.property.difference)+"%\n        ")]),_vm._v(" "),_c('span',{staticClass:"report-metrics-tile__value report-metrics-tile__value"},[_vm._v(_vm._s(_vm.property.sum))])])])]),_vm._v(" "),_vm._t("chart")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }