import toastr from 'toastr'

const hsh = {
  notice: 'success',
  error: 'error',
  info: 'info',
  warning: 'warning'
}

export default function showFlashes() {
  $('.flashes').children().each((_, el) => {
    toastr[hsh[el.dataset.key] || 'info'](el.textContent)
    el.remove()
  })
}
