<template>
  <div class="panel-body">
    <form @submit.prevent="onSubmit">
      <fieldset :disabled="editingDisabled">
        <FormSyncErrors />
        <FormPlainErrors />

        <div class="alert alert-warning" role="alert" v-if="!isLocation">
          <div class="message">
            You can only choose categories for media that exist within our GMB listing.
            Different GMB listing categories (hotels, restaurants, etc) have different media category options.
          </div>
        </div>
        <FormCategories v-model="object.category" :categories="categories" />
        <FormFallbackCategory v-model="object.fallbackCategory" />
        <ImageSelect v-if="!(object.videoUrl || lockedSubmit)" />
        <VideoSelect v-if="!object.galleryImage" />

        <FormLocationsSelect v-if="multilocation" @selectChanged="changeLocationIds" />
        <FormLocationTagsSelect v-if="multilocation" @selectChanged="changeLocationTags" />
      </fieldset>

      <fieldset :disabled="schedueldDisabled">
        <FormScheduled text="Media" />
      </fieldset>

      <div class="form-group">
        <input
          v-if="!object.obfuscatedId || object.state === 'scheduled_in_future'"
          :disabled="lockedSubmit"
          type="submit"
          name="commit"
          :value="object.obfuscatedId ? 'Update' : 'Submit'"
          class="btn btn-space btn-primary">
        <a href="#" @click="onBack">Back</a>

        <div class="float-right" v-if="object.obfuscatedId">
          <a v-if="object.obfuscatedId"
            :class="['btn btn-danger', { disabled: !apiConnection }]"
            :href="`/api/gmb/locations/${object.remoteLocationId}/media/${object.obfuscatedId}`"
            @click.prevent="onDelete">
            Delete
          </a>
          <a
            :class="['btn btn-primary mr-1', { disabled: !apiConnection }]"
            :href="`/gmb/locations/${object.remoteLocationId}/media/${object.obfuscatedId}/clone`">
            Clone
          </a>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import FormPlainErrors from '../../local_posts/form/plain_errors'
import FormSyncErrors from '../../local_posts/form/sync_errors'
import FormScheduled from '../../local_posts/form/scheduled'
import FormCategories from './categories'
import FormFallbackCategory from './fallback_category'
import ImageSelect from '../../../components/image_select/index'
import VideoSelect from '../../../components/video_select'

import FormLocationsSelect from '../../../components/locations_select'
import FormLocationTagsSelect from '../../../components/location_tags_select'

export default {
  computed: {
    ...mapState(['object', 'multilocation', 'editingDisabled', 'isLocation', 'categories', 'lockedSubmit', 'apiConnection']),
    schedueldDisabled() {
      if (['live', 'failed'].includes(this.object.state)) return true

      // if not posted it time
      if (this.object.state === 'processing' && this.object.obfuscatedId) return true

      return false
    }
  },
  methods: mapActions(['onSubmit', 'onDelete', 'onBack', 'changeLocationIds', 'changeLocationTags']),
  components: {
    FormCategories,
    FormSyncErrors,
    FormPlainErrors,
    FormLocationsSelect,
    FormLocationTagsSelect,
    FormScheduled,
    FormFallbackCategory,
    ImageSelect,
    VideoSelect
  }
}
</script>
