<template>
  <div class="chart-contaner">
    <div class="chart" ref="chart" :style="{height: `${chartHeight}px`}">CHART</div>
    <div class="chart-legend">
      <ChartLegeng
        :color="legeng.color"
        :name="legeng.name"
        :values="legeng.values"
        :percentage="legeng.percentage"
        :compare="legeng.compare" />
    </div>
  </div>
</template>

<script>
import camelCaseKeys from 'camelcase-keys-deep'
import { mapGetters } from 'vuex'
import Highcharts from 'highcharts'
import { differenceClass } from 'common/helpers'
import {
  chartOptionsDefault,
  tooltipOptions,
  tooltipHeader,
  getValues,
  getPercentage
} from './helpers'
import ChartLegeng from './chart_legend'

function tickPositioner() {
  return this.names.map((_, index) => {
    if (index === 0 || index === this.max || index === Math.ceil(this.max / 2)) {
      return index
    }
    return null
  }).filter((item) => item !== null)
}

const countPercent = (point, series) => {
  let percentage = 0
  if (point.index !== 0) {
    const prev = series.data[point.index - 1].y
    const current = point.y
    if (Math.max(prev, current) === 0) return 0
    percentage = Math.round((current - prev) / Math.max(prev, current) * 100)
  }

  return percentage
}

export default {
  components: { ChartLegeng },
  props: {
    chartId: { type: String, required: true },
    chartHeight: { type: Number, default: 150 },
    chartColor: { type: String, default: '#377dff' },
    noLabels: { type: Boolean, default: false }
  },
  computed: mapGetters(['chartData', 'stats']),
  mounted() {
    if (this.chartData && this.chartData.length) {
      this.initChart(this.chartData)
    }
  },
  data() {
    return {
      legeng: {}
    }
  },
  methods: {
    initChart(data) {
      if (this.chart) {
        this.chart.destroy()
        this.chart = null
      }
      if (!data?.length) return

      const metric = data.find(({ id }) => id === this.chartId)
      const chartData = metric.data[0].data.reduce((memo, { x, y }) => {
        const key = (new Date(x)).setDate(1)

        if (typeof memo[key] === 'undefined') memo[key] = 0
        memo[key] += y

        return memo
      }, {})

      const seriesData = []
      Object.keys(chartData).forEach((key) => {
        const date = new Date(parseInt(key, 10))
        seriesData.push({
          name: `${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`,
          y: chartData[key],
          color: this.chartColor,
          label: metric.name
        })
      })

      const stat = this.stats.find(({ id }) => id === this.chartId)
      this.legeng = {
        color: this.chartColor,
        name: metric.name,
        values: getValues(stat, false),
        percentage: getPercentage(camelCaseKeys(stat)),
        compare: false
      }

      const tooltip = {
        ...tooltipOptions,
        formatter() {
          const percentage = countPercent(this.point, this.series)
          const tooltipContent = []
          tooltipContent.push(tooltipHeader(this.point.name))
          tooltipContent.push(`<div class="tooltip-line">
            <span class="point" style="color:${this.point.options.color}">\u25CF</span>
            <span class="date">${this.point.options.label}</span>
            <span class="value">${this.point.options.y}</span>
            <span class="difference ${differenceClass(percentage)}">${percentage}%</span></div>`)
          return tooltipContent.join('')
        }
      }

      this.chart = Highcharts.chart(this.$refs.chart, {
        ...chartOptionsDefault({
          chartType: 'column',
          chartHeight: this.chartHeight
        }),
        xAxis: {
          type: 'category',
          tickPositioner: this.noLabels ? tickPositioner : undefined
        },
        yAxis: {
          min: 0,
          title: { enabled: false },
          gridLineColor: '#D5DAE2',
          gridLineDashStyle: 'longdash'
        },
        plotOptions: {
          series: {
            pointPadding: 0.1,
            groupPadding: 0
          }
        },
        series: [{
          name: 'break',
          color: this.chartColor,
          borderRadius: 2,
          data: seriesData,
          dataLabels: {
            enabled: !this.noLabels,
            useHTML: true,
            y: 25,
            formatter() {
              const y = 25
              const percentage = countPercent(this.point, this.series)
              const percentageColor = this.point.shapeArgs.height < y ? '#77838F' : '#fff'
              const labelMargin = this.point.shapeArgs.height < y ? `-${y + this.point.shapeArgs.height + 16}px 0 0` : '0'
              return `<div style='font-family: Nunito; text-align: center; width: ${this.point.pointWidth}px; margin: ${labelMargin}'>
                <span style='display: block; color: #3D454D; font-size: 16px; line-height: 20px'>${this.point.y}</span>
                <span style='color: ${percentageColor}; font-size: 12px; line-height: 20px'>${percentage}%</span>
              </div>`
            }
          }
        }],
        tooltip
      })
    }
  },
  watch: {
    chartData: 'initChart'
  }
}
</script>
