<template>
  <div class="tab-pane active" role="tabpanel">
    <FormEventPeriod title="Event Title" />
    <FormCta />
  </div>
</template>

<script>
import FormEventPeriod from './common/period'
import FormCta from './common/cta'

export default {
  components: { FormCta, FormEventPeriod }
}
</script>
