<template>
  <div class="mb-5">
    <h2 class="h3 m-0 mb-2 font-weight-semi-bold text-dark">Default values</h2>
    <div class="form-field mb-3 switcher-container">
      <label class="switcher">
        <input type="checkbox" v-model="publishVideo">
        <span class="caption">
          <span class="text-primary"
            data-toggle="tooltip"
            :data-original-title="publishVideoHint">
            <i class="far fa-info-circle" />
          </span>
          Publish videos to GBP
        </span>
        <i class="toogle-switch" />
      </label>
    </div>
  </div>
</template>

<script>
import { DEFAULT_ERROR_MESSAGE } from '../../../../common/constants'

export default {
  props: {
    publishVideoByDefault: { type: Boolean, required: true },
    mobileBusinessPath: { type: String, default: '' }
  },
  data() {
    return {
      publishVideo: this.publishVideoByDefault
    }
  },
  created() {
    this.publishVideoHint = 'When enabled, all videos from employees with pre moderation setting off ' +
      'will be automatically published to GBPs as separate media entities. Disabled will result in not ' +
      'sending videos by default.'
  },
  mounted() {
    $('[data-toggle="tooltip"]').tooltip()
  },
  methods: {
    update() {
      const formData = new FormData()

      formData.append('mobile_business[publish_video_by_default]', this.publishVideo)

      axios.patch(this.mobileBusinessPath, formData)
        .then(() => {
          toastr.success('Setting updated successfully')
        })
        .catch(() => {
          toastr.error(DEFAULT_ERROR_MESSAGE)
        })
    }
  },
  watch: {
    publishVideo() {
      this.update()
    }
  }
}
</script>
