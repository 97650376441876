<template>
  <div class="table-bulk-head-row">
    <div class="table-bulk-actions-container" :class="['table-bulk-actions-container', { disabled: selectedCount === 0 } ]">
      <div class="d-flex align-items-center gap-6 font-size-14">
        <template v-if="selectedCount === 0">
          None selected
        </template>
        <template v-else-if="selectedCount === totalCount">
          {{ `Selected ${selectedCount} reports` }}
          <a href="#" class="font-size-12 font-weight-semi-bold text-primary" @click.prevent="toggleAll(false)">Unselect</a>
        </template>
        <template v-else>
          {{ `Selected ${selectedCount} report${selectedCount === 1 ? '' : 's'} of ${totalCount}` }}
          <a href="#" class="font-size-12 font-weight-semi-bold text-primary" @click.prevent="toggleAll(true)">Select all</a>
          <a href="#" class="font-size-12 font-weight-semi-bold text-primary" @click.prevent="toggleAll(false)">Unselect</a>
        </template>
      </div>
      <div class="d-flex align-items-center gap-6">
        <a href="#"
          class="d-inline-flex align-items-center border border-danger rounded font-size-12 gap-2 px-3 py-2 text-danger"
          @click.prevent="deleteReports">
          <i class="far fa-trash" />
          Delete
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedCount: { type: Number, required: true },
    totalCount: { type: Number, required: true },
    rows: { type: Array, required: true }
  },
  methods: {
    deleteReports() {
      this.$emit('deleteReports', this.rows.filter(({ checked }) => checked).map(({ id }) => id))
    },
    toggleAll(bool) {
      this.$emit('toggleAll', bool)
    }
  }
}
</script>
