<template>
  <div class="tab-pane active" role="tabpanel">
    <FormCta />
  </div>
</template>

<script>
import FormCta from './common/cta'

export default {
  components: { FormCta }
}
</script>
