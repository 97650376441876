<template>
  <div class="report-metrics-tile">
    <div class="d-flex align-items-start gap-4 flex-1">
      <div class="report-metrics-tile__icon">
        <i :class="mapIcons[property.id]" />
      </div>

      <div class="d-flex flex-column h-100">
        <span class="font-size-18 font-weight-semi-bold">{{ property.name }}</span>

        <div class="d-flex align-items-center gap-2 mt-auto">
          <span class="report-metrics-tile__value report-metrics-tile__value--prev">{{ property.sumCompare }}</span>
          <div :class="[
            'report-metrics-tile__difference',
            {
              'report-metrics-tile__difference--upwards': property.difference > 0,
              'report-metrics-tile__difference--downwards': property.difference < 0
            }
          ]">
            <i v-if="property.difference > 0" class="fa-regular fa-arrow-up-right" />
            <i v-if="property.difference < 0" class="fa-regular fa-arrow-down-right" />
            <span v-if="property.difference === 0">-</span>
            {{ property.difference > 0 ? `+${property.difference}` : property.difference }}%
          </div>
          <span class="report-metrics-tile__value report-metrics-tile__value">{{ property.sum }}</span>
        </div>
      </div>
    </div>
    <slot name="chart" />
  </div>
</template>

<script>
export default {
  props: {
    property: { type: Object, required: true },
    mapIcons: { type: Object, default: () => ({}) }
  }
}
</script>
