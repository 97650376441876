<template>
  <div class="form-field">
    <span v-if="!hiddenLabel && label" class="caption">{{ label }}</span>
    <textarea
      v-if="type === 'textarea'"
      v-model.lazy="model"
      :class="{ 'is-invalid': errors[field] }"
      rows="5" />
    <input
      v-else
      :type="type"
      v-model.lazy="model"
      :class="{ 'is-invalid': errors[field] }"
      :placeholder="placeholder">
    <div class="my-1 small">
      How to <a :href="linkToSetupDoc" target="_blank">obtain</a> and <a :href="linkToSecureDoc" target="_blank">secure</a> an API key.
    </div>
    <small class="text-muted" v-if="description.length">{{ description }}</small>
    <small class="text-muted">
      Enter your API key to be used for the Business and Address Autocomplete input in the Geogrid widget form <a :href="href">{{ href }}</a>.
      <br>
      Please enable both Maps Javascript API and Places API.
    </small>
    <div class="invalid-feedback d-block" v-if="errors[field]">
      {{ errors[field].join(', ') }}
    </div>
  </div>
</template>

<script>
import TextInput from './text_input'

export default {
  extends: TextInput,
  data() {
    return {
      href: 'https://developers.google.com/places/web-service/get-api-key'
    }
  },
  computed: {
    linkToSetupDoc() {
      if (window.Styxie.WLC.appCodename === 'lv') return 'http://help.localviking.com/en/articles/7245991-geogrid-widget-api-setup'

      return 'https://help.localbrandmanager.com/en/articles/7246696-geogrid-geobooster-widgets-api-setup'
    },
    linkToSecureDoc() {
      if (window.Styxie.WLC.appCodename === 'lv') return 'https://help.localviking.com/en/articles/7244281-widgets-api-keys-security'

      return 'https://help.localbrandmanager.com/en/articles/7246562-widgets-api-keys-security'
    }
  }
}
</script>
