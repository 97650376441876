export function collectImageAndExifs(state, data, entity) {
  if (state.object.galleryImage) {
    data.append('exif_state', state.exifState)
    data.append(`${entity}[keep_original_filename]`, state.object.keepOriginalFilename)
    data.append(`${entity}[image_id]`, state.object.galleryImage.id)

    if (state.exifState === 'edit') {
      Object.keys(state.imageCurrentExifs).forEach((key) => {
        data.append(`exifs[${key}]`, state.imageCurrentExifs[key])
      })
    }

    if (entity === 'gmb_media') {
      data.append(`${entity}[media_description]`, state.mediaDescription)
    }
  } else if (state.object.deleteImage) {
    data.append(`${entity}[remove_images]`, 1)
  }
}

export function collectVideo(state, data, entity) {
  if (state.object.videoUrl) {
    data.append(`${entity}[video_url]`, state.object.videoUrl)

    if (entity === 'gmb_media') {
      data.append(`${entity}[media_description]`, state.mediaDescription)
    }
  } else if (state.object.deleteImage) {
    data.append(`${entity}[remove_video_url]`, 1)
  }
}

export function collectMultilocation(state, data) {
  if (state.multilocation) {
    state.locationIds.forEach((id) => data.append('location_ids[]', id))
    state.locationTags.forEach((tag) => data.append('location_tags[]', tag))
  }
}

export function submitUrl(state, entity, multilocationEntity) {
  if (state.multilocation) return `/api/gmb/multi_location_${multilocationEntity || entity}`
  return `/api/gmb/locations/${state.object.remoteLocationId}/${entity}`
}

export const multilocationStore = {
  state: {
    locationIds: [],
    locationTags: []
  },
  getters: {
    selectedSource(state) {
      if (state.multilocation) {
        if (state.locationIds.length) {
          return { type: 'ids', entity: state.locationIds[0] }
        } if (state.locationTags.length) {
          return { type: 'tags', entity: state.locationTags[0] }
        }
      } else {
        return { type: 'ids', entity: state.object.remoteLocationId }
      }

      return false
    }
  },
  actions: {
    changeLocationIds({ state, commit, dispatch }, ids) {
      commit('changeLocationIds', ids)
      dispatch('fetchExifPreview')
      if (state.object.topicType) dispatch('previewSummary')
    },
    changeLocationTags({ state, commit, dispatch }, tags) {
      commit('changeLocationTags', tags)
      dispatch('fetchExifPreview')
      if (state.object.topicType) dispatch('previewSummary')
    }
  },
  mutations: {
    changeLocationIds(state, ids) {
      state.locationIds = ids
    },
    changeLocationTags(state, tags) {
      state.locationTags = tags
    }
  }
}
