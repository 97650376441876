<template>
  <div class="card card-border-color card-border-color-dark card-default card-table">
    <div class="card-header">
      <span class="title text-capitalize" @click.prevent="filtersShown = !filtersShown">
        {{ kind }} organizations
        <i :class="['far', { 'fa-caret-up': filtersShown, 'fa-caret-down': !filtersShown }]" />
      </span>
      <input ref="searchInput"
        v-model.trim="search"
        type="text"
        class="form-control float-right"
        placeholder="search"
        style="width: auto">
    </div>
    <div class="card-body pt-2">
      <TransitionExpand>
        <div v-show="filtersShown" class="pt-1">
          <div class="form-group mb-0" v-for="({ label, options }, key) in filtersAttributes" :key="key">
            <label for="" class="col-2">{{ label }}</label>
            <div class="form-check form-check-inline" v-for="(v, k) in options" :key="k">
              <input class="form-check-input" type="radio" :id="key + k"
                :value="v"
                v-model="filters[key]">
              <label class="form-check-label" :for="key + k">{{ k }}</label>
            </div>
          </div>
        </div>
      </TransitionExpand>

      <div class="col-12">
        <VueSlimTable
          ref="table"
          :source="tableSource"
          :columns="columns"
          class="table table-fixed organizations-table table-striped table-light"
          :per-page="10">
          <template #cell:organization="{ row }">
            <p class="m-0">
              <a :href="`/admin/organizations/${row.id}`">{{ row.title }}</a>
            </p>
            <!-- eslint-disable vue/no-v-html -->
            <div v-if="row.paypalEmail" class="d-flex flex-column">
              <small title="paypal email" v-html="checkEmail(row.paypalEmail)" />
              <small title="paypal plan">{{ row.legacyPaypalPlan }}</small>
            </div>
            <!-- eslint-disable vue/no-v-html -->
          </template>
          <template #cell:totalSuspensionRate="{ row }">
            <p :class="['m-0','small', { 'color-danger': row.suspensionRateExceeded }]">
              Suspension rate: {{ row.suspensionRate }}%
            </p>
            <p class="m-0 small">
              Total locations: {{ row.totalLocationsCount }}
            </p>
            <p class="m-0 small text-nowrap">
              Total suspended locations: {{ row.totalSuspensionsCount }}
            </p>
            <div v-if="row.bannedAt" class="d-flex flex-column">
              <small class="color-danger">Banned at: {{ row.bannedAt }}</small>
              <small class="color-danger">Ban note: {{ row.banNote }}</small>
            </div>
          </template>
          <template #cell:users="{ value }">
            <ol class="pl-4 mb-0">
              <li v-for="u in value" :key="u.email">
                <a :href="`/users/${u.id}/impersonate`" rel="nofollow" data-method="post">
                  <i class='far fa-sign-in' />
                </a>
                <!-- eslint-disable vue/no-v-html -->
                <span v-html="checkEmail(u.email)" />
                <!-- eslint-disable vue/no-v-html -->
                ({{ u.role }}) {{ u.name }}
              </li>
            </ol>
          </template>
          <template #cell:wlcs="{ value }">
            <ol class="pl-4 mb-0">
              <li v-for="wlc in value" :key="wlc.domain">
                <template v-if="wlc.googleAppId && wlc.googleAppSecret">
                  <i class='far fa-key' />
                  <i v-if="wlc.synced" class='far fa-check' />
                  <i v-else class='far fa-times' />
                </template>

                {{ wlc.domain }}
              </li>
            </ol>
          </template>
        </VueSlimTable>
      </div>
    </div>
  </div>
</template>

<script>
import TransitionExpand from 'vue_widgets/components/transition_expand'

const ALL_KEY = 'All'

export default {
  props: ['kind'],
  components: { TransitionExpand },
  data() {
    return {
      search: '',
      timeout: null,
      filtersShown: false,
      filters: {
        totalSuspensionRate: { },
        wlcDomain: ALL_KEY,
        apiKeys: ALL_KEY,
        apiVerified: ALL_KEY,
        type: ALL_KEY
      }
    }
  },
  computed: {
    customFilters() {
      const hsh = { kind: this.kind }

      if (this.filters.totalSuspensionRate !== ALL_KEY) hsh.by_total_suspension_rate = this.filters.totalSuspensionRate
      if (this.filters.wlcDomain !== ALL_KEY) hsh.by_wlc_domain = this.filters.wlcDomain
      if (this.filters.apiKeys !== ALL_KEY) hsh.by_api_keys = this.filters.apiKeys
      if (this.filters.apiVerified !== ALL_KEY) hsh.by_api_errors = this.filters.apiVerified
      if (this.filters.type !== ALL_KEY) hsh.by_type = this.filters.type

      return hsh
    }
  },
  watch: {
    search() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(this.$refs.table.refetch, 300)
    },
    customFilters() {
      this.$refs.table.refetch()
    }
  },
  created() {
    this.columns = [
      { title: 'Organization', key: 'organization' },
      { title: 'Signed Up', key: 'createdAt', orderable: true },
      { title: 'G Accounts', key: 'googleAccountsCount' },
      { title: 'Suspension Rate', key: 'totalSuspensionRate', orderable: true },
      { title: 'Geogrids Balance', key: 'geogridWalletBalance', orderable: true },
      { title: 'Geogrids Estimation ', key: 'geogridCostsMonthlyEstimation', orderable: true },
      { title: 'Users', key: 'users' },
      { title: 'WLCs', key: 'wlcs' }
    ]

    this.filtersAttributes = {
      totalSuspensionRate: {
        label: 'Suspension Rate',
        options: {
          All: { },
          'Below 20%': { to: 0.2 },
          '20-50%': { from: 0.2, to: 0.5 },
          '50-70%': { from: 0.5, to: 0.7 },
          '70-90%': { from: 0.7, to: 0.9 },
          '90% and above': { from: 0.9 }
        }
      },
      wlcDomain: {
        label: 'WLC Domain',
        options: { All: ALL_KEY, Verified: 'verified', 'Not verified': 'pending' }
      },
      apiKeys: {
        label: 'API keys',
        options: { All: ALL_KEY, Set: 'has_oauth_keys', 'Not set': 'no_oauth_keys' }
      },
      apiVerified: {
        label: 'API access',
        options: { All: ALL_KEY, Verified: 'verified', 'Has Errors': 'has_errors' }
      },
      type: {
        label: 'User type',
        options: { All: ALL_KEY, LV: 'lv', Linda: 'linda' }
      }
    }
  },
  mounted() {
    if (this.kind === 'active') this.$refs.searchInput.focus()
  },
  methods: {
    checkEmail(email) {
      if (this.search !== '') {
        return email.replace(
          new RegExp(`^${this.search}`, 'i'),
          (match) => `<span class="warning-400">${match}</span>`
        )
      }
      return email
    },
    tableSource(params) {
      return axios.get(
        '/api/admin/organizations',
        {
          params: { ...params, ...this.customFilters, search: this.search },
          paramsSerializer(json) { return qs.stringify(json, { arrayFormat: 'brackets' }) }
        }
      )
        .then((res) => res.data)
    }
  }
}
</script>
