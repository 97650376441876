<template>
  <div class="legend">
    <div class="name">
      <div class="bullet" :style="{backgroundColor: color}" />
      {{ name }}
      <div class="description" v-if="description.length">
        {{ description }}
      </div>
    </div>
    <div class="data">
      <strong :class="['values', {compare: compare}]">{{ values }}</strong>
      <div class="percentage--wrapper">
        <span
          v-if="percentage !== null"
          :class="['percentage', differenceClass]">
          {{ Math.abs(percentage) }}%
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { differenceClass } from 'common/helpers'

export default {
  props: {
    color: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    values: {
      type: [Number, String],
      default: ''
    },
    percentage: {
      type: [Number, Object],
      default: 0
    },
    compare: Boolean
  },
  computed: {
    differenceClass() {
      return differenceClass(this.percentage)
    }
  }
}
</script>
