import FacebookAccountsIndex from 'vue_widgets/facebook_accounts/index'

Styxie.Initializers.FacebookAccounts = {
  index: (props) => {
    $(() => {
      // eslint-disable-next-line no-new
      new Vue({
        el: document.getElementById('vue-facebook-accounts'),
        render: (h) => h(FacebookAccountsIndex, { props })
      })
    })
  }
}
