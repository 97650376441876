<template>
  <div class="admin-cancelations">
    <h1>Subscription cancellations</h1>

    <div class="card">
      <div class="chart-container">
        <div class="chart" ref="chart" />
      </div>
    </div>

    <div class="container">
      <div class="row mb-4">
        <stats-card v-for="item in stats" :item="item" :key="item.key" />
      </div>
    </div>

    <div class="card container">
      <div class="row my-2">
        <div class="col-lg-4 col-md-12 form-field form-field-m mb-2">
          <span class="caption">State</span>
          <Multiselect
            ref="selectState"
            v-model="state"
            :options="cancellationStates"
            :searchable="false"
            :close-on-select="true"
            :show-labels="false"
            placeholder="Pick a state">
            <template slot="beforeList">
              <li @click="state = null; $refs.selectState.deactivate()" class="multiselect__element">
                <span class="multiselect__option">all</span>
              </li>
            </template>
          </Multiselect>
        </div>
        <div class="col-lg-4 col-md-12 form-field form-field-m mb-2">
          <span class="caption">Reason</span>
          <Multiselect
            ref="selectReason"
            v-model="reason"
            :options="reasons"
            :searchable="false"
            :close-on-select="true"
            :show-labels="false"
            placeholder="Pick a reason">
            <template slot="beforeList">
              <li @click="reason = null; $refs.selectReason.deactivate()" class="multiselect__element">
                <span class="multiselect__option">all</span>
              </li>
            </template>
          </Multiselect>
        </div>
        <div class="col-lg-4 col-md-12 form-field form-field-m mb-3">
          <span class="caption">Date range</span>
          <div class="admin-cancelations-datepickr">
            <flat-pickr v-model="date" :config="config" />
            <button @click="clearDate" :disabled="!startDate" class="btn">Clear date</button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-12  mb-0">
          <button @click="clearAllFilters" class="btn btn-sm">Clear all filters</button>
        </div>
      </div>
      <div class="mt-3">
        <VueSlimTable
          ref="table"
          :source="tableSource"
          :columns="columns"
          class="table table-striped table-light"
          :per-page="10">
          <template #cell:user="{ value }">
            <p class="m-0">
              <a :href="`/users/${ value.id }/impersonate`" rel="nofollow" data-method="post">
                <i class="far fa-sign-in" />
              </a>
              {{ value.email }}
            </p>
          </template>
          <template #cell:comments="{ value }">
            <div class="table-comments">
              <p class="m-0">{{ value }}</p>
            </div>
          </template>
        </VueSlimTable>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import toastr from 'toastr'
import flatPickr from 'vue-flatpickr-component'
import Multiselect from 'vue-multiselect'
import Highcharts from 'highcharts'
import { FLATPICKR_WEEKDAYS_MAP } from 'common/constants'
import statsCard from './stats_card'

export default {
  name: 'AdminWidget',
  components: {
    statsCard,
    Multiselect,
    flatPickr
  },
  data() {
    return {
      chartData: [
        { name: 'total', data: []},
        { name: 'initiated', data: []},
        { name: 'canceled', data: []},
        { name: 'completed', data: []}
      ],
      dates: [],
      stats: {},
      reason: null,
      state: null,
      date: null,
      startDate: null,
      endDate: null,
      colors: ['#377dff', '#01c9a7', '#f9ae34', '#dc443d']
    }
  },
  created() {
    this.columns = [
      { title: 'User', key: 'user' },
      { title: 'State', key: 'state' },
      { title: 'Registration', key: 'userCreated' },
      { title: 'Cancellation', key: 'finishedAt' },
      { title: 'Reason', key: 'reason' },
      { title: 'Returning', key: 'likelihoodToReturn' },
      { title: 'Comments', key: 'comments' }
    ]
    this.reasons = [
      'covid',
      'training',
      'time',
      'support',
      'bugs',
      'cost',
      'setup',
      'complicated',
      'closed',
      'functionality',
      'unready'
    ]
    this.cancellationStates = ['initiated', 'canceled', 'completed']
    this.config = {
      mode: 'range',
      maxDate: 'today',
      dateFormat: 'd/m/Y',
      locale: {
        firstDayOfWeek: FLATPICKR_WEEKDAYS_MAP[Styxie.USER.beginningOfWeek]
      },
      onChange: ([start, end], dateStr) => {
        if (start && end) {
          [this.startDate, this.endDate] = dateStr.split(' to ')
        }
      }
    }

    for (let i = 30; i >= 0; i--) {
      const day = new Date()
      day.setDate(day.getDate() - i)
      this.dates.push(day.toISOString().split('T')[0])
    }

    this.chartData.forEach((obj) => {
      this.dates.forEach((date) => {
        obj.data.push([date, 0])
      })
    })
  },
  computed: {
    customFilters() {
      return {
        state: this.state,
        reason: this.reason,
        start_date: this.startDate,
        end_date: this.endDate
      }
    }
  },
  watch: {
    customFilters() {
      this.$refs.table.refetch()
    }
  },
  mounted() {
    const url = '/api/admin/subscription_cancelations/stats'

    axios
      .get(url)
      .then((response) => {
        this.stats = response.data.stat_card_data
        this.updateChartData(response.data.chart_data)
      })
      .catch((error) => { toastr.error(error) })
  },
  methods: {
    clearAllFilters() {
      this.reason = null
      this.state = null
      this.date = null
      this.startDate = null
      this.endDate = null
    },
    clearDate() {
      this.date = null
      this.startDate = null
      this.endDate = null
    },
    updateChartData(response) {
      this.chartData.forEach((obj) => {
        const name = obj.name.toString()
        response.forEach((res) => {
          obj.data.forEach((x) => {
            if (x[0] === res.date) {
              x[1] = res[name]
            }
          })
        })
      })

      this.initChart()
    },
    initChart() {
      this.chart = Highcharts.chart(this.$refs.chart, {
        chart: {
          type: 'spline'
        },
        title: {
          text: 'Cancellations in 30 days'
        },
        colors: this.colors,
        credits: {
          enabled: false
        },
        plotOptions: {
          series: {
            marker: {
              symbol: 'circle'
            }
          }
        },
        xAxis: {
          type: 'category'
        },
        series: this.chartData
      })
    },
    tableSource(params) {
      return axios.get('/api/admin/subscription_cancelations', {
        params: { ...params, ...this.customFilters },
        paramsSerializer(json) { return qs.stringify(json, { arrayFormat: 'brackets' }) }
      }).then((res) => res.data)
    }
  }
}
</script>
