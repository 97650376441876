<template>
  <div ref="navigation" class="audit-report-navigation" :class="{ pinned }">
    <div class="audit-report-navigation__list">
      <a v-for="section in sections"
        :key="section.key"
        href="#"
        class="audit-report-navigation__item"
        :class="{ active: sectionInView === section.key }"
        @click.prevent="scrollTo(section.key)">
        {{ section.name }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sections: {
      type: Array,
      required: true
    },
    sectionInView: {
      type: String,
      required: false,
      default: null
    }
  },
  data: () => ({
    pinned: false
  }),
  mounted() {
    this.observer = new IntersectionObserver(
      ([e]) => {
        this.pinned = e.intersectionRatio < 1
      },
      { threshold: 1, rootMargin: '-1px 0px 0px 0px' }
    )

    this.observer.observe(this.$refs.navigation)
  },
  destroyed() {
    this.observer.disconnect()
    this.observer = null
  },
  methods: {
    scrollTo(sectionKey) {
      this.$emit('scrollTo', sectionKey)
    }
  }
}
</script>
