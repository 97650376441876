import Vue from 'vue'
import VueReportShow from 'vue_widgets/reports/show/index'
import createInsightsStore from 'vue_widgets/reports/show/store_report'

export default function initReportsShow(props, isPublic = false) {
  $(() => {
    const store = createInsightsStore({
      ...props,
      isPublic,
      insightsPath: props.reportPath,
      chosenLocation: props.locations && props.locations.length === 1 ? props.locations[0] : null
    })

    new Vue({ // eslint-disable-line no-new
      el: document.getElementById('vue-reports-show'),
      store,
      render: (h) => h(VueReportShow)
    })
  })
}
