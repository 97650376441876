<template>
  <div>
    <div v-if="inProgress" class='alert alert-info' role='alert'>
      <div class='message my-1'>
        <i class='far fa-spinner fa-spin fa-fw' />
        Sync account locations in progress.
      </div>
    </div>

    <VueSlimTable
      ref="table"
      :source="tableSource"
      :columns="columns"
      class="table table-striped table-light table-xs">
      <template #cell:toggle="{ row }">
        <div class="location-action">
          <label class="switcher">
            <input type="checkbox" :disabled="row.fetchJobJid" :checked="row.enabled" @input="toogleLocation({ e: $event, row: row })">
            <i class="toogle-switch" />
          </label>
        </div>
        <template v-if="row.fetchJobJid">
          <i class="far fa-spinner fa-pulse mr-1" />
          Sync in progress
        </template>
      </template>

      <template #cell:verified="{ row }">{{ row.states.includes('verified') ? 'Yes' : 'No' }}</template>
    </VueSlimTable>
  </div>
</template>

<script>
import { DEFAULT_ERROR_MESSAGE } from 'common/constants'
import { App } from 'common/rails'

export default {
  props: {
    apiOrgLocationsPath: { type: String, required: true },
    accId: { type: String, required: true },
    fetchInProgress: { type: Boolean, required: true }
  },
  data() {
    return {
      inProgress: this.fetchInProgress
    }
  },
  methods: {
    tableSource(params) {
      return axios.get(
        this.apiOrgLocationsPath,
        {
          params: { ...params, by_account: this.accId },
          paramsSerializer(json) { return qs.stringify(json, { arrayFormat: 'brackets' }) }
        }
      ).then((res) => res.data.rows)
    },
    toogleLocation({ e, row }) {
      const bool = e.target.checked
      const action = `${bool ? 'en' : 'dis'}able`
      axios.post(`${this.apiOrgLocationsPath}/${row.id}/${action}`)
        .then(() => {
          toastr.success(`Location was ${action}d.`)
          if (bool) {
            row.fetchJobJid = 'fakeJobId'
            const locationSub = App.cable.subscriptions.create({ channel: 'OrganizationsLocationChannel', source: 'gmb', id: row.remoteLocationId }, {
              received: (res) => {
                if (res.success) {
                  this.$nextTick(() => {
                    row.fetchJobJid = null
                    toastr.success(`${row.name} synced successfully`)
                    App.cable.subscriptions.remove(locationSub)
                  })
                }
              }
            })
          }
        })
        .catch((res) => {
          e.target.checked = !bool
          toastr.error((res.response && res.response.data && res.response.data.error) || DEFAULT_ERROR_MESSAGE)
        })
    }
  },
  created() {
    this.columns = [
      { title: '', key: 'toggle' },
      { title: 'Location', key: 'name', orderable: true },
      { title: 'Verified', key: 'verified', orderable: true },
      { title: 'Address', key: 'address', orderable: true },
      { title: 'Store Code', key: 'storeCode', orderable: true }
    ]

    const locationsSub = App.cable.subscriptions.create(
      { channel: 'GoogleAccountChannel', google_account_id: this.accId },
      {
        connected: () => {
          if (this.inProgress) {
            locationsSub.perform('check_fetch_ready', { acc_id: this.accId })
          }
        },
        received: (res) => {
          if (res.kind === 'locations') {
            this.inProgress = false
            this.$nextTick(() => { this.$refs.table.refetch() })
          }
        }
      }
    )

    $(document).one('turbolinks:visit', () => { App.cable.subscriptions.remove(locationsSub) })
  }
}
</script>
