<template>
  <div class="report-geogrid audit-chart pb-0 mb-2">
    <div class="half">
      <strong class="d-block font-size-18 font-weight-semi-bold mb-2">Geogrid</strong>
      <AuditDescription class="mb-4">
        GeoGrid search results show the local rankings of the featured business in different parts of town.
      </AuditDescription>
      <div class="report-geogrid__info">
        <div class="report-geogrid__line">
          <div class="report-geogrid__column">
            <p>Created</p>
            <span>{{ formatDate }}</span>
          </div>
          <div class="report-geogrid__column">
            <dl>
              <dt>
                <p>AGR</p>
                <span>{{ geogrid.agr }}</span>
              </dt>
              <dd>Average Grid Rank. Only nodes with ranks equal to "20" and less considered.</dd>
            </dl>
          </div>
        </div>
        <div class="report-geogrid__line">
          <div class="report-geogrid__column">
            <p>Google region</p>
            <span>{{ geogrid.attrs.localLanguageEnabled ? 'Local' : 'Global' }}</span>
          </div>
          <div class="report-geogrid__column">
            <dl>
              <dt>
                <p>ATGR</p>
                <span>{{ geogrid.atgr }}</span>
              </dt>
              <dd>Average Total Grid Rank. All nodes considered. The "21" value is used for nodes with ranks over "20".</dd>
            </dl>
          </div>
        </div>
        <div class="report-geogrid__line">
          <div class="report-geogrid__column">
            <p>Grid</p>
            <span>{{ geogrid.grid }}</span>
          </div>
          <div class="report-geogrid__column">
            <dl>
              <dt>
                <p>SoLV</p>
                <span>{{ solvPercent }}</span>
              </dt>
              <dd>
                Share of Local Voice indicates the percentage of search nodes where your business ranks "3" or better.
                A higher value, close to 100%, signifies a stronger market presence.
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
    <div class="half half--geogrid-map">
      <div class="geogrid-map" id="geogrid-map" />
      <img class="geogrid-map--static" :src="`/${geogrid.headlessImagePath}`" alt="Static geogrid preview">
    </div>
  </div>
</template>

<script>
import { ensureGoogleMap } from 'common/map_helpers'
import { createMarkers, showGridMarkers, fitBounds } from 'vue_widgets/geogrids/map/helpers'
import formatISODatetime from '../../helpers/formatters'

export default {
  props: {
    sectionData: { type: Object, required: true }
  },
  data: () => ({
    geogrid: null,
    googleMapApiKey: null
  }),
  created() {
    this.geogrid = this.sectionData.geogrid
    this.googleMapApiKey = this.sectionData.googleMapApiKey
    this.formatDate = formatISODatetime(this.geogrid.createdAt, 'fullWithTZ')
  },
  computed: {
    solvPercent() {
      if (Number.isNaN(parseFloat(this.geogrid.solv))) return 'N/A'

      return `${parseInt(this.geogrid.solv * 100, 10)}%`
    }
  },
  mounted() {
    ensureGoogleMap({ googleMapApiKey: this.googleMapApiKey }, () => {
      this.map = new google.maps.Map(document.getElementById('geogrid-map'), {
        disableDefaultUI: true,
        center: {
          lat: parseFloat(this.geogrid.attrs.gridCenterLat),
          lng: parseFloat(this.geogrid.attrs.gridCenterLng)
        }
      })

      this.mapMarkers = createMarkers()

      showGridMarkers(
        this.geogrid.attrs,
        this.geogrid.gridRanks,
        [],
        [],
        this.mapMarkers,
        this.map,
        new google.maps.Size(30, 30)
      )

      fitBounds(
        {
          mapMarkers: this.mapMarkers,
          map: this.map
        },
        this.geogrid.attrs.gridSize * this.geogrid.attrs.gridSize - 1,
        {
          top: 15, bottom: 15, left: 15, right: 15
        }
      )
    })
  }
}
</script>
