<template>
  <div>
    <h2 class="content-title d-flex align-items-center gap-4">
      Statistics
      <a :href="`/locations/${locationId}/archive`" class="btn btn-xs ml-auto rounded">Archive</a>
    </h2>
    <rangeDate />
    <div class="mb-3">
      <label class="d-inline-flex gap-2 mb-0">
        <div :class="['switch-button empty-label', { disabled: breakdownByMonthsDisabled }]">
          <input v-model=breakdownByMonths :disabled="breakdownByMonthsDisabled" type="checkbox">
          <span>
            <label />
          </span>
        </div>
        <span :class="['font-weight-semi-bold color-dark', { 'text-grey': breakdownByMonthsDisabled, 'cursor-pointer': !breakdownByMonthsDisabled }]">
          Breakdown by months
        </span>
      </label>
    </div>
    <div class="insights" v-if="insightsExists">
      <LocaiotnListingInsight :breakdown-by-months="breakdownByMonths" />
      <div class="insights-part">
        <BusinessContactInsight :breakdown-by-months="breakdownByMonths" />
      </div>
      <div class="insights-part">
        <SearchingBusinessInsight :breakdown-by-months="breakdownByMonths" />
      </div>
    </div>
    <div class="insights" v-else>
      <div class="widget">
        <div class="empty-state text-center mt-3">
          <img src="~/no_data/insights.svg" alt="No Insights Img" height=100 width=150>
          <div class="text-center text-muted mb-2">
            <h3 class="mt-4 mb-4">You don't have any insights yet.</h3>
            Insights will be synced soon. Please wait.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import rangeDate from './range_date'
import LocaiotnListingInsight from './locaiotn_listing_insight'
import BusinessContactInsight from './business_contact_insight'
import SearchingBusinessInsight from './searching_business_insight'

export default {
  components: {
    rangeDate,
    LocaiotnListingInsight,
    BusinessContactInsight,
    SearchingBusinessInsight
  },
  props: {
    locationId: { type: String, required: true }
  },
  computed: mapGetters(['insightsExists', 'insightsDateRanges']),
  data() {
    return {
      breakdownByMonths: false,
      breakdownByMonthsDisabled: false
    }
  },
  watch: {
    insightsDateRanges(value) {
      const { ranges, compare } = value
      const start = moment(ranges[0].start)
      const end = moment(ranges[0].end)
      const diff = moment(end).diff(moment(start), 'months', true)

      if (compare) {
        this.breakdownByMonths = false
        this.breakdownByMonthsDisabled = true

        return
      }

      if (Math.round(diff) < 3 && !this.compare) {
        if (this.breakdownByMonths) {
          toastr.warning('Breakdown by months was disabled. Try to increase date range.')
        }
        this.breakdownByMonths = false
        this.breakdownByMonthsDisabled = true
      } else {
        this.breakdownByMonthsDisabled = false
      }
    }
  }
}
</script>
