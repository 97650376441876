import axios from 'axios'
import turbolinksReload from '../../common/turbolinks_reload'

export default ({ mobileBusinessPath }) => {
  const renewSubscriptionBtn = document.getElementById('js-business-renew-subscription')
  let loading = false

  renewSubscriptionBtn?.addEventListener('click', (e) => {
    e.preventDefault()
    if (loading) return

    loading = true
    axios.patch(`${mobileBusinessPath}/subscribe`)
      .then(() => {
        turbolinksReload(() => {
          toastr.success('Subscribed successfully')
        })
      }).finally(() => { loading = false })
  })
}
