<template>
  <div>
    <PopupShare
      v-if="geogrid.publicUrl"
      :active="showSharePopup"
      :url="geogrid.publicUrl"
      :public-config-url="publicConfigUrl"
      :search-term-id="searchTermId"
      @close="showSharePopup = false" />
    <b-row v-show="geogrid.obfuscatedId && showOnMapComponents && pageTab === 'show'" class='inmap top left'>
      <b-col xs=4 class='d-flex flex-wrap gap-1 pl-0'>
        <button @click="repeatGeogrid(geogrid)" class="btn btn-secondary btn-sm">
          <span class='wrap-icon'><i class="far fa-repeat" /></span>
          <span class='text'>Repeat</span>
        </button>
        <button v-if="!isLive" @click="runNow" class="btn btn-secondary btn-sm" :disabled="isRequesting">
          <span class='wrap-icon'><i class="far fa-play" /></span>
          <span class='text'>Run</span>
        </button>
        <ButtonGetGeogridImage
          ref="ButtonGetGeogridImage"
          classes='btn btn-sm'
          :geogrid='geogrid'
          :with-text="true" />
        <a :href='`${geogridsPath}/${geogrid.obfuscatedId}/csv_export`'
          class="btn btn-secondary btn-sm" data-turbolinks="false">
          <span class='wrap-icon'><i class='far fa-file-csv' /></span>
          <span class='text'>CSV</span>
        </a>
        <ButtonGetGeogridGif v-if="!isLive" />
        <button v-if="geogrid.publicUrl" class="btn btn-secondary btn-sm" @click="showSharePopup = true">
          <span class='wrap-icon'><i class="far fa-share" /></span>
          <span class='text'>Share</span>
        </button>
        <button @click="deleteGeogrid(geogrid.obfuscatedId)" class="btn btn-secondary btn-sm delete">
          <span class='wrap-icon'><i class="far fa-trash" /></span>
          <span class='text'>Delete</span>
        </button>
        <button @click="$emit('showCompetitorsScore')" class="btn btn-secondary btn-sm">
          <span class='wrap-icon'><i class="far fa-scale-unbalanced-flip" /></span>
          <span class='text'>Competitors</span>
        </button>
      </b-col>
    </b-row>
    <div class='inmap top right vertical'>
      <button @click='toggleOnMapComponents' v-show="geogrid.obfuscatedId && pageTab === 'show'" class='btn w-100'>
        <span class='wrap-icon'>
          <i :class="{'far fa-eye-slash': !showOnMapComponents, 'far fa-eye': showOnMapComponents}" />
        </span>
      </button>
    </div>
    <div class='inmap bottom right vertical'>
      <button @click='fitBounds' class='btn w-100'>
        <span class='wrap-icon'>
          <i class="far fa-compress-arrows-alt" />
        </span>
      </button>
      <button @click='zoom(1)' class='btn zoom-out w-100'>
        <span class='wrap-icon'>
          <i class="far fa-plus" />
        </span>
      </button>
      <button @click='zoom(-1)' class='btn zoom-in w-100'>
        <span class='wrap-icon'>
          <i class="far fa-minus" />
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { DEFAULT_ERROR_MESSAGE, NOT_ENOUGH_GEOGRID_CREDITS } from 'common/constants'
import ButtonGetGeogridImage from '../components/geogrid_image'
import ButtonGetGeogridGif from '../components/geogrid_gif'
import PopupShare from '../components/popup_share'

export default {
  name: 'GeogridButtons',
  components: {
    ButtonGetGeogridImage, ButtonGetGeogridGif, PopupShare
  },
  props: { geogridsPath: String },
  data: () => ({
    isRequesting: false,
    showSharePopup: false
  }),
  computed: {
    ...mapState(['geogrid', 'config', 'showOnMapComponents', 'pageTab', 'pageState', 'searchTerm']),
    isLive() {
      return this.pageState === 'live'
    },
    publicConfigUrl() {
      return this.config?.publicUrl
    },
    searchTermId() {
      return this.searchTerm.id
    }
  },
  methods: {
    ...mapMutations(['repeatGeogrid', 'toggleOnMapComponents', 'fitBounds', 'zoom']),
    ...mapActions(['deleteGeogrid']),
    runNow() {
      this.isRequesting = true
      axios.post(
        this.searchTerm.onDemainGeogridLink,
        { keyword: this.searchTerm.text }
      ).then((response) => {
        if (response.data.started) {
          toastr.success(
            'It will take a few minutes, please check back a bit later',
            'We started preparing your report'
          )
        } else if (response.data.error === 'not_enough_credits') {
          toastr.error(NOT_ENOUGH_GEOGRID_CREDITS, 'Not enough geogrid credits')
        } else {
          toastr.error(DEFAULT_ERROR_MESSAGE)
        }
      }).catch((_error) => {
        toastr.error(DEFAULT_ERROR_MESSAGE)
      }).finally(() => {
        this.isRequesting = false
      })
    }
  }
}
</script>
