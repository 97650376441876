<template>
  <div ref="searchTermsList" id="search-terms" class="scroll ver">
    <SearchTerm ref="searchTermCard"
      v-for="(st, index) in indexedSearchTermCards"
      :data-index="index"
      :can-draw-chart="st.canDrawChart"
      :st='st'
      :key='st.obfuscatedId'
      @click.native='showSearchTerm(st.text)' />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import SearchTerm from './search_term'

export default {
  name: 'SearchTermsList',
  components: { SearchTerm },
  methods: mapActions(['showSearchTerm']),
  data: () => ({
    indexedSearchTermCards: []
  }),
  computed: mapState(['config', 'searchTerm']),
  created() {
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) return

        const index = parseInt(entry.target.dataset.index, 10)
        this.indexedSearchTermCards[index].canDrawChart = true
        this.observer.unobserve(entry.target)
      })
    }, { root: this.$refs.searchTerms })
  },
  destroyed() {
    this.observer.disconnect()
    this.observer = null
  },
  watch: {
    searchTerm: {
      handler() {
        const searchTermsWereLoaded = this.config.searchTerms.length === this.indexedSearchTermCards.length

        if (this.config.searchTerms && !searchTermsWereLoaded) {
          this.indexedSearchTermCards = this.config.searchTerms.map((item) => ({
            ...item,
            canDrawChart: false
          }))

          this.$nextTick(() => {
            this.$refs.searchTermCard.forEach((card) => {
              this.observer.observe(card.$el)
            })
          })
        }
      },
      immediate: true
    }
  }
}

</script>
