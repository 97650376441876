<template>
  <tr :class="{ 'row-checked': row.checked }">
    <td class="column align-top" column-caption="Created At">
      <div class="d-flex gap-4">
        <AppCheckbox class="d-inline-flex" :value="row.checked" @input="onRowCheckToggle" />
        <a :href="reportShowUrl">{{ row.createdAt }}</a>
      </div>
    </td>
    <td class="column align-top" column-caption="Location">
      <div class="d-flex flex-column gap-6 color-dark">
        <div v-for="({ name, storeCode, address, locationType }, index) in row.locations" :key="index" class="d-flex flex-column gap-1">
          <strong>{{ name }}</strong>
          <span v-if="storeCode" class="text-muted font-size-14">{{ storeCode }}</span>
          <span v-if="locationType === 'sab'">Service Area Business</span>
          <span v-else-if="address" class="font-size-14">{{ address }}</span>
        </div>
      </div>
    </td>
    <td class="align-top" column-caption="Configuration Name">
      <a :href="reportConfigUrl">{{ row.configurationName }}</a>
    </td>
    <td class="column align-top" column-caption="Periods">
      <div class="d-flex flex-column gap-2">
        <span v-for="(period, index) in row.periods" :index="index">{{ period }}</span>
      </div>
    </td>
    <td class="align-top" column-caption="Actions">
      <div class="d-flex align-items-center justify-content-end gap-4 pt-1">
        <a :href="row.reportUrl" target="_blank" class="text-primary">
          <i class="far fa-link" />
        </a>
        <a href="#" class="text-danger" @click.prevent="deleteReport">
          <i class="far fa-trash" />
        </a>
      </div>
    </td>
  </tr>
</template>

<script>
import AppCheckbox from 'vue_widgets/components/checkbox'

export default {
  components: { AppCheckbox },
  props: {
    row: { type: Object, required: true }
  },
  computed: {
    reportShowUrl() {
      return `/reports/${this.row.id}`
    },
    reportConfigUrl() {
      return `/reports/configurations/${this.row.configurationId}/edit`
    }
  },
  methods: {
    onRowCheckToggle() {
      this.$emit('onRowCheckToggle', this.row.id)
    },
    deleteReport() {
      this.$emit('deleteReports', [this.row.id])
    }
  }
}
</script>
