import Vue from 'vue'
import DashboardSetup from '../vue_widgets/dashboard/setup'

function trackFbq(price, planName) {
  /* eslint-disable no-undef */
  fbq(
    'track',
    'Purchase',
    {
      currency: 'USD',
      value: price || 1,
      chosen_plan: planName
    }
  )
  /* eslint-enable no-undef */
}

function trackGA(price, planName, userId) {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(
    'event',
    'conversion',
    {
      send_to: 'AW-746302239/5V1hCPCjsakBEJ_W7uMC',
      value: price || 1,
      currency: 'USD',
      transaction_id: userId
    }
  )
}

Styxie.Initializers.Dashboard = {
  setup({ wlc, csrf }) {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-setup'),
        render: (h) => h(DashboardSetup, { props: { wlc, csrf } })
      })
    })
  },

  welcome({
    price, planName, userId, fbqEnabled
  }) {
    trackGA(price, planName, userId)
    window.setTimeout(() => {
      if (fbqEnabled) trackFbq(price, planName)
      window.location.href = '/dashboard/onboarding'
    }, 3500)
  }
}
