import GeogridComparison from 'vue_widgets/geogrids/stats/geogrid_comparison'

Styxie.Initializers.StatsGeogrids = {
  compare: (props) => {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-geogrid-comparison'),
        render: (h) => h(GeogridComparison, { props })
      })
    })
  }
}
