<template>
  <div class="geogrid-comparison">
    <div class="geogrid-comparison__container">
      <div class="geogrid-comparison__head">
        <div class="geogrid-comparison__head__logo">
          <img :src="logoPath" alt="Logo">
        </div>
        <div class="geogrid-comparison__head__print no-print">
          <button type="button" class="btn btn-transparent gap-2 px-3" @click="print">
            <i class="fa-regular fa-file-pdf" />
            Print PDF
          </button>
        </div>
      </div>
      <div class="geogrid-comparison__common-info geogrid-comparison__common-info--bg-white p-4 mb-2">
        <span class="location-name">{{ locationInfo.name }}</span>
        <span v-if="locationInfo.storeCode" class="location-store-code">{{ locationInfo.storeCode }}</span>
        <span v-if="locationInfo.address">{{ locationInfo.address }}</span>
        <span class="only-print">{{ searchTerm }}</span>
      </div>
      <div class="geogrid-comparison__common-info p-4 mb-2 no-print">
        <div>
          <span class="d-block text-muted">Search Term:</span>
          <span>{{ searchTerm }}</span>
        </div>
      </div>
      <table class="geogrids-comparing">
        <tbody>
          <tr>
            <td v-for="(gg, index) in geogrids" :key="`${gg.id}-${index}`">
              <div class="geogrid-comparison__common-info geogrid-comparison__common-info--bg-white p-3">
                <span>{{ gg.createdAtFormatted }}</span>
                <div class="d-flex align-items-center gap-4 font-size-14">
                  <div class="d-flex align-items-center gap-1">
                    <span class="text-muted font-size-14">AGR:</span>
                    <span :class="['geogrid-data', { [geogridDiff.agr.wrapClass]: index === 1 }]">
                      {{ toFixed(gg.agr) }}
                      <template v-if="index === 1 && !isGeogridValuesNaN('agr')">
                        <i :class="geogridDiff.agr.iconClass" />
                        <span>{{ geogridDiff.agr.diff.toFixed(2) }}</span>
                      </template>
                    </span>
                  </div>
                  <div class="d-flex align-items-center gap-1">
                    <span class="text-muted font-size-14">ATGR:</span>
                    <span :class="['geogrid-data', { [geogridDiff.atgr.wrapClass]: index === 1 }]">
                      {{ toFixed(gg.atgr) }}
                      <template v-if="index === 1 && !isGeogridValuesNaN('atgr')">
                        <i :class="geogridDiff.atgr.iconClass" />
                        <span>{{ geogridDiff.atgr.diff.toFixed(2) }}</span>
                      </template>
                    </span>
                  </div>
                  <div class="d-flex align-items-center gap-1">
                    <span class="text-muted font-size-14">SoLV:</span>
                    <span :class="['geogrid-data', { [geogridDiff.solv.wrapClass]: index === 1 }]">
                      {{ Math.round(gg.solv * 100) }}%
                      <template v-if="index === 1 && !isGeogridValuesNaN('solv')">
                        <i :class="geogridDiff.solv.iconClass" />
                        <span>{{ geogridDiff.solv.diff }}%</span>
                      </template>
                    </span>
                  </div>
                </div>
                <div class="geogrid-map__container">
                  <div :id="`geogrid-map-${gg.id}-${index}`" class="geogrid-map" />
                  <img class="geogrid-map--static" :src="gg.imageUrl" alt="Static geogrid preview">
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { ensureGoogleMap } from 'common/map_helpers'
import { createMarkers, showGridMarkers, fitBounds } from 'vue_widgets/geogrids/map/helpers'
import geogridComparisonMixin from 'vue_widgets/mixins/geogrid_comparison_mixin'

const METERS_IN_MILE = 1609.34

export default {
  mixins: [geogridComparisonMixin],
  name: 'GeogridComparison',
  props: {
    geogrids: { type: Array, required: true },
    logoPath: { type: String, required: true },
    googleMapApiKey: { type: String, required: true }
  },
  computed: {
    locationInfo() {
      return {
        name: this.geogrids[0].businessName,
        storeCode: this.geogrids[0].businessStoreCode,
        address: this.geogrids[0].businessAddress
      }
    },
    searchTerm() {
      return this.geogrids[0].searchTerm
    }
  },
  methods: {
    drawGeogrid(geogrid, index) {
      this[`map-${geogrid.id}-${index}`] = new google.maps.Map(document.getElementById(`geogrid-map-${geogrid.id}-${index}`), {
        disableDefaultUI: true,
        center: {
          lat: parseFloat(geogrid.gridCenterLat),
          lng: parseFloat(geogrid.gridCenterLng)
        }
      })

      this.mapMarkers = createMarkers()

      showGridMarkers(
        {
          gridCenterLat: geogrid.gridCenterLat,
          gridCenterLng: geogrid.gridCenterLng,
          gridSize: geogrid.gridSize,
          distanceInMeters: geogrid.gridDistanceMeasure === 'meters' ? geogrid.gridPointDistance : geogrid.gridPointDistance * METERS_IN_MILE
        },
        geogrid.ranks,
        [],
        [],
        this.mapMarkers,
        this[`map-${geogrid.id}-${index}`],
        new google.maps.Size(30, 30)
      )

      fitBounds(
        {
          mapMarkers: this.mapMarkers,
          map: this[`map-${geogrid.id}-${index}`]
        },
        geogrid.gridSize * geogrid.gridSize - 1,
        {
          top: 15, bottom: 15, left: 15, right: 15
        }
      )
    },
    print() {
      window.print()
    }
  },
  mounted() {
    ensureGoogleMap({ googleMapApiKey: this.googleMapApiKey }, () => {
      this.geogrids.forEach((gg, index) => {
        this.drawGeogrid(gg, index)
      })
    })
  }
}
</script>
