<template>
  <transition name="app-modal">
    <div class="app-modal-mask" v-if="value" @mouseup.self="maskClicked">
      <div class="app-modal-container" :class="size">
        <div class="app-modal-header" v-if="$slots.header">
          <slot name="header" />
        </div>

        <div class="app-modal-body">
          <slot name="body" />
        </div>

        <div class="app-modal-footer" v-if="$slots.footer">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    value: { type: Boolean, default: false },
    size: { type: String, default: 'md' }
  },
  watch: {
    value(bool) {
      document.body.classList.toggle('noscroll', bool)
    }
  },
  methods: {
    maskClicked() {
      this.$emit('input', false)
    }
  }
}
</script>
