export const fields = [
  {
    type: 'text',
    key: 'businessDetails',
    title: 'Business details',
    titleClasses: 'caption',
    classes: 'w-100',
    placeholder: 'All',
    hint: 'Name, Address, PlaceID, Store code'
  },
  {
    type: 'select',
    key: 'selectedCategories',
    title: 'Categories',
    titleClasses: 'caption',
    classes: 'w-100',
    placeholder: 'All',
    options: [],
    searchable: true,
    showLabels: false,
    allowEmpty: true,
    multiple: true,
    maxHeight: 540
  },
  {
    type: 'select',
    key: 'selectedTags',
    title: 'Tags',
    titleClasses: 'caption',
    classes: 'w-100',
    placeholder: 'All',
    options: [],
    searchable: false,
    showLabels: false,
    allowEmpty: true,
    multiple: true,
    maxHeight: 540
  },
  {
    type: 'select',
    key: 'gridSize',
    title: 'Grid size',
    titleClasses: 'caption',
    classes: 'w-100',
    placeholder: 'All',
    options: [
      { id: '', name: 'All' },
      { id: 3, name: '3x3' },
      { id: 5, name: '5x5' },
      { id: 7, name: '7x7' },
      { id: 9, name: '9x9' },
      { id: 13, name: '13x13' }
    ],
    trackBy: 'id',
    label: 'name',
    searchable: false,
    showLabels: false,
    allowEmpty: true,
    maxHeight: 540
  },
  {
    type: 'text',
    key: 'searchTerm',
    title: 'Search term',
    titleClasses: 'caption',
    classes: 'w-100',
    placeholder: 'All'
  },
  {
    type: 'select',
    key: 'state',
    title: 'States',
    titleClasses: 'caption',
    classes: 'w-100',
    placeholder: 'All',
    options: [
      { id: 'deleted', name: 'Deleted' },
      { id: 'firstTop3', name: 'TOP-3 first time' },
      { id: 'anyTop3', name: 'TOP-3 any time' }
    ],
    trackBy: 'id',
    label: 'name',
    searchable: false,
    showLabels: false,
    allowEmpty: true,
    maxHeight: 540
  }
]

export default {
  fields
}
