<template>
  <div class="gallery-widget scheduled-widget">
    <template v-if="allSchedules === 0 && filter.value === 'all'">
      <div class="empty">
        <h2>No images scheduled yet.</h2>
        <p>You can do this after selecting the images you are interested in</p>
      </div>
    </template>
    <template v-else>
      <div class="gallery-widget-head">
        <h2>Schedules</h2>
        <div class="dropdown">
          <div class="dropdown-head" data-toggle="dropdown">
            <i class="far fa-calendar-alt" />
            <p>{{ filter.label }}</p>
            <i class="far fa-chevron-down" />
          </div>
          <div class="dropdown-menu">
            <a
              @click.prevent="sortBy(option)"
              class="dropdown-item"
              href="#"
              v-for="option in filterOptions"
              :key="option.value">{{ option.label }}</a>
          </div>
        </div>
      </div>
      <div v-if="mediaScheduleList.length > 0" class="scheduled-widget-body scroll ver pr-2">
        <AppTable
          :next-page="nextPage"
          :filtered-media-schedule-list="mediaScheduleList"
          :window-width="windowWidth"
          :item-status="itemStatus"
          :go-to-scheduled-item="goToScheduledItem" />
      </div>
      <div v-else class="empty">
        <h2 class="m-0">No records found for this filter</h2>
      </div>
    </template>
  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import AppTable from './table.vue'

export default {
  components: { AppTable },
  data() {
    return {
      mediaScheduleList: [],
      windowWidth: 0,
      filter: { label: 'All time', value: 'all' },
      filterOptions: [
        { label: 'Today', value: 'today' },
        { label: 'Tomorrow', value: 'tomorrow' },
        { label: 'This week', value: 'week' },
        { label: 'This month', value: 'month' },
        { label: 'All time', value: 'all' }
      ],
      page: 1,
      allSchedules: 0
    }
  },
  created() {
    this.sortBy(this.filterOptions[this.filterOptions.length - 1])
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.getWindowWidth)
      this.getWindowWidth()
    })
  },
  methods: {
    fetchShedulesItems() {
      axios('/api/media_schedules', {
        params: {
          filter: this.filter.value,
          page: this.page
        }
      })
        .then((res) => {
          this.mediaScheduleList = [...this.mediaScheduleList, ...res.data.rows]
          this.allSchedules = parseInt(res.headers['x-total-count'], 10)
          this.formatData()
        })
    },
    nextPage() {
      if (this.mediaScheduleList.length < this.allSchedules) {
        this.page++
        this.fetchShedulesItems()
      }
    },
    sortBy(option) {
      this.filter = option
      this.mediaScheduleList = []
      this.page = 1
      this.fetchShedulesItems()
    },
    formatData() {
      this.mediaScheduleList.forEach((item) => {
        item.startDayLong = moment(item.startDay).format('D MMMM YYYY')
        item.startDayShort = moment(item.startDay).format('D.MM.YY')
        item.end = moment(item.startDay).add(item.days, 'day').format('MMM D')

        if (moment().diff(moment(item.startDay).add(item.days, 'day'), 'days') > 0) {
          item.progress = 'complited'
        } else {
          item.progress = 'progress'
          item.progressValue = (moment().diff(moment(item.startDay), 'day') / item.days) * 100
        }
      })
    },
    goToScheduledItem(id) {
      window.location = `media_schedules/${id}`
    },
    itemStatus(item) {
      if (item.processStatus === 'failed') {
        return 'Failed'
      }
      if (item.progress === 'complited') {
        return 'Done'
      }
      return 'In Progress'
    },
    getWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowWidth)
  }
}
</script>
