<template>
  <VueSlimTable
    ref="table"
    :source="getBusinesses"
    :columns="columns"
    class="table mv-businesses-table">
    <template #row="{ row }">
      <tr>
        <td v-for="({ key }, index) in columns" :key="key" :class="{ 'w-55': index === 0, 'w-15': index !== 0 }">
          <template v-if="key === 'name'">
            <div class="d-flex align-items-center gap-2">
              <template v-if="row.disconnected">
                <i :id="row.id" class="color-warning far fa-exclamation-circle" />
                <BTooltip :target="row.id" title="Location is disconnected" />
              </template>
              <a :href="getBusinessLink(row.id)">{{ row.name }}</a>
            </div>
            <small class="d-block text-muted">{{ transformLocationData(row.locationData) }}</small>
          </template>
          <template v-else>
            {{ row[key] }}
          </template>
        </td>
      </tr>
    </template>
    <template #pagination>
      <span>&nbsp;</span>
    </template>
  </VueSlimTable>
</template>

<script>
export default {
  props: {
    getBusinessLink: { type: Function },
    getBusinesses: { type: Function },
    transformLocationData: { type: Function },
    search: { type: String, default: '' }
  },
  created() {
    this.columns = [
      { key: 'name', title: 'Business', orderable: true },
      { key: 'employeesCount', title: 'Workers', orderable: false },
      { key: 'checkinsCount', title: 'Moments', orderable: false },
      { key: 'postsCount', title: 'Posts', orderable: false }
    ]
  },
  watch: {
    search() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.$refs.table.reload()
      }, 500)
    }
  }
}
</script>
