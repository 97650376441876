<template>
  <div :class="['alert', type]" role="alert">
    <div class="message">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: ''
    }
  }
}
</script>
