<template>
  <div class="panel-body">
    <form @submit.prevent="onSubmit" novalidate>
      <fieldset :disabled="wasNotPublishedInTime">
        <FormPlainErrors />

        <FormTopicTabs />
        <FormSummary />
        <ImageSelect v-if="object.topicType !== 'alert'" />

        <FormLocationsSelect v-if="multilocation" @selectChanged="changeLocationIds" />
        <FormLocationTagsSelect v-if="multilocation" @selectChanged="changeLocationTags" />

        <FormScheduled text="Post" />
        <FormRecurent text="Post" />
      </fieldset>

      <div class="form-group">
        <input :disabled="wasNotPublishedInTime" type="submit" name="commit"
          value="Submit"
          class="btn btn-space btn-primary">
        <a href="#" @click="onBack">Back</a>

        <div class="float-right" v-if="object.obfuscatedId">
          <a
            v-if="object.recurrentDays"
            @click.prevent="stopRecurring"
            :class="['btn btn-danger', { disabled: !apiConnection }]"
            :href="`/api/gmb/locations/${object.remoteLocationId}/local_posts/${object.obfuscatedId}/stop_recurring`">
            Stop recurring
          </a>
          <a
            :class="['btn btn-danger', { disabled: !apiConnection }]"
            :href="`/api/gmb/locations/${object.remoteLocationId}/local_posts/${object.obfuscatedId}`"
            @click.prevent='onDelete'>
            Delete
          </a>
          <a
            :class="['btn btn-primary mr-1', { disabled: !apiConnection }]"
            :href="`/gmb/locations/${object.remoteLocationId}/local_posts/${object.obfuscatedId}/clone`">
            Clone
          </a>
        </div>
        <br>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import FormPlainErrors from './plain_errors'
import FormTopicTabs from './topic_tabs'
import FormSummary from './summary'
import FormScheduled from './scheduled'
import FormRecurent from './recurent'
import ImageSelect from '../../../components/image_select/index'
import FormLocationsSelect from '../../../components/locations_select'
import FormLocationTagsSelect from '../../../components/location_tags_select'

export default {
  computed: mapState(['object', 'multilocation', 'wasNotPublishedInTime', 'apiConnection']),
  methods: mapActions(['onSubmit', 'onDelete', 'onBack', 'changeLocationIds', 'changeLocationTags', 'stopRecurring']),
  components: {
    FormPlainErrors,
    FormTopicTabs,
    FormSummary,
    FormLocationsSelect,
    FormLocationTagsSelect,
    FormScheduled,
    FormRecurent,
    ImageSelect
  }
}
</script>
