<template>
  <div class="col-xl-3 col-md-6 col-12 p-0 mb-3">
    <div class='card text-center'>
      <p>{{ item.title }}</p>
      <h2>{{ item.completed }}</h2>
      <p>Out of {{ item.total }} initiated.</p>
    </div>
    <div class="chart-container">
      <div class="chart" ref="chart" />
    </div>
  </div>
</template>

<script>
import Highcharts from 'highcharts'

export default {
  props: ['item'],
  data() {
    return {
      colors: [
        '#377dff',
        '#359b84',
        '#ffb34d',
        '#48558c',
        '#8cd3e8',
        '#107aa9',
        '#fcc4c7',
        '#933944',
        '#ffc57d'
      ]
    }
  },
  methods: {
    initChart() {
      this.chart = Highcharts.chart(this.$refs.chart, {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        credits: {
          enabled: false
        },
        colors: this.colors,
        title: {
          text: null
        },
        series: [{
          name: 'quantity',
          colorByPoint: true,
          size: '40%',
          innerSize: '50%',
          data: this.item.chart
        }]
      })
    }
  },
  mounted() {
    this.initChart()
  }
}
</script>
