import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import axiosTransform from 'common/axios'

Vue.use(Vuex)

const withDiff = (state) => {
  let difference

  if (state.sumCompare === state.sum) {
    difference = 0
  } else if (state.sumCompare === 0 && state.sum > 0) {
    difference = 100
  } else if (state.sumCompare > 0 && state.sum === 0) {
    difference = -100
  } else {
    difference = Math.round(state.sum * 100 / state.sumCompare) - 100
  }

  return { difference, ...state }
}

export default function createStore(data) {
  return new Vuex.Store({
    state: {
      ...data,
      searchTerms: [],
      isDataLoading: false,
      parsedData: null
    },
    actions: {
      fetchInsights({ commit, state }) {
        commit('lockView', true)

        const params = {}
        if (state.chosenLocation) {
          params.location_ids = state.chosenLocation.id
        }

        axios.get(state.insightsPath, {
          params,
          headers: { Accept: 'application/json' },
          paramsSerializer(json) { return qs.stringify(json, { arrayFormat: 'brackets' }) },
          ...axiosTransform
        }).then((res) => {
          const resData = state.responseKey ? res.data[state.responseKey] : res.data

          const parsedData = {}
          resData.stats.forEach((stat) => {
            const currentData = resData.chartData.find((el) => el.id === stat.id)
            const compareData = resData.chartCompareData.find((el) => el.id === stat.id)
            parsedData[stat.id] = {
              ...withDiff(stat),
              chartData: [currentData, compareData]
            }
          })

          commit('setParsedData', {
            parsedData,
            searchTerms: resData.searchTerms ? resData.searchTerms : []
          })
        }).finally(() => {
          commit('lockView', false)
        })
      },
      selectLocation({ commit, dispatch }, locationId) {
        commit('selectLocation', locationId)
        dispatch('fetchInsights')
      }
    },
    mutations: {
      selectLocation(state, locationId) {
        state.chosenLocation = state.locations.find(({ id }) => id === locationId)
      },
      setParsedData(state, { parsedData, searchTerms }) {
        state.parsedData = parsedData
        state.searchTerms = searchTerms
      },
      lockView(state, payload) {
        state.isDataLoading = payload
      }
    }
  })
}
