<template>
  <div class="table-actions d-flex justify-content-between flex-wrap">
    <Checkbox
      :value="selectedAll"
      :part-selected="partSelected.isPartSelected"
      @input="onSelected(false)">
      <template v-if="partSelected.selectedCount === partSelected.length">
        <span class="location-count mr-2">{{ partSelected.captionAll }}</span>
        <a href="#" class="ml-1" @click.stop.prevent="onSelected(false)">Unselect</a>
      </template>
      <template v-else>
        <span class="location-count mr-2">{{ partSelected.caption }}</span>
        <a href="#" class="ml-1 mr-2" @click.stop.prevent="onSelected(true)">Select All</a>
        <a href="#" class="ml-1" @click.stop.prevent="onSelected(false)">Unselect</a>
      </template>
    </Checkbox>
    <div class="actions">
      <div v-if="keywordsCommon.forceCheckEnabled">
        <button @click.stop.prevent="updateManualRanks" class="btn btn-primary rounded">
          Force Check
        </button>
      </div>
      <DropdownAction
        :actions="exportActions"
        :options="{
          isHeadShown: false,
          isCommonAction: false
        }"
        title="Export"
        @saveSettings="exportKeywords" />
      <DropdownAction
        :actions="frequencyActions"
        :options="{
          isHeadShown: false,
          isCommonAction: false,
          radioFormName: 'check_frequency'
        }"
        title="Set Frequency"
        @saveSettings="changeFrequency">
        <template #description>
          <p class="text-muted">If keywords with different refresh rate settings are selected, the new value will be set for all of them</p>
        </template>
      </DropdownAction>
      <DropdownAction
        :actions="typeActions"
        :options="{
          headLabel: 'Keywords type',
          isCommonLabel: 'All types',
          buttonCaption: 'Delete Keywords'
        }"
        title="Delete"
        title-fa-icon-class="far fa-trash color-danger"
        @saveSettings="deleteKeywords" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Checkbox from '../../components/checkbox'
import DropdownAction from '../../components/dropdown_action'

export default {
  components: { Checkbox, DropdownAction },
  computed: mapState(['orgLocationPath']),
  props: {
    selectedAll: {
      type: Boolean,
      default: false
    },
    keywords: {
      type: Array,
      default() { return [] }
    },
    partSelected: Object,
    keywordsCommon: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      typeActions: {
        type: 'checkbox',
        actions: [
          { label: 'Local Keywords', data: { selected: false }, attr: 'local' },
          { label: 'Organic Keywords', data: { selected: false }, attr: 'organic' },
          { label: 'Organic Mobile Keywords', data: { selected: false }, attr: 'organic_mobile' }
        ]
      },
      exportActions: {
        type: 'links',
        actions: [
          {
            label: 'Current Ranks to CSV',
            href: 'current_ranks',
            iconClass: 'far fa-file-csv'
          },
          {
            label: 'Current Ranks to CSV V2',
            href: 'current_ranks_v2',
            iconClass: 'far fa-file-csv'
          },
          {
            label: 'Ranks History to CSV',
            href: 'ranks_history',
            iconClass: 'far fa-file-csv'
          }
        ]
      },
      frequencyActions: {
        type: 'radio',
        actions: [
          { label: '48 hours', value: 'two_days' },
          { label: 'One week', value: 'one_week' },
          { label: 'Two weeks', value: 'two_weeks' },
          { label: 'One month', value: 'one_month' }
        ]
      }
    }
  },
  methods: {
    onSelected(value) {
      this.$emit('onSelected', value)
    },
    exportKeywords(exportType) {
      this.$emit('exportKeywords', exportType)
    },
    changeFrequency(actions) {
      const frequency = actions.find((action) => action.selected).value
      this.$emit('changeFrequency', frequency)
    },
    deleteKeywords(settings) {
      const types = settings.reduce((memo, { attr, data }) => {
        if (data.selected) {
          memo.push(attr)
        }
        return memo
      }, [])
      this.$emit('deleteKeywords', types)
    },
    updateManualRanks() {
      this.$emit('updateManualRanks')
    }
  }
}
</script>
