export default {
  data: () => ({
    activeMedia: null,
    loadingOriginalMedia: true
  }),
  methods: {
    handleMediaLoad() {
      this.loadingOriginalMedia = false
    }
  },
  watch: {
    activeMedia(newVal) {
      if (!newVal) {
        setTimeout(() => {
          this.loadingOriginalMedia = true
        })
      }
    }
  }
}
