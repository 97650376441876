<template>
  <div class="insight-metrics" :class="{compare: compare}">
    <div
      v-for="s in stats"
      :key="s.id"
      class="tile"
      :class="{ active: compare && s.id === currentStat }"
      @click.prevent="setCurrentStat(s.id)">
      <div class='widget' :class="[(s.difference * s.sum_compare > 0) ? 'widget-primary' : '' ]">
        <div class='widget-head'>
          <span data-toggle="tooltip" :data-original-title="statsHelpers[s.id]">
            {{ s.name }}
          </span>
        </div>
        <div class="row mt-auto">
          <div class="col">
            <div class="earnings-resume">
              <div class="earnings-value earnings-value-big">
                <div class="earnings-counter">
                  <div v-if="compare">
                    {{ s.difference > 0 ? `+${s.difference}` : s.difference }}%
                  </div>
                  <div v-else>
                    {{ s.sum | compactInteger }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col text-right" v-if="compare">
            <div class="earnings-counter type-B" data-toggle="tooltip" :data-original-title="compareTooltip">{{ s.sum_compare | compactInteger }}</div>
            <div class="earnings-counter type-A" data-toggle="tooltip" :data-original-title="currentTooltip">{{ s.sum | compactInteger }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  computed: mapGetters(['stats', 'currentTooltip', 'compareTooltip', 'currentStat', 'compare']),
  methods: mapMutations(['setCurrentStat']),
  updated() {
    $('[data-toggle="tooltip"]').tooltip()
  },
  data() {
    return {
      statsHelpers: {
        queries_direct: 'The number of times the listing was shown when searching for the location directly.',
        queries_indirect: 'The number of times the listing was shown for a related or categorical search (for example: "restaurant near me").',
        actions_driving_directions: 'The numbers of times driving directions were requested.',
        actions_phone: 'The number of times the phone or call now link was clicked.',
        actions_website: 'The number of times the website link was clicked.',
        local_post_actions_call_to_action: 'The number of times the call to action button was clicked on Google.',
        local_post_views_search: 'The number of times GMB posts were viewed on search.',
        photos_views_merchant: 'The number of views on media items uploaded by the GMB listing owner(s).',
        photos_views_customers: 'The number of views on media items uploaded by customers.',
        photos_count_merchant: 'The total number of media items that are currently live that have been uploaded by the GMB owner(s).',
        photos_count_customers: 'The total number of media items that are currently live that have been uploaded by customers.',
        queries_chain: 'The number of times the listing was shown as a result of a search for the chain it belongs to or the brand it sells (for example: "starbucks", "adidas").', // eslint-disable-line max-len
        views_maps: 'The number of times the listing was viewed on Google Maps.',
        views_search: 'The number of times the listing was viewed on Google Search.',
        local_posts: 'The number of GMB posts created.',
        reviews: 'The number of GMB reviews received.',
        images: 'The number of merchant photos uploaded.'
      }
    }
  }
}
</script>
