<template>
  <div>
    <FilterComponent ref="customFilterLive"
      class="mb-4"
      :fields="filterFields"
      :session-key="sessionKey"
      @onFilterChange="onFilterChange" />
    <TableAction v-if="partSelected.selectedCount !== 0"
      :part-selected="partSelected"
      :checked="checkAll"
      :geogrids="geogrids"
      :rows-total-count="totalGeogrids"
      :loading-csv="loadingCsv"
      @onChecked="checkGeogrids"
      @downloadCsv="downloadCsv" />
    <VueSlimTable
      ref="table"
      :source="tableSource"
      :columns="shownColumns"
      class="geogrids-table live-config-table"
      :per-page="perPage">
      <template #head:business_name>
        <span class="d-inline-flex align-middle pl-2">
          <Checkbox
            :value="checkAll"
            :part-selected="partSelected.isPartSelected"
            @click.native.prevent.stop="checkGeogrids(!checkAll)" />
          Location
        </span>
      </template>
      <template #row="{ row }">
        <TableRow class="geogrids-table__row" :ref="`live-config-row-${row.obfuscatedId}`"
          :row="row"
          :geogrids-path="geogridsPath"
          @onDeleteRow="reload"
          @onRowCheckToggle="onRowCheckToggle" />

        <tr class="geogrids-table__tags" :class="{ checked: row.checked }">
          <td colspan="5">
            <div class='mt-2 pl-2'>
              <Tags
                :ref='`${row.obfuscatedId}-tags`'
                :initial-tags="row.tags"
                :searchable-tags='true'
                @addTagToFilter='addTagToFilter' />
            </div>
          </td>
          <!-- spacer for background -->
          <td colspan="2" />
        </tr>
      </template>
      <template #head:grid_ranks="{ column }">
        <div class="d-inline-flex">
          <span>{{ column.title }}</span>
          <i id='geogrid-ranks-tooltip' class="fa-info-circle far float-right pl-1" />
          <b-popover
            id='key-popover'
            placement='leftbottom'
            target='geogrid-ranks-tooltip'
            custom-class='geogrids-popover'
            triggers='click hover'>
            <div class='text-dark'>
              <b>AGR</b>
              <div class='mb-2'>Average Grid Rank. Only nodes with ranks equal to "20" and less considered.</div>
              <b>ATGR</b>
              <div class='mb-2'>Average Total Grid Rank. All nodes considered. The "21" value is used for nodes with ranks over "20".</div>
              <b>SoLV</b>
              <div>
                Share of Local Voice indicates the percentage of search nodes where your business ranks "3" or better.
                A higher value, close to 100%, signifies a stronger market presence.
              </div>
            </div>
          </b-popover>
        </div>
      </template>
      <template #pagination>
        <span>&nbsp;</span>
      </template>
    </VueSlimTable>
    <Pagination :page="currentPage" :per-page="perPage" :total-count="totalGeogrids" @updatePage="updatePageNumber" />
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import axios from 'axios'
import { mapState } from 'vuex'
import snakeCaseKeys from 'snakecase-keys'
import { extractSessionStorage, insertSessionStorage } from 'common/helpers'
import FilterComponent from 'vue_widgets/components/filter'
import axiosTransform from '../../../common/axios'
import TableAction from './index/table_action'
import TableRow from './index/table_row'
import Tags from '../components/tags'
import Pagination from '../../components/pagination'
import Checkbox from '../../components/checkbox'
import { fields } from '../filter_options'
import fetchFiltersMixin from '../mixins/fetchFiltersMixin.js'

const csrf = document.querySelector('meta[name="csrf-token"]')
axios.defaults.headers.common['X-CSRF-Token'] = csrf.getAttribute('content')

export default {
  name: 'GeogridsHistoryWidget',
  mixins: [fetchFiltersMixin],
  components: {
    Tags, TableRow, Pagination, FilterComponent, TableAction, Checkbox
  },
  props: {
    initialData: Object,
    apiGeogridsPath: String,
    geogridsPath: String
  },
  data() {
    return {
      checkAll: false,
      geogrids: [],
      currentPage: 1,
      totalGeogrids: 0,
      perPage: 7,
      isBusy: false,
      filters: {},
      fields,
      bulkCsvData: '',
      loadingCsv: false
    }
  },
  created() {
    this.filtersPath = this.apiGeogridsPath
    this.columns = [
      { key: 'business_name', title: 'Location', orderable: true },
      { key: 'categories', title: 'Categories', orderable: false },
      { key: 'keyword', title: 'Search term', orderable: true },
      { key: 'created_at', title: 'Created At', orderable: true },
      { key: 'grid_ranks', title: 'Grid Ranks', orderable: true },
      { key: 'grid', title: 'Grid', orderable: true },
      { key: 'actions', title: 'Actions', orderable: false }
    ]

    this.forbiddenFilterFields = ['state']
    this.sessionKey = `live_geogrids_filter_${Cookies.get('user_email')}`
    this.sessionPageKey = `live_geogrids_page_${Cookies.get('user_email')}`

    this.currentPage = extractSessionStorage(this.sessionPageKey) || 1
  },
  computed: {
    ...mapState(['deletedGeogridId']),
    filterFields() {
      return this.fields.filter((field) => !this.forbiddenFilterFields.includes(field.key))
    },
    selectedRowIds() {
      return this.checkAll ? 'all' : this.$refs.table.rows.filter((row) => row.checked).map((el) => el.obfuscatedId)
    },
    shownColumns() {
      return this.partSelected.selectedCount > 0 ? [] : this.columns
    },
    partSelected() {
      const selectedCount = this.checkAll ? this.totalGeogrids : this.geogrids.filter((row) => row.checked).length
      return {
        selectedCount: selectedCount,
        length: this.totalGeogrids,
        isPartSelected: selectedCount !== 0 && selectedCount !== this.totalGeogrids,
        caption: `Selected ${selectedCount} ${this.$options.filters.pluralize('geogrid', selectedCount)} of ${this.totalGeogrids}`,
        captionAll: `Selected ${selectedCount} ${this.$options.filters.pluralize('geogrid', selectedCount)}`
      }
    }
  },
  methods: {
    addTagToFilter(tag) {
      if (this.$refs.customFilterLive.filterValues.selectedTags) {
        const { selectedTags } = this.$refs.customFilterLive.filterValues
        const tagIndex = selectedTags.indexOf(tag)
        if (tagIndex === -1) {
          this.$refs.customFilterLive.onFilterChange({ key: 'selectedTags' }, [...selectedTags, tag], 0, false)
        } else {
          selectedTags.splice(tagIndex, 1)
          this.$refs.customFilterLive.onFilterChange({ key: 'selectedTags' }, selectedTags, 0, false)
        }
      } else {
        this.$refs.customFilterLive.onFilterChange({ key: 'selectedTags' }, [tag], 0, false)
      }
    },
    onFilterChange(newFilterValues) {
      this.filters = newFilterValues
      this.updatePageNumber(1)
    },
    tableSource(prms) {
      this.isBusy = true

      const params = {
        order: [{ field: 'created_at', direction: 'desc' }],
        ...prms,
        ...this.filters,
        page: this.currentPage
      }

      return axios.get(
        this.apiGeogridsPath,
        {
          params: snakeCaseKeys(params),
          paramsSerializer(json) { return qs.stringify(json, { arrayFormat: 'brackets' }) },
          ...axiosTransform
        }
      )
        .then((res) => {
          this.totalGeogrids = res.data.totalGeogrids
          this.geogrids = res.data.geogrids.map((geogrid) => ({ ...geogrid, checked: this.checkAll }))
          return this.geogrids
        })
        .finally(() => {
          this.isBusy = false
        })
    },
    updatePageNumber(page) {
      this.currentPage = page
      insertSessionStorage(this.sessionPageKey, this.currentPage)
      this.reload()
    },
    reload() {
      this.totalGeogrids = 0
      this.$refs.table?.refetch()
    },
    areGeogridsChecked() {
      return !this.geogrids.find((row) => !row.checked) && this.totalGeogrids <= this.perPage
    },
    onRowCheckToggle({ configId, bool }) {
      this.geogrids.find((row) => row.obfuscatedId === configId).checked = bool
      this.checkAll = this.areGeogridsChecked()
    },
    checkGeogrids(value) {
      this.checkAll = value
      this.$refs.table.rows.forEach((row) => {
        row.checked = value
      })
    },
    downloadCsv() {
      this.loadingCsv = true
      axios.put(
        '/api/geogrids_bulk_actions/csv_export',
        {
          geogrid_ids: this.selectedRowIds,
          ...snakeCaseKeys(this.filters)
        }
      )
        .then((res) => {
          toastr.success('Your CSV is preparing.')
          window.open(`/downloads/${res.data}`)
        })
        .finally(() => {
          setTimeout(() => {
            this.loadingCsv = false
          }, 500)
        })
    }
  },
  watch: {
    deletedGeogridId() {
      this.reload()
    }
  }
}
</script>
