function initSelects() {
  $('.question-tags').turboSelect({
    width: '100%',
    multiple: true,
    tags: true,
    tokenSeparators: [','],
    placeholder: 'Add tags...'
  })

  $('.category-select').turboSelect({
    width: '100%',
    placeholder: 'Select a category'
  })
}

Styxie.Initializers.AdminKnowledgeBaseQuestions = {
  show: () => {
    $(initSelects)
  },

  update: () => {
    $(initSelects)
  }
}
