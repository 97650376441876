<template>
  <div class="chart-switcher">
    <div v-for="option in options" :key="option.value">
      <input
        :id="`${name}-${option.value}`"
        v-model="selectedValue"
        :name="name"
        :value="option.value"
        type="radio"
        @change="onChange">
      <label :for="`${name}-${option.value}`" class="chart-radio">
        <i v-if="option.iconClasses" :class="option.iconClasses" />
        <span v-if="option.label">{{ option.label }}</span>
      </label>
    </div>
  </div>
</template>

<script>
import { extractSessionStorage, insertSessionStorage } from 'common/helpers'

export default {
  props: {
    name: { type: String, required: true },
    options: { type: Array, required: true }
  },
  data() {
    return {
      selectedValue: ''
    }
  },
  created() {
    this.selectedValue = extractSessionStorage(this.name) || this.options[0].value
    this.$emit('input', this.selectedValue)
  },
  methods: {
    onChange() {
      insertSessionStorage(this.name, this.selectedValue)
      this.$emit('input', this.selectedValue)
    }
  }
}
</script>
