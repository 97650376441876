import camelCaseKeys from 'camelcase-keys-deep'
import Vue from 'vue'
import GeoboosterWidgetsIndex from '../../vue_widgets/mobile/geobooster_widgets/geobooster_widgets_index'
import GeoboosterWidgetsEdit from '../../vue_widgets/mobile/geobooster_widgets/geobooster_widgets_edit'

Styxie.Initializers.MobileWidgets = {
  index: (params) => {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-geobooster-widgets-index'),
        render: (h) => h(GeoboosterWidgetsIndex, { props: { content: camelCaseKeys(params) } })
      })
    })
  },
  edit: (params) => {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-geobooster-widgets-edit'),
        render: (h) => h(GeoboosterWidgetsEdit, { props: { content: camelCaseKeys(params) } })
      })
    })
  }
}
