<template>
  <div class="location-calendar">
    <h2 class="content-title">Calendar</h2>
    <div class="calendar-control">
      <DatePicker :just-months="true" date-format="Y-m-d" :value="today" @input="getDate" />
      <div class="calendar-labels">
        <div class="calendar-label posts">{{ posts }} {{ 'Post' | pluralize(posts) }}</div>
        <div class="calendar-label media">{{ media }} {{ 'Media file' | pluralize(media) }}</div>
        <div class="calendar-label reports">{{ reports }} {{ 'Report' | pluralize(reports) }}</div>
        <div class="calendar-label gg-reports">{{ geogrids }} {{ 'Geogrid' | pluralize(geogrids) }}</div>
        <div class="calendar-label audits">{{ audits }} {{ 'Audit' | pluralize(audits) }}</div>
      </div>
    </div>
    <div id="js-calendar-template" />
  </div>
</template>

<script>
import axios from 'axios'
import DatePicker from '../../components/datepicker'
import emptyCalendarTemplate from '../../../common/gmb/locations/calendar_template'

const COUNTERS = ['posts', 'media', 'reports', 'geogrids', 'audits']

function drawPreview(ranks) {
  const preview = document.createElement('div')
  preview.classList.add('geogrid-preview')
  preview.classList.add(`size-${ranks.length}`)
  ranks.forEach((row) => {
    const tr = document.createElement('div')
    tr.classList.add('tr')
    row.forEach((rank) => {
      const td = document.createElement('div')
      td.classList.add('td')
      td.classList.add(`gg-background--gg${rank}`)
      tr.append(td)
    })
    preview.append(tr)
  })

  return preview
}

function drawGeogrids() {
  const ids = []
  const self = this
  self.querySelectorAll('.description-item').forEach((item) => {
    ids.push(item.getAttribute('data-id'))
  })
  axios.get(`/api/geogrids/previews?ids=${ids}`)
    .then(({ data }) => {
      data.forEach((preview) => {
        self
          .querySelector(`[data-id="${preview.obfuscated_id}"]`)
          .querySelector('.report-img')
          .innerHTML = drawPreview(preview.ranks).outerHTML
      })
    })
}

export default {
  components: { DatePicker },
  data() {
    return {
      posts: 0,
      media: 0,
      reports: 0,
      geogrids: 0,
      audits: 0
    }
  },
  props: {
    apiOrgLocationPath: {
      type: String,
      default: ''
    }
  },
  created() {
    this.path = `${this.apiOrgLocationPath}/posts_calendar`
    this.today = [new Date()]
  },
  mounted() {
    const $calendar = $('#js-calendar-template')
    if (!$calendar.length) return

    $calendar.html(emptyCalendarTemplate)
    this.fetchCalendar($calendar, this.path)
  },
  methods: {
    fetchCalendar($calendar, path) {
      $calendar.toggleWrapper({ parentClass: '.simple-calendar' }, true)

      axios.get(path)
        .then(({ data }) => {
          $calendar.html(data.template)
          COUNTERS.forEach((key) => {
            this[key] = data[key]
          })
          $('[data-toggle="dropdown"]').dropdown()
          $('[stop-propagation]').click((e) => { e.stopPropagation() })
        })
        .catch(() => {
          $calendar.html(emptyCalendarTemplate)
          COUNTERS.forEach((key) => {
            this[key] = 0
          })
          $calendar.find('.simple-calendar').addClass('fetch-error')
        })
        .finally(() => {
          $calendar.toggleWrapper({}, false)
          $calendar.find('li.gg-reports').on('show.bs.dropdown', drawGeogrids)
        })
    },
    getDate(date) {
      const $calendar = $('#js-calendar-template')
      if (!$calendar.length) return

      this.fetchCalendar($calendar, `${this.path}?start_date=${date[0]}`)
    }
  }
}
</script>
