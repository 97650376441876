<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="form-group">
    <div class="row">
      <div class="col-lg-6 col-sm-12">
        <div class="form-group row">
          <div class="col-lg-2">
            <label class="text-lg-right" for="post_summary_source">Summary</label>
          </div>
          <div class="col-lg-10">
            <textarea class="form-control"
              rows="15"
              name="post_summary_source"
              :value="object.summarySource"
              @input="updateSummarySource($event.target.value)"
              ref="summarySource" />
            <small class="float-right">
              {{ summarySourceLength }}
            </small>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-sm-12">
        <div class="row">
          <div class="col-lg-10 offset-lg-1" v-if="showPreview">
            <div role="alert" class="alert alert-danger" v-if="previewError">
              <div class="message">
                {{ previewError }}
              </div>
            </div>

            <div v-else>
              <div class="summary-preview" v-html="object.summary.replace(/\n/g, '<br/>')" />
              <span class="float-right">
                <small>
                  {{ object.summary.length }} symbol{{ object.summary.length === 1 ? '' : 's' }}
                </small>

                <i class="far fa-info-circle fa-fw"
                  aria-hidden="true"
                  data-container="body"
                  data-toggle="popover"
                  data-placement="bottom"
                  data-content="Can be different due to chaining posts or other Spintax/Liquid variables." />
              </span>
            </div>
          </div>

          <div class="col-lg-10 offset-lg-1" v-else>
            <div role="alert" class="alert alert-warning">
              <div class="message">
                Summary preview will not work until you choose at least one location from select
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-4 offset-sm-1 col-lg-6">
        <button name="button" type="button" class="btn btn-secondary" @click="previewSummary(true)">Regenerate Spintax</button>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-12 col-sm-4 offset-sm-1 col-lg-6">
        <button
          name="button"
          type="button"
          class="btn btn-secondary"
          @click="insertDrop('{{ previous_post_search_url }}')">
          Insert Previous Post SERP URL
        </button>
      </div>
    </div>
  </div>
</template>
<!-- eslint-enable vue/no-v-html -->

<script>
import {
  mapState, mapGetters, mapActions, mapMutations
} from 'vuex'

export default {
  computed: {
    ...mapState(['object', 'multilocation', 'entityType', 'entity', 'previewError']),
    ...mapGetters(['selectedSource']),
    showPreview() {
      if (!this.multilocation) return true
      return this.selectedSource
    },
    summarySourceLength() {
      const { length } = this.object.summarySource.replace(/\r\n/g, '\n')
      return `${length} symbol${length === 1 ? '' : 's'}`
    }
  },
  methods: {
    ...mapActions(['previewSummary']),
    ...mapMutations(['insertDrop', 'updateSummarySource'])
  },
  watch: {
    'object.summarySource'(_) {
      this.$refs.summarySource.style.height = `${this.$refs.summarySource.scrollHeight + 4}px`
      this.previewSummary(false)
    }
  },
  mounted() {
    $('[data-toggle="popover"]').popover({ trigger: 'hover' })
    this.$refs.summarySource.style.height = `${this.$refs.summarySource.scrollHeight + 18}px`
  }
}
</script>
