<template>
  <li class="list-group-item">
    <h4 class="d-inline-block mb-0">
      {{ displayedName }}
      <small>
        <a v-if="!formShown" href="#" @click.prevent="onToggleForm(true)">
          <i class="far fa-pencil" />
        </a>
      </small>
    </h4>

    <form action="/" @submit.prevent="onUpdateItem" v-if="formShown" class="mt-3">
      <fieldset :disabled="isUpdating || isDeleting">
        <div class="form-group col-6">
          <select class="col-6 form-control" v-model="priceOption" style="height: 52px">
            <option>Price Options</option>
            <option v-for="(text, value) in priceOptions" :value="value">{{ text }}</option>
          </select>

          <input type="number" class="col-5 form-control float-right" style="height: 52px"
            :disabled="disabledPrice"
            v-model="price">
        </div>

        <div class="form-group col-6">
          <textarea name="description" class="form-control" cols="30"
            rows="10"
            placeholder="Description"
            v-model="description" />
          <small class="text-muted float-right">{{ description.length }} / 300</small>
        </div>

        <div class="form-group col-6">
          <button type="submit" class="btn btn-primary" :disabled="isDescriptionOversized">
            <i v-if="isUpdating" class="far fa-spin fa-spinner" />
            <template v-else>Save</template>
          </button>
          <button class="btn btn-link" @click="onToggleForm(false)" type="button">Cancel</button>
          <button class="btn btn-danger float-right" @click="onRemoveItem" type="button">
            <i v-if="isDeleting" class="far fa-spin fa-spinner" />
            <template v-else>Remove</template>
          </button>
        </div>
      </fieldset>
    </form>

    <div v-else>
      <p class="mb-0 mt-2" v-if="priceWithCurrency">{{ priceWithCurrency }}</p>
      <p class="mb-0">{{ description }}</p>
    </div>
  </li>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { runSwal } from 'common/delete_with_swal'

const EDITABLE_PRICE_OPTIONS = ['hasPrice']
const STRUCTURED_DESCRIPTION_MAX_LENGTH = 300

function resolvePriceOption() {
  if (this.serviceItem.price) {
    return this.serviceItem.price.units ? 'hasPrice' : 'free'
  }

  return null
}

function resolveDescription() {
  if (this.serviceItem.freeFormServiceItem) {
    return this.serviceItem.freeFormServiceItem.label.description || ''
  } if (this.serviceItem.structuredServiceItem) {
    return this.serviceItem.structuredServiceItem.description || ''
  }

  return ''
}

function resolvePrice() {
  return this.serviceItem.price ? this.serviceItem.price.units : null
}

export default {
  props: {
    serviceItem: { type: Object, required: true }
  },
  data() {
    return {
      formShown: false,
      priceOption: null,
      description: '',
      price: null,
      isUpdating: false,
      isDeleting: false
    }
  },
  computed: {
    ...mapGetters(['serviceItemsMap']),
    disabledPrice() {
      return !EDITABLE_PRICE_OPTIONS.includes(this.priceOption)
    },
    displayedName() {
      if (this.serviceItem.structuredServiceItem) {
        return this.serviceItemsMap[this.serviceItem.structuredServiceItem.serviceTypeId] || this.serviceItem.structuredServiceItem.serviceTypeId
      }
      return this.serviceItem.freeFormServiceItem.label.displayName
    },
    priceWithCurrency() {
      if (this.serviceItem.price) {
        if (this.serviceItem.price.units) {
          return this.serviceItem.price.units + (this.serviceItem.price.currencyCode || '')
        }
        return 'Free'
      }

      return ''
    },
    isDescriptionOversized() {
      return STRUCTURED_DESCRIPTION_MAX_LENGTH < this.description.length
    }
  },
  methods: {
    ...mapActions(['removeItem', 'updateItem']),
    onUpdateItem() {
      const data = { item: this.serviceItem, description: this.description }

      const currencyCode = this.serviceItem.price && this.serviceItem.price.currencyCode
      if (this.priceOption === 'hasPrice') {
        data.price = { units: this.price }
        if (currencyCode) data.price.currencyCode = currencyCode
      } else if (currencyCode) {
        data.price = { currencyCode }
      }

      this.isUpdating = true
      this.updateItem(data)
        .then(() => {
          toastr.success('Service Item successfully updated!')
          this.onToggleForm(false)
        })
    },
    onRemoveItem() {
      runSwal(() => {
        this.isDeleting = true
        this.removeItem(this.serviceItem)
          .then(() => {
            toastr.success('Service Item successfully removed!')
            this.onToggleForm(false)
          })
      })
    },
    onToggleForm(bool) {
      this.formShown = bool
      this.isDeleting = false
      this.isUpdating = false
      this.price = resolvePrice.call(this)
      this.priceOption = resolvePriceOption.call(this)
      this.description = resolveDescription.call(this)
    }
  },
  created() {
    this.priceOptions = {
      noPrice: 'No price',
      free: 'Free',
      hasPrice: 'Has Price'
    }

    this.onToggleForm(false)
  }
}
</script>
