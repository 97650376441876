<template>
  <tr class="js-account-item" :data-account_id="row.id">
    <td>
      <div class="project-item-user">
        <div class="user-avatar">
          <AppImage :src="row.picture" :fallback-src="require('no_data/avatar.png')" />
        </div>
        <div class="user-info">
          <div>
            <div class="name">
              {{ row.name }}
              <a target="_blank" class="m-l-1" :href="rssLink">
                <i class="far fa-rss" aria-hidden="true" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </td>

    <td>
      <a :href="accLink">{{ row.email }}</a>
    </td>

    <td>
      <span data-toggle="tooltip" data-placement="top" title="Enabled locations" ref="tooltip1">
        {{ row.locations.enabled }}
      </span> /
      <span data-toggle="tooltip" data-placement="top" title="All locations" ref="tooltip2">
        {{ row.locations.all }}
      </span>
    </td>

    <td>
      <span>
        {{ row.status }}
        <i class="far fa-exclamation-triangle" v-if="row.errors.length > 0"
          data-toggle="popover" ref="popover1" data-placement="bottom"
          data-html="true"
          tabindex="1"
          data-container="body" :data-content='row.errors.join("\n")' />
      </span>
    </td>

    <td>
      <div class="btn-group btn-group-sm btn-group--width-auto d-flex justify-content-end">
        <div v-if="row.syncInProgress" class="btn btn-secondary pointer-events-none">
          <div class="js-spinner">
            <i class="far fa-spinner fa-pulse" />
          </div>
        </div>

        <a v-if="row.status === 'Reconnect required' || row.status === 'Disconnected'"
          class="btn btn-secondary" :href='"/google_accounts/new?acc_id=" + row.id'>
          Reconnect
        </a>

        <a v-if="row.status === 'Connected'" class="btn btn-secondary"
          :class="{'pointer-events-none': row.syncInProgress}"
          rel="nofollow"
          data-method="patch"
          :href="accLink + 'sync'">
          {{ row.syncInProgress ? 'Syncing' : 'Sync' }}
        </a>

        <a class="btn btn-secondary btn--rect" :href="accLink + 'edit'">
          <i class="far fa-edit" aria-hidden="true" />
        </a>

        <a class="js-delete-action btn btn-secondary btn--rect" :href="accLink">
          <i class="far fa-trash" aria-hidden="true" />
        </a>
      </div>
    </td>
  </tr>
</template>

<script>
import AppImage from 'vue_widgets/components/image'

export default {
  props: {
    row: { type: Object, required: true }
  },
  components: { AppImage },
  created() {
    this.rssLink = `/sy/google_accounts/${this.row.id}/local_posts.rss`
    this.accLink = `/google_accounts/${this.row.id}/`
  },
  mounted() {
    $(this.$refs.tooltip1).tooltip()
    $(this.$refs.tooltip2).tooltip()
    $(this.$refs.popover1).popover({ trigger: 'focus', html: true })
  }
}
</script>
