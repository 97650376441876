function initForm() {
  $(() => {
    $('input[type="radio"]').on('click change', (event) => {
      const radio = event.currentTarget
      if (radio.value === 'solo') {
        $('.js-registration-form').hide()
        $('.js-non-agency-banner').show()
      } else {
        $('.js-registration-form').show()
        $('.js-non-agency-banner').hide()
      }
    })
  })
}

Styxie.Initializers.UsersRegistrations = {
  new: initForm,
  create: initForm
}
