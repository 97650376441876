<template>
  <div>
    <SlindingPanel :show-panel="showPanel" @closeSlidingPanel="toggleSlidePannel(false)" position="right add-google-place"
      @shown="onPanelShown"
      @beforeHide="clearMapMarkers">
      <div class="map" ref="map" />
      <div class="form">
        <h4>Add new location</h4>

        <div class="tabs new-location">
          <div class="d-flex align-items-stretch w-100">
            <input v-model='currentTab' type="radio" name="payment-tab"
              id="regular-business"
              value="RegularTab">
            <label for="regular-business">By business name</label>
            <input v-model='currentTab' type="radio" name="payment-tab"
              id="sab-business"
              value="SabTab">
            <label for="sab-business">By keyword</label>
          </div>
          <span class='hint'><strong> Please note! </strong> Service Area Businesses can be found only by keyword.</span>
        </div>
        <component :is="currentTab" @placesChanged="placesChanged" :googe-session-token="googeSessionToken" />
      </div>
    </SlindingPanel>
  </div>
</template>

<script>
import SlindingPanel from 'vue_widgets/components/sliding_panel'

import { ensureGoogleMap } from 'common/map_helpers'
import SabTab from './add_google_place/sab_tab'
import RegularTab from './add_google_place/regular_tab'

const MAP_MAX_ZOOM = 18
function getPlaceIcon({ selected }) {
  return `http://maps.google.com/mapfiles/ms/icons/${selected ? 'green' : 'red'}-dot.png`
}

export default {
  components: { SlindingPanel, SabTab, RegularTab },
  props: {
    mapsKey: String
  },
  data() {
    return {
      showPanel: false,
      currentTab: 'RegularTab',
      googeSessionToken: null,
      markers: []
    }
  },
  provide() {
    return {
      toggleSlidePannel: this.toggleSlidePannel
    }
  },
  methods: {
    toggleSlidePannel(reloadTable) {
      this.showPanel = !this.showPanel
      if (reloadTable) this.$emit('reload-table')
    },
    onPanelShown() {
      if (this.googeSessionToken) {
        this.map = new google.maps.Map(this.$refs.map, {
          center: { lat: 30, lng: -30 },
          zoom: 3,
          disableDefaultUI: true,
          zoomControl: true,
          scaleControl: true
        })
      }
    },
    placesChanged(places) {
      if (!this.map) return

      this.clearMapMarkers()

      const bounds = new google.maps.LatLngBounds()
      places.forEach((place) => {
        if (place.lng) {
          const marker = new google.maps.Marker({
            map: this.map,
            icon: getPlaceIcon(place),
            position: { lat: place.lat, lng: place.lng }
          })
          this.markers.push(marker)
          bounds.extend(marker.getPosition())
        }
      })

      if (this.markers.length) {
        this.map.fitBounds(bounds)
        if (this.map.getZoom() > MAP_MAX_ZOOM) this.map.setZoom(MAP_MAX_ZOOM)
      }
    },
    clearMapMarkers() {
      if (this.markers.length) {
        this.map.setZoom(3)
        this.map.setCenter({ lat: 30, lng: -30 })
      }
      this.markers.forEach((marker) => marker.setMap(null))
      this.markers = []
    }
  },
  watch: {
    currentTab: 'clearMapMarkers'
  },
  mounted() {
    ensureGoogleMap({ googleMapApiKey: this.mapsKey }, () => {
      this.googeSessionToken = new google.maps.places.AutocompleteSessionToken()
      this.map = null
    })
  }
}
</script>
