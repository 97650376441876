<template>
  <div class="panel-body">
    <form @submit.prevent="onSubmit">
      <fieldset>
        <div class="form-group row mx-0">
          <label class="col-form-label ml-3">EXIF Template Title</label>
          <div class="col-sm-8 col-lg-4">
            <input type="text" class="form-control" v-model="templateTitle" required>
          </div>
        </div>
        <div class="row mt-4 advenced-exifs">
          <div class="col-md-7">
            <AdvancedMain />
            <AdvancedGps />
            <AdvancedDate />
          </div>
          <div class="col-md-5 pl-0">
            <AdvancedPreview />
            <div class="pl-3" v-if="mapUrl">
              <a :href="mapUrl" target="_blank">Show on google map</a>
            </div>
            <AdvancedAdvanced />
          </div>
        </div>
      </fieldset>
      <div class="form-group">
        <input type="submit" name="commit" value="Submit" class="btn btn-space btn-primary">
        <a href="/exif_templates" class="ml-2">Cancel</a>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import AdvancedMain from '../../components/image_select/steps/advanced/main'
import AdvancedGps from '../../components/image_select/steps/advanced/gps'
import AdvancedDate from '../../components/image_select/steps/advanced/date'
import AdvancedPreview from '../../components/image_select/steps/advanced/preview'
import AdvancedAdvanced from '../../components/image_select/steps/advanced/advanced'

const MAX_LAT = 90
const MAX_LON = 180
function isValidCoord(str, max) {
  const coord = parseFloat(str, 10)
  return !Number.isNaN(coord) && coord.toString() === str && coord <= max && coord >= -max
}

export default {
  computed: {
    ...mapState(['imagePreviewExifs']),
    templateTitle: {
      get() { return this.$store.state.exifTemplate.title },
      set(val) { this.$store.commit('updateTemplateTitle', val) }
    },
    mapUrl() {
      const { latitude, longitude } = this.imagePreviewExifs
      if (isValidCoord(latitude, MAX_LAT) && isValidCoord(longitude, MAX_LON)) {
        return `https://www.google.com/maps/place/${latitude}+${longitude}`
      }
      return false
    }
  },
  methods: mapActions(['fetchPreviewExifs', 'onSubmit']),
  components: {
    AdvancedMain, AdvancedGps, AdvancedDate, AdvancedPreview, AdvancedAdvanced
  },
  mounted() {
    this.fetchPreviewExifs()
  }
}
</script>
