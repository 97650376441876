<template>
  <div>
    <LocationSelect
      class="w-57 mb-4"
      v-model="filters.location"
      :locations="locations"
      @input="filters.location = $event" />
    <div class="d-flex align-items-end gap-6">
      <CustomSelect
        v-model="filters.rating"
        class="w-35"
        label="Rating"
        placeholder="All"
        :allow-empty="true"
        :values="ratingOptions" />
      <CustomSelect
        v-model="filters.period"
        class="w-35"
        label="Period"
        placeholder="All"
        :allow-empty="true"
        :values="periodOptions" />
      <CustomSelect
        v-model="filters.response"
        class="w-35"
        label="Response"
        placeholder="Any"
        :allow-empty="true"
        :values="responseOptions" />
      <button class="btn btn-pretender px-0 border-0 gap-2 w-15"
        :class="{ 'text-muted': !anyFiltersSelected }"
        :disabled="!anyFiltersSelected"
        @click="clearFilters">
        <i class="far fa-eraser" aria-hidden="true" />
        Clear filters
      </button>
    </div>
  </div>
</template>
<script>
import CustomSelect from 'vue_widgets/components/custom_select'
import LocationSelect from './location_select'

export default {
  components: {
    CustomSelect,
    LocationSelect
  },
  props: {
    value: { type: Object, required: true },
    locations: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    filters: {
      response: '',
      rating: '',
      period: '',
      location: ''
    }
  }),
  created() {
    const ALL = { text: 'All', value: '' }
    this.ratingOptions = [
      ALL,
      { text: '5 star only', value: 5 },
      { text: '4 star only', value: 4 },
      { text: '3 star only', value: 3 },
      { text: '2 star only', value: 2 },
      { text: '1 star only', value: 1 }
    ]
    this.responseOptions = [
      ALL,
      { text: 'With response', value: 'true' },
      { text: 'No response', value: 'false' }
    ]
    this.periodOptions = [
      ALL,
      { text: 'Month', value: 30 },
      { text: 'Three months', value: 90 },
      { text: 'Six months', value: 180 },
      { text: 'One year', value: 365 }
    ]
  },
  computed: {
    anyFiltersSelected() {
      return Object.keys(this.filters).find((filterName) => this.filters[filterName])
    }
  },
  methods: {
    clearFilters() {
      this.filters = Object.keys(this.filters).reduce((mem, key) => {
        mem[key] = ''
        return mem
      }, {})
    }
  },
  watch: {
    filters: {
      handler() {
        this.$emit('input', this.filters)
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
