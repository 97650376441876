<template>
  <a v-if="(newImageRelativeUrl || geogrid.imagePublicPath)"
    :href='`${newImageRelativeUrl || geogrid.imagePublicPath}`'
    :class='classes'
    :id='`${geogrid.obfuscatedId}-get-image`'
    target='_blank'
    @click='showSpinner'>
    <span class='wrap-icon'><i class='far fa-file-image' /></span>
    <span class='text' v-if='withText'>Open PNG</span>
  </a>
  <a v-else
    href='#'
    :class='classes'
    data-turbolinks='false'
    @click.prevent='generateImage'>
    <span class='wrap-icon'>
      <i :class="{'far fa-file-image': !imageGenerating, 'far fa-spinner fa-pulse': imageGenerating }" />
    </span>
    <span class='text' v-if='withText'>{{ imageGenerating ? 'PNG in progress' : 'Generate PNG' }}</span>
  </a>
</template>

<script>

import toastr from 'toastr'
import { DEFAULT_ERROR_MESSAGE } from '../../../common/constants'

export default {
  name: 'ButtonGetGeogridImage',
  props: {
    classes: String,
    withText: Boolean,
    geogrid: Object
  },
  data() {
    return {
      newImageRelativeUrl: '',
      imageGenerating: false
    }
  },
  watch: {
    geogrid() {
      this.imageGenerating = false
      this.newImageRelativeUrl = ''
    }
  },
  methods: {
    showSpinner() {
      this.imageGenerating = true
      setTimeout(() => {
        this.imageGenerating = false
      }, 500)
    },
    generateImage() {
      if (!this.geogrid.obfuscatedId) return
      this.imageGenerating = true
      axios.post(this.geogrid.generateImagePath).then((response) => {
        if (response.data.error === 'image in progress') {
          toastr.error('Image will be downloaded as soon as generation is finished', 'PNG generation in progress')
        } else if (response.data.error === 'image already created') {
          this.newImageRelativeUrl = response.data.url
          setTimeout(() => {
            this.imageGenerating = false
            $(`#${this.geogrid.obfuscatedId}-get-image`)[0].click()
          }, 500)
        }
      })
        .catch((_error) => {
          toastr.error(DEFAULT_ERROR_MESSAGE)
        })
    }
  }
}
</script>
