<template>
  <div>
    <div class="review-switch">
      <a href="#" :class="{ active: showDeleted === false }" @click.prevent="() => { showDeleted = false }">
        {{ live }} Live reviews
      </a>
      <span> / </span>
      <a href="#" :class="{ active: showDeleted === true }" @click.prevent="() => { showDeleted = true }">
        {{ deleted }} Deleted reviews
      </a>
    </div>
    <div class="panel panel-default">
      <div class="panel-body">
        <div v-if="isLoading || reviewsList.length" :class="['pb-6', { 'pointer-events-none opacity-50': isLoading }]">
          <ul class="timeline timeline-reversed">
            <li v-for="review in reviewsList" :key="review.remote_id" class="timeline-item">
              <reviewItem :api-connection="apiConnection" :review="review" tag="li" />
            </li>
          </ul>
          <div class="text-center">
            <button v-if="showMore" class="btn btn-primary" @click="fetchReviews">Show more</button>
            <h3 v-else-if="reviewsList.length" class="text-center text-muted mt-3">No more reviews</h3>
          </div>
        </div>
        <div v-else class='empty-state text-center text-muted mt-6 mb-6'>
          <img :src="noReviews" alt="No Reviews Img" height=100>
          <h3 class='mt-4 mb-4'>This location doesn't have any reviews yet.</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import noReviews from 'no_data/reviews.svg'
import axios from 'axios'
import { DEFAULT_ERROR_MESSAGE } from 'common/constants'
import reviewItem from './item'

export default {
  components: { reviewItem },
  props: {
    live: { type: Number, default: 0 },
    deleted: { type: Number, default: 0 },
    apiReviewsPath: { type: String, required: true },
    apiConnection: { type: Boolean, required: true }
  },
  data() {
    return {
      noReviews: noReviews,
      page: 1,
      reviewsList: [],
      showMore: true,
      showDeleted: false,
      isLoading: true
    }
  },
  methods: {
    fetchReviews() {
      this.isLoading = true

      axios.get(this.apiReviewsPath, {
        params: {
          page: this.page,
          deleted: this.showDeleted
        }
      }).then((res) => {
        if (this.page === 1) {
          this.reviewsList = []
        }
        this.page += 1
        if (res.data.length < 10) {
          this.showMore = false
        }

        this.reviewsList = this.reviewsList.concat(res.data)
      }).catch(() => {
        toastr.error(DEFAULT_ERROR_MESSAGE)
      }).finally(() => {
        this.isLoading = false
      })
    }
  },
  watch: {
    showDeleted: {
      immediate: true,
      handler() {
        this.page = 1
        this.fetchReviews()
      }
    }
  }
}
</script>
