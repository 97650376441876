import toastr from 'toastr'
import Vue from 'vue'
import axios from 'axios'
import { DEFAULT_ERROR_MESSAGE } from 'common/constants'

import TagsSelect from '../vue_widgets/gmb/locations/tags_select'
import NoteChange from '../vue_widgets/gmb/locations/note'
import { runSwal } from '../common/delete_with_swal'
import VueInsights from '../vue_widgets/gmb/locations/insights/index'
import createInsightsStore from '../vue_widgets/gmb/locations/insights/store_insights'
import VueArchiveInsights from '../vue_widgets/gmb/locations/archive_insights/index'
import createArchiveInsightsStore from '../vue_widgets/gmb/locations/archive_insights/store_insights'
import { App } from '../common/rails'
import VueLocationsIndex from '../vue_widgets/gmb/locations/index'
import Calendar from '../vue_widgets/gmb/locations/calendar'
import Coords from '../vue_widgets/gmb/locations/coords'
import SearchImpressions from '../vue_widgets/gmb/locations/search_impressions'

function initCalendar(params) {
  /* eslint-disable no-new */
  new Vue({
    el: document.getElementById('js-calendar-container'),
    render: (h) => h(Calendar, { props: { apiOrgLocationPath: params.apiOrgLocationPath } })
  })
  /* eslint-disable no-new */
}

function toggleLocationState({ apiOrgLocationPath }) {
  const ajaxSettings = {
    checked: {
      successMessage: 'Location was enabled.',
      url: `${apiOrgLocationPath}/enable`
    },
    unchecked: {
      successMessage: 'Location was disabled.',
      url: `${apiOrgLocationPath}/disable`
    },
    errorMessage: 'Something went wrong! Please try again later.'
  }

  $('.js-gmb-location-enabled-checkbox').on('change', (e) => {
    const $target = $(e.currentTarget)
    const state = $target.prop('checked') ? 'checked' : 'unchecked'
    const param = ajaxSettings[state]
    $target.prop('disabled', true)
    $('.alert-info').toggleClass('d-none')
    $('.nav-tabs').toggleClass('disabled-tabs')

    $.ajax2({
      method: 'POST',
      url: param.url,
      error: (res) => {
        const message = (res.responseJSON && res.responseJSON.text) || ajaxSettings.errorMessage
        toastr.error(message)
        $target.prop('checked', !$target.prop('checked'))
      },
      success: () => {
        toastr.success(param.successMessage)
      },
      complete: () => {
        $target.prop('disabled', false)
      }
    })
  })
}

// TODO: refactor this functions
function initServiceAreaList() {
  $('.readmore-toggle').click((e) => {
    e.preventDefault()

    const $linkEl = $(e.currentTarget)
    const $textWrapperEl = $linkEl.siblings('.readmore')

    $textWrapperEl.toggleClass('readmore-cropped')
    if ($textWrapperEl.hasClass('readmore-cropped')) {
      $linkEl.text($linkEl.data().showAllText)
    } else {
      $linkEl.text($linkEl.data().hideText)
    }
  })
}

function initDeleteLink() {
  const $link = $('#js-location-delete-link')
  const onClickHandler = () => {
    runSwal(() => {
      $link.off('click', onClickHandler)
      $link.get(0).click()
    })
    return false
  }
  $link.click(onClickHandler)
}

function initSyncButton(id) {
  $('.js-sync-orgloc').on('click', (e) => {
    e.preventDefault()
    axios.patch(`/api/organizations_locations/${id}/sync/`).then((resp) => {
      $(e.currentTarget.parentElement).text('Location is syncing')
      toastr.success(resp.data.message)
    }).catch(() => {
      toastr.error(DEFAULT_ERROR_MESSAGE)
    })
  })
}

function initMapLoading() {
  const map = document.querySelector('.js-map')
  if (!map) return
  map.addEventListener('error', () => {
    const parentMap = map.parentElement
    const msg = map.getAttribute('data-error-message')
    map.remove()
    parentMap.innerHTML = `${`${'<div class="map error">' +
      '<i class="far fa-map-marked-alt"></i>' +
      '<span>'}${msg}</span>` +
      '</div>'}${parentMap.innerHTML}`
  })
}

function bindSyncChannel(sourceId, source) {
  const sub = App.cable.subscriptions.create({ channel: 'OrganizationsLocationChannel', id: sourceId, source: source }, {
    received: ({ success, id, name }) => {
      if (success) {
        $(document).one('turbolinks:load', () => toastr.success('Location successfully synced.'))
        Turbolinks.visit(window.location.pathname)
      } else if (source === 'gp') {
        if (id) {
          toastr.error(`This location is a duplicate, manual sync was turned on for <u><a href="${id}">${name}</a></u>`, '', { timeOut: 0 })
        } else {
          $(document).one('turbolinks:load', () => toastr.error('Location is no longer persisted in Google. Manual sync is not available.'))
          Turbolinks.visit(window.location)
        }
      } else {
        toastr.error(DEFAULT_ERROR_MESSAGE)
      }
    }
  })

  $(document).one('turbolinks:visit', () => {
    App.cable.subscriptions.remove(sub)
  })
}

const initCoordsComponent = ({
  googleMapApiKey, coords, userFormattedAddress, apiOrgLocationPath, hasLocations, newGoogleAccountPath
}) => {
  /* eslint-disable no-new */
  new Vue({
    el: document.getElementById('js-location-coords'),
    render: (h) => h(Coords, {
      props: {
        googleMapApiKey, coords, userFormattedAddress, apiOrgLocationPath, hasLocations, newGoogleAccountPath
      }
    })
  })
  /* eslint-disable no-new */
}

Styxie.Initializers.OrganizationsLocations = {
  index: (props) => {
    $(() => {
      /* eslint-disable no-new */
      new Vue({
        el: document.getElementById('js-locations'),
        render: (h) => h(VueLocationsIndex, { props })
      })
      /* eslint-disable no-new */
    })
  },
  show: (params) => {
    $(() => {
      if (params.syncSource !== 'no') bindSyncChannel(params.syncSource === 'gmb' ? params.remoteId : params.id, params.syncSource)
      if (params.enabled) {
        initCalendar(params)
        initServiceAreaList()
        initDeleteLink()
        initSyncButton(params.id)
        initMapLoading()
        initCoordsComponent(params)

        /* eslint-disable no-new */
        if (params.isGmbLocation) {
          new Vue({
            el: document.getElementById('vue-insights'),
            store: createInsightsStore(params.insightsParams),
            render: (h) => h(VueInsights, { props: { locationId: params.id } })
          })
        }

        if (params.isSearchImpressions) {
          new Vue({
            el: document.getElementById('vue-search-impressions'),
            render: (h) => h(SearchImpressions, { props: { locationId: params.id } })
          })
        }

        $('.js-turn-on-manuall-sync').on('click', (e) => {
          e.preventDefault()
          axios.post(`/api/organizations_locations/${params.id}/turn_on_manual_sync`).then(() => {
            toastr.success('Location manual sync started.')
          }).catch(() => {
            toastr.error(DEFAULT_ERROR_MESSAGE)
          })
        })

        const $note = document.getElementById('js-note-change')
        new Vue({
          el: $note,
          render: (h) => h(NoteChange, {
            props: {
              noteText: $note.dataset.note,
              apiOrgLocationPath: params.apiOrgLocationPath
            }
          })
        })

        const $tags = document.getElementById('js-tags-select')

        new Vue({
          el: $tags,
          render: (h) => h(TagsSelect, {
            props: {
              selectedTags: JSON.parse($tags.dataset.selectedTags),
              tags: JSON.parse($tags.dataset.tags),
              apiOrgLocationPath: params.apiOrgLocationPath
            }
          })
        })
        /* eslint-enable no-new */
      } else {
        toggleLocationState(params)
      }
    })
  },
  archive: (params) => {
    $(() => {
      if (params.enabled) {
        initDeleteLink()

        /* eslint-disable no-new */
        if (params.isGmbLocation) {
          new Vue({
            el: document.getElementById('vue-insights'),
            store: createArchiveInsightsStore(params.insightsParams),
            render: (h) => h(VueArchiveInsights, { props: { locationId: params.id } })
          })
        }
        /* eslint-enable no-new */
      } else {
        toggleLocationState(params)
        if (params.syncSource !== 'no') bindSyncChannel(params.syncSource === 'gmb' ? params.remoteId : params.id, params.syncSource)
      }
    })
  }
}
