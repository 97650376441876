<template>
  <div v-if="showTags" class="form-field mb-5">
    <span>Location tags</span>
    <CustomSelect :option-data="field" v-model="selectedTags" />
    <div class="tags-selector-taglist">
      <div v-for="(tag, index) in selectedTags" :key="index" class="tags-selector-taglist-item">
        {{ tag }}
        <span class="icon" @click="removeTag(tag)">
          <i class="far fa-times" />
        </span>
      </div>
    </div>
    <select class="d-none" name="report_configuration[location_tags][]" multiple="true" v-model="selectedTags">
      <option v-for="(tag, index) in tags" :key="index" :value="tag" :selected="{ selected: selectedTags.includes(tag) }">{{ tag }}</option>
    </select>
  </div>
</template>

<script>
import CustomSelect from 'vue_widgets/components/filter/select'

export default {
  components: { CustomSelect },
  props: {
    initialTags: { type: Array, default: () => []},
    tags: { type: Array, default: () => []}
  },
  data() {
    return {
      selectedTags: this.initialTags,

      field: {
        searchable: true,
        internalSearch: true,
        showLabels: false,
        allowEmpty: true,
        maxHeight: 540,
        multiple: true,
        placeholder: '',
        options: this.tags
      }
    }
  },
  methods: {
    removeTag(tag) {
      this.selectedTags.splice(this.selectedTags.findIndex((st) => st === tag), 1)
    }
  },
  computed: {
    showTags() {
      return this.selectedTags.length !== 0
    }
  }
}
</script>
