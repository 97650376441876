<template>
  <div>
    <div class="d-flex justify-content-end mb-3">
      <button v-if="showNewWebhookButton" class="btn btn-primary" @click="$emit('newWebhook')">
        <i class="fa-regular fa-plus-circle" />
        <span class="pl-2">Add new webhook</span>
      </button>
    </div>
    <VueSlimTable
      ref="table"
      class="table"
      :columns="columns"
      :source="fetchWebhooks">
      <template #row="{ row }">
        <tr>
          <td>
            {{ row.url }}
          </td>
          <td>
            {{ getFormattedEvents(row.events) }}
          </td>
          <td>
            <button class="btn btn-pretender text-primary" @click="editWebhook(row)">
              <i class="fa-regular fa-pen" />
            </button>
            <button class="btn btn-pretender text-danger" @click="deleteWebhook(row.id)">
              <i class="fa-regular fa-trash" />
            </button>
          </td>
        </tr>
        <tr v-if="editableWebhook?.id === row.id">
          <td colspan="3">
            <WebhooksEdit
              :webhook="editableWebhook"
              :webhooks-api-url="webhooksApiUrl"
              @save="onWebhookSave"
              @close="editableWebhook = null" />
          </td>
        </tr>
      </template>
    </VueSlimTable>
  </div>
</template>

<script>

import axios from 'axios'
import toastr from 'toastr'
import { runSwal } from 'common/delete_with_swal'

import {
  mappings as eventMappings
} from './webhook_events'

import WebhooksEdit from './edit'

export default {
  components: {
    WebhooksEdit
  },
  props: {
    webhooksApiUrl: { type: String, required: true },
    showNewWebhookButton: { type: Boolean, required: false }
  },
  data: () => ({
    editableWebhook: null
  }),
  created() {
    this.columns = [
      {
        key: 'url',
        title: 'URL'
      },
      {
        key: 'events',
        title: 'Events'
      },
      {
        key: 'actions',
        title: 'Actions'
      }
    ]
  },
  methods: {
    async fetchWebhooks() {
      return axios({
        method: 'get',
        url: this.webhooksApiUrl
      })
        .then((response) => response.data)
    },
    refetchTable() {
      clearTimeout(this.filterTimeout)

      this.filterTimeout = setTimeout(() => {
        this.$refs.table.refetch()
      }, 500)
    },
    deleteWebhook(id) {
      runSwal(() => {
        axios
          .delete(`${this.webhooksApiUrl}/${id}`)
          .then(() => {
            this.refetchTable()
            setTimeout(() => {
              toastr.success('Webhook deleted successfully')
            }, 500)
          })
      })
    },
    getFormattedEvents(events) {
      return events.map((eventKey) => eventMappings[eventKey]).join(', ')
    },
    editWebhook(editableWebhook) {
      const currentWebhookId = this.editableWebhook?.id
      this.editableWebhook = null
      if (editableWebhook.id === currentWebhookId) {
        return
      }

      this.$nextTick(() => {
        this.editableWebhook = editableWebhook
      })
    },
    onWebhookSave() {
      this.$refs.table.refetch()
      this.editableWebhook = null
    }
  }
}
</script>
