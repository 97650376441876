<template>
  <div class="form-group row mb-2">
    <label class="col-12 col-sm-3 col-form-label text-sm-right" for="gmb_media_category">Fallback to "Additional" Category</label>
    <div class="col-12 col-sm-8 col-lg-6 pt-2">
      <div class="form-group">
        <div class="switch-button switch-button-yesno">
          <input type="checkbox" name="fallbackCategory" id="fallbackCategory"
            :checked="value"
            @change="onChange">
          <span>
            <label for="fallbackCategory" />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['value'],
  methods: {
    onChange(e) {
      this.$emit('input', e.currentTarget.checked)
    }
  }
}
</script>
