import Vue from 'vue'
import AuditDescription from 'vue_widgets/audit_reports/blanks/audit_description'
import AuditReportShow from 'vue_widgets/audit_reports/report'

export default function initAuditReportsShow(props, isPublic = false) {
  $(() => {
    Vue.component('AuditDescription', AuditDescription)

    new Vue({ // eslint-disable-line no-new
      el: document.getElementById('vue-audit-report-show'),
      render: (h) => h(AuditReportShow, { props: { ...props, isPublic } })
    })
  })
}
