<template>
  <div class="onboarding">
    <AppForm :wlc="wlc" :csrf="csrf" @domain-changed="domainChanged" />
    <AppContent :domain="domain" />
  </div>
</template>

<script>
import AppForm from './index/form'
import AppContent from './index/content'

export default {
  components: { AppForm, AppContent },
  props: {
    wlc: { type: Object },
    csrf: { type: String, required: true }
  },
  data() {
    return {
      domain: this.wlc?.domain
    }
  },
  methods: {
    domainChanged(domain) {
      this.domain = domain
    }
  }
}
</script>
