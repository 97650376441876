import Vuex from 'vuex'
import axios from 'axios'
import toastr from 'toastr'

import { DEFAULT_ERROR_MESSAGE } from '../../../common/constants'

import { BLANK_EXIFS, RECOMMENDED_EXIFS } from '../../components/image_select/store'

export default function createStore(prms) {
  const store = new Vuex.Store({
    state: {
      ...prms,
      imageCurrentExifs: prms.exifTemplate.id ? prms.exifTemplate.exifs : { ...RECOMMENDED_EXIFS },
      imagePreviewExifs: { ...BLANK_EXIFS },
      imagePreviewErrors: {}
    },
    getters: {
      object(state) { return state.exifTemplate },
      selectedSource() {
        return true
      }
    },
    actions: {
      updateExifs({ commit }, exifs) {
        commit('updateExifs', exifs)
      },
      changeCurrentExif({ dispatch, commit }, { identifier, value }) {
        commit('changeCurrentExif', { identifier, value })
        dispatch('fetchPreviewExifs')
      },
      fetchPreviewExifs({ commit, state }) {
        axios.post('/api/gmb/preview/exif_template', { fields: state.imageCurrentExifs })
          .then(
            (success) => {
              commit('updatePreviewExifs', success.data)
            },
            (error) => {
              toastr.error(error.response.data.error || DEFAULT_ERROR_MESSAGE)
            }
          )
      },
      onSubmit({ state }) {
        if (Object.keys(state.imagePreviewErrors).length) {
          toastr.error('Please correct inputs first')
          document.body.scrollIntoView({ behavior: 'smooth' })
          return
        }

        const url = '/api/exif_templates'
        const data = new FormData()

        data.append('exif_template[title]', state.exifTemplate.title)
        Object.keys(state.imageCurrentExifs).forEach((key) => {
          data.append(`exif_template[exifs][${key}]`, state.imageCurrentExifs[key])
        })

        let request
        if (state.exifTemplate.id) {
          request = axios.patch(`${url}/${state.exifTemplate.id}`, data)
        } else {
          request = axios.post(url, data)
        }

        request
          .then(
            (res) => {
              toastr.success('EXIF Template successfully created.')
              Turbolinks.visit(res.data.path)
            },
            (err) => {
              toastr.error(err.response.data.error)
            }
          )
      }
    },
    mutations: {
      updateExifs(state, exifs) {
        state.exifTemplate.exifs = exifs
      },
      updatePreviewExifs(state, payload) {
        state.imagePreviewExifs = payload.fields
        state.imagePreviewErrors = payload.errors
      },
      updateTemplateTitle(state, title) {
        state.exifTemplate.title = title
      },
      changeCurrentExif(state, { identifier, value }) {
        state.imageCurrentExifs[identifier] = value
      }
    }
  })

  return store
}
