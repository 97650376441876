<template>
  <div class="range-date">
    <rangePreset :preset="preset" @setPreset="setPreset" />
    <div class="range-custom">
      <DatePicker
        mode="range"
        :max-date="maxDate"
        :min-date="minDate"
        v-model="range1"
        @input="setDateRanges" />
      <DatePicker v-if="insightsDateRanges.compare"
        mode="range"
        :max-date="maxDate"
        :min-date="minDate"
        v-model="range2"
        @input="setDateRanges" />
    </div>
    <checkbox
      :value="insightsDateRanges.compare"
      @input="onChange">
      Compare
    </checkbox>
    <a
      class="btn btn-link with-icon export-csv text-muted"
      data-download="true"
      :href="csvPath"
      :title="csvTitle"
      data-toggle="tooltip"
      target="_blank"
      :data-original-title="csvTitle">
      <i class="far fa-file-csv" />
      Export To CSV
    </a>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import rangePreset from './range_preset'
import DatePicker from '../../../components/datepicker'
import checkbox from '../../../components/checkbox'

export default {
  components: {
    checkbox,
    rangePreset,
    DatePicker
  },
  data() {
    return {
      preset: 'month',
      range1: null,
      range2: null
    }
  },
  computed: {
    ...mapGetters(['insightsDateRanges', 'csvPath', 'csvTitle', 'minDate']),
    maxDate() {
      return moment.utc().subtract(3, 'days').toDate()
    }
  },
  methods: {
    ...mapActions(['updateDateRanges']),
    onChange(value) {
      this.updateDateRanges({
        compare: value,
        ranges: this.insightsDateRanges.ranges
      })
    },
    setPreset({ preset, ranges }) {
      this.preset = preset
      this.range1 = [ranges[0].start, ranges[0].end]
      this.range2 = [ranges[1].start, ranges[1].end]
      this.updateDateRanges({
        compare: this.insightsDateRanges.compare,
        ranges
      })
    },
    setDateRanges() {
      this.preset = 'custom'
      this.updateDateRanges({
        compare: this.insightsDateRanges.compare,
        ranges: [{
          start: this.range1[0],
          end: this.range1[1]
        }, {
          start: this.range2[0],
          end: this.range2[1]
        }]
      })
    }
  }
}
</script>
