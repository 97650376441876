<template>
  <div :class="['form-field', customClasses, { warning, error }]">
    <slot name="caption" />
    <div class="search-wrap">
      <input type="text"
        ref="theInput"
        :disabled="isDisabled"
        :value="value"
        :placeholder="placeholder"
        @input="updateValue($event.target.value)">
      <button v-show="value && !isDisabled" type="button" class="btn-delete" @click="clearValue" />
    </div>
    <slot name="description" />
  </div>
</template>

<script>
export default {
  props: {
    value: { type: String },
    placeholder: { type: String },
    warning: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false },
    customClasses: { type: String }
  },
  watch: {
    isDisabled(newVal) {
      if (!newVal) this.$nextTick(() => { this.$refs.theInput.focus() })
    }
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value.trim())
    },
    clearValue() {
      this.$emit('input', '')
    }
  }
}
</script>
