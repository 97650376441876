import Vue from 'vue'
import ReviewsDashboard from 'vue_widgets/reviews_dashboard/index'

Styxie.Initializers.ReviewsDashboard = {
  index: (props) => {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-reviews-dashboard'),
        render: (h) => h(ReviewsDashboard, {
          props
        })
      })
    })
  }
}
