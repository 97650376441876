<template>
  <table ref="table"
    @wheel="scroll">
    <thead>
      <tr>
        <th>#</th>
        <th>Start day</th>
        <template v-if="windowWidth > 1440">
          <th>Due time</th>
          <th>Locations</th>
          <th>Media</th>
        </template>
        <template v-else>
          <th>Due</th>
          <th>Loc/Media</th>
        </template>
        <th>Status</th>
      </tr>
    </thead>
    <tbody ref="tableBody">
      <tr
        @click="goToScheduledItem(item.obfuscatedId)"
        v-for="(item, index) in filteredMediaScheduleList"
        :key="item.obfuscatedId"
        :class="{ failed: itemStatus(item) === 'Failed' }">
        <td>{{ index + 1 }}</td>
        <td>
          {{ windowWidth > 1440 ? item.startDayLong : item.startDayShort }}
        </td>
        <template v-if="windowWidth > 1440">
          <td>{{ item.days }} days</td>
          <td>{{ item.locationsCount }}</td>
          <td>{{ item.mediaCount }}</td>
        </template>
        <template v-else>
          <td>{{ item.days }} d.</td>
          <td>{{ `${item.locationsCount}/${item.mediaCount}` }}</td>
        </template>
        <td>{{ itemStatus(item) }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    filteredMediaScheduleList: {
      type: Array,
      default: () => []
    },
    windowWidth: { type: Number, default: 0 },
    itemStatus: { type: Function },
    goToScheduledItem: { type: Function },
    nextPage: { type: Function }
  },
  methods: {
    scroll() {
      this.nextPage()
    }
  }
}
</script>
