<template>
  <tr>
    <td column-caption="Profile Name">
      {{ row.profileName }}
    </td>
    <td column-caption="Permissions" class="column">
      <div class="d-flex flex-column gap-2">
        <div v-for="permissionGroup in row.permissions" class="d-flex" :key="permissionGroup.icon">
          <i :class="permissionGroup.icon" />
          <div class="d-flex flex-wrap gap-1 pl-4">
            <div v-for="({ name }, index) in permissionGroup.pages"
              :key="index"
              class="tag">
              <div class="tag-text">
                <span>{{ name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </td>
    <td column-caption="Last Sync At" class="text-nowrap">
      {{ row.syncLastAt }}
    </td>
    <td column-caption="Disconnected At" class="text-nowrap">
      {{ row.disconnectedAt }}
    </td>
    <td class="text-right">
      <a href="#" @click.prevent="disconnectFacebookAccount">
        <i class="far fa-trash text-danger" />
      </a>
    </td>
  </tr>
</template>

<script>
import axios from 'axios'
import { DEFAULT_ERROR_MESSAGE } from 'common/constants'

export default {
  props: {
    row: { type: Object, required: true }
  },
  methods: {
    disconnectFacebookAccount() {
      swal({
        text: 'Are you sure? Once deleted, you will not be able to recover this!',
        icon: 'warning',
        buttons: ['Cancel', 'Delete'],
        dangerMode: true
      }).then((result) => {
        if (result) {
          axios.delete(`/api/facebook_accounts/${this.row.id}`).then(() => {
            toastr.success('Facebook account successfully disconnected.')
            window.location.reload()
          }).catch(() => {
            toastr.error(DEFAULT_ERROR_MESSAGE)
          })
        }
      })
    }
  }
}
</script>
