import Vue from 'vue'
import AdminWidget from '../../vue_widgets/admin/subscription_cancelations/admin_widget.vue'

Styxie.Initializers.AdminSubscriptionCancelations = {
  index: () => {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-subscription-cancelations'),
        render: (h) => h(AdminWidget)
      })
    })
  }
}
