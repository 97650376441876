<template>
  <img @error="onError">
</template>

<script>
export default {
  props: {
    fallbackSrc: { type: String, required: false }
  },
  methods: {
    onError(e) {
      if (this.fallbackSrc) e.target.src = this.fallbackSrc
    }
  }
}
</script>
