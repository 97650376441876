<template>
  <div>
    <div class="form-group row align-items-center mb-3">
      <div class="col-12 col-sm-3 text-sm-right">
        Webhook URL
      </div>
      <div class="col-12 col-lg-4 col-md-6 col-sm-9 form-field">
        <input :disabled="isRequesting" type="text" v-model="webhookUrlModel">
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-3" />
      <div class="d-flex flex-column gap-2 mb-4 col-12 col-lg-4 col-md-6 col-sm-9">
        <p class="m-0">
          Select events that will trigger the webhook:
        </p>
        <span v-if="isNew" class="text-info">You will be able to test this webhook after creation</span>
        <div
          v-for="(eventToggle, eventType) in eventToggles"
          class="d-flex justify-content-between"
          :key="eventType">
          <CheckboxToggle v-model="eventToggle.active">
            <span class="pl-2">{{ eventToggle.text }}</span>
          </CheckboxToggle>
          <button
            v-if="!isNew"
            :disabled="isRequesting || !webhookUrlModel"
            type="button"
            class="btn btn-xs rounded"
            @click="testWebhook(eventType)">
            Test
          </button>
        </div>
        <p>
          You will receive a JSON payload every time any of the selected events are triggered in your organization.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-3" />
      <div class="col-12 col-lg-4 col-md-6 col-sm-9">
        <div class="d-flex justify-content-between">
          <div class="d-flex flex-wrap justify-content-between gap-3">
            <button :disabled="isRequesting" class="btn btn-primary rounded" @click="submit">{{ saveButtonText }}</button>
          </div>
          <div class="d-flex flex-wrap justify-content-between gap-3">
            <button :disabled="isRequesting" class="btn rounded" @click="$emit('close')">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosTransform from 'common/axios'
import toastr from 'toastr'
import { runSwal } from 'common/delete_with_swal'
import { copyToClipboardWithAnimation } from 'common/copy_to_clipboard'

import CheckboxToggle from 'vue_widgets/components/checkbox_toggle'

import {
  GEOGRID_CREATED,
  REVIEW_CREATED,
  AUDIT_REPORT_CREATED,
  AUDIT_REPORT_FAILED,
  LOCATION_UPDATED,
  LOCATION_VERIFIED,
  LOCATION_REACTIVATED,
  LOCATION_DEACTIVATED,
  GOOGLE_ACCOUNT_DISCONNECTED,
  mappings
} from './webhook_events'

export default {
  components: {
    CheckboxToggle
  },
  props: {
    webhook: { type: Object, required: false, default: null },
    webhooksApiUrl: { type: String, required: true }
  },
  data() {
    return {
      webhookUrlModel: this.webhook?.url,
      isRequesting: false,
      eventToggles: {
        [GEOGRID_CREATED]: {
          active: this.webhook?.events.includes(GEOGRID_CREATED),
          text: mappings[GEOGRID_CREATED]
        },
        [AUDIT_REPORT_CREATED]: {
          active: this.webhook?.events.includes(AUDIT_REPORT_CREATED),
          text: mappings[AUDIT_REPORT_CREATED]
        },
        [AUDIT_REPORT_FAILED]: {
          active: this.webhook?.events.includes(AUDIT_REPORT_FAILED),
          text: mappings[AUDIT_REPORT_FAILED]
        },
        [REVIEW_CREATED]: {
          active: this.webhook?.events.includes(REVIEW_CREATED),
          text: mappings[REVIEW_CREATED]
        },
        [LOCATION_UPDATED]: {
          active: this.webhook?.events.includes(LOCATION_UPDATED),
          text: mappings[LOCATION_UPDATED]
        },
        [LOCATION_VERIFIED]: {
          active: this.webhook?.events.includes(LOCATION_VERIFIED),
          text: mappings[LOCATION_VERIFIED]
        },
        [LOCATION_DEACTIVATED]: {
          active: this.webhook?.events.includes(LOCATION_DEACTIVATED),
          text: mappings[LOCATION_DEACTIVATED]
        },
        [LOCATION_REACTIVATED]: {
          active: this.webhook?.events.includes(LOCATION_REACTIVATED),
          text: mappings[LOCATION_REACTIVATED]
        },
        [GOOGLE_ACCOUNT_DISCONNECTED]: {
          active: this.webhook?.events.includes(GOOGLE_ACCOUNT_DISCONNECTED),
          text: mappings[GOOGLE_ACCOUNT_DISCONNECTED]
        }
      },
      webhookTestPayload: null
    }
  },
  methods: {
    submit() {
      if (!this.webhookUrlModel) {
        toastr.warning('Please specify the Webhook URL and try again.')
        return
      }
      if (!this.newSelectedEvents.length) {
        toastr.warning('You need to enable at least one event')
        return
      }
      this.isRequesting = true
      this.saveWebhook()
        .then((response) => {
          toastr.success('Webhook URL is saved successfully')
          if (!this.isNew) {
            this.$emit('save')
          } else {
            this.$emit('newWebhookCreated', response.data)
          }
        })
        .catch((res) => {
          this.handleError(res.response)
        })
        .finally(() => {
          this.isRequesting = false
        })
    },
    saveWebhook() {
      const method = this.isNew ? 'post' : 'patch'
      const url = this.isNew ? this.webhooksApiUrl : `${this.webhooksApiUrl}/${this.webhook.id}`
      return axios[method](url, {
        webhook: {
          url: this.webhookUrlModel,
          events: this.newSelectedEvents
        }
      }, axiosTransform)
    },
    testWebhook(eventType) {
      if (this.isNew) return
      if (!this.webhookUrlModel) {
        toastr.error('Please specify the Webhook URL and try again.')
        return
      }

      this.isRequesting = true
      this.saveWebhook()
        .then(() => {
          axios.put(`${this.webhooksApiUrl}/${this.webhook.id}/test`, {
            type: eventType
          })
            .then((response) => {
              this.webhookTestPayload = JSON.stringify(response.data, null, 2)

              runSwal(() => {}, {
                title: 'Webhook successfully sent',
                content: `
                  <p>Webhook payload:</p>
                  <div class="webhook-payload-modal__code">
                    <pre class="text-left">${this.webhookTestPayload}</pre>
                    <button class="button-copy">
                      <i class="far fa-copy" ></i>
                    </button>
                  </div>
                `,
                buttons: [null, 'OK'],
                dangerMode: false,
                icon: 'success',
                className: 'webhook-payload-modal'
              }, (contentNode) => {
                contentNode.querySelector('.button-copy').addEventListener('click', this.copyTestPayload)
              })
            })
            .catch(({ response }) => {
              this.handleError(response)
            })
            .finally(() => {
              this.isRequesting = false
            })
        })
        .catch((response) => {
          this.handleError(response)
          this.isRequesting = false
        })
    },
    handleError(response) {
      if (response.headers['content-type'].includes('text/html')) return

      const { text: messages } = response.data
      if (Array.isArray(messages)) {
        messages.forEach((message) => {
          toastr.error(`${this.capitalize(message)}`)
        })
      } else {
        toastr.error(`${this.capitalize(messages)}`)
      }
    },
    capitalize(input) {
      return input.charAt(0).toUpperCase() + input.slice(1)
    },
    convertCamelCaseToWords(input) {
      if (input === 'test') return ''
      return `${input.replace(/([a-z])([A-Z])/g, '$1 $2')} `
    },
    copyTestPayload(e) {
      copyToClipboardWithAnimation({ element: e.currentTarget, textToCopy: this.webhookTestPayload })
    }
  },
  computed: {
    newSelectedEvents() {
      return Object.keys(this.eventToggles)
        .filter((eventType) => this.eventToggles[eventType].active)
    },
    saveButtonText() {
      if (this.isNew) return 'Create webhook'
      return 'Save webhook'
    },
    isNew() {
      return !this.webhook
    }
  }
}
</script>
