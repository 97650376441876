export default {
  data: () => ({
    sectionInView: null
  }),
  mounted() {
    this.intersections = {}
    this.observer = new IntersectionObserver(this.updateSectionInView, {
      threshold: [0, 0.1, 0.25, 0.5, 0.75, 1]
    })
    const { reportSections } = this.$refs

    reportSections?.forEach((anchor) => {
      this.observer.observe(anchor.$el)
    })
  },
  destroyed() {
    this.observer.discoonect()
    this.observer = null
  },
  methods: {
    scrollToSection(sectionKey) {
      const OFFSET = 20
      const el = this.$refs.reportSections?.find((node) => node.$el.dataset.sectionKey === sectionKey).$el
      const y = el.getBoundingClientRect().top + window.scrollY - this.$refs.navigation.$el.clientHeight - OFFSET

      window.scrollTo({ top: y, behavior: 'smooth' })
    },
    updateSectionInView(entries) {
      entries.forEach((entry) => {
        this.intersections[entry.target.dataset.sectionKey] = entry.intersectionRatio
      })
      const viewedSectionKeys = Object.keys(this.intersections)
      const getDominantSection = (acc, sectionKey) => {
        if (this.intersections[acc] > this.intersections[sectionKey]) return acc
        return sectionKey
      }
      this.sectionInView = viewedSectionKeys.reduce(getDominantSection, viewedSectionKeys[0])
    }
  }
}
