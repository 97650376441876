import Vue from 'vue'
import axios from 'axios'
import BulkQna from '../../../vue_widgets/gmb/bulk_qna/index'
import createStore from '../../../vue_widgets/gmb/bulk_qna/store'
import { runSwal } from '../../../common/delete_with_swal'

Styxie.Initializers.GmbQuestionsBulkQna = {
  index(prms) {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-bulk-qna'),
        store: createStore(prms.bulks),
        render: (h) => h(BulkQna)
      })
    })
  },
  show(prms) {
    $(() => {
      $('.js-custom-delete').click((e) => {
        e.preventDefault()
        runSwal(() => {
          axios.delete(`/api/gmb/locations/${prms.locationId}/bulk_qna/${prms.bulkId}`)
            .then(() => {
              window.location.href = `/gmb/locations/${prms.locationId}/questions/bulk_qna/`
            })
        })
      })
    })
  }
}
