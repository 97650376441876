<template>
  <div>
    <b-form @submit.prevent='createGeogrid' class='mb-2'>
      <b-row>
        <b-col md=6 sm=12>
          <identifyBusiness
            ref='identifyBusiness'
            :all-locations='allLocations'
            :gmb-locations-path='gmbLocationsPath'
            select2prefix='new-geogrid' />
        </b-col>
        <b-col md=3 sm=12>
          <div :class='["form-field", {warning: showNearKeywordHint || showHotelKeywordHint}]'>
            <span class='caption'>Search term:</span>
            <input
              v-model='searchTerm'
              ref='searchTerm'
              type='text'
              placeholder='Enter a search term'
              required>
          </div>
          <b-popover
            show
            v-if='showNearKeywordHint || showHotelKeywordHint'
            placement='bottom'
            :target='$refs.searchTerm'
            triggers='click hover'>
            <div v-if="showNearKeywordHint" class='p-3'>
              Don’t use hyper-local search terms like <i>‘near me’</i> or <i>‘close by’</i>
              as they often force Google to change the search locale. <br>Search results consistency not assured.
            </div>
            <div v-if="showHotelKeywordHint" class='p-3'>
              If you use hotel and rental-related search terms, Google can give you search results that are not related to GMB,
              thus they won't be listed on the Geogrid map.
            </div>
          </b-popover>
        </b-col>
        <b-col sm=2>
          <languageResults class='mt-2' ref='languageResults' />
        </b-col>
        <b-col sm=1 class='pl-0'>
          <div class='form-field'>
            <span class="caption">&zwnj;</span>
            <button class='btn btn-primary w-100' type='submit'>
              <i class='far fa-search' />
            </button>
          </div>
        </b-col>
      </b-row>
    </b-form>
    <tags :show-edit-buttons='true' ref='tags' />
    <Checkbox class='float-right' v-model='emailNotification'>Email</Checkbox>
  </div>
</template>

<script>

import toastr from 'toastr'
import axios from 'axios'
import { mapState, mapMutations, mapActions } from 'vuex'
import snakeCaseKeys from 'snakecase-keys'
import identifyBusiness from '../attrs_components/business'
import tags from '../components/tags'
import Checkbox from '../../components/checkbox'
import languageResults from '../attrs_components/language'
import { DEFAULT_ERROR_MESSAGE, NOT_ENOUGH_GEOGRID_CREDITS } from '../../../common/constants'
import geogridAttrsPresetsDefined from '../../../common/gmb/geogrids/helpers'

export default {
  name: 'NewGeogrid',
  components: {
    identifyBusiness, Checkbox, languageResults, tags
  },
  props: {
    keysPath: String,
    placesApiKeys: Array,
    gmbLocationsPath: String,
    allLocations: Array,
    apiGeogridsPath: String
  },
  data() {
    return {
      searchTerm: '',
      showNearKeywordHint: false,
      showHotelKeywordHint: false
    }
  },
  watch: {
    newGeogrid: {
      handler(newVal) {
        this.$refs.tags.renderTags(newVal.tags)
        this.$refs.languageResults.language = newVal.attrs.localLanguageEnabled ? 'Local' : 'Global'
        this.$refs.identifyBusiness.renderForm(newVal.attrs)
        this.searchTerm = newVal.searchTerm
        this.emailNotification = newVal.attrs.shouldNotify
      },
      deep: true
    },
    searchTerm(val) {
      if (val && (val.includes('near') || val.includes('close') || val.includes('local'))) this.showKeywordHint = true
      else this.showKeywordHint = false
      if (val && (val.includes('near') || val.includes('close') || val.includes('local'))) {
        this.showNearKeywordHint = true
      } else if (val && (val.includes('hotel') || val.includes('rent') || val.includes('booking') || val.includes('motel'))) {
        this.showHotelKeywordHint = true
      } else {
        this.showNearKeywordHint = false
        this.showHotelKeywordHint = false
      }
    }
  },
  computed: {
    ...mapState(['newGeogrid']),
    emailNotification: {
      get() { return this.$store.state.shouldNotify },
      set(val) { this.$store.commit('shouldNotify', val) }
    }
  },
  methods: {
    ...mapMutations(['showLoader', 'hideLoader']),
    ...mapActions(['goTo']),
    createGeogrid() {
      const data = {
        tags: this.$refs.tags.tags,
        geogrid: {
          searchTerm: this.searchTerm
        },
        attrsParams: {
          ...this.$refs.identifyBusiness.getBusinessInfo(),
          gridCenterLat: this.$store.state.mapCenterLat,
          gridCenterLng: this.$store.state.mapCenterLng,
          gridPointDistance: this.$store.state.distance,
          gridDistanceMeasure: this.$store.state.distanceMeasure,
          gridSize: this.$store.state.gridSize,
          localLanguageEnabled: this.$refs.languageResults.languageFlag,
          placesDataSource: 'crawl',
          shouldNotify: this.emailNotification,
          disabledPoints: Array.from(this.$store.state.disabledPoints)
        }
      }
      if (!data.attrsParams.businessName) {
        toastr.error('Please provide <b> business </b> to create geogrid.')
      } else if (!data.attrsParams.gridCenterLat || !data.attrsParams.gridCenterLng) {
        toastr.error("We can't create geogrid without coordinates', 'Please, use option 'Sticky grid'")
      } else if (data.attrsParams.dataEngineFailed) {
        toastr.error('Please provide <b> proper data engine </b> to create geogrid.')
      } else {
        this.showLoader()
        axios.post(this.apiGeogridsPath, snakeCaseKeys(data, { deep: true })).then((response) => {
          if (response.data.id) {
            this.onGeogridSubmited(data)
          } else {
            toastr.error(DEFAULT_ERROR_MESSAGE)
          }
        }).catch((error) => {
          if (error.response.data.error === 'not_enough_credits') {
            toastr.error(NOT_ENOUGH_GEOGRID_CREDITS, 'Not enough geogrid credits')
          } else if (error.response.data.error === 'broken_key') {
            toastr.error('Selected Places API key is invalid')
          } else if (error.response.data.error === 'invalid_geogrid') {
            toastr.error('Try to select business to track again manually.', "We couldn't fetch business data")
          } else {
            toastr.error(DEFAULT_ERROR_MESSAGE)
          }
        }).finally(() => {
          this.hideLoader()
        })
      }
    },
    onGeogridSubmited(data) {
      toastr.success('It will take a few minutes, please check back a bit later', 'We started preparing your report')
      this.$emit('reloadHistory')
      geogridAttrsPresetsDefined(data.attrsParams)
      this.cleanForm()
      this.goTo('index')
    },
    cleanForm() {
      this.$refs.identifyBusiness.clearForm()
      this.searchTerm = ''
      this.$refs.tags.renderTags([])
    }
  }
}
</script>
