<template>
  <div>
    <textarea
      ref="textarea"
      class="form-control"
      :value="value"
      @input.prevent="onInput"
      v-bind="$attrs" />

    <span :class="['float-right', { 'text-danger': currentCount > limit }]">
      {{ currentCount }} / {{ limit }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: String },
    limit: { type: Number, required: true },
    inputAttrs: { type: Object, default() { return {} } }
  },
  computed: {
    currentCount() {
      return this.value.length
    }
  },
  methods: {
    onInput(e) {
      this.$refs.textarea.style.height = `${this.$refs.textarea.scrollHeight + 4}px`
      this.$emit('change', e.target.value.replace(/\r\n/g, '\n'))
    }
  }
}
</script>
