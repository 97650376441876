<template>
  <div class="card-spot-info" :class="{ selected: place.selected }">
    <div class="card-spot-info__top">
      <span class="card-spot-info__position">{{ place.order }}</span>
      <img
        class="card-spot-info__image"
        :src="place.imageSrc || require('no-image.png')"
        :alt="`Image of the '${place.name} location'`">
      <div class="card-spot-info__text">
        <a class="card-spot-info__name" :href="place.mapsUrl" target="_blank">{{ place.name }}</a>
        <span class="card-spot-info__formatted-address">{{ placeFormattedAddress }}</span>
      </div>
    </div>
    <div class="card-spot-info__categories" v-if="place.categories && place.categories.length">
      <span v-for="(category, index) in place.categories" :key="`${category}${index}`">
        {{ category }}
      </span>
    </div>
    <div class="card-spot-info__bottom">
      <div class="card-spot-info__metrics">
        <span
          v-for="({name, value}, index) in metrics"
          :key="`metric-${index}`"
          class="card-spot-info__metric">
          <span>{{ name }}</span>
          <b>{{ value }}</b>
        </span>
      </div>
      <div class="card-spot-info__geogrid-preview geogrid-preview-wrapper cursor-pointer"
        :id="`change-report-source-${place.placeId}`"
        @click="changeReportSource(place.placeId)">
        <b-tooltip :target="`change-report-source-${place.placeId}`" triggers="hover">
          Show the Business Ranks on Geogrid
        </b-tooltip>
        <GeogridPreview :ranks="place.geogrid.ranks" />
      </div>
    </div>
  </div>
</template>

<script>
import GeogridPreview from '../components/geogrid_preview'

export default {
  components: {
    GeogridPreview
  },
  props: {
    place: Object
  },
  data() {
    return {
      selected: false
    }
  },
  watch: {
    'place.selected'(val) {
      if (val) {
        this.$el.scrollIntoView({ behavior: 'smooth' })
      }
    }
  },
  computed: {
    placeFormattedAddress() {
      return this.place.address ?? 'Service Area Business'
    },
    metrics() {
      const NA = 'N/A'
      const round = (num) => Math.round(num * 100) / 100
      const format = (num) => (num ? round(num) : NA)
      return [
        {
          name: 'AGR',
          value: format(this.place.geogrid.agr)
        },
        {
          name: 'ATGR',
          value: format(this.place.geogrid.atgr)
        },
        {
          name: 'SolV',
          value: this.place.geogrid.solv ? `${(round(this.place.geogrid.solv) * 100).toFixed(0)}%` : NA
        }
      ]
    }
  },
  methods: {
    changeReportSource() {
      this.$emit('showCompetitorGeogrid', { competitor: this.place })
    }
  }
}
</script>
